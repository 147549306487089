import styled from "styled-components";
import { Section } from "../../GlobalStyles";

export const Container = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 20px;
`;

export const PrimaryWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddWalletContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
