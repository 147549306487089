import { useQuery } from "@tanstack/react-query";
import { followersLoader } from "../loader";

export const useFollowerQuery = ({ id, enabled = true }) => {
  return useQuery({
    queryKey: ["followers", id],
    queryFn: async () => {
      const data = await followersLoader({ params: { id } });

      if (data.error) {
        console.error(data.error);
        throw new Error(data.error);
      }

      return data.followers;
    },
    enabled: !!id && enabled,
    refetchOnMount: true,
  });
};
