import Cropper from "react-easy-crop";
import TopNav from "../TopNav";
import {
  Container,
  CropperContainer,
  ToolsContainer,
} from "./ImageEditor-Styles";
import { useImageEditorStore } from "./imageEditorStore";
import { useState } from "react";
import { getCroppedImg } from "../../utils/canvas-utils";
import {
  BodyText,
  ButtonRect,
  InvisibleInputImage,
  Title,
} from "../../GlobalStyles";
import useGlobalModal from "../../hooks/useGlobalModal";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";
import UploadMediaModal from "../Modal/UploadMediaModal";
import { theme } from "../../utils/theme";
import { BetaIcon } from "../BetaIcon";

export default function ImageEditor() {
  const { image, reset, setImageToBeUploaded } = useImageEditorStore();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  const { handleMediaChange, loading } = useMomentPhotoUpload();

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const resetEditor = () => {
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );

      setCroppedImage(croppedImage);
      const fetchedImage = await fetch(croppedImage);

      const blob = await fetchedImage.blob();

      const file = new File([blob], "image", { type: "image/jpeg" });

      setImageToBeUploaded(file);

      toggleSheetModal();
      setSheetModalContent(<UploadMediaModal media={file} />);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container>
      <TopNav
        type={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Title weight="400">Image Editor</Title>
            <BetaIcon />
          </div>
        }
        onBack={reset}
      />
      <CropperContainer>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={9 / 16}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit="cover"
        />
      </CropperContainer>
      <ToolsContainer>
        <ToolsContainer
          style={{ gap: "12px", flexDirection: "row", flex: "0", padding: 0 }}
        >
          <ButtonRect style={{ width: "fit-content" }} onClick={resetEditor}>
            <BodyText color={theme.colors.red} weight="600">
              Reset
            </BodyText>
          </ButtonRect>
          <ButtonRect style={{ width: "fit-content" }}>
            <BodyText weight="600">Change Photo</BodyText>
            <InvisibleInputImage
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                handleMediaChange(e, "image");
                setCrop({ x: 0, y: 0 });
                setRotation(0);
                setZoom(1);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
              disabled={loading}
            />
          </ButtonRect>
        </ToolsContainer>
        <ButtonRect
          onClick={showCroppedImage}
          style={{
            width: "fit-content",
            background: theme.colors.greyBtn,
            border: "none",
          }}
        >
          <BodyText weight="600">Upload Photo</BodyText>
        </ButtonRect>
      </ToolsContainer>
    </Container>
  );
}
