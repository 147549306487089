import { styled } from "styled-components";
import { StyledPrimaryButton } from "../../styles/modal.styles";
import { BodyText, HeaderL } from "../../GlobalStyles";
import PropTypes from "prop-types";
import useGlobalModal from "../../hooks/useGlobalModal";
import { theme } from "../../utils/theme";
import { useState } from "react";

const WrapperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100dvh;
  gap: 60px;
  text-align: center;
  margin-bottom: 60px;
  padding: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
  text-align: center;
  margin-bottom: 60px;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const BadgeIcon = styled.img`
  height: 144px;
  width: 144px;
  object-fit: cover;
  border-radius: 50%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const Button = styled(StyledPrimaryButton)`
  width: 20%;
  cursor: pointer;
`;

export default function BadgeAttainment({ badges }) {
  const { toggleCenterModal } = useGlobalModal();
  const [currentBadge, setCurrentBadge] = useState(badges[0]);

  const handleNextBadge = () => {
    const nextBadge = badges[badges.indexOf(currentBadge) + 1];
    setCurrentBadge(nextBadge);
  };

  const handleClose = () => {
    toggleCenterModal();
  };

  const handleClick = () => {
    if (badges.indexOf(currentBadge) === badges.length - 1) handleClose();
    else handleNextBadge();
  };

  return (
    <WrapperContainer>
      <Wrapper>
        <ContentContainer>
          <HeaderL>You earned a new badge!</HeaderL>
          <BadgeIcon src={currentBadge?.image} alt="image" />
          <BodyText color={theme.colors.grey}>
            <HeaderL>{currentBadge?.title}</HeaderL>
            {currentBadge?.description}
          </BodyText>
        </ContentContainer>
      </Wrapper>
      <ButtonContainer>
        <Button onClick={handleClick}>
          {badges.indexOf(currentBadge) === badges.length - 1
            ? "Close"
            : "Next"}
        </Button>
      </ButtonContainer>
    </WrapperContainer>
  );
}

BadgeAttainment.propTypes = {
  badges: PropTypes.array.isRequired,
};
