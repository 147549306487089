import styled from "styled-components";

export const TrimmerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TimelineContainer = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 4px;
`;

export const ThumbnailTrack = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  position: relative;
  align-items: center;

  img {
    height: 100%;
    width: 100px;
    object-fit: cover;
  }
`;

export const ThumbnailItem = styled.div`
  position: absolute;
  height: 80px;
  width: 80px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const TrimmerHandle = styled.div`
  position: absolute;
  top: 0;
  width: 30px;
  height: 100%;
  background-color: ${({ theme, isNotAllowed }) =>
    isNotAllowed ? theme.colors.red : theme.colors.green};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 0.9)};
`;

export const MarksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;
