import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Section } from "../../GlobalStyles";
import FanFollowers from "../../components/Followers/FanFollowers";
import TopNav from "../../components/TopNav";
import { NavBarTypes } from "../../constants/constants";
import ErrorPage from "../Error";
import { useFollowerQuery } from "./hooks/followersQueries";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export default function Followers() {
  const { id } = useParams();
  const { data: followers, error, isLoading } = useFollowerQuery({ id });

  if (error) return <ErrorPage />;

  return (
    <Container>
      <TopNav type={NavBarTypes.FOLLOWERS} />
      <FanFollowers fans={followers} isLoading={isLoading} />
    </Container>
  );
}
