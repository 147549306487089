import styled from "styled-components";
import PropTypes from "prop-types";
import { useRef, useState } from "react";

const CarouselContainer = styled.div`
  padding: 0 10%;
  gap: 25px;
  z-index: 2;
  height: 100%;
  width: 100vw;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default function MomentCardsCarousel({ children }) {
  const [currentItem, setCurrentItem] = useState(0);
  const carouselRef = useRef(null);

  // Handle scrolling or interaction event
  const handleScroll = () => {
    const container = carouselRef.current;
    const scrollLeft = container.scrollLeft;
    // Calculate the current item based on scroll position
    const itemWidth = container.offsetWidth;
    const newItem = Math.round(scrollLeft / itemWidth);
    
    if (newItem !== currentItem) {
      setCurrentItem(newItem);
    }
  };
  return (
    <CarouselContainer 
      ref={carouselRef}
      onScroll={handleScroll}
    >
      {children}
    </CarouselContainer>
  );
}

MomentCardsCarousel.propTypes = {
  children: PropTypes.node.isRequired,
};