import PropTypes from "prop-types";
import { BodyText, Button, ButtonL, ButtonRect } from "../../GlobalStyles";
import Bell from "../../assets/svg/bell.svg?react";
import BellCheck from "../../assets/svg/bell-check.svg?react";
import styled from "styled-components";
import { BUTTON_SIZES } from "../../constants/constants";
import { theme } from "../../utils/theme";

const { colors } = theme;

export const SubButton = styled(Button)`
  background: ${({ isSubscribed, theme }) =>
    isSubscribed ? theme.colors.blackTransparent : theme.colors.greyBtn};
  border: ${({ isSubscribed, theme }) =>
    isSubscribed ? `1px solid ${theme.colors.green}` : "1px solid transparent"};
`;

const SubButtonL = styled(ButtonL)`
  background: ${({ isSubscribed, theme }) =>
    isSubscribed ? theme.colors.blackTransparent : theme.colors.greyBtn};
  border: ${({ isSubscribed, theme }) =>
    isSubscribed ? `1px solid ${theme.colors.green}` : "1px solid transparent"};
`;

const SubButtonWide = styled(ButtonRect)`
  background: ${({ isSubscribed, theme }) =>
    isSubscribed ? theme.colors.blackTransparent : theme.colors.greyBtn};
  border: ${({ isSubscribed, theme }) =>
    isSubscribed ? `1px solid ${theme.colors.green}` : "1px solid transparent"};
  padding: 0px 0px;
  width: 122px;
  height: 32px;
  border-radius: 10px;
  min-height: 32px;
`;

export default function SubscribeButton({
  isSubscribed,
  handleClick,
  size = BUTTON_SIZES.MEDIUM,
}) {
  if (size === BUTTON_SIZES.MEDIUM) {
    return (
      <SubButton onClick={handleClick} isSubscribed={isSubscribed}>
        {isSubscribed ? (
          <BellCheck height={14} width={14} stroke={colors.green} />
        ) : (
          <Bell height={14} width={14} stroke="white" />
        )}
      </SubButton>
    );
  }
  if (size === BUTTON_SIZES.LARGE) {
    return (
      <SubButtonL onClick={handleClick} isSubscribed={isSubscribed}>
        {isSubscribed ? (
          <BellCheck height={14} width={14} stroke={colors.green} />
        ) : (
          <Bell height={14} width={14} stroke="white" />
        )}
      </SubButtonL>
    );
  }

  if (size === BUTTON_SIZES.WIDE) {
    return (
      <SubButtonWide onClick={handleClick} isSubscribed={isSubscribed}>
        {isSubscribed ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <BellCheck height={14} width={14} stroke={colors.green} />
            <BodyText
              style={{
                fontSize: 12,
              }}
            >
              Subscribed
            </BodyText>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Bell height={14} width={14} stroke="white" />
            <BodyText
              style={{
                fontSize: 12,
              }}
            >
              Subscribe
            </BodyText>
          </div>
        )}
      </SubButtonWide>
    );
  }
}

SubscribeButton.propTypes = {
  isSubscribed: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  size: PropTypes.string,
};
