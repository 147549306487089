import styled from "styled-components";

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  filter: ${({ blurred }) => (blurred ? "blur(10px) brightness(70%)" : "none")};
  pointer-events: ${({ blurred }) => (blurred ? "none" : "auto")};
  padding: ${({ isRetro }) => (isRetro ? "0 16px 20px" : "0 20px 16px 20px")};
  margin-top: ${({ isRetro }) => (isRetro ? "16px" : "0")};
  flex: 1;
`;

export const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`;
