import styled from "styled-components";
import { Section } from "../../GlobalStyles";

export const ArtistSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export const ArtistDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 16px;
`;

export const ArtistNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
