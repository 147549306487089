import { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import { theme } from "../../utils/theme";
import { isCinnyAuthenticated, isCinnySupported } from "../../utils/cinny-utils";
import { Section } from "../../GlobalStyles";
// import Cinny from "remoteApp/App";
// import CinnySettings from "remoteApp/CinnySettings";
import { configClass, varsClass } from "folds";
import ErrorPage from "../../components/ErrorPage";

const ChatSection = styled(Section)`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "0")};
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  color: white;
  gap: 0px;
  font-family: ${theme.fontStyles.suisse};
  position: relative;  
  padding: ${({ padding }) => (padding ? padding : "0")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "normal")};
  flex: none;  

  & .client-container {
    flex: none;
    height: 95vh;
    flex-grow: 1;
    overflow: auto;

    & .room-view__content-wrapper {
      padding-bottom: 10px;
    }
  }

  & .loading-display {
    top: 36px; // based on RoomBanner's natural height
    height: calc(100% - 36px); // based on RoomBanner's natural height
  }
`;

let CinnyChat;
if(isCinnySupported()) {
  import('../../utils/federated-cinny-utils').then((module) => {
     CinnyChat = module.CinnyChat     
     module.MatrixCinnySettings.applyTheme();
  });        
}

function Chat() {
  const sectionRef = useRef();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let cinnyUserState = localStorage.getItem('cinny_user_id')
  let cinnyUserTokenState = localStorage.getItem('cinny_access_token')

  useEffect(() => { 
    if (window) document.body.classList.add(configClass, varsClass);

    if (isCinnyAuthenticated() && !isLoggedIn) setIsLoggedIn(true);
  }, [isLoggedIn]);

  const onResize = el => {
    const visualHeight = window.visualViewport.height;
    el.style.height = `${visualHeight}px`;
    
    const client = el.querySelector('.client-container');
    if (client) client.style.height = `${visualHeight - 40}px`;
  };

  useEffect(() => {
    const chatSection = sectionRef?.current;
    if (chatSection && window) {
      const visualHeight = window.visualViewport.height;
      chatSection.style.height = `${visualHeight}px`;

      window.addEventListener('resize', () => onResize(chatSection));
    }
    return () => {
      window.removeEventListener('resize', () => onResize(chatSection));
    };
  }, [sectionRef]);

  if(!isCinnySupported()) {
    return (
      <ErrorPage 
        errorMessage={"Woops! Looks like you have an outdated browser or operating system. Please update to the latest version to access chat"} 
      />
    )
  }

  if(!isCinnyAuthenticated()) {
    return (
      <ErrorPage 
        errorMessage={"Woops! Looks like the room isn't ready yet for this event. You can come back later!"} 
      />
    );
  }

  return (
      // <ChatSection className="chat-section" ref={sectionRef}>
        <CinnyChat />
      // </ChatSection>
    );      
}

export default Chat;
