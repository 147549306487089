import { useQuery } from "@tanstack/react-query";
import { fetchSingleCollectible } from "../../api/collectibles";

export const useCollectibleQuery = ({ userId, collectibleId, type = 'airdrop' }) => {
  return useQuery({
    queryKey: ["collectible", userId, collectibleId, type],
    queryFn: () => {
      return fetchSingleCollectible(userId, collectibleId, type);
    },
    refetchInterval: 1000 * 60 * 4, // Refetch events every 1 min,
  });
};
