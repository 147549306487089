import PropTypes from "prop-types";
import styled from "styled-components";
import { Section } from "../GlobalStyles";
import Spinner from "./Spinner";

const LoadingSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ fullscreen }) => (fullscreen ? "100vh" : "auto")};
`;

export default function LoadingScreen({ fullScreen }) {
  return (
    <LoadingSection fullscreen={fullScreen ? 1 : 0}>
      <Spinner />
    </LoadingSection>
  );
}

LoadingScreen.propTypes = {
  fullScreen: PropTypes.bool,
};
