export const CheckInDispatchEvents = {
  SET_CHECK_IN_ALREADY: "SET_CHECK_IN_ALREADY",
  SET_CHECKED_IN: "SET_CHECKED_IN",
  SET_CURRENT_EVENT: "SET_CURRENT_EVENT",
  SET_ELIGIBLE: "SET_ELIGIBLE",
  SET_AI_IMAGE: "SET_AI_IMAGE",
  SET_SELECTING_MOMENTS: "SET_SELECTING_MOMENTS",
  SET_PERFORMANCES: "SET_PERFORMANCES",
  SET_SELECTED_PERFORMANCES: "SET_SELECTED_PERFORMANCES",
  SET_CURRENT_CHECK_IN: "SET_CURRENT_CHECK_IN",
  SET_MOMENTS_COLLECTED: "SET_MOMENTS_COLLECTED",
  SET_ERRORS: "SET_ERRORS",
  SET_INELIGIBILITY: "SET_INELIGIBILITY",
  SET_IS_TOO_FAR: "SET_IS_TOO_FAR",
  SET_HAS_MOMENT_EXISTED: "SET_HAS_MOMENT_EXISTED",
  SET_NEARBY: "SET_NEARBY",
  SET_LOADING: "SET_LOADING",
  SET_RESET: "SET_RESET",
  SET_SUCCESSFULLY_CLAIMED_MOMENT: "SUCCESSFULLY_CLAIMED_MOMENT",
  SET_SUCCESSFULLY_EARNED_XP: "SUCCESSFULLY_EARNED_XP",
  SET_EARNED_XP: "EARNED_XP",
  SET_MOMENT_NEEDS_LOGIN: "SET_MOMENT_NEEDS_LOGIN",
  SUCCESSFULLY_CHECKED_IN: "SUCCESSFULLY_CHECKED_IN",
  SUCCESSFULLY_ADDED_MOMENT: "SUCCESSFULLY_ADDED_MOMENT",
  SET_RESET_EXCEPT_CURRENT_EVENT: "SET_RESET_EXCEPT_CURRENT_EVENT",
};

export const LocationDispatchEvents = {
  DENIED: "DENIED",
  HAS_PERMISSION: "HAS_PERMISSION",
  NOT_SUPPORTED: "NOT_SUPPORTED",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_LOCATION: "SET_LOCATION",
  SET_COUNTRY: "SET_COUNTRY",
  SET_CITY: "SET_CITY",
  SET_PERMISSION_STATE: "SET_PERMISSION_STATE",
  TOGGLE_LOADING: "TOGGLE_LOADING",
  RESET_LOCATION: "RESET_LOCATION",
  SKIP_LOCATION: "SKIP_LOCATION",
};

export const UserDispatchEvents = {
  SET_LOGGED_IN_USER: "SET_LOGGED_IN_USER",
  SET_SESSION: "SET_SESSION",
};

export const GlobalModalDispatchEvents = {
  TOGGLE_SHEET_MODAL: "TOGGLE_SHEET_MODAL",
  SET_SHEET_MODAL_CONTENT: "SET_SHEET_MODAL_CONTENT",
  OPEN_SHEET_MODAL: "OPEN_SHEET_MODAL",
  CLOSE_SHEET_MODAL: "CLOSE_SHEET_MODAL",
  TOGGLE_CENTER_MODAL: "TOGGLE_CENTER_MODAL",
  OPEN_CENTER_MODAL: "OPEN_CENTER_MODAL",
  CLOSE_CENTER_MODAL: "CLOSE_CENTER_MODAL",
  SET_CENTER_MODAL_CONTENT: "SET_CENTER_MODAL_CONTENT",
  TOGGLE_FULL_MODAL: "TOGGLE_FULL_MODAL",
  SET_FULL_MODAL_CONTENT: "SET_FULL_MODAL_CONTENT",
};

export const MomentUploadDispatchEvents = {
  SET_FAN_MEDIA: "SET_FAN_MEDIA",
  SET_MOMENT: "SET_MOMENT",
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  SET_RESET: "SET_RESET",
};

export const BadgeAttainmentEvents = {
  BADGE_ATTAINMENT_START: "BADGE_ATTAINMENT_START",
  BADGE_ATTAINMENT_NEXT: "BADGE_ATTAINMENT_NEXT",
  BADGE_ATTAINMENT_DONE: "BADGE_ATTAINMENT_DONE",
};

export const AccountKitDispatchEvents = {
  SET_ALCHEMY_PROVODER: "SET_ALCHEMY_PROVIDER",
  SET_ERROR: "SET_ERROR",
  SET_TXN_HASH: "SET_TXN_HASH",
  SET_CLAIM_STATUS: "SET_CLAIM_STATUS",
  SET_MOMENT: "SET_MOMENT",
  SET_CURRENT_PENDING_MESSAGE: "SET_CURRENT_PENDING_MESSAGE",
};

export const SearchDispatchEvents = {
  SET_LOADING: "SET_LOADING",
  SET_MODE: "SET_MODE",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_AUTOCOMPLETE_RESULTS: "SET_AUTOCOMPLETE",
  SET_EVENTS: "SET_EVENTS",
  SET_ARTISTS: "SET_ARTISTS",
  SET_VENUES: "SET_VENUES",
  SET_FANS: "SET_FANS",
  SET_ERROR: "SET_ERROR",
};

export const NotificationDispatchEvents = {
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_NEW_NOTIFICATION: "SET_NEW_NOTIFICATION",
  SET_NOTIFIABLE_TOKEN: "SET_NOTIFIABLE_TOKEN",

  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  TOGGLE_LOADING: "TOGGLE_LOADING",

  SET_NOTIFICATION_PERMISSION_STATE: "SET_NOTIFICATION_PERMISSION_STATE",
  NOTIFICATION_HAS_PERMISSION: "NOTIFICATION_HAS_PERMISSION",
  NOTIFICATION_DENIED: "NOTIFICATION_DENIED",
  NOTIFICATION_NOT_SUPPORTED: "NOTIFICATION_NOT_SUPPORTED",
  SKIP_NOTIFICATION: "SKIP_NOTIFICATION",

  SET_PUSH_PERMISSION_STATE: "SET_PUSH_PERMISSION_STATE",
  PUSH_HAS_PERMISSION: "PUSH_HAS_PERMISSION",
  PUSH_DENIED: "PUSH_DENIED",
  PUSH_NOT_SUPPORTED: "PUSH_NOT_SUPPORTED",
  SKIP_PUSH: "SKIP_PUSH",
};
