import PropTypes from "prop-types";
import BackButton from "../../components/BackButton";

import {
  BackButtonContainer,
  Container,
  HeaderContainer,
  TextContainer,
} from "./Error-Styles";
import Monkey from "../../assets/svg/see-no-evil-monkey.svg?react";
import { theme } from "../../utils/theme";
import { BodyText, ButtonRect, HeaderL, HeaderXxl } from "../../GlobalStyles";
import { useNavigate } from "react-router-dom";

export default function ErrorPage({ type, description }) {
  const navigate = useNavigate();
  const subheader = type === "404" ? "Page not found" : "Something went wrong";
  const _description =
    type === "404"
      ? "The page you’re trying to view doesn’t exist. It may be moved or deleted....we think!"
      : description;
  const buttonText = type === "404" ? "Go back" : "Reload page";

  const handleClick = () => {
    type === "404" ? navigate(-1) : navigate(0);
  };

  return (
    <Container>
      <BackButtonContainer>
        <BackButton type="ghost" />
      </BackButtonContainer>
      <Monkey height={80} width={80} />
      <TextContainer>
        <HeaderContainer>
          <HeaderXxl
            style={{ fontStyle: "italic", textTransform: "uppercase" }}
          >
            Oops!
          </HeaderXxl>
          <HeaderL style={{ textTransform: "uppercase" }}>{subheader}</HeaderL>
        </HeaderContainer>
        {_description && (
          <BodyText
            color={theme.colors.grey}
            style={{
              textAlign: "center",
            }}
          >
            {_description}
          </BodyText>
        )}
      </TextContainer>
      <ButtonRect
        onClick={handleClick}
        style={{
          marginTop: "120px",
          background: theme.colors.greyBtn,
          border: "none",
        }}
      >
        <BodyText weight="600">{buttonText}</BodyText>
      </ButtonRect>
    </Container>
  );
}

ErrorPage.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string,
};
