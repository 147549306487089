import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  border-radius: 16px;
  align-self: center;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
`;

export const Image = styled.img`
  max-height: calc(350px - 32px);
  max-width: calc(350px - 32px);
  object-fit: cover;
  align-self: center;
  border-radius: 16px;
`;

export const ChainIconContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export const ArtistContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UserIcon = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  object-fit: cover;
`;

export const DescItem = styled(Container)`
  flex: 1;
`;

export const DescriptionItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  margin: 8px 0;
`;
