import CheckInContextProvider from "../contexts/CheckInContext";

export default function withCheckInContextProvider(WrappedComponent) {
  return function WithLogProps(props) {
    return (
      <CheckInContextProvider>
        <WrappedComponent {...props} />
      </CheckInContextProvider>
    );
  };
}
