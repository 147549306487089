import { getOneVenue } from "../../api/venues";

export const venueLoader = async ({ venueId, venueName, userId }) => {
  const venue = await getOneVenue(venueId, venueName, userId);

  if (!venue.success) {
    return { error: venue.error };
  }

  return { venueDetails: venue.data };
};
