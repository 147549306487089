import { usePrivy } from "@privy-io/react-auth";
import { Connect } from "../../components/ConnectSpotify/components/Connect";
import { BgImage } from "../../components/Events/GetLocationPage";

import { BottomView } from "../../components/ConnectSpotify/components/BottomView";
import { HeadingText } from "../../components/ConnectSpotify/components/HeadingText";
import { BACKGROUND_IMAGES } from "../../constants/constants";
import { useUserStore } from "../Profile/store/userStore";
import { useUserSpotifyFollowing } from "./hooks/spotifyQueries";
import { ArtistBox } from "../../components/ConnectSpotify/components/ArtistBox";
import { SearchBar } from "../../components/ConnectSpotify/components/SearchBar";
import { useEffect, useMemo, useState } from "react";

const ConnectSpotify = () => {
  const { user } = usePrivy();
  const { userSpotifyAccessToken } = useUserStore();
  const [search, setSearch] = useState("");
  const spotifyFollowingQuery = useUserSpotifyFollowing({
    accessToken: userSpotifyAccessToken,
  });

  const [subscribedList, setSubscribedList] = useState([]);

  // const spotifyArtists = spotifyFollowingQuery.data;

  const spotifyArtists = spotifyFollowingQuery.data;

  const isConnected = !!spotifyArtists;
  const isLinked = user.spotify != undefined;

  useEffect(() => {
    const subscribedURLS = spotifyArtists?.map((artist) => artist.href) ?? [];

    setSubscribedList(subscribedURLS);
  }, [spotifyArtists]);

  const filteredArtists = useMemo(() => {
    if (search == "") {
      return spotifyArtists;
    }
    return spotifyArtists.filter((artist) =>
      artist.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, spotifyArtists]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <BgImage src={BACKGROUND_IMAGES.SEARCH.CITY} alt="background image" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 16px 0px 16px",
          alignContent: "center",
          gap: 16,
          overflow: "hidden",
          flexGrow: 1,
          zIndex: 2,
        }}
      >
        <HeadingText isConnected={isConnected} />
        {isConnected && <SearchBar search={search} setSearch={setSearch} />}

        {!isConnected && <Connect isLinked={isLinked} />}

        {isConnected && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1, // Ensure it takes up the remaining space
              overflow: "hidden",
              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                overflow: "auto",
                justifyContent: "center", // Center items horizontally
                alignItems: "flex-start", // Align items at the start vertically
                columnGap: "16px",
                rowGap: "16px",
                paddingBottom: 200,
              }}
            >
              {filteredArtists?.map((spotifyArtist, index) => (
                <ArtistBox
                  key={index}
                  image={spotifyArtist.images[1].url}
                  name={spotifyArtist.name}
                  url={spotifyArtist.href}
                  subscribedList={subscribedList}
                  setSubscribedList={setSubscribedList}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <BottomView
        isConnected={isConnected}
        count={subscribedList?.length ?? 0}
        countTotal={spotifyArtists?.length ?? 0}
        subscribedList={subscribedList}
        spotifyArtists={spotifyArtists}
      />
    </div>
  );
};

export default ConnectSpotify;
