import PropTypes from "prop-types";
import { BodyText } from "../../../GlobalStyles";
import { useEffect, useState } from "react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import ArtistEventDateBox from "../ArtistEventDateBox";
import { ArtistEventsContainer, SeeAllEvents } from "./ArtistEvents-Styles";
import {
  ArtistEventContainer,
  ArtistEventDetailsContainer,
  ArtistEventDetais,
} from "../ArtistEventDateBox/ArtistEventDateBox-Styles";
import { theme } from "../../../utils/theme";

export default function ArtistEvents({ events, isLoading }) {
  // Set initial state for showing all events
  const [showAllEvents, setShowAllEvents] = useState(false);

  // Display all events if showAllEvents is true, otherwise display the first 3 events
  const [displayedEvents, setDisplayedEvents] = useState();

  useEffect(() => {
    if (!events || isLoading) return;

    setDisplayedEvents(events?.slice(0, 3));
  }, [events, isLoading]);

  // Toggle showAllEvents state
  const handleSeeAllEvents = () => {
    setShowAllEvents(!showAllEvents);

    if (!showAllEvents) {
      setDisplayedEvents(events);
    } else {
      setDisplayedEvents(events?.slice(0, 3));
    }
  };

  if (isLoading) {
    return (
      <ArtistEventsContainer>
        <SkeletonElement height="21px" width="130px" />
        {Array.from({ length: 3 }, (_, index) => index).map((index) => (
          <ArtistEventContainer key={index}>
            <ArtistEventDetailsContainer>
              <SkeletonElement height="58px" width="58px" radius="10px" />
              <ArtistEventDetais style={{ gap: "6px" }}>
                <SkeletonElement height="16px" width="200px" />
                <SkeletonElement height="16px" width="150px" />
              </ArtistEventDetais>
            </ArtistEventDetailsContainer>
            <SkeletonElement height="20px" width="20px" />
          </ArtistEventContainer>
        ))}
      </ArtistEventsContainer>
    );
  }

  if (
    !events ||
    events?.length === 0 ||
    (events?.length > 0 && !events[0]?.event_id)
  )
    return (
      <BodyText weight="600" color={theme.colors.grey}>
        No upcoming events
      </BodyText>
    );

  return (
    <ArtistEventsContainer>
      <BodyText weight="600" color={theme.colors.grey}>
        Upcoming Events
      </BodyText>
      {/* Map through and render each displayed event */}
      {displayedEvents?.map((event) => {
        return <ArtistEventDateBox key={event.event_id} event={event} />;
      })}
      {/* Render 'See more' or 'Show less' button based on the number of events */}
      {events.length > 3 && (
        <SeeAllEvents onClick={handleSeeAllEvents}>
          {showAllEvents ? "Show less" : "See more"}
        </SeeAllEvents>
      )}
    </ArtistEventsContainer>
  );
}

ArtistEvents.propTypes = {
  events: PropTypes.array,
  isLoading: PropTypes.bool,
};
