import festivals from "../constants/festival-constants";

export const getFestivals = () => {
	return festivals;
}

export const getFestivalEvents = async (festivalId) => {
	try {
		let events = await fetch(`${import.meta.env.VITE_API_URL}/festivals/${festivalId}/events`);

		events = await events.json();
		
		return events;
	} catch (err) {
		throw new Error(err);
	}
}