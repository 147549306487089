import { theme } from "../../../utils/theme";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Search from "../../../assets/svg/search.svg?react";
import Close from "../../../assets/svg/close.svg?react";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import {
  RecentContentContainer,
  RecentDetails,
  RecentIconPlaceholder,
  RecentItemContainer,
  RecentName,
} from "./RecentSearchItem-Styles";
import { useRemoveSearchHistory } from "../../../routes/Search/hooks/searchQueries";
import { RECENT_SEARCH_TYPES } from "../../../constants/search";
import ArtistItem from "../../Artist/ArtistItem";

const { colors } = theme;
const { TEXT, ARTIST } = RECENT_SEARCH_TYPES;

export default function RecentSearchItem({ recent }) {
  const { query } = recent;
  const { type, value } = query;

  const { loggedInUser } = useAuth();
  const [removed, setRemoved] = useState(false);
  const removeSearchMutation = useRemoveSearchHistory();

  const handleRemove = async () => {
    setRemoved(true);

    removeSearchMutation.mutate({ loggedInUser, type, value });
  };

  if (removed) return <></>;

  if (type === TEXT) {
    return (
      <RecentItemContainer>
        <RecentDetails as={Link} to={`/search?query=${value}&tab=event`}>
          <RecentIconPlaceholder>
            <Search stroke={colors.grey} height={14} width={14} />
          </RecentIconPlaceholder>
          <RecentContentContainer>
            <RecentName>{value}</RecentName>
          </RecentContentContainer>
        </RecentDetails>
        <Close
          stroke={colors.grey}
          height={16}
          width={16}
          onClick={handleRemove}
        />
      </RecentItemContainer>
    );
  }

  if (type === ARTIST) {
    return (
      <ArtistItem
        artist={value}
        isSearchMode
        handleRemoveHistory={handleRemove}
      />
    );
  }
}

RecentSearchItem.propTypes = {
  recent: PropTypes.object.isRequired,
};
