export const getSetlistByPerformanceId = async ({ performanceId, date }) => {
  try {
    // Fetch nearby events
    let setlist = await fetch(
      `${import.meta.env.VITE_API_URL}/performances/${performanceId}/setlist?date=${date}`
    );

    if (!setlist.ok) {
      console.warn(`${setlist.status} ${setlist.statusText}`);
      return false;
    }

    // Parse response
    setlist = await setlist.json();

    return setlist;
  } catch (err) {
    throw new Error(err);
  }
};

export const searchSetlists = async ({ artistName, venueName, date }) => {
  try {
    // Fetch nearby events
    let setlist = await fetch(
      `${import.meta.env.VITE_API_URL}/setlists/search?artistName=${artistName}&venueName=${venueName}&date=${date}`
    );

    if (!setlist.ok) {
      console.warn(`${setlist.status} ${setlist.statusText}`);
      return false;
    }

    // Parse response
    setlist = await setlist.json();

    return setlist;
  } catch (err) {
    throw new Error(err);
  }
};
