import { usePrivy } from "@privy-io/react-auth";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { UserContext } from "../contexts/UserContext";
import { UserDispatchEvents } from "../constants/dispatchEvents";
import { getUserByPrivyId, updateUserDetails } from "../api/profile";

function useAuth() {
  const { user } = usePrivy();
  const { context, dispatch } = useContext(UserContext);
  const { loggedInUser, sessionId } = context;

  const fetchSessionCookie = () => {
    const cookie = Cookies.get("session-id");

    if (!cookie)
      Cookies.set("session-id", uuidv4(), { secure: true, expires: 365 });
    else dispatch(UserDispatchEvents.SET_SESSION, cookie);
  };

  const fetchCurrentUser = async () => {
    if (user) return await getUserByPrivyId(user.id);
  };

  const updateCurrentUser = async (detail) => {
    const updatedUser = await updateUserDetails(loggedInUser.id, detail);
    dispatch(UserDispatchEvents.SET_LOGGED_IN_USER, updatedUser);
  };

  const setLoggedInUser = (loggedInUser) => {
    dispatch(UserDispatchEvents.SET_LOGGED_IN_USER, loggedInUser);
  };

  return {
    loggedInUser,
    sessionId,
    fetchCurrentUser,
    fetchSessionCookie,
    updateCurrentUser,
    setLoggedInUser,
  };
}

export default useAuth;
