import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Section } from "../../GlobalStyles";
import FollowingTabs from "../../components/Following/FollowingTabs";
import TopNav from "../../components/TopNav";
import { NavBarTypes } from "../../constants/constants";
import ErrorPage from "../Error";
import { useFollowing } from "./hooks/followingQueries";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export default function Following() {
  const { id: userId } = useParams();

  const { data, error, isLoading } = useFollowing({ userId });

  if (error) return <ErrorPage />;

  return (
    <Container>
      <TopNav type={NavBarTypes.FOLLOWING} />
      <FollowingTabs
        artists={data?.artists}
        fans={data?.fans}
        isLoading={isLoading}
      />
    </Container>
  );
}
