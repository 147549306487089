import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { addLocation } from "../../../api/activities";
import {
  getSearchLocations,
  getSearchLocationsExact,
} from "../../../api/events";

export const useLocationQuery = ({ query, enabled }) => {
  return useQuery({
    queryKey: ["location-exact", query],
    queryFn: async () => {
      if (query && query.length > 0) {
        const locations = await getSearchLocationsExact(query);
        return locations?.data;
      }
      return [];
    },
    enabled: !!query && query.length > 0 && enabled,
    initialData: [],
  });
};

export const useAllLocationsQuery = () => {
  return useQuery({
    queryKey: ["location-all"],
    queryFn: async () => {
      const locations = await getSearchLocations();
      return locations?.data;
    },
    initialData: [],
  });
};

export const useAllLocations = () => {
  return useQuery({
    queryKey: ["location-json"],
    queryFn: async () => {
      const locations = await getSearchLocations();

      return locations?.data;
    },
  });
};

export const useLocationXPQuery = ({ userId }) => {
  return useMutation({
    mutationKey: ["location-xp", userId],
    mutationFn: async () => {
      if (userId) {
        const result = await addLocation(userId);

        if (result.success)
          toast.success(`Added location +${result?.data?.xp?.xp}xp`);
      }
    },
  });
};
