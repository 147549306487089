import PropTypes from "prop-types";
import { BodyText, Header } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { PartialEmptyStateContainer } from "./PartialEmptyState-Styles";

const { colors } = theme;

export default function PartialEmptyState({
  icon,
  title,
  description,
  button,
  style,
}) {
  return (
    <PartialEmptyStateContainer style={style}>
      {icon}
      {title && <Header style={{ textTransform: "uppercase" }}>{title}</Header>}
      <BodyText color={colors.grey}>{description}</BodyText>
      {button}
    </PartialEmptyStateContainer>
  );
}

PartialEmptyState.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.any,
  button: PropTypes.element,
  style: PropTypes.object,
};
