import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { ScrollableEventsContainer } from "../../../routes/Events/Events-Styles";

export const WideSkeleton = () => {
  return (
    <>
      <SkeletonElement
        backgroundColor="#ffffff14"
        width="100px"
        height="21px"
        radius="16px"
      />

      <SkeletonElement
        width="100%"
        style={{ minWidth: "142px" }}
        height="143px"
        radius="10px"
        backgroundColor="#ffffff14"
      />
    </>
  );
};
