import PropTypes from "prop-types";
import LikeButton from "../../LikeButton";
import useAuth from "../../../hooks/useAuth";
import UserPill from "../../UserPill";
import {
  Container,
  Image,
  MomentDetails,
  Overlay,
} from "./PerformanceMoment-Styles";
import { GlassmorphPlaceholder } from "../../../constants/constants";

export default function PerformanceMoment({ moment }) {
  const { loggedInUser } = useAuth();
  const {
    moment_id,
    user_id,
    performance_artist_id,
    is_liked,
    total_likes,
    fan_photo_urls,
    avatar,
    username,
  } = moment;
  const user = { id: user_id, avatar, username };
  const isVideo = fan_photo_urls?.some((url) => url.includes("mp4"));

  return (
    <Container to={`/profile/${user_id}/moments/${moment_id}`}>
      {isVideo ? (
        <Image
          as="video"
          src={`${fan_photo_urls[0]}#t=0`}
          muted
          loop
          autoPlay
          playsInline
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          onLoadedMetadata={(e) => {
            e.target.pause();
          }}
          style={{
            pointerEvents: "none",
          }}
        />
      ) : (
        <Image
          src={fan_photo_urls[0]}
          alt="moment"
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
      )}
      <Overlay />
      <MomentDetails>
        <LikeButton
          moment={{
            creator_id: user_id,
            moment_id,
            artist_id: performance_artist_id,
            user_id: loggedInUser?.id,
          }}
          isCurrentUserLiked={is_liked}
          likeCount={total_likes}
          isFetched
        />
        <UserPill user={user} />
      </MomentDetails>
    </Container>
  );
}

PerformanceMoment.propTypes = {
  moment: PropTypes.object,
};
