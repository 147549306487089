import { createContext, useState } from "react";
import { NotificationDispatchEvents } from "../constants/dispatchEvents";
import PropTypes from "prop-types";

export const NotificationContext = createContext({});

const initialState = {
  notifications: [],
  new_notification: null,
  notifiable_token: null,

  isNotificationPermitted: null,
  notificationPermissionState: null,
  isPushPermitted: null,
  pushPermissionState: null,
  isLoading: false,
  error: null
};

export default function NotificationProvider(params) {
  const { children } = params;
  const [context, setContext] = useState(initialState);

  const dispatch = (actionType, payload) => {
    switch (actionType) {
      case NotificationDispatchEvents.TOGGLE_LOADING:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: context.isLoading,
          }));
        }
        break;
      case NotificationDispatchEvents.SET_ERROR_MESSAGE:
        {
          setContext((prev) => ({
            ...prev,
            error: payload,
          }));
        }
        break;

      case NotificationDispatchEvents.NOTIFICATION_HAS_PERMISSION:
        {
          setContext((prev) => ({
            ...prev,
            isNotificationPermitted: true,
            error: null,
          }));
        }
        break;
      case NotificationDispatchEvents.NOTIFICATION_NOT_SUPPORTED:
        {
          setContext((prev) => ({
            ...prev,
            isNotificationPermitted: false,
            error: "System notification is not supported by this browser.",
          }));
        }
        break;
      case NotificationDispatchEvents.NOTIFICATION_DENIED:
        {
          setContext((prev) => ({
            ...prev,
            isNotificationPermitted: false,
            notificationPermissionState: "denied",
            error:
              "Looks like you denied permission to check your notification, go to browser settings so you do not miss out on exciting stuff!",
          }));
        }
        break;
      case NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE:
        {
          setContext((prev) => ({
            ...prev,
            notificationPermissionState: payload,
          }));
        }
        break;

      case NotificationDispatchEvents.PUSH_HAS_PERMISSION:
        {
          setContext((prev) => ({
            ...prev,
            isPushPermitted: true,
            error: null,
          }));
        }
        break;
      case NotificationDispatchEvents.PUSH_NOT_SUPPORTED:
        {
          setContext((prev) => ({
            ...prev,
            isPushPermitted: false,
            error: "System push is not supported by this browser.",
          }));
        }
        break;
      case NotificationDispatchEvents.PUSH_DENIED:
        {
          setContext((prev) => ({
            ...prev,
            isPushPermitted: false,
            pushPermissionState: "denied",
            error:
              "Looks like you denied permission to receive push notifications, go to browser settings so you do not miss out on exciting stuff!",
          }));
        }
        break;
      case NotificationDispatchEvents.SET_PUSH_PERMISSION_STATE:
        {
          setContext((prev) => ({
            ...prev,
            pushPermissionState: payload,
          }));
        }
        break;

      case NotificationDispatchEvents.SET_NOTIFICATIONS:
        {
          setContext((prev) => ({
            ...prev,
            notifications: payload,
          }));
        }
        break;
      case NotificationDispatchEvents.SET_NEW_NOTIFICATION:
        {
          setContext((prev) => ({
            ...prev,
            new_notification: payload,
          }));
        }
        break;
      case NotificationDispatchEvents.SET_NOTIFIABLE_TOKEN:
        {
          setContext((prev) => ({
            ...prev,
            notifiable_token: payload,
          }));
        }
        break;
    }
  };

  return (
    <NotificationContext.Provider value={{ context, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
};
