import PropTypes from "prop-types";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { BodyText, ButtonRect } from "../../GlobalStyles";
import EyeIcon from "../../assets/svg/eye.svg?react";
import TrashPlainIcon from "../../assets/svg/trash-plain.svg?react";
import { removeUserLatestMessage } from "../../api/profile";

const DropdownContentWrapper = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  align-items: center;
  flex-direction: column;
  background: ${theme.colors.black};
  border-radius: 10px;
  border: 1px solid ${theme.colors.grey2};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 200px;
  max-width: 200px;
  z-index: ${({ index }) => index - 1};
  top: 20px;
  left: -196px;
  padding: 8px 0;
  gap: 4px;
`;

const DropdownItemWrapper = styled(ButtonRect)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  padding: 0 16px;
  height: 40px;
  width: 100%;

  border: none;
  background: transparent;

  &:disabled {
    opacity: 0.6;
  }
`;

const DropdownItemLabel = styled(BodyText)`
  line-height: 1;
`;

export default function MessageDropdownContent({
  isOpen = false,
  isRead = false,
  setIsDropdownOpen,
  messageId,
  handlePopSelectedItem,
  handleReadClick,
  userId,
  sender_id,
  refetch,
}) {
  const handleRemoveClick = async () => {
    await removeUserLatestMessage(userId, sender_id);
    refetch();
    setIsDropdownOpen(!isOpen);
    handlePopSelectedItem(messageId);
  };

  return (
    <DropdownContentWrapper isOpen={isOpen} index={messageId}>
      <DropdownItemWrapper onClick={handleReadClick} disabled={isRead}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "21px",
          }}
        >
          <EyeIcon stroke="white" width="16px" height="14px" />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "21px",
          }}
        >
          <DropdownItemLabel
            color={isRead ? theme.colors.grey : theme.colors.softWhite}
          >
            Mark as read
          </DropdownItemLabel>
        </div>
      </DropdownItemWrapper>
      <DropdownItemWrapper onClick={handleRemoveClick}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "21px",
          }}
        >
          <TrashPlainIcon stroke="white" width="16px" height="16px" />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "21px",
          }}
        >
          <DropdownItemLabel>Clear this notification</DropdownItemLabel>
        </div>
      </DropdownItemWrapper>
    </DropdownContentWrapper>
  );
}

MessageDropdownContent.propTypes = {
  isOpen: PropTypes.bool,
  isRead: PropTypes.bool,
  setIsDropdownOpen: PropTypes.func,
  messageId: PropTypes.string,
  handlePopSelectedItem: PropTypes.func,
  handleReadClick: PropTypes.func,
  userId: PropTypes.string,
  sender_id: PropTypes.string,
  refetch: PropTypes.func,
};
