export const subscribeToArtist = async (userId, artistId, device_token) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "subscribe_artist",
        target_type: "artist",
        target_id: artistId,
        artist_id: artistId,
        device_token: device_token ?? null,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to subscribe to artist" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const unsubscribeFromArtist = async (
  userId,
  artistId,
  actionId,
  device_token
) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        artist_id: artistId,
        action_type: "subscribe_artist",
        action_id: actionId,
        target_type: "artist",
        target_id: artistId,
        device_token: device_token ?? null,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to unsubscribe from artist" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const getSubscribeAction = async (userId, artistId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity/${userId}?action_type=subscribe_artist&target_type=artist&target_id=${artistId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to fetch subscribe action" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch subscribe action" };
  }

  return { success: true, data: payload.data };
};

export const followArtist = async (userId, artistId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "follow_artist",
        target_type: "artist",
        target_id: artistId,
        artist_id: artistId,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to follow artist" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const unfollowArtist = async (userId, artistId, actionId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        artist_id: artistId,
        action_type: "follow_artist",
        action_id: actionId,
        target_type: "artist",
        target_id: artistId,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to unfollow artist" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch artist data" };
  }

  return { success: true, data: payload.data };
};

export const getFollowActionArtist = async (userId, artistId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity/${userId}?action_type=follow_artist&target_type=artist&target_id=${artistId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to fetch follow action" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch follow action" };
  }

  return { success: true, data: payload.data };
};

export const collectMoment = async (userId, momentId, artistId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "collect_moment",
        target_type: "moment",
        target_id: momentId,
        artist_id: artistId,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to collect moment" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch moment data" };
  }

  return { success: true, data: payload.data };
};

export const followUser = async (userId, targetId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "follow_user",
        target_type: "user",
        target_id: targetId,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to follow user" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch user data" };
  }

  return { success: true, data: payload.data };
};

export const unfollowUser = async (userId, targetId, actionId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "follow_user",
        action_id: actionId,
        target_type: "user",
        target_id: targetId,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to unfollow user" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch user data" };
  }

  return { success: true, data: payload.data };
};

export const getFollowActionUser = async (userId, targetId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity/${userId}?action_type=follow_user&target_type=user&target_id=${targetId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to fetch follow action" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to fetch follow action" };
  }

  return { success: true, data: payload.data };
};

export const addLocation = async (userId) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "add_location",
        target_type: "user",
        target_id: userId,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to add location XP" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to add location XP" };
  }

  return { success: true, data: payload.data };
};

export const addOnboardArtists = async (userId, artist_ids) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/users/activity`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        action_type: "add_fav_artist",
        target_type: "artists",
        target_id: artist_ids[0],
        artist_ids: artist_ids,
      }),
    }
  ).catch((err) => {
    console.error(err);
    return { success: false, error: "Failed to add onboarding artists XP" };
  });

  const payload = await response.json();

  if (!payload.success) {
    console.error(payload.error);
    return { success: false, error: "Failed to add onboarding artists XP" };
  }

  return { success: true, data: payload.data };
};
