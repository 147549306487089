import { create } from "zustand";

export const useImageEditorStore = create((set) => ({
  // Retro flow state
  isOpen: false,
  setIsOpen: (value) => set(() => ({ isOpen: value })),
  image: null,
  setImage: (value) => set(() => ({ image: value })),
  imageToBeUploaded: null,
  setImageToBeUploaded: (value) => set(() => ({ imageToBeUploaded: value })),
  video: null,
  setVideo: (value) => set(() => ({ video: value })),
  // Loading state
  isLoading: false,
  setIsLoading: (value) => set(() => ({ isLoading: value })),
  // Success state
  isSuccess: false,
  setIsSuccess: (value) => set(() => ({ isSuccess: value })),
  // Error state
  isError: false,
  setIsError: (value) => set(() => ({ isError: value })),
  // Reset state
  reset: () =>
    set(() => ({
      isOpen: false,
      image: null,
      video: null,
      isLoading: false,
      isSuccess: false,
      isError: false,
    })),
}));
