import { create } from "zustand";

export const useDatePickerStore = create((set, get) => {
  return {
    isMonthPickerOpen: false,
    isYearPickerOpen: false,
    getIsDatePickerOpen: () =>
      get().isMonthPickerOpen || get().isYearPickerOpen,
    toggleMonthPicker: () =>
      set((state) => ({
        isMonthPickerOpen: !state.isMonthPickerOpen,
        isYearPickerOpen: false,
      })),
    toggleYearPicker: () =>
      set((state) => ({
        isYearPickerOpen: !state.isYearPickerOpen,
        isMonthPickerOpen: false,
      })),
    toggleDatePicker: () =>
      set(() => ({ isMonthPickerOpen: false, isYearPickerOpen: false })),
  };
});
