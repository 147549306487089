import { BodyText, FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  DescriptionItem,
  DescriptionLabelWithIcon,
} from "./CollectibleDescriptionItem-Styles";
import PropTypes from "prop-types";

const { colors } = theme;

export default function CollectibleDescriptionItem({
  label,
  value,
  icon,
  capitalizeValue,
}) {
  if (!label || !value) return null;

  return (
    <DescriptionItem>
      <BodyText weight="600">{label}</BodyText>
      <DescriptionLabelWithIcon>
        <FooterText
          color={colors.grey}
          style={{ textTransform: capitalizeValue ? "capitalize" : "none" }}
        >
          {value}
        </FooterText>
        {icon}
      </DescriptionLabelWithIcon>
    </DescriptionItem>
  );
}

CollectibleDescriptionItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.element,
  capitalizeValue: PropTypes.bool,
};
