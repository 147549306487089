import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: auto;
  background: ${({ theme }) => theme.colors.blue};
  padding: 24px;
`;

export const FeeContainer = styled.div`
  display: flex;
  gap: 4px;
`;
