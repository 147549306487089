import useGlobalModal from "../../hooks/useGlobalModal";
import PropTypes from "prop-types";
import Sheet from "react-modal-sheet";
import { theme } from "../../utils/theme";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";
import { useCheckoutStore } from "../Checkout/checkoutStore";

const defaltStyle = {
  container: {
    background: theme.colors.background,
    borderRadius: "20px 20px 0 0",
    height: "fit-content !important",
    maxHeight: "100% !important",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "12px",
  },
  backdrop: {
    background: "rgba(0, 0, 0, 0.5)",
  },
};

export default function SheetModal() {
  const {
    isSheetModalOpen,
    sheetModalContent,
    toggleSheetModal,
    setSheetModalContent,
  } = useGlobalModal();
  const { loading } = useMomentPhotoUpload();
  const { setPaymentMethod, setCardPaymentMethod } = useCheckoutStore();

  const closeModal = () => {
    if (loading) return;

    setSheetModalContent(null);
    toggleSheetModal();
    setPaymentMethod(null);
    setCardPaymentMethod(null);
  };

  const mergedStyle = {
    sheet: {
      ...defaltStyle.sheet,
    },
    container: {
      ...defaltStyle.container,
    },
    content: {
      ...defaltStyle.content,
    },
    backdrop: {
      ...defaltStyle.backdrop,
    },
  };

  return (
    <Sheet
      isOpen={isSheetModalOpen}
      onClose={closeModal}
      detent="content-height"
    >
      <Sheet.Container style={mergedStyle.container}>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller draggableAt="both" style={mergedStyle.content}>
            {sheetModalContent}
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={closeModal} />
    </Sheet>
  );
}

SheetModal.propTypes = {
  modalProps: PropTypes.object,
};
