import PropTypes from "prop-types";
import SearchResults from "./SearchResults";
import { SearchSkeleton } from "../../routes/Search/Search-Styles";
import RetroIcon from "../../assets/svg/retro-moment.svg?react";
import Mic from "../../assets/svg/mic.svg?react";
import { useSearchParams } from "react-router-dom";
import { useSearchParamsHook } from "../../routes/Search/hooks/searchParams";
import { theme } from "../../utils/theme";
import EmptyState from "../EmptyState";
import RecentSearches from "./RecentSearches";

export default function InputMode({ loading, autocompleteResults, error }) {
  const [searchParams] = useSearchParams();
  const { isRetro, query } = useSearchParamsHook({ searchParams });

  if (loading) {
    return <SearchSkeleton />;
  }

  if (isRetro && !query) {
    return (
      <div
        style={{
          padding: "0 20px 16px 20px",
        }}
      >
        <RecentSearches />
      </div>
    );
  }

  if (error || !autocompleteResults || autocompleteResults?.length === 0)
    return (
      <EmptyState
        icon={
          isRetro ? (
            <RetroIcon
              height={28}
              width={28}
              stroke={theme.colors.white}
              strokeWidth={1}
            />
          ) : (
            <Mic stroke="white" height={26} width={26} />
          )
        }
        title={"Oops, There’s nothing here."}
        description={`Maybe try searching for another one?`}
      />
    );

  return <SearchResults data={autocompleteResults} />;
}

InputMode.propTypes = {
  loading: PropTypes.bool,
  autocompleteResults: PropTypes.array,
  error: PropTypes.string,
};
