import { getBadgeQualifications, getBadgesByUser } from "../../api/badges";

const badgeLoader = async ({ params }) => {
	if (!params.id) return null;

	const { id } = params;

	try {
		await getBadgeQualifications(id);
		const badges = await getBadgesByUser(id);

		return badges;
	} catch (err) {
		console.warn(err);
		return null;
	}
}

export default badgeLoader;