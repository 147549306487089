import { useOAuthTokens, usePrivy } from "@privy-io/react-auth";
import PropTypes from "prop-types";

import Spotify from "../../../assets/svg/spotify.svg?react";
import { ButtonRect, FooterText, HeaderXl } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";

export const Connect = ({ isLinked = false }) => {
  const { unlinkSpotify, user, linkSpotify } = usePrivy();

  const { reauthorize } = useOAuthTokens();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "40%",
      }}
    >
      <Spotify height={80} width={80} />
      <HeaderXl>CONNECT SPOTIFY</HeaderXl>
      <ButtonRect
        style={{
          width: 170,
          height: 37,
          color: "black",
          backgroundColor: "#6EDBBB",
          fontWeight: 600,
          padding: "8px 14px",
        }}
        onClick={() => {
          if (!isLinked) linkSpotify();
          else reauthorize();
        }}
      >
        Add your Artists
      </ButtonRect>
      <FooterText
        onClick={() => unlinkSpotify(user.spotify.subject)}
        color={theme.colors.green}
      >
        Earn XP
      </FooterText>
    </div>
  );
};

Connect.propTypes = {
  isLinked: PropTypes.bool,
};
