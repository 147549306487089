import { Link } from "react-router-dom";
import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const ThumbnailContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-overflow: ellipsis;
  min-width: ${({ isRetroFlow }) => (isRetroFlow ? "108px" : "100%")};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
`;

export const MomentName = styled(BodyText)`
  /* Make Text two lines then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
`;
