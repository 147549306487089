import styled from "styled-components";
import { ButtonRect } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  border-radius: 10px;
`;

export const RetroButton = styled(ButtonRect)`
  background: ${({ theme }) => theme.colors.black};
  gap: 8px;
  border: none;
`;
