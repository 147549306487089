import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FooterText } from "../../GlobalStyles";
import { AvatarPlaceholder } from "../../constants/constants";
import { Container, Image } from "./UserPill-Styles";

export default function UserPill({ user }) {
  const navigate = useNavigate();
  const { avatar, id, username } = user || {};

  const handleRedirect = (e) => {
    e.preventDefault();

    navigate(`/profile/${id}`);
  };

  return (
    <Container onClick={handleRedirect}>
      <Image
        src={avatar ?? AvatarPlaceholder}
        alt={username}
        onError={(e) => (e.target.src = AvatarPlaceholder)}
      />
      <FooterText>{username}</FooterText>
    </Container>
  );
}

UserPill.propTypes = {
  user: PropTypes.object.isRequired,
};
