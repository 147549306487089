import { useEffect, useState } from "react";
import { Header } from "../../components/EventsV2/Header";
import NearYou from "../../components/EventsV2/NearYou";
import { TopPicks } from "../../components/EventsV2/TopPicks";
import useGeolocation from "../../hooks/useGeolocation";
import useGeolocationPermission from "../Events/hooks/geoLocationHooks";
import useAuth from "../../hooks/useAuth";
import { PermissionState } from "../../enums/permission";
import { useQueryClient } from "@tanstack/react-query";
import { NewEventFrom } from "../../components/EventsV2/NewEventFrom";
import { PopularInArea } from "../../components/EventsV2/PopularInArea";
import { FansPanel } from "../../components/EventsV2/FansPanel";
import useCheckIn from "../../hooks/useCheckIn";
import DeniedGeo from "../../components/Modal/DeniedGeo";
import useGlobalModal from "../../hooks/useGlobalModal";
import withCheckInContextProvider from "../../hoc/withCheckInContextProvider";
import CheckInEvent from "../../components/CheckIn/CheckInEvent";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import CheckInToast from "../../components/CheckIn/CheckInToast";
import { GetLocationContainer } from "../Events/Events-Styles";
import GetLocationComponent from "../../components/Events/GetLocationComponent";
import { toast } from "react-toastify";
import { NearbyVenues } from "../../components/EventsV2/NearbyVenues";
import { ArtistsPanel } from "../../components/EventsV2/ArtistsPanel";

const EventsV2 = () => {
  const { fetchSessionCookie, loggedInUser } = useAuth();
  const {
    handleLocationPermission,
    getCurrentPosition,
    resetLocation,
    permissionState,
  } = useGeolocation();
  const permState = useGeolocationPermission();
  const queryClient = useQueryClient();
  const { isOpen, handleToggle } = useCheckInModalToggle();
  const { setCurrentEvent, handleCheckInCurrentEvent } = useCheckIn();
  const [localCurrentEvent, setLocalCurrentEvent] = useState(null);
  const [gettingLocation, setGettingLocation] = useState(false);

  const refetchQueries = () => {
    queryClient.refetchQueries({
      queryKey: ["nearby_events", loggedInUser?.id?.toString() ?? null],
    });
    // queryClient.refetchQueries({
    //   queryKey: ["recommended_events"],
    // });
    // queryClient.refetchQueries({
    //   queryKey: ["newly_appended"],
    // });
    // queryClient.refetchQueries({
    //   queryKey: ["popular_in_area"],
    // });
    // queryClient.refetchQueries({
    //   queryKey: ["events_friends_interested"],
    // });
  };

  const handleOpenCheckInModal = () => {
    if (!navigator.geolocation) return;
    handleToggle();
    handleCheckInCurrentEvent();
  };

  const handleGetLocation = async () => {
    if (!navigator.geolocation) {
      toast.error("Geolocation is not supported by this browser.", {
        toastId: "geolocation-not-supported",
      });
      return;
    }
    setGettingLocation(true);
    await getCurrentPosition();
    setGettingLocation(false);
  };

  // TODO: Clean up hooks
  useEffect(() => {
    handleLocationPermission();
    fetchSessionCookie();
    if (permState === PermissionState.GRANTED) getCurrentPosition();

    refetchQueries();

    return () => {
      resetLocation();
    };
  }, [permState]);

  // Get current location every 10 seconds if permission is granted
  useEffect(() => {
    if (permState === PermissionState.GRANTED) {
      const intervalId = setInterval(() => getCurrentPosition(), 1000);
      return () => clearInterval(intervalId);
    }
  }, [getCurrentPosition, permState]);

  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  // Show denied geo modal if permission is denied
  useEffect(() => {
    if (permState === PermissionState.DENIED) {
      setSheetModalContent(<DeniedGeo />);
      toggleSheetModal();
    }
  }, [permState]);

  useEffect(() => {
    if (localCurrentEvent) setCurrentEvent(localCurrentEvent);
  }, [localCurrentEvent]);

  if (isOpen) {
    return (
      <CheckInEvent
        onClose={handleToggle}
        onRefresh={handleCheckInCurrentEvent}
      />
    );
  }

  return (
    <>
      {permState === PermissionState.PROMPT &&
        permissionState != PermissionState.SKIPPED &&
        permissionState != PermissionState.GRANTED &&
        !!permState &&
        !!permissionState && (
          <GetLocationContainer>
            <GetLocationComponent
              getLocation={handleGetLocation}
              gettingLocation={gettingLocation}
            />
          </GetLocationContainer>
        )}
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "16px 20px",
          gap: 16,
        }}
      >
        <Header />
        <TopPicks />
        <NewEventFrom />
        <PopularInArea />
        <NearYou setLocalEvent={setLocalCurrentEvent} />
        <FansPanel />
        <ArtistsPanel />
        <NearbyVenues />
        <CheckInToast
          event={localCurrentEvent}
          onClick={handleOpenCheckInModal}
        />
      </div>
    </>
  );
};

export default withCheckInContextProvider(EventsV2);
