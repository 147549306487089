import PropTypes from "prop-types";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import ActiveMoment from "../ActiveMoment";
import {
  ActiveMomentsCarousel,
  Container,
  HeaderContainer,
  NavCircle,
  NavCirclesContainer,
} from "./UserActiveMoments-Styles";
import { useRef, useState } from "react";

export default function UserActiveMoments({ activeMoments, user }) {
  const [currentItem, setCurrentItem] = useState(0);
  const carouselRef = useRef(null);

  // Handle scrolling or interaction event
  const handleScroll = () => {
    const container = carouselRef.current;
    const scrollLeft = container.scrollLeft;
    // Calculate the current item based on scroll position minus x padding
    const itemWidth = container.offsetWidth - 40;

    const newItem = Math.round(scrollLeft / itemWidth);

    if (newItem !== currentItem) setCurrentItem(newItem);
  };

  return (
    <Container>
      <HeaderContainer>
        <BodyText weight="600">
          Active Moments{" "}
          <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
            ({activeMoments?.length})
          </FooterText>
        </BodyText>
        {activeMoments?.length > 1 && (
          <NavCirclesContainer>
            {Array.from({ length: activeMoments?.length }).map((_, index) => (
              <NavCircle key={index} filled={index === currentItem} />
            ))}
          </NavCirclesContainer>
        )}
      </HeaderContainer>
      <ActiveMomentsCarousel ref={carouselRef} onScroll={handleScroll}>
        {activeMoments?.map((moment, index) => (
          <ActiveMoment
            key={moment?.moment_id || index}
            user={user}
            moment={moment}
          />
        ))}
      </ActiveMomentsCarousel>
    </Container>
  );
}

UserActiveMoments.propTypes = {
  activeMoments: PropTypes.array.isRequired,
  user: PropTypes.object,
};
