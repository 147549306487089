import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useMomentsStore } from "../../routes/MomentsFeed/stores/momentsStore";
import { ScrollableContainer } from "./Moment-Styles";
import MomentView from "./MomentView";
import { MomentSkeleton } from "./MomentSkeleton";

export default function ScrollableMoments({
  moments,
  isMomentsFeed,
  isLikesFetched,
  isLoading = true,
  isFYP,
  listRef,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) {
  const { momentsIndex, eventsIndex, setMomentsIndex, setEventsIndex } =
    useMomentsStore();
  const prevScrollTop = useRef(0);

  useEffect(() => {
    const element = listRef.current;
    const scrollOffset =
      (isFYP ? momentsIndex : eventsIndex) * window.innerHeight;

    if (element && element.scrollTop !== scrollOffset) {
      requestAnimationFrame(() => {
        element.style.scrollBehavior = "auto";
        element.scrollTop = scrollOffset;
        element.style.scrollBehavior = "smooth";
      });
    }
  }, [isFYP, listRef]);

  const handleScroll = useCallback(
    debounce((e) => {
      const element = e.target;
      const bottomScrollTop = element.scrollTop + element.clientHeight;
      const currentIndex =
        prevScrollTop.current < element.scrollTop
          ? Math.ceil(bottomScrollTop / window.innerHeight) - 1
          : Math.floor(bottomScrollTop / window.innerHeight) - 1;

      prevScrollTop.current = element.scrollTop;

      if (currentIndex != -1)
        if (isFYP) {
          setMomentsIndex(currentIndex);
        } else {
          setEventsIndex(currentIndex);
        }

      if (
        currentIndex >= moments?.length - 10 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    }, 10),
    [
      isFYP,
      moments?.length,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      setMomentsIndex,
      setEventsIndex,
    ]
  );

  useEffect(() => {
    const element = listRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, listRef]);

  if (isLoading) return <MomentSkeleton isFYP />;

  return (
    <ScrollableContainer ref={listRef} id="scrollable-moments">
      {moments?.map((moment, index) => {
        if (moment?.fan_photo_urls?.length < 1) return null;

        return (
          <MomentView
            key={index}
            moment={moment}
            isMomentsFeed={isMomentsFeed}
            isLikesFetched={isLikesFetched}
          />
        );
      })}
    </ScrollableContainer>
  );
}

ScrollableMoments.propTypes = {
  moments: PropTypes.array,
  isMomentsFeed: PropTypes.bool,
  isLikesFetched: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFYP: PropTypes.bool,
  listRef: PropTypes.object,
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
};
