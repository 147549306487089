import { GlassmorphPlaceholder } from "../../../constants/constants";
import { FooterText, OneLineText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  EventDetailsContainer,
  EventImage,
  EventTextContainer,
} from "../../CheckIn/CheckInToast/CheckInToast-Styles";
import PropTypes from "prop-types";
import _moment from "moment";

const { colors } = theme;

export default function EventDetails({ event }) {
  const { headline_artist, image, venue, country, date } = event;

  return (
    <EventDetailsContainer
      style={{
        justifyContent: "flex-start",
        padding: 0,
      }}
    >
      <EventImage
        src={image ?? GlassmorphPlaceholder}
        alt={event}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />

      <EventTextContainer>
        <OneLineText weight="600" style={{ marginBottom: "4px" }}>
          {headline_artist}
        </OneLineText>
        <OneLineText color={colors.grey} size={theme.fontSizes.mobile.footer}>
          {venue}, {country}
        </OneLineText>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <FooterText color={colors.green}>
            {_moment(date).format("ddd DD MMM YYYY")}
          </FooterText>
          <FooterText color={colors.grey}>
            {_moment(date).format("LT")}
          </FooterText>
        </div>
      </EventTextContainer>
    </EventDetailsContainer>
  );
}

EventDetails.propTypes = {
  event: PropTypes.object.isRequired,
};
