import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AvatarPlaceholder } from "../../constants/constants";
import { BodyText } from "../../GlobalStyles";
import useAuth from "../../hooks/useAuth";
import FollowUserButton from "./FollowUserButton";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ProfileIcon = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileName = styled(BodyText)`
  /* Make Text two lines then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1.2;
`;

const FollowSubContainer = styled.div`
  display: flex;
  gap: 12px;
  z-index: 2;
`;

export default function ProfileItem({ user, innerRef }) {
  const { loggedInUser } = useAuth();
  const isCurrentUser = loggedInUser?.id === user?.id;

  return (
    <Container ref={innerRef} as={Link} to={`/profile/${user.id}`}>
      <ProfileDetails>
        <ProfileIcon
          src={user?.avatar ?? AvatarPlaceholder}
          onError={(e) => (e.target.src = AvatarPlaceholder)}
        />
        <ProfileName>{user.username || "Fan"}</ProfileName>
      </ProfileDetails>
      <FollowSubContainer>
        {!isCurrentUser && (
          <FollowUserButton user={user} isFollowing={user?.following} />
        )}
      </FollowSubContainer>
    </Container>
  );
}

ProfileItem.propTypes = {
  user: PropTypes.object,
  innerRef: PropTypes.any,
};
