export const PermissionState = {
  GRANTED: "granted",
  DENIED: "denied",
  PROMPT: "prompt",
  SKIPPED: "skipped",
};

export const NotificationPermissionState = {
  GRANTED: "granted",
  DENIED: "denied",
  PROMPT: "prompt",
  SKIPPED: "skipped",
  DEFAULT: "default",
};

export const PushPermissionState = {
  GRANTED: "granted",
  DENIED: "denied",
  PROMPT: "prompt",
  SKIPPED: "skipped",
};


export const LocationPermission = "location-permission";
export const NotificationPermission = "notification-permission";
export const PushPermission = "push-permission";
