import PropTypes from "prop-types";
import { BodyText } from "../../../GlobalStyles";
import { ActionContainer } from "./XPAction-Styles";
import { handleXPActionIcon, handleXPActionText } from "../../../utils/xp";

export default function XPAction({ action }) {
  const actionIcon = handleXPActionIcon(action);
  const actionText = handleXPActionText(action);

  return (
    <ActionContainer>
      {actionIcon}
      <BodyText secondary>{actionText}</BodyText>
    </ActionContainer>
  );
}

XPAction.propTypes = {
  icon: PropTypes.node,
  action: PropTypes.string,
};
