import { useLogEventClick } from "../../Events/hooks/interestedQueries";
import useAuth from "../../../hooks/useAuth";
import { useUserStore } from "../../../routes/Profile/store/userStore";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  EventDateContainer,
  EventImage,
  EventImageContainer,
  EventText,
  EventVenueContainer,
  InterestedButtonContainer,
} from "../../Events/EventCardItem";
import InterestedButton from "../../Events/InterestedButton";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import moment from "moment";
import { theme } from "../../../utils/theme";

export const NoBoxEventCard = ({
  event,
  isArtist,
  vertical_position = undefined,
  horizontal_position = undefined,
  header = undefined,
}) => {
  const { event_id, combined_artist_ids, artist_id } = event;
  const logEventClick = useLogEventClick();
  const { loggedInUser } = useAuth();
  const { userCountry } = useUserStore();
  const { fontSizes, colors } = theme;

  const eventImage = (event) => {
    const artistImage =
      !event?.artist_image || event?.artist_image == null
        ? null
        : event?.artist_image;

    const eventImage =
      !event?.image || event?.image == null
        ? GlassmorphPlaceholder
        : event?.image;

    return isArtist && artistImage ? artistImage : eventImage;
  };

  return (
    <Link
      style={{
        width: 118,
        height: 205,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center !important",
        gap: 4,
        position: "relative",
      }}
      to={`/events/${event?.event_id}?date=${event?.date}`}
      onClick={() => {
        logEventClick.mutate({
          user_id: loggedInUser?.id,
          combined_artist_ids: combined_artist_ids ?? [artist_id],
          event_id: event_id,
          horizontal_position: horizontal_position,
          vertical_position: vertical_position,
          header: header,
          country: userCountry,
        });
      }}
    >
      <EventImageContainer
        style={{
          width: 118,
          height: 140,
          borderRadius: 16,
          maxWidth: 118,
          maxHeight: 140,
          padding: null,
        }}
      >
        {/* The event image */}
        <EventImage
          style={{
            width: 118,
            height: 140,
            borderRadius: 16,
          }}
          loading="lazy"
          src={eventImage(event)}
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
        {/* The interested button container */}
        <InterestedButtonContainer>
          {/* The interested button */}
          <InterestedButton event={event} size={20} />
        </InterestedButtonContainer>
      </EventImageContainer>

      <EventText
        weight="600"
        style={{
          width: "100%",
          fontSize: 14,
          color: "white",
          textAlign: "center",
          lineHeight: null,
        }}
      >
        {isArtist && event.artist_name
          ? event?.artist_name
          : event.headline_artist}
      </EventText>
      <EventVenueContainer>
        <EventText
          style={{
            opacity: 0.6,
            fontWeight: 400,
            fontSize: 12,
            textAlign: "center",
            lineHeight: null,
          }}
          size={fontSizes.mobile.footer}
        >
          {event.city}
        </EventText>
      </EventVenueContainer>

      <EventDateContainer>
        <EventText
          style={{
            textAlign: "center",
            width: "100%",
            lineHeight: null,
          }}
          size={fontSizes.mobile.footer}
          color={colors.softGreen}
        >
          {moment(event.date).format("ddd D MMM")}
        </EventText>
      </EventDateContainer>
    </Link>
  );
};

NoBoxEventCard.propTypes = {
  event: PropTypes.object,
  isArtist: PropTypes.bool,
  isEvent: PropTypes.bool,
  vertical_position: PropTypes.number,
  horizontal_position: PropTypes.number,
  header: PropTypes.string,
};
