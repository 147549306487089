import { useEffect, useState } from "react";
import { getVersion } from "../../utils/version";
import { getBackendVersion } from "../../api/version";
import { BodyText } from "../../GlobalStyles";
import styled from "styled-components";
import { theme } from "../../utils/theme";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const Line = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  opacity: 0.5;
  margin: 12px 0;
  width: 100%;
  align-self: center;
`;

export default function AppVersion() {
  const [backendVersion, setBackendVersion] = useState(null);

  useEffect(() => {
    getBackendVersion().then((backendVersion) =>
      setBackendVersion(backendVersion.Version)
    );
  }, []);

  return (
    <Container>
      <Line />
      <BodyText color={colors.grey}>{`frontend: v${
        getVersion() || "N/A"
      }`}</BodyText>
      <BodyText color={colors.grey}>{`backend: v${
        backendVersion || "N/A"
      }`}</BodyText>
    </Container>
  );
}
