import PropTypes from "prop-types";
import Countdown from "react-countdown";
import styled from "styled-components";
import { ButtonGhost } from "../../GlobalStyles";
import Clock from "../../assets/svg/clock.svg?react";
import PhotoRemove from "../../assets/svg/photo-remove.svg?react";
import VideoRemove from "../../assets/svg/video-remove.svg?react";
import useGlobalModal from "../../hooks/useGlobalModal";
import IconWithText from "../IconWithText";
import DeletePhotoModal from "../Modal/DeletePhotoModal";
import { theme } from "../../utils/theme";
import MomentDropdown from "../Dropdown/MomentDropdown";
import MomentMediaDropdown from "../Dropdown/MomentMediaDropdown";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 2;
`;

const CompletedContainer = styled(Container)`
  gap: 8px;
`;

const ButtonContainer = styled(Container)`
  gap: 8px;
`;

export default function MomentFanPhotoButtons({ moment, date }) {
  const { moment_id, user_id, fan_photo_urls: fanPhotos, type } = moment || {};
  const { setCenterModalContent, toggleCenterModal } = useGlobalModal();

  const remainingTime = new Date(date);
  remainingTime.setDate(remainingTime.getDate() + 2);

  const isVideo = fanPhotos?.some((photo) => photo.includes("mp4"));

  const handleRemovePhotoButton = () => {
    setCenterModalContent(<DeletePhotoModal />);
    toggleCenterModal();
  };

  if (type === "retro" && fanPhotos?.length >= 1) {
    return (
      <Container>
        <ButtonContainer>
          <ButtonGhost onClick={handleRemovePhotoButton}>
            {isVideo ? (
              <VideoRemove height={20} width={20} stroke="white" />
            ) : (
              <PhotoRemove height={20} width={20} stroke="white" />
            )}
          </ButtonGhost>
          <MomentMediaDropdown isVideo={isVideo} />
          <MomentDropdown momentId={moment_id} userId={user_id} />
        </ButtonContainer>
      </Container>
    );
  } else if (type === "retro" && (fanPhotos?.length < 1 || !fanPhotos)) {
    return (
      <CompletedContainer>
        <MomentDropdown momentId={moment_id} userId={user_id} />
      </CompletedContainer>
    );
  }

  return (
    <Countdown
      date={remainingTime}
      renderer={({ days, hours, minutes, completed }) => {
        // Convert days to hours
        hours += days * 24;

        if (completed) {
          return (
            <CompletedContainer>
              <MomentDropdown momentId={moment_id} userId={user_id} />
            </CompletedContainer>
          );
        }

        if (fanPhotos?.length >= 1 && !completed) {
          return (
            <Container>
              <ButtonContainer>
                <IconWithText
                  icon={
                    <Clock height={14} width={14} fill={theme.colors.green} />
                  }
                  text={`${hours}h ${minutes}m`}
                />
              </ButtonContainer>
              <ButtonContainer>
                <ButtonGhost onClick={handleRemovePhotoButton}>
                  {isVideo ? (
                    <VideoRemove height={20} width={20} stroke="white" />
                  ) : (
                    <PhotoRemove height={20} width={20} stroke="white" />
                  )}
                </ButtonGhost>
                <MomentMediaDropdown isVideo={isVideo} />
                <MomentDropdown momentId={moment_id} userId={user_id} />
              </ButtonContainer>
            </Container>
          );
        }
      }}
    />
  );
}

MomentFanPhotoButtons.propTypes = {
  moment: PropTypes.object.isRequired,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
    .isRequired,
  fanPhotos: PropTypes.array,
};
