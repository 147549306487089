import {
  CINNY_ACCESS_TOKEN,
  CINNY_USER_ID,
  CINNY_DEVICE_ID,
  CINNY_HOMESERVER_BASE_URL,
  CINNY_SUPPORTED_BROWSER_VERSIONS,
} from "../routes/Chat/constants";

/**
 * @param {String} key key of item in localStorage
 * @returns {string} value of found item
 */
export const getSecret = (key) => localStorage.getItem(key);

/**
 * @returns {boolean} true if enough cinny secrets found; false if otherwise
 */
export const isCinnyAuthenticated = () =>
  !!getSecret(CINNY_USER_ID) && !!getSecret(CINNY_ACCESS_TOKEN);

/**
 * @returns {void} use to clear cinny localStorage keys explicitly
 */
export const forceClearCinnyKeys = () => {
  [
    CINNY_ACCESS_TOKEN,
    CINNY_USER_ID,
    CINNY_DEVICE_ID,
    CINNY_HOMESERVER_BASE_URL,
  ].forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const isCinnySupported = () => {
  const userAgent = navigator.userAgent;
  let isSupported = true;

  // Check if the user agent contains "Mobile" to identify mobile browsers
  if (/Mobile/i.test(userAgent)) {
    // Regular expression to extract the browser name and version
    const match = userAgent.match(
      /(Edge|Chrome|Firefox|Safari|Opera|UC Browser|SamsungBrowser)\/([\d.]+)/
    );

    if (match && match.length >= 3) {
      const browserName = match[1];
      const browserVersion = parseFloat(match[2])?.toFixed(2) ?? null;

      if (!!browserName && !!browserVersion) {
        CINNY_SUPPORTED_BROWSER_VERSIONS.map((row) => {
          if (row.browser == browserName) {
            isSupported = browserVersion < row.version ? false : true;

            // ios check
            if (row.browser == "Safari") {
              const iosVersion = userAgent.match(/(Version)\/([\d.]+)/);
              if (!!iosVersion && iosVersion?.length > 2) {
                const iosVersionNumber = parseFloat(iosVersion[2])?.toFixed(2);
                isSupported = iosVersionNumber < row.version ? false : true;
                return isSupported;
              }

              const iosVersion2 = userAgent.match(/iPhone OS (\d+_\d+)/);
              if (!!iosVersion2 && iosVersion2?.length >= 2) {
                const iosVersion2Number = parseFloat(
                  iosVersion2[1].replace("_", ".")
                )?.toFixed(2);
                isSupported = iosVersion2Number < row.version ? false : true;
              }
            }
          }
        });
      }
    }
  }
  return isSupported;
};
