import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getUserLatestMessages,
} from "../../../api/profile";

export const useUserMessages = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["messages", userId.toString()],
    queryFn: async () => {
      const messages = await getUserLatestMessages(userId);
      return messages?.data ?? [];
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch messages every 1 min,
  });
};