import MomentCard from "../../Moments/MomentCard";
import {
  HeaderL,
  HeaderXxl,
  HeaderXxxl,
  Subheader,
} from "../../../GlobalStyles";
import {
  ActionModalContainer,
  ModalContainer,
  ModalWrapper,
  StyledPrimaryButton,
} from "../../../styles/modal.styles";
import useCheckIn from "../../../hooks/useCheckIn";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { setImageOfEvent, setImageofMoment } from "../../../utils/utils";
import { theme } from "../../../utils/theme";
import MomentCardsCarousel from "../../Moments/MomentCardsCarousel";
import { ContentContainer, MomentCardsContainer } from "./MomentCheckIn-Styles";

const { colors } = theme;

export default function MomentCheckIn() {
  const {
    currentEvent,
    momentsCollected,
    selectedPerformances,
    setSuccessfullyEarnedXp,
    successfullyEarnedXp,
    earnedXp,
  } = useCheckIn();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();

  const handleGoToProfile = () => {
    navigate(`/profile/${loggedInUser.id}`);
  };

  const handleFinishedXp = async () => {
    await setSuccessfullyEarnedXp();
  };

  if (loggedInUser && successfullyEarnedXp) {
    return (
      <ModalContainer>
        <ModalWrapper
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          <ContentContainer>
            <HeaderXxl>CONGRATULATIONS!</HeaderXxl>
            <Subheader
              weight="400"
              style={{
                marginTop: "24px",
              }}
            >
              You earned
            </Subheader>
            <HeaderXxxl color={colors.green}>{earnedXp?.earnedXp}XP</HeaderXxxl>
            <Subheader weight="400" color={colors.grey}>
              You now have {earnedXp?.newTotalXp}xp in total.
            </Subheader>
          </ContentContainer>
        </ModalWrapper>
        <ActionModalContainer>
          <StyledPrimaryButton onClick={handleGoToProfile}>
            Finish
          </StyledPrimaryButton>
        </ActionModalContainer>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer isMoment>
      <div></div>
      <ModalWrapper>
        <MomentCardsCarousel>
          {!loggedInUser && selectedPerformances.length > 1 ? (
            selectedPerformances.map((performance, index) => {
              return (
                <MomentCard
                  key={index}
                  performance={performance}
                  event={currentEvent}
                  image={
                    performance?.ai_image_url ?? setImageOfEvent(currentEvent)
                  }
                  isFanPhoto={false}
                  noAccount={true}
                />
              );
            })
          ) : !loggedInUser && selectedPerformances.length === 1 ? (
            <MomentCardsContainer>
              <MomentCard
                event={currentEvent}
                image={
                  selectedPerformances[0]?.ai_image_url ??
                  setImageOfEvent(currentEvent)
                }
                noAccount={true}
              />
            </MomentCardsContainer>
          ) : momentsCollected.length > 1 ? (
            momentsCollected.map((moment, index) => {
              return (
                <MomentCard
                  key={index}
                  moment={moment}
                  event={currentEvent}
                  image={setImageofMoment(moment)}
                  isFanPhoto={false}
                  noAccount={loggedInUser ? false : true}
                  user={loggedInUser}
                />
              );
            })
          ) : (
            <MomentCardsContainer>
              <MomentCard
                moment={momentsCollected[0]}
                event={currentEvent}
                image={setImageofMoment(momentsCollected[0])}
                noAccount={loggedInUser ? false : true}
                user={loggedInUser}
              />
            </MomentCardsContainer>
          )}
        </MomentCardsCarousel>
        <ContentContainer>
          <HeaderL as="h1" style={{ textTransform: "uppercase" }}>
            {momentsCollected.length > 1
              ? "Moment cards unlocked"
              : "Moment card unlocked"}
          </HeaderL>
          <Subheader weight="400" color={colors.grey}>
            Congratulations! You own a piece of history.
          </Subheader>
        </ContentContainer>
      </ModalWrapper>
      <ActionModalContainer>
        <StyledPrimaryButton onClick={handleFinishedXp}>
          Complete
        </StyledPrimaryButton>
      </ActionModalContainer>
    </ModalContainer>
  );
}
