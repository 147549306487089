export const contract = {
  id: 1,
  status: "active",
  fail_reason: null,
  tx_hash: "0x123456789abcdef",
  contract_address: "0xabcdef123456789",
  events_contract_id: "event_contract_001",
  artist_id: "artist_001",
  event_id: "event001",
  royalty: 10,
  fees: 2,
  escrow_address: "0xescrow123456789",
  created_at: "2023-10-01T12:00:00Z",
  per_account_max: 4,
  sale_start_date: "2023-10-10T12:00:00Z",
  resale_max_setting: 150,
  royalties_max_setting: 10,
  image: "https://example.com/image.jpg",
};

export const tickets = [
  {
    id: 1,
    created_at: "2023-10-01T12:00:00Z",
    tickets_contract_id: "abc123",
    type: "VIP",
    resale_max: 150,
    royalties_max: 10,
    name: "VIP Ticket",
    quantity: 5,
    price: 40.19,
    event_id: "event001",
  },
  {
    id: 2,
    created_at: "2023-10-02T12:00:00Z",
    tickets_contract_id: "def456",
    type: "General Admission",
    resale_max: 50,
    royalties_max: 5,
    name: "General Admission",
    quantity: 200,
    price: 20.99,
    event_id: "event002",
  },
  // Add more ticket objects as needed
];
