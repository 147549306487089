import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyText, FooterText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import MomentThumbnail from "../Moments/MomentThumbnail";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px;
`;

export const PastMomentsContainer = styled.div`
  display: grid;
  gap: 16px 8px;
  place-content: center;
  overflow-x: hidden;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(8, minmax(0, 130px));
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(6, minmax(0, 130px));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(4, minmax(0, 130px));
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(3, minmax(0, 130px));
  }

  @media (max-width: 315px) {
    grid-template-columns: repeat(2, minmax(0, 130px));
  }
`;

export default function UserPastMoments({ pastMoments }) {
  return (
    <Container>
      <BodyText weight="600">
        Moments{" "}
        <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
          ({pastMoments?.length})
        </FooterText>
      </BodyText>
      <PastMomentsContainer>
        {pastMoments?.map((moment, index) => (
          <MomentThumbnail key={index} moment={moment} />
        ))}
      </PastMomentsContainer>
    </Container>
  );
}

UserPastMoments.propTypes = {
  pastMoments: PropTypes.array.isRequired,
};
