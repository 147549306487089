import styled from "styled-components";
import {
  BodyText,
  ButtonRect,
  FooterText,
  InvisibleInputImage,
} from "../../GlobalStyles";
import PhotoPlus from "../../assets/svg/photo-plus.svg?react";
import useMomentPhotoUpload from "../../hooks/useMomentPhotoUpload";

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  gap: 16px;
`;

const AddPhotoButton = styled(ButtonRect)`
  padding: 8px;
  position: relative;
  gap: 6px;
  background: rgba(17, 17, 31, 0.8);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function MomentCardUpload() {
  const { handleMediaChange } = useMomentPhotoUpload();

  return (
    <Container>
      <AddPhotoButton>
        <PhotoPlus height={14} width={14} stroke="white" />
        <BodyText weight="600">Add Photo</BodyText>
        <InvisibleInputImage
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            handleMediaChange(e, "image");
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
      </AddPhotoButton>
      <TextContainer>
        <FooterText>Add a photo to capture the Moment</FooterText>
        <FooterText>Available for 48 hours after the event</FooterText>
      </TextContainer>
    </Container>
  );
}
