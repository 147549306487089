import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Tear from "../../assets/svg/tear.svg?react";
import SearchIcon from "../../assets/svg/search.svg?react";
import ArtistItem from "../Artist/ArtistItem";
import { FollowingSkeleton } from "./FollowingSkeleton";
import EmptyState from "../EmptyState";
import RectButtonIconText from "../RectButtonIconText";
import { theme } from "../../utils/theme";
import { BodyText } from "../../GlobalStyles";
import PartialEmptyState from "../PartialEmptyState";
import UserPlus from "../../assets/svg/user-plus.svg?react";
import Bell from "../../assets/svg/bell.svg?react";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 0 20px;
  flex: 1;
`;

export default function ArtistFollowing({ artists, isLoading }) {
  const navigate = useNavigate();
  const [randomizer] = useState(Math.floor(Math.random() * 2));

  if (isLoading) {
    return (
      <Container>
        {Array.from({ length: 12 }).map((_, index) => (
          <FollowingSkeleton key={index} isArtist={true} />
        ))}
      </Container>
    );
  }

  if (!artists || artists.length === 0) {
    // Render the empty tab if there are no artists.
    return (
      <EmptyState
        icon={<Tear height={24} width={24} stroke="white" opacity={0.6} />}
        title="No Favourite Artists?"
        description="Follow and subscribe to artists to get updates and personalised event recommendations."
        button={
          <RectButtonIconText
            icon={
              <SearchIcon stroke={theme.colors.white} height={14} width={14} />
            }
            text={<BodyText weight="600">Search artists</BodyText>}
            onClick={() => {
              navigate("/search?tab=artist");

              setTimeout(() => {
                document.querySelector("input[name=search]")?.focus();
              }, 100);
            }}
          />
        }
      />
    );
  }

  return (
    <Container>
      {artists.map((artist, index) => (
        <ArtistItem key={index} artist={artist} />
      ))}
      {artists.length < 8 && (
        <PartialEmptyState
          icon={
            randomizer === 0 ? (
              <UserPlus
                stroke={theme.colors.white}
                height={24}
                width={24}
                opacity={0.6}
              />
            ) : (
              <Bell
                stroke={theme.colors.white}
                height={24}
                width={24}
                opacity={0.6}
              />
            )
          }
          description={
            randomizer === 0 ? (
              "Follow artists to get smarter event recommendations."
            ) : (
              <BodyText color={theme.colors.grey}>
                Subscribe to artists to hear from them directly. And earn{" "}
                <span style={{ color: theme.colors.green }}>XP.</span>
              </BodyText>
            )
          }
        />
      )}
    </Container>
  );
}

ArtistFollowing.propTypes = {
  artists: PropTypes.array,
  isLoading: PropTypes.bool,
};
