import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import useAuth from "../../../hooks/useAuth";
import MomentThumbnailCard from "../MomentThumbnailCard";
import {
  DetailsContainer,
  IndividualMomentContainer,
  RoomButtonContainer,
} from "./OnePerformanceMoment-Styles";
import { OneLineText } from "../../Artist/ArtistEventDateBox/ArtistEventDateBox-Styles";
import RoomButton from "../../RoomButton";

/**
 * Renders an active moment with details like artist, venue, and room button.
 *
 * @param {object} moment - the moment object containing details like moment_id, headline_artist, performance_artist, and venue_name
 * @param {object} user - the user object
 * @return {React.JSX.Element} Returns the rendered active moment component
 */
export default function OnePerformanceMoment({ moment, user }) {
  const { loggedInUser } = useAuth();
  const { headline_artist, performance_artist, room } = moment;

  // Check if the user is the owner of the profile
  const isOwnProfile = user?.id === loggedInUser?.id;

  // Render the active moment component
  return (
    <IndividualMomentContainer>
      <MomentThumbnailCard moment={moment} type="active" size="big" />
      <DetailsContainer>
        {/* Render performance_artist if not null, else render headline_artist */}
        <OneLineText weight="600">
          {performance_artist ?? headline_artist}
        </OneLineText>
        <RoomButtonContainer>
          {/* Render EventRoomButton if it's the user's own profile */}
          {isOwnProfile && (
            <RoomButton moment={moment} size="big" shape="rect" />
          )}
          {/* Render total_members */}
          {isOwnProfile && (
            <FooterText>{room?.total_members ?? 0} chatting</FooterText>
          )}
        </RoomButtonContainer>
      </DetailsContainer>
    </IndividualMomentContainer>
  );
}

OnePerformanceMoment.propTypes = {
  moment: PropTypes.object,
  user: PropTypes.object,
};
