import PropTypes from "prop-types";
import { Minus, Plus } from "@phosphor-icons/react";
import {
  BodyText,
  Button,
  FooterText,
  HeaderL,
  HeaderSm,
} from "../../../GlobalStyles";
import {
  Container,
  TicketButtons,
  TicketDetails,
  TicketFees,
} from "./Ticket-Styles";
import { useEffect, useState } from "react";
import { useBuyTicketStore } from "../buyTicketStore";
import { formatPrice } from "../../../utils/utils";
import { theme } from "../../../utils/theme";
import { useCheckoutStore } from "../../Checkout/checkoutStore";

export default function Ticket({ ticket, isMaxQuantity }) {
  const { type, price, quantity: ticketQuantity } = ticket || {};
  const { ticketsSelected, updateTicketSelected, handleTotal } =
    useBuyTicketStore();
  const { handleTotal: handleCheckoutTotal } = useCheckoutStore();
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const quantity = ticketsSelected.filter((t) => t.id === ticket.id).length;
    setQuantity(quantity);
  }, [ticketsSelected, ticket.id]);

  const formattedPrice = formatPrice(price);

  const handleQuantityChange = (newQuantity, isRemove) => {
    if (newQuantity > ticketQuantity || newQuantity < 0) return;

    updateTicketSelected(ticket, isRemove);
    handleTotal(price, isRemove);
    handleCheckoutTotal(price, isRemove);
  };

  return (
    <Container>
      <TicketDetails>
        <BodyText weight="600">{type}</BodyText>
        <TicketFees>
          <TicketFees style={{ gap: "0px" }}>
            <HeaderL lineHeight="1">${formattedPrice[0]}</HeaderL>
            <HeaderSm>.{formattedPrice[1] ?? "00"}</HeaderSm>
          </TicketFees>
          <FooterText color={theme.colors.grey}>+$0.00 fee</FooterText>
        </TicketFees>
      </TicketDetails>
      <TicketButtons>
        <Button
          onClick={() => handleQuantityChange(quantity - 1, true)}
          disabled={quantity === 0}
        >
          <Minus size={16} weight="bold" />
        </Button>
        <BodyText weight="600">{quantity}</BodyText>
        <Button
          primary
          onClick={() => handleQuantityChange(quantity + 1)}
          disabled={quantity >= ticketQuantity || isMaxQuantity}
        >
          <Plus size={16} weight="bold" />
        </Button>
      </TicketButtons>
    </Container>
  );
}

Ticket.propTypes = {
  ticket: PropTypes.object.isRequired,
  isMaxQuantity: PropTypes.bool,
};
