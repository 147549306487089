import { useMemo } from "react";
import { BodyText, FooterText, Header } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import {
  Container,
  HeaderContainer,
  Line,
  RecordContainer,
  RecordsContainer,
} from "./XPModal-Styles";
import { useXpHistoryQuery } from "../../../routes/XPHistory/xpHistoryQueries";
import PropTypes from "prop-types";
import XPAction from "../XPAction";
import { useNavigate } from "react-router-dom";
import useGlobalModal from "../../../hooks/useGlobalModal";

const { colors } = theme;

export default function XPModal({ userId }) {
  const { data, isLoading } = useXpHistoryQuery({ userId });
  const navigate = useNavigate();
  const { toggleCenterModal } = useGlobalModal();

  const { totalEarned, totalAvailable } = useMemo(() => {
    if (!data) return { totalEarned: 0, totalAvailable: 0 };

    const records = data.map((record) => record.xp);

    // Total earned by removing negatives
    const totalEarned = records
      .filter((record) => record >= 0)
      .reduce((a, b) => a + b, 0);

    // Total available
    const totalAvailable = records.reduce((a, b) => a + b, 0);

    return { totalEarned, totalAvailable };
  }, [data]) || { totalEarned: 0, totalAvailable: 0 };

  const limitedData = !isLoading ? data?.slice(0, 25) : [];

  const handleNavigate = () => {
    toggleCenterModal();
    navigate(`/profile/${userId}/xp-history`);
  };

  if (isLoading) {
    return (
      <Container>
        <HeaderContainer>
          <Header style={{ textTransform: "uppercase" }}>XP History</Header>
          {Array.from({ length: 2 }).map((_, index) => (
            <RecordContainer
              key={index}
              style={{
                padding: "0 16px",
              }}
            >
              <SkeletonElement height="17px" width="60%" />
              <SkeletonElement height="17px" width="15%" />
            </RecordContainer>
          ))}
          <Line />
        </HeaderContainer>
        <RecordsContainer>
          {Array.from({ length: 25 }).map((_, index) => (
            <RecordContainer key={index}>
              <SkeletonElement height="17px" width="60%" />
              <SkeletonElement height="17px" width="15%" />
            </RecordContainer>
          ))}
        </RecordsContainer>
      </Container>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <Header style={{ textTransform: "uppercase" }}>XP History</Header>
        <RecordContainer
          style={{
            padding: "0 16px",
          }}
        >
          <BodyText color={colors.grey}>Total earned</BodyText>
          <BodyText color={colors.green} weight="600">
            {totalEarned}xp
          </BodyText>
        </RecordContainer>
        <RecordContainer
          style={{
            padding: "0 16px",
          }}
        >
          <BodyText color={colors.grey}>Available</BodyText>
          <BodyText color={colors.green}>{totalAvailable}xp</BodyText>
        </RecordContainer>
        <Line />
      </HeaderContainer>
      <RecordsContainer>
        <RecordsContainer
          style={{
            padding: 0,
            gap: "24px",
          }}
        >
          {limitedData?.length > 0 &&
            limitedData.map((record, index) => {
              if (index > 25) return null;

              const isPositive = record.xp > 0;
              return (
                <RecordContainer key={record.id}>
                  <RecordContainer
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <XPAction action={record?.action_type} />
                  </RecordContainer>
                  <FooterText color={isPositive ? colors.green : colors.red}>
                    {isPositive && "+"}
                    {record?.xp}
                    xp
                  </FooterText>
                </RecordContainer>
              );
            })}
        </RecordsContainer>
        {data?.length > 25 && (
          <FooterText
            color={colors.grey}
            style={{
              cursor: "pointer",
              alignSelf: "center",
              justifySelf: "flex-end",
            }}
            onClick={handleNavigate}
          >
            Show all
          </FooterText>
        )}
      </RecordsContainer>
    </Container>
  );
}

XPModal.propTypes = {
  userId: PropTypes.any,
};
