import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import TopNav from "../../components/TopNav";
import {
  AvatarPlaceholder,
  GlassmorphPlaceholder,
  NavBarTypes,
} from "../../constants/constants";
import { useCollectibleQuery } from "./collectiblesQueries";
import {
  ArtistContainer,
  ChainIconContainer,
  Container,
  DescriptionItemsContainer,
  HeaderContainer,
  Image,
  ImageContainer,
  UserIcon,
} from "./Collectible-Styles";
import {
  BodyText,
  ButtonRectXs,
  FooterText,
  FooterTextSm,
  HeaderXl,
  IconWithText,
  Section,
  Title,
} from "../../GlobalStyles";
import ArtistIcon from "../../components/Artist/ArtistIcon";
import BaseIcon from "../../assets/svg/base.svg?react";
import PosterIcon from "../../assets/svg/poster-icon.svg?react";
import PhotoIcon from "../../assets/svg/photo.svg?react";
import AirdropIcon from "../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../assets/svg/edition-icon.svg?react";
import OpenseaIcon from "../../assets/svg/opensea.svg?react";
import MagicEdenIcon from "../../assets/svg/magic-eden.svg?react";
import USDCIcon from "../../assets/svg/usdc.svg?react";
import { theme } from "../../utils/theme";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";
import ErrorPage from "../../components/ErrorPage";
import moment from "moment";
import CollectibleDescriptionItem from "../../components/Collectibles/CollectibleDescriptionItem";

const { colors } = theme;

export default function Collectible() {
  const { profileId, collectibleId } = useParams();
  const [searchParams] = useSearchParams();
  const editionType = searchParams?.get("type");

  const { data, isLoading, error } = useCollectibleQuery({
    userId: profileId,
    collectibleId,
    type: editionType,
  });
  const navigate = useNavigate();

  const {
    id,
    created_at,
    contract_address,
    contract_type,
    image,
    edition_name,
    artist,
    description,
    chain,
    symbol,
    type,
    transaction_hash,
    user,
    eden_url,
    opensea_url,
    total_supply,
    royalty,
  } = data || {};
  const { artist_id, artist_name, profile_img, banner_img } = artist || {};
  const { id: user_id, avatar, username } = user || {};
  const isAirdrop = editionType === "airdrop";
  const transactionLink = isAirdrop
    ? "https://sepolia.basescan.org/tx/"
    : "https://basescan.org/tx/";
  const foundersPassDesc =
    "Holders of the Founder's Pass have a share in the ownership of Momentify Ltd. Only 500 will ever be claimable. Entitles holders to additional benefits and including $MOMENTS token airdrop multipliers.";

  if (isLoading)
    return (
      <Section>
        <TopNav type={NavBarTypes.COLLECTIBLES} />
        <Container style={{ padding: "0 20px 16px" }}>
          <HeaderContainer>
            <SkeletonElement height="24px" width="100px" />
            <HeaderContainer style={{ gap: "12px" }}>
              <SkeletonElement height="24px" width="80px" />
              <SkeletonElement height="24px" width="80px" />
            </HeaderContainer>
          </HeaderContainer>
          <SkeletonElement height="350px" width="100%" radius="8px" />
          <SkeletonElement height="38px" width="250px" />
          <ArtistContainer>
            <SkeletonElement height="40px" width="40px" radius="50%" />
            <SkeletonElement height="24px" width="100px" />
          </ArtistContainer>
          <HeaderContainer>
            <IconWithText>
              <SkeletonElement height="17px" width="80px" />
              <SkeletonElement height="17px" width="100px" />
            </IconWithText>
            <SkeletonElement height="17px" width="100px" />
          </HeaderContainer>
          <Container style={{ gap: "8px" }}>
            <SkeletonElement height="14px" width="100px" />
            <SkeletonElement height="14px" width="100%" />
            <SkeletonElement height="14px" width="100%" />
            <SkeletonElement height="14px" width="50%" />
          </Container>
          <Container style={{ gap: "12px" }}>
            <SkeletonElement height="20px" width="200px" />
            <DescriptionItemsContainer>
              <SkeletonElement height="27px" width="100%" />
              <SkeletonElement height="27px" width="100%" />
            </DescriptionItemsContainer>
            <DescriptionItemsContainer>
              {Array.from({ length: 6 }).map((_, index) => (
                <SkeletonElement key={index} height="63px" width="100%" />
              ))}
            </DescriptionItemsContainer>
            <SkeletonElement
              height="22px"
              width="150px"
              style={{
                alignSelf: "center",
              }}
            />
          </Container>
        </Container>
      </Section>
    );

  if (error) return <ErrorPage />;

  return (
    <Section>
      <TopNav type={NavBarTypes.COLLECTIBLES} />
      <Container style={{ padding: "0 20px 16px" }}>
        <HeaderContainer>
          <Title>#{id}</Title>
          <HeaderContainer style={{ gap: "12px" }}>
            <IconWithText gap="8px">
              {type === "Poster" ? (
                <PosterIcon stroke={colors.grey} height="17px" width="17px" />
              ) : (
                <PhotoIcon stroke={colors.grey} height="17px" width="17px" />
              )}

              <FooterText
                color={colors.grey}
                style={{ textTransform: "capitalize" }}
              >
                {type === "Poster" ? "Poster" : "Photo"}
              </FooterText>
            </IconWithText>
            {editionType && (
              <IconWithText gap="8px">
                {isAirdrop ? (
                  <AirdropIcon stroke={colors.grey} height={17} width={17} />
                ) : (
                  <EditionIcon stroke={colors.grey} height={17} width={17} />
                )}
                <FooterText
                  color={colors.grey}
                  style={{ textTransform: "capitalize" }}
                >
                  {editionType}
                </FooterText>
              </IconWithText>
            )}
          </HeaderContainer>
        </HeaderContainer>
        <ImageContainer>
          {image && (
            <Image
              src={image}
              alt="collectible"
              onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            />
          )}
          {chain && (
            <ChainIconContainer>
              <BaseIcon />
            </ChainIconContainer>
          )}
        </ImageContainer>
        {edition_name && (
          <HeaderXl style={{ textTransform: "uppercase" }} truncate>
            {edition_name}
          </HeaderXl>
        )}
        <ArtistContainer onClick={() => navigate(`/artists/${artist_id}`)}>
          <ArtistIcon imageUrl={profile_img || banner_img} />
          <BodyText truncate>{artist_name}</BodyText>
        </ArtistContainer>
        <HeaderContainer>
          <IconWithText onClick={() => navigate(`/profile/${user_id}`)}>
            <BodyText weight="600">Owned by</BodyText>
            <IconWithText gap="8px">
              <UserIcon
                src={avatar || AvatarPlaceholder}
                onError={(e) => (e.target.src = AvatarPlaceholder)}
              />
              <FooterText>{username}</FooterText>
            </IconWithText>
          </IconWithText>
          <BodyText color={colors.green}>
            {moment(created_at).format("DD MMM YYYY")}
          </BodyText>
        </HeaderContainer>
        <Container style={{ gap: "8px" }}>
          <BodyText weight="600">Description</BodyText>
          <BodyText color={colors.grey}>
            {!isAirdrop ? foundersPassDesc : description || "No description"}
          </BodyText>
        </Container>
        <Container style={{ gap: "12px" }}>
          <BodyText weight="600">Trade your collectible</BodyText>
          <DescriptionItemsContainer style={{ margin: 0 }}>
            {eden_url && (
              <IconWithText
                style={{ gap: "8px" }}
                as={eden_url ? Link : "div"}
                to={eden_url}
                target="_blank"
              >
                <MagicEdenIcon />
                <FooterText>Magic Eden</FooterText>
              </IconWithText>
            )}
            {opensea_url && (
              <IconWithText
                style={{ gap: "8px" }}
                as={opensea_url ? Link : "div"}
                to={opensea_url}
                target="_blank"
              >
                <OpenseaIcon />
                <FooterText>Opensea</FooterText>
              </IconWithText>
            )}
          </DescriptionItemsContainer>
        </Container>
        <DescriptionItemsContainer>
          <CollectibleDescriptionItem
            label="Price"
            value="FREE!"
            icon={<USDCIcon height={16} width={16} />}
          />
          {contract_type && (
            <CollectibleDescriptionItem
              label="Edition"
              value={contract_type}
              icon={<EditionIcon />}
            />
          )}
          {(typeof royalty === "number" || typeof royalty === "string") && (
            <CollectibleDescriptionItem
              label="Creator earnings"
              value={`${royalty}%`}
            />
          )}
          {total_supply && (
            <CollectibleDescriptionItem
              label="Total supply"
              value={total_supply}
            />
          )}
          {contract_address && (
            <CollectibleDescriptionItem
              label="Contract address"
              value={`${contract_address.slice(0, 4)}...${contract_address.slice(-4)}`}
            />
          )}
          {symbol && (
            <CollectibleDescriptionItem
              label="Contract symbol"
              value={symbol}
            />
          )}
        </DescriptionItemsContainer>
        {transaction_hash && (
          <ButtonRectXs
            style={{ width: "fit-content", alignSelf: "center" }}
            as={Link}
            to={`${transactionLink}${transaction_hash}`}
            target="_blank"
          >
            <FooterTextSm weight="600">See transaction</FooterTextSm>
          </ButtonRectXs>
        )}
      </Container>
    </Section>
  );
}
