import { getMomentByMomentIdUserId } from "../../api/moment";

export const momentLoader = async ({ momentId, ownerId, loggedInUserId }) => {
  const { success, data: moment } = await getMomentByMomentIdUserId({
    momentId,
    ownerId,
    loggedInUserId,
  });

  if (!success) return null;

  return moment;
};
