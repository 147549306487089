import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export const NearbyVenuesSkeleton = () => {
  return (
    <>
      <SkeletonElement
        backgroundColor="#ffffff14"
        width="150px"
        height="21px"
        radius="16px"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              width: "100%",
            }}
          >
            <SkeletonElement
              width="58px"
              height="58px"
              radius="10px"
              backgroundColor="#ffffff14"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                flex: 1,
              }}
            >
              <SkeletonElement
                backgroundColor="#ffffff14"
                width="200px"
                height="18px"
                radius="4px"
              />
              <SkeletonElement
                backgroundColor="#ffffff14"
                width="100px"
                height="14px"
                radius="4px"
              />
              <SkeletonElement
                backgroundColor="#ffffff14"
                width="100px"
                height="14px"
                radius="4px"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
