import styled from "styled-components";
import { Section } from "../../GlobalStyles";

export const ProfileSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: white;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  align-items: ${({ center }) => (center ? "center" : "stretch")};
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  text-align: ${({ center }) => (center ? "center" : "left")};
`;