import { usePrivy } from "@privy-io/react-auth";
import { useContext, useEffect, useRef } from "react";
import { GlobalModalDispatchEvents } from "../constants/dispatchEvents";
import { GlobalModalContext } from "../contexts/ModalContext";
import { checkIfPWAisInstalled } from "../utils/utils";
import PWANudgeModal from "../components/Modal/PWANudgeModal/PWANudge";

export default function useGlobalModal() {
  const { context, dispatch } = useContext(GlobalModalContext);
  const hasNudgeAppeared = useRef(false);
  const {
    isSheetModalOpen,
    isSheetModalClosed,
    isCenterModalOpen,
    isCenterModalClosed,
    sheetModalContent,
    centerModalContent,
    fullModalContent,
  } = context;
  const { ready } = usePrivy();

  useEffect(() => {
    // Initialize the ref from local storage
    const nudgeAppeared = localStorage.getItem("PWA_APPEARED") === "true";
    hasNudgeAppeared.current = nudgeAppeared;

    // Set up the beforeunload event listener to reset the flag
    const handleBeforeUnload = () => {
      localStorage.setItem("PWA_APPEARED", "false");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const toggleSheetModal = () => {
    dispatch(GlobalModalDispatchEvents.TOGGLE_SHEET_MODAL);
  };

  const toggleCenterModal = () => {
    dispatch(GlobalModalDispatchEvents.TOGGLE_CENTER_MODAL);
  };

  const openCenterModal = () => {
    dispatch(GlobalModalDispatchEvents.OPEN_CENTER_MODAL);
  };

  const closeCenterModal = () => {
    dispatch(GlobalModalDispatchEvents.CLOSE_CENTER_MODAL);
  };

  const openSheetModal = () => {
    dispatch(GlobalModalDispatchEvents.OPEN_SHEET_MODAL);
  };

  const closeSheetModal = () => {
    dispatch(GlobalModalDispatchEvents.CLOSE_SHEET_MODAL);
  };

  const setSheetModalContent = (content) => {
    dispatch(GlobalModalDispatchEvents.SET_SHEET_MODAL_CONTENT, content);
  };

  const setCenterModalContent = (content) => {
    dispatch(GlobalModalDispatchEvents.SET_CENTER_MODAL_CONTENT, content);
  };

  const openSheetPWAModal = () => {
    if (!ready) return;

    const isPWAInstalled = checkIfPWAisInstalled();

    if (isPWAInstalled) return;

    if (hasNudgeAppeared.current == true) return;

    hasNudgeAppeared.current = true;
    localStorage.setItem("PWA_APPEARED", "true");
    setSheetModalContent(<PWANudgeModal />);
    openSheetModal();
  };

  return {
    isSheetModalClosed,
    isSheetModalOpen,
    isCenterModalClosed,
    isCenterModalOpen,
    sheetModalContent,
    centerModalContent,
    fullModalContent,
    toggleSheetModal,
    toggleCenterModal,
    openCenterModal,
    closeCenterModal,
    setSheetModalContent,
    setCenterModalContent,
    openSheetPWAModal,
    openSheetModal,
    closeSheetModal,
  };
}
