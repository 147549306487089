import styled from "styled-components";

export const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LineItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LineLeftItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
