import styled from "styled-components";
import { BottomNavBarHeight } from "../../../constants/constants";

export const MenuContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 10px;
  z-index: 1;
`;

export const MomentPhoto = styled.img`
  height: calc(100svh - ${BottomNavBarHeight});
  width: 100vw;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  object-fit: cover;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
`;

export const MomentVideoContainer = styled.div`
  height: calc(100svh - ${BottomNavBarHeight});
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  object-fit: cover;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
`;

export const MomentVideo = styled.video`
  max-height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const RetroMomentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const RetroMomentPhoto = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  filter: brightness(0.8);
`;
