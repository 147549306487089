import styled from "styled-components";
import { HorizontalScrollableContainer, Section } from "../../GlobalStyles";
import { theme } from "../../utils/theme";

export const EventsSection = styled(Section)`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "0")};
  color: white;
  gap: 16px;
  font-family: ${theme.fontStyles.suisse};
  position: relative;
  padding: ${({ padding }) => (padding ? padding : "0")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "normal")};
  min-height: 100%;
`;

export const RemainingEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 0 0 20px 20px;
  position: relative;
`;

export const ScrollableEventsContainer = styled(HorizontalScrollableContainer)`
  gap: 8px;
  margin-right: -20px !important;
  padding-right: 20px;
  margin-left: -20px;
  padding-left: 20px;
  overflow: scroll;
`;

export const EmptyEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 12px;
  padding: 1rem;
`;

export const GetLocationContainer = styled.div`
  position: fixed;

  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 300000;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
