import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
`;

export const MomentCardsContainer = styled.div`
  display: flex;
  position: relative;
  width: ${({ hasFanPhoto }) => (hasFanPhoto ? "95%" : "auto")};
`;
