import styled from "styled-components";

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    stroke: ${({ theme }) => theme.colors.grey};
  }
`;
