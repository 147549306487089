import PropTypes from "prop-types";
import { Arrow, Container, DropdownContainer } from "./Dropdown-Styles";
import { useEffect, useRef, useState } from "react";

export default function Dropdown({ button, children }) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownItemClick = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <div onClick={handleDropdownItemClick} ref={buttonRef}>
        {button}
      </div>
      {open && (
        <DropdownContainer ref={dropdownRef}>
          <Arrow />
          {children}
        </DropdownContainer>
      )}
    </Container>
  );
}

Dropdown.propTypes = {
  button: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};
