import TopNav from "../../components/TopNav";
import { BodyText, ButtonRect, Section } from "../../GlobalStyles";
import {
  Checkbox,
  CheckboxContainer,
  CheckboxItem,
  ContentContainer,
  Textbox,
} from "./Report-Styles";
import FlagIcon from "../../assets/svg/flag.svg?react";
import CheckGreen from "../../assets/svg/check-green.svg?react";
import { theme } from "../../utils/theme";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  REPORT_ENTITY_TYPES,
  REPORT_REASONS,
  REPORT_TYPES,
} from "../../constants/constants";
import { sendReport } from "../../api/report";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import useGlobalModal from "../../hooks/useGlobalModal";
import ReportModal from "../../components/Modal/ReportModal";

const { EVENT } = REPORT_TYPES;
const { EVENT_ENTITY } = REPORT_ENTITY_TYPES;
const {
  MISSING_EVENT,
  WRONG_VENUE,
  DATE_OR_TIME,
  MAIN_ARTIST,
  SUPPORTING_ARTIST,
  IMAGE,
} = REPORT_REASONS;

export default function Report() {
  const { loggedInUser, login } = useMomentifyLogin();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const { register, handleSubmit, watch } = useForm();
  const reference_url = watch("reference_url");
  const [searchParams] = useSearchParams();
  const [selectedReason, setSelectedReason] = useState();
  const type = searchParams.get("type");
  const entityType = searchParams.get("entity_type");
  const entityId = searchParams.get("entity_id")
    ? parseInt(searchParams.get("entity_id"))
    : null;
  const reason = searchParams.get("reason");
  const [submitting, setSubmitting] = useState(false);

  const handleSelect = (info) => {
    selectedReason === info ? setSelectedReason(null) : setSelectedReason(info);
  };

  const showSheetModal = (success) => {
    if (!loggedInUser) return login();

    setSheetModalContent(<ReportModal isSuccess={success} />);
    toggleSheetModal();
  };

  const onSubmit = async (data) => {
    if (!loggedInUser) return;
    setSubmitting(true);

    const { reference_url } = data || {};

    const { success } = await sendReport({
      user_id: loggedInUser.id,
      type,
      entity_type: entityType,
      entity_id: entityId,
      reason: selectedReason || reason,
      reference_url,
    });

    showSheetModal(success);
    setSubmitting(false);
  };

  if (type === EVENT && entityType === EVENT_ENTITY && !reason) {
    return (
      <Section>
        <TopNav type={`Report incorrect event details`} />
        <ContentContainer>
          <BodyText color={theme.colors.grey}>
            {`Tell us what we got wrong, We'll fix it and reward you with `}
            <BodyText color={theme.colors.green}>10xp.</BodyText>
          </BodyText>
          <CheckboxContainer>
            <CheckboxItem onClick={() => handleSelect(WRONG_VENUE)}>
              <Checkbox filled={selectedReason === WRONG_VENUE}>
                {selectedReason === WRONG_VENUE && <CheckGreen />}
              </Checkbox>
              <BodyText>Wrong venue</BodyText>
            </CheckboxItem>
            <CheckboxItem onClick={() => handleSelect(DATE_OR_TIME)}>
              <Checkbox filled={selectedReason === DATE_OR_TIME}>
                {selectedReason === DATE_OR_TIME && <CheckGreen />}
              </Checkbox>
              <BodyText>Date or time</BodyText>
            </CheckboxItem>
            <CheckboxItem onClick={() => handleSelect(MAIN_ARTIST)}>
              <Checkbox filled={selectedReason === MAIN_ARTIST}>
                {selectedReason === MAIN_ARTIST && <CheckGreen />}
              </Checkbox>
              <BodyText>Main artist</BodyText>
            </CheckboxItem>
            <CheckboxItem onClick={() => handleSelect(SUPPORTING_ARTIST)}>
              <Checkbox filled={selectedReason === SUPPORTING_ARTIST}>
                {selectedReason === SUPPORTING_ARTIST && <CheckGreen />}
              </Checkbox>
              <BodyText>Supporting artist</BodyText>
            </CheckboxItem>
            <CheckboxItem onClick={() => handleSelect(IMAGE)}>
              <Checkbox filled={selectedReason === IMAGE}>
                {selectedReason === IMAGE && <CheckGreen />}
              </Checkbox>
              <BodyText>Image</BodyText>
            </CheckboxItem>
          </CheckboxContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textbox
              placeholder="Share any useful supporting info. Including the ticket URL."
              {...register("reference_url")}
            />
            <ButtonRect
              background={theme.colors.greyBtn}
              style={{
                width: "fit-content",
                margin: "0 auto",
                border: "none",
              }}
              type="submit"
              disabled={!selectedReason || !loggedInUser || submitting}
            >
              <FlagIcon stroke={theme.colors.white} />
              <BodyText color={theme.colors.white} weight="600">
                Report
              </BodyText>
            </ButtonRect>
          </form>
        </ContentContainer>
      </Section>
    );
  }

  if (
    type === EVENT &&
    entityType === EVENT_ENTITY &&
    reason === MISSING_EVENT
  ) {
    return (
      <Section>
        <TopNav type={`Report missing event`} />
        <ContentContainer>
          <BodyText color={theme.colors.grey}>
            {`Paste the ticket link (e.g. Ticketmaster URL) for the missing event here. We'll add it and reward you with `}
            <BodyText color={theme.colors.green}>10xp.</BodyText>
          </BodyText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textbox
              placeholder="Add ticket URL here. All sites accepted."
              {...register("reference_url")}
            />
            <ButtonRect
              background={theme.colors.greyBtn}
              style={{
                width: "fit-content",
                margin: "0 auto",
                border: "none",
              }}
              type="submit"
              disabled={!reference_url || !loggedInUser || submitting}
            >
              <FlagIcon stroke={theme.colors.white} />
              <BodyText color={theme.colors.white} weight="600">
                Report
              </BodyText>
            </ButtonRect>
          </form>
        </ContentContainer>
      </Section>
    );
  }
}
