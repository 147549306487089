import styled from "styled-components";
import { Button as _Button } from "../../../GlobalStyles";

export const Button = styled(_Button)`
  height: 16px;
  width: 16px;
  border-radius: 4px;
  padding: 3px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.green : "rgba(255, 255, 255, 0.15)"};
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(0deg, #42fbae1a, #42fbae21)"
      : "rgba(17, 17, 31, 0.2)"};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
