import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  BodyText,
  ButtonRect,
  FooterText,
  FooterTextSm,
} from "../../../GlobalStyles";
import useAuth from "../../../hooks/useAuth";
import { useSubscribeOnboardMutate } from "../../../routes/Artist/hooks/artistQueries";
import { theme } from "../../../utils/theme";

const BottomContainer = styled.div`
  background: linear-gradient(
    rgba(0, 0, 0, 0.27) 0%,
    rgb(0, 0, 0) 20%,
    rgb(0, 0, 0) 84.38%
  );
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  /* padding: 20px 80px 40px 80px; */
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
`;

export default BottomContainer;

export const BottomView = ({ selectedArtists }) => {
  const navigate = useNavigate();
  const artistsCount = selectedArtists.length;
  const { loggedInUser } = useAuth();
  const artistIds = selectedArtists.map((artist) => artist?.id);

  const subscribeOnboardMutation = useSubscribeOnboardMutate({
    loggedInUserId: loggedInUser?.id,
    artistIds: artistIds,
  });

  const onFavorite = async () => {
    await subscribeOnboardMutation.mutateAsync();
    navigate(`/profile/${loggedInUser?.id}`);
    // navigate(`/profile/spotify`);
  };

  const onSkip = () => {
    navigate(`/profile/${loggedInUser?.id}`);
    // navigate(`/profile/spotify`);
  };

  return (
    <BottomContainer>
      {/* Count */}
      <FooterTextSm style={{ color: "#99999D" }}>
        {artistsCount} out of 3
      </FooterTextSm>
      {/* Button */}
      <ButtonRect
        style={{
          borderRadius: "10px",
          backgroundColor: "#F7F7F71F",
          border: "none",

          padding: undefined,
        }}
        disabled={artistsCount == 0}
        onClick={onFavorite}
      >
        <BodyText
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
            alignItems: "center",
          }}
        >
          <BodyText style={{ color: "#F7F7F7" }}>Select and Follow</BodyText>
          <FooterText color={theme.colors.green}>+50xp</FooterText>
        </BodyText>
      </ButtonRect>
      {/* Skip */}
      <BodyText onClick={onSkip} style={{ color: "#F7F7F7", opacity: 0.6 }}>
        Skip (0xp)
      </BodyText>
    </BottomContainer>
  );
};

BottomView.propTypes = {
  selectedArtists: PropTypes.array,
};
