import { BodyText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { Container } from "./BetaIcon-Styles";

export const BetaIcon = () => {
  return (
    <Container>
      <BodyText color={theme.colors.green} weight="600">
        Beta
      </BodyText>
    </Container>
  );
};
