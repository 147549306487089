import { usePrivy, useWallets } from "@privy-io/react-auth";
import TopNav from "../../components/TopNav";
import { NavBarTypes } from "../../constants/constants";
import {
  AddWalletContainer,
  Container,
  ContentContainer,
  PrimaryWalletContainer,
} from "./Wallets-Styles";
import { BodyText, Button } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import WalletCard from "../../components/Wallet/WalletCard";
import Plus from "../../assets/svg/plus.svg?react";
import WalletsSkeleton from "../../components/Wallet/WalletsSkeleton";
import useAuth from "../../hooks/useAuth";
import { useLinkedWallets } from "../../hooks/useLinkedWallets";
import ErrorPage from "../Error";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { colors } = theme;

export default function Wallets() {
  const { ready } = useWallets();
  const { linkWallet, authenticated, user } = usePrivy();
  const { loggedInUser } = useAuth();
  const { linkedWallets } = useLinkedWallets();
  const navigate = useNavigate();
  const { embedded_wallet_id: address } = loggedInUser || {};

  const primaryWallet =
    (linkedWallets || linkedWallets?.length) &&
    linkedWallets.find((wallet) => wallet.address === address);

  useEffect(() => {
    if (!ready) return;

    const redirectToLogin = () => {
      if (!authenticated && !user && !loggedInUser) {
        navigate(`/login`);
      }
    };

    redirectToLogin();
  }, [authenticated, user, loggedInUser, navigate]);

  if (!ready) return <WalletsSkeleton />;

  if (!linkedWallets || linkedWallets?.length === 0) return <ErrorPage />;

  return (
    <Container>
      <TopNav type={NavBarTypes.WALLET} />
      <ContentContainer>
        <PrimaryWalletContainer>
          <BodyText weight="600">Primary Wallet</BodyText>
          <BodyText color={colors.grey}>
            Assets claimed or bought will be sent to the wallet that is set as
            your Primary at the time of any transaction.
          </BodyText>
          <WalletCard wallet={primaryWallet} primary />
        </PrimaryWalletContainer>
        {linkedWallets.length > 1 && (
          <BodyText weight="600">Other Wallets</BodyText>
        )}

        {linkedWallets
          ?.filter((wallet) => wallet.address !== primaryWallet.address)
          ?.map((wallet) => (
            <WalletCard key={wallet.address} wallet={wallet} />
          ))}
        <AddWalletContainer onClick={linkWallet}>
          <Button>
            <Plus height={16} stroke="white" />
          </Button>
          <BodyText color={colors.grey}>Add another wallet</BodyText>
        </AddWalletContainer>
      </ContentContainer>
    </Container>
  );
}
