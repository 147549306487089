import { styled } from "styled-components";
import useCheckIn from "../../hooks/useCheckIn";
import {
  ActionModalContainer,
  ModalContainer,
  StyledSecondaryButton,
  StyledPrimaryButton,
} from "../../styles/modal.styles";
import { BodyText, HeaderL, Subheader } from "../../GlobalStyles";
import PinImageLoading from "../../assets/svg/pin-location-green.svg";
import PinImageFail from "../../assets/svg/pin-location-red.svg";
import CheckInSuccess from "../../assets/svg/checkin-success.svg";
import PropTypes from "prop-types";
import MomentCheckIn from "./MomentCheckIn";
import { StockImage } from "../../constants/constants";
import ClaimMoments from "./ClaimMoments";
import { theme } from "../../utils/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
  flex-direction: column;
  gap: 60px;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;
`;

const EventCardImage = styled.img`
  object-fit: cover;
  height: 242px;
  width: 242px;
  border-radius: 16px;
`;

const StyledPinImage = styled.img`
  height: 40px;
  background: transparent;
`;

export default function CheckInComponent({ onClose, onRefresh }) {
  const {
    currentEvent,
    error,
    isTooFar,
    successfullyCheckedIn,
    successfullyClaimedMoment,
    successfullyEarnedXp,
    momentNeedsLogin,
    selectingMoments,
  } = useCheckIn();
  const { image } = currentEvent;

  let icon, displayStatusTitle, displayStatusSubtitle, actionContent;

  icon = PinImageLoading;
  displayStatusTitle = "Verifying your location";
  displayStatusSubtitle = `Please wait while we confirm you're at the event.`;

  if (isTooFar) {
    icon = PinImageFail;
    displayStatusTitle = "You're too far from the venue";
    displayStatusSubtitle = error;
    actionContent = (
      <>
        <StyledPrimaryButton onClick={onRefresh}>Try Again</StyledPrimaryButton>
        <StyledSecondaryButton onClick={onClose}>
          Go back to feed
        </StyledSecondaryButton>
      </>
    );
  } else if (error) {
    icon = PinImageFail;
    displayStatusTitle = "Oops!";
    displayStatusSubtitle = error;
    actionContent = (
      <StyledSecondaryButton onClick={onClose}>
        Go back to feed
      </StyledSecondaryButton>
    );
  } else if (successfullyCheckedIn) {
    icon = CheckInSuccess;
    displayStatusTitle = "Check in success";
    displayStatusSubtitle = (
      <BodyText color={theme.colors.grey}>
        Wait, there’s more!
        <br />
        You will automatically be redirected to the next page.
      </BodyText>
    );
  }

  if (successfullyClaimedMoment || momentNeedsLogin || successfullyEarnedXp)
    return <MomentCheckIn onClose={onClose} onRefresh={onRefresh} />;

  if (selectingMoments) return <ClaimMoments />;

  return (
    <ModalContainer>
      <div></div>
      <Wrapper>
        <EventCardImage
          src={image == "None" || !image ? StockImage : image}
          alt="event-image"
        />
        <ContentContainer>
          <StyledPinImage src={icon} alt="icon" />
          <HeaderL>{displayStatusTitle.toUpperCase()}</HeaderL>
          <Subheader weight="400" color={theme.colors.grey}>
            {displayStatusSubtitle}
          </Subheader>
        </ContentContainer>
      </Wrapper>
      <ActionModalContainer>{actionContent}</ActionModalContainer>
    </ModalContainer>
  );
}

CheckInComponent.propTypes = {
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
};
