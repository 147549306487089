import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { BgImage } from "../../components/Events/GetLocationPage";
import { ArtistBox } from "../../components/SelectFavoriteArtists/components/ArtistBox";
import { ArtistBoxSkeleton } from "../../components/SelectFavoriteArtists/components/ArtistBoxSkeleton";
import { BottomView } from "../../components/SelectFavoriteArtists/components/BottomView";
import { HeadingText } from "../../components/SelectFavoriteArtists/components/HeadingText";
import { SearchBar } from "../../components/SelectFavoriteArtists/components/SearchBar";
import { BACKGROUND_IMAGES } from "../../constants/constants";
import { useSearchAllArtists } from "../Search/hooks/searchQueries";

const SelectFavoriteArtists = () => {
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 200);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [allLoadedArtists, setAllLoadedArtists] = useState([]);

  const searchArtistQuery = useSearchAllArtists({ query: debouncedSearch });

  useEffect(() => {
    if (!searchArtistQuery.isFetching && !searchArtistQuery.isLoading) {
      const newArtists = searchArtistQuery.data.pages?.flat() ?? [];
      setAllLoadedArtists(newArtists);
    }
  }, [
    searchArtistQuery.isFetching,
    searchArtistQuery.isLoading,
    searchArtistQuery.data,
  ]);

  const filteredArtists = useMemo(() => {
    return allLoadedArtists.filter(
      (artist) => !selectedArtists.some((selected) => selected.id === artist.id)
    );
  }, [allLoadedArtists, selectedArtists]);

  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && searchArtistQuery.hasNextPage) {
          searchArtistQuery.fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [searchArtistQuery.hasNextPage, searchArtistQuery.fetchNextPage]
  );
  const containerRef = useRef();

  // Calculate the appropriate size for ArtistBox using vw
  const artistBoxSize = "calc(20vw - 16px)"; // 25vw ensures 4 items per row on larger screens
  const minArtistBoxSize = "90px"; // Minimum size for very small screens
  const maxArtistBoxSize = "180px"; // Maximum size for larger screens

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [debouncedSearch]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <BgImage src={BACKGROUND_IMAGES.SEARCH.CITY} alt="background image" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 24px 0px 24px",
          alignContent: "center",
          gap: 16,
          overflow: "hidden",
          flexGrow: 1,
          zIndex: 2,
        }}
      >
        <HeadingText />
        <SearchBar search={search} setSearch={setSearch} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            overflow: "auto",
            paddingBottom: 135,
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(auto-fit, minmax(max(${minArtistBoxSize}, min(${artistBoxSize}, ${maxArtistBoxSize})), 1fr))`,
              gap: "16px",
              justifyContent: "center",
              alignContent: "start",
              justifyItems: "center", // Center items horizontally within their grid cells
              alignItems: "center", // Center items vertically within their grid cells
              maxWidth: "1200px", // Adjust this value as needed
              width: "100%",
            }}
            ref={containerRef}
          >
            {selectedArtists?.map((artist, index) => {
              return (
                <ArtistBox
                  innerRef={null}
                  artist={artist}
                  setSelectedArtists={setSelectedArtists}
                  selectedArtists={selectedArtists}
                  key={index}
                />
              );
            })}
            {filteredArtists?.map((artist, index) => {
              const isLastElement = (index + 1) % 26 === 0;

              if (artist.artist_name != null)
                return (
                  <ArtistBox
                    innerRef={isLastElement ? lastElementRef : null}
                    artist={artist}
                    setSelectedArtists={setSelectedArtists}
                    selectedArtists={selectedArtists}
                    key={index}
                  />
                );
              else return null;
            })}
            {(searchArtistQuery.isFetching || searchArtistQuery.isLoading) &&
              Array.from({ length: 12 }).map((_, index) => (
                <ArtistBoxSkeleton key={index} isArtist={true} />
              ))}
          </div>
        </div>
      </div>
      <BottomView selectedArtists={selectedArtists} />
    </div>
  );
};

export default SelectFavoriteArtists;
