import { useLogEventClick } from "../../Events/hooks/interestedQueries";
import useAuth from "../../../hooks/useAuth";
import { useUserStore } from "../../../routes/Profile/store/userStore";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  EventDateContainer,
  EventText,
  EventVenueContainer,
  InterestedButtonContainer,
} from "../../Events/EventCardItem";
import InterestedButton from "../../Events/InterestedButton";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import moment from "moment";
import { theme } from "../../../utils/theme";
import { HeaderSm, HeaderXl } from "../../../GlobalStyles";
import styled from "styled-components";

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.6) 100%
  ); */

  background: linear-gradient(
      180deg,
      rgba(12, 13, 23, 0) 31.53%,
      rgba(12, 13, 23, 0.8) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left !important;
  padding: 16px;
  justify-content: end;
`;

export const WideEventCard = ({
  event,
  isArtist,
  vertical_position = undefined,
  horizontal_position = undefined,
  header = undefined,
}) => {
  const { event_id, combined_artist_ids, artist_id } = event;
  const logEventClick = useLogEventClick();
  const { loggedInUser } = useAuth();
  const { userCountry } = useUserStore();
  const { fontSizes, colors } = theme;

  const eventImage = (event) => {
    const artistImage =
      !event?.artist_image || event?.artist_image == null
        ? null
        : event?.artist_image;

    const eventImage =
      !event?.image || event?.image == null
        ? GlassmorphPlaceholder
        : event?.image;

    return isArtist && artistImage ? artistImage : eventImage;
  };
  return (
    <Link
      style={{
        minWidth: "100%",
        minHeight: 143,
        borderRadius: 12,
        display: "flex",
        backgroundImage: `url(${eventImage(event)})`,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "left !important",
        backgroundSize: "cover", // Ensure the image covers the entire area
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent image repetition
        padding: 12,
        justifyContent: "end",
        position: "relative",
      }}
      to={`/events/${event?.event_id}?date=${event?.date}`}
      onClick={() => {
        logEventClick.mutate({
          user_id: loggedInUser?.id,
          combined_artist_ids: combined_artist_ids ?? [artist_id],
          event_id: event_id,
          horizontal_position: horizontal_position,
          vertical_position: vertical_position,
          header: header,
          country: userCountry,
        });
      }}
    >
      <Overlay
        style={{
          borderRadius: 12,
          padding: 12,
        }}
      >
        <InterestedButtonContainer
          style={{
            top: 12,
            right: 12,
            zIndex: 10,
          }}
        >
          {/* The interested button */}
          <InterestedButton isFilled={true} event={event} size={20} />
        </InterestedButtonContainer>

        <EventVenueContainer>
          <HeaderSm style={{ fontSize: 21.7 }}>
            {event?.venue_name?.toUpperCase()}
          </HeaderSm>
        </EventVenueContainer>

        <EventDateContainer>
          <EventText size={fontSizes.mobile.footer} color={colors.softGreen}>
            {moment(event.date).format("ddd D MMM")}
          </EventText>
          <EventText size={fontSizes.mobile.footer} color={colors.grey}>
            {moment(event.date).format("LT")}
          </EventText>
        </EventDateContainer>
      </Overlay>
    </Link>
  );
};

WideEventCard.propTypes = {
  event: PropTypes.object,
  isArtist: PropTypes.bool,
  isEvent: PropTypes.bool,
  vertical_position: PropTypes.number,
  horizontal_position: PropTypes.number,
  header: PropTypes.string,
};
