import styled from "styled-components";
import { ButtonRect, ButtonSm } from "../../../GlobalStyles";

export const CollectButtonContainer = styled(ButtonRect)`
  padding: 10px 36px;
  background: rgba(255, 255, 255, 0.12);
  border: none;
`;

export const SmallButton = styled(ButtonSm)`
  background-color: ${({ theme }) => theme.colors.green};
  border: none;
  padding: 0px;
`;
