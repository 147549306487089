import { create } from "zustand";

export const useMomentsStore = create((set) => ({
  // FYP Index
  momentsIndex: 0,
  setMomentsIndex: (value) => set(() => ({ momentsIndex: value })),

  // Events Index
  eventsIndex: 0,
  setEventsIndex: (value) => set(() => ({ eventsIndex: value })),
}));
