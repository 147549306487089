import PropTypes from "prop-types";
import { BodyText, Header } from "../../../GlobalStyles";
import useMomentPhotoUpload from "../../../hooks/useMomentPhotoUpload";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { theme } from "../../../utils/theme";
import {
  ButtonsContainer,
  Container,
  HeaderContainer,
  Photo,
  PhotoContainer,
  PrimaryButton,
  SecondaryButton,
  Video,
  VideoContainer,
} from "./UploadMediaModal-Styles";
import useAuth from "../../../hooks/useAuth";

const { colors } = theme;

export default function UploadMediaModal({ media, cropped, type }) {
  const { loggedInUser } = useAuth();
  const {
    handleSubmitMedia,
    uploadVideo,
    loading,
    handleRemoveMediaFromSelection,
    moment,
  } = useMomentPhotoUpload();
  const {
    isCenterModalOpen,
    toggleCenterModal,
    isSheetModalOpen,
    toggleSheetModal,
  } = useGlobalModal();

  const isVideo = type === "video";
  const isRetro = moment?.type === "retro";
  const hasEnoughXp = loggedInUser?.xp >= 10;

  const exitModal = () => {
    handleRemoveMediaFromSelection();
    isCenterModalOpen && toggleCenterModal();
    isSheetModalOpen && toggleSheetModal();
  };

  return (
    <Container>
      <HeaderContainer>
        <Header
          style={{
            textTransform: "uppercase",
          }}
        >
          Upload {isVideo ? "Video" : "Photo"}
        </Header>
        <BodyText color={colors.grey}>
          Add this {isVideo ? "video" : "photo"} as your Moment
          {!isRetro ? (
            "."
          ) : (
            <>
              {" "}
              for <BodyText color={colors.green}>5xp.</BodyText>
            </>
          )}
        </BodyText>
      </HeaderContainer>
      {isVideo ? (
        <VideoContainer>
          <Video src={media} alt="video" autoPlay loop playsInline muted />
        </VideoContainer>
      ) : (
        <PhotoContainer>
          <Photo
            src={media && !cropped ? URL.createObjectURL(media) : media}
            alt="photo"
          />
        </PhotoContainer>
      )}
      {isRetro && (
        <BodyText
          color={colors.grey}
          style={{
            marginBottom: "24px",
          }}
        >
          Available XP:{" "}
          <BodyText color={colors.green}>{loggedInUser?.xp}xp</BodyText>
        </BodyText>
      )}
      <ButtonsContainer>
        <SecondaryButton onClick={exitModal} disabled={loading}>
          <BodyText weight="600">Back</BodyText>
        </SecondaryButton>
        <PrimaryButton
          onClick={isVideo ? uploadVideo : handleSubmitMedia}
          disabled={loading || !media || (isRetro && !hasEnoughXp)}
        >
          <BodyText weight="600">
            {loading ? (
              "Uploading..."
            ) : isRetro ? (
              <>
                Confirm{" "}
                <BodyText color={colors.green} style={{ marginLeft: "8px" }}>
                  -5xp
                </BodyText>
              </>
            ) : (
              "Confirm"
            )}
          </BodyText>
        </PrimaryButton>
      </ButtonsContainer>
    </Container>
  );
}

UploadMediaModal.propTypes = {
  media: PropTypes.any,
  cropped: PropTypes.any,
  type: PropTypes.string,
};
