import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyText, Title, Button as _Button } from "../../GlobalStyles";
import TwoTickets from "../../assets/svg/two-tickets.svg?react";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
`;

const Button = styled(_Button)`
  padding: 13px 19px;
  gap: 8px;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const BadgeIcon = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  object-fit: cover;
  border-radius: 50%;
`;

export default function FestivalPromo({ festival }) {
  const { url, badges } = festival;

  return (
    <Container>
      <Title>Don’t wanna miss anything?</Title>
      <Button as={Link} to={url} target="_blank">
        <TwoTickets height={18} width={18} />
        <BodyText weight="600">Buy all-access pass</BodyText>
      </Button>
      <BodyText as="p">
        The All Access Pass is your season ticket to Access All Areas - one
        ticket that gets you into every single show in the series.
      </BodyText>
      <BodyText>Get our Golden (lvl 3) badge by attending 6 events!</BodyText>
      <BadgeContainer>
        {badges.map(
          (badge, index) =>
            index <= 2 && (
              <BadgeIcon key={index} src={badge.image} size="60px" />
            )
        )}
      </BadgeContainer>
    </Container>
  );
}

FestivalPromo.propTypes = {
  festival: PropTypes.object.isRequired,
};
