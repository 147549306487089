import AAALogo from "/AAA-logo.png";

const festivals = [
	{
		id: 1,
		name: "Access All Areas",
		logo: AAALogo,
		description: "Access All Areas is back! Now in our third year of bringing you the best new and emerging music, we’re stepping it up even further this year. For AAA24 we’re taking our independent festival on the road, showcasing our favourite artists in multiple venues around the city. Over the space of eight weeks we’ll be kicking off your 2024 by introducing you to some incredible musicians and you can get yourself onto the list for every single show. The All Access Pass is your season ticket to Access All Areas - one ticket that gets you into every single show in the series. \n\nAccess All Areas: your ticket to 2024",
		url: "https://link.dice.fm/XAqss5DJHGb",
		image: "https://cdn0.hitched.co.uk/vendor/7241/3_2/960/jpg/2020-sbe8-outdoor-3_4_307241-166082207614870.jpeg",
		venue: "London, Multiple venues",
	},
]

export default festivals;