import PropTypes from "prop-types";
import Countdown from "react-countdown";
import { FooterText } from "../../../GlobalStyles";
import Clock from "../../../assets/svg/clock.svg?react";
import { theme } from "../../../utils/theme";
import {
  Container,
  EventName,
  Header,
  HeaderTextContainer,
  MomentsContainer,
  RemainingTimeContainer,
} from "./ActiveMoment-Styles";
import _moment from "moment";
import OnePerformanceMoment from "../OnePerformanceMoment";
import MultiplePerformancesMoment from "../MultiplePerformancesMoment";
import { OneLineText } from "../../Artist/ArtistEventDateBox/ArtistEventDateBox-Styles";

const { colors } = theme;

/**
 * Renders the countdown component
 * @param {object} moment - The moment object
 * @returns {React.JSX.Element} - The countdown component
 */
const CountdownComponent = ({ moment }) => {
  // Get remaining time by getting moment created time and adding 2 days
  const remainingTime = new Date(moment?.created_at);
  remainingTime.setDate(remainingTime.getDate() + 2);

  return (
    <Countdown
      date={remainingTime}
      renderer={({ days, hours, minutes, completed }) => {
        // Convert days to hours
        hours += days * 24;

        if (completed) return null;

        return (
          // Render remaining time
          <RemainingTimeContainer>
            <Clock
              style={{ position: "relative", bottom: "2px" }}
              height={12}
              width={12}
              fill={colors.green}
            />
            <FooterText color={colors.grey}>
              {" "}
              {hours}h {minutes}m left
            </FooterText>
          </RemainingTimeContainer>
        );
      }}
    />
  );
};

CountdownComponent.propTypes = {
  moment: PropTypes.object.isRequired,
};

/**
 * Renders the active moment component
 * @param {object} user - The user object
 * @param {object} moment - The moment object
 * @returns {React.JSX.Element} - The active moment component
 */
export default function ActiveMoment({ user, moment }) {
  // Destructure event_name and moments from moment object
  const { event_name, moments } = moment;
  const { venue_name, event_date } = moments[0] || {};

  // Render active moment component
  return (
    <Container>
      <Header>
        <HeaderTextContainer>
          <EventName weight="600">{event_name}</EventName>
          <OneLineText size={theme.fontSizes.mobile.footer} color={colors.grey}>
            {venue_name}
          </OneLineText>
        </HeaderTextContainer>
        <HeaderTextContainer
          style={{
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <FooterText color={colors.green}>
            {_moment(event_date).utc().format("ddd DD MMM")}
          </FooterText>
          <CountdownComponent moment={moments[0]} />
        </HeaderTextContainer>
      </Header>
      <MomentsContainer>
        {moments?.length === 1 ? (
          // Render one active moment if there's only one moment
          <OnePerformanceMoment moment={moments[0]} user={user} />
        ) : moments?.length > 1 ? (
          // Render carousel if there's more than one moment
          <MultiplePerformancesMoment moments={moments} user={user} />
        ) : null}
      </MomentsContainer>
    </Container>
  );
}

ActiveMoment.propTypes = {
  user: PropTypes.object,
  moment: PropTypes.object.isRequired,
};
