import styled from "styled-components";
import { ButtonRect, ButtonSm } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ theme }) => theme.colors.background};
  padding: 16px 20px 32px;
  border-radius: 16px;
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const PhotoContainer = styled.div`
  position: relative;
  margin: 16px 0 24px 0;
`;

export const Photo = styled.img`
  height: 300px;
  max-height: 300px;
  border-radius: 6px;
  border: 0.5px solid rgba(255, 255, 255, 0.15);
  object-fit: cover;
  border-radius: 8px;
  aspect-ratio: 9/16;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 16px 0 24px 0;
`;

export const Video = styled.video`
  max-height: 300px;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  border-radius: 8px;
`;

export const ChangePhotoButton = styled(ButtonSm)`
  position: absolute;
  bottom: 3px;
  right: 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export const PrimaryButton = styled(ButtonRect)`
  min-width: fit-content;
  width: 102px;
  border: none;
  background: rgba(255, 255, 255, 0.12);
  position: relative;

  &:disabled {
    opacity: 0.6;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 12px;
  background: transparent;
`;
