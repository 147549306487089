import { useEffect, useImperativeHandle, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import BackIcon from "../../assets/svg/back-no-tail.svg?react";
import CloseIcon from "../../assets/svg/close.svg?react";
import SearchIcon from "../../assets/svg/search.svg?react";
import HintIcon from "../../assets/svg/hint.svg?react";
import EmptyState from "../../components/EmptyState";
import RetroAddFlow from "../../components/Retro/RetroAddFlow";
import InputMode from "../../components/Search/InputMode";
import SearchHome from "../../components/Search/SearchHome";
import SubmitMode from "../../components/Search/SubmitMode";
import {
  RECENT_SEARCH_TYPES,
  SEARCH_PARAMS,
  SEARCH_TABS,
} from "../../constants/search";
import { PermissionState } from "../../enums/permission";
import useAuth from "../../hooks/useAuth";
import useGeolocation from "../../hooks/useGeolocation";
import useGeolocationPermission from "../Events/hooks/geoLocationHooks";
import {
  Form,
  HeaderContainer,
  InputContainer,
  SearchContainer,
  SearchInput,
} from "./Search-Styles";
import { useSearchParamsHook } from "./hooks/searchParams";
import {
  useInfiniteRetroSearch,
  useInfiniteSearch,
  useNearestVenue,
  useSaveSearchHistory,
} from "./hooks/searchQueries";
import { useRetroFlowStore } from "./hooks/searchStore";
import EphemeralTips from "../../components/Tips/EphemeralTips";
import { theme } from "../../utils/theme";
import { FooterText } from "../../GlobalStyles";
import { EPHEMERAL_TIPS } from "../../constants/constants";

const { ARTIST: ARTIST_TAB } = SEARCH_TABS;
const { QUERY, TAB, ARTIST, QUICK_SEARCH } = SEARCH_PARAMS;
const { TEXT } = RECENT_SEARCH_TYPES;

export default function Search() {
  const { loggedInUser } = useAuth(); // To get logged in user info
  const [searchParams, setSearchParams] = useSearchParams(); // Search params
  const { isOpen } = useRetroFlowStore();
  const searchRef = useRef(null);

  const { register, handleSubmit, reset } = useForm(); // React form
  const { name, onChange, onBlur, ref } = register("search"); // Form input
  const permState = useGeolocationPermission();
  const { currentLocation, getCurrentPosition } = useGeolocation();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => searchRef.current);

  // Mutation to save search history
  const saveSearchHistoryMutation = useSaveSearchHistory();

  const {
    query,
    tab,
    country,
    city,
    year,
    month,
    artist,
    isRetro,
    isPast,
    quickSearch,
  } = useSearchParamsHook({
    searchParams,
  });

  // Handle location and session on load
  useEffect(() => {
    if (permState === PermissionState.GRANTED) {
      getCurrentPosition();
    }
  }, [permState]);

  // Search Query
  // Add currentgeo
  const searchQuery = useInfiniteSearch({
    query,
    type: tab || ARTIST_TAB, // Default search type is artist when no tab is set
    country,
    city,
    year,
    month,
    artistId: artist,
    isPast,
    userId: loggedInUser?.id,
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    quickSearch,
    isRetro,
  });

  useNearestVenue({
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
  });

  const isSearchLoading = searchQuery.isLoading;
  const searchResults = searchQuery.data?.pages?.flat();
  const searchError = searchQuery.error;
  const fetchNextPage = searchQuery.fetchNextPage;
  const hasNextPage = searchQuery.hasNextPage;

  const retroSearch = useInfiniteRetroSearch({
    query,
    artistId: artist,
    year,
    month,
    country,
    city,
    isRetro,
    isPast,
    type: tab,
  });
  const isRetroLoading = retroSearch.isLoading || retroSearch.isRefetching;
  const retroResults = retroSearch.data?.pages?.flat();
  const retroError = retroSearch.error;
  const retroFetchNextPage = retroSearch.fetchNextPage;
  const retroHasNextPage = retroSearch.hasNextPage;

  useEffect(() => {
    getCurrentPosition();
  }, [query]);
  // Debounced search
  const debounceSearch = useDebouncedCallback((query) => {
    if (query === undefined) return;

    artist && searchParams.delete(ARTIST);

    quickSearch && searchParams.delete(QUICK_SEARCH);

    searchParams.set(QUERY, query?.trim());

    setSearchParams(searchParams);
  }, 300);

  const handleOnChange = (e) => {
    onChange(e);

    debounceSearch(e.target.value);
  };

  // Submit search and save search history
  const onSubmitEnter = async (data) => {
    if (!data.search || tab || isRetro) return;

    document.querySelector("input[name=search]")?.blur();

    searchParams.set(QUERY, data.search);
    searchParams.set(TAB, "event");
    setSearchParams(searchParams);

    saveSearchHistoryMutation.mutate({
      loggedInUser,
      type: TEXT,
      value: data.search,
    });
  };

  const goToHomeSearch = () => {
    setSearchParams({});

    reset();
    getCurrentPosition();
    // Focus on input field
    setTimeout(() => {
      document.querySelector("input[name=search]")?.focus();
    }, 100);
  };

  const resetSearch = () => {
    searchParams.set(QUERY, "");
    searchParams.delete(ARTIST);
    setSearchParams(searchParams);

    reset();

    // Focus on input field
    setTimeout(() => {
      document.querySelector("input[name=search]")?.focus();
    }, 100);
  };

  return (
    <SearchContainer>
      <Form onSubmit={handleSubmit(onSubmitEnter)} action=".">
        <HeaderContainer>
          {(tab || isRetro) && (
            <BackIcon
              stroke="white"
              height={12}
              width={12}
              onClick={goToHomeSearch}
            />
          )}
          <InputContainer>
            <SearchIcon
              stroke="rgba(255, 255, 255, 0.12)"
              height={16}
              width={16}
            />
            <SearchInput
              type="search"
              name={name}
              enterKeyHint="search"
              defaultValue={query || ""}
              placeholder={
                isRetro
                  ? `Search for an artist to add to Moment`
                  : `What are you looking for?`
              }
              onChange={handleOnChange}
              onBlur={onBlur}
              ref={searchRef}
            />
            {query && (
              <CloseIcon
                stroke="white"
                height={16}
                width={16}
                onClick={resetSearch}
              />
            )}
          </InputContainer>
        </HeaderContainer>
      </Form>
      {!loggedInUser?.location && (
        <EphemeralTips
          icon={<HintIcon fill={theme.colors.green} height={12} width={12} />}
          message={
            <FooterText color={theme.colors.grey}>
              <FooterText
                color={theme.colors.grey}
                style={{ textDecoration: "underline" }}
                onClick={() => navigate("/profile/settings")}
              >
                Add your home city
              </FooterText>{" "}
              for better recommendations.{" "}
              <FooterText color={theme.colors.green}>+50xp</FooterText>
            </FooterText>
          }
          type={EPHEMERAL_TIPS.HOME_TIP_SEARCH}
        />
      )}
      {!query && !tab && !isRetro ? (
        <SearchHome />
      ) : (query || isRetro) && !tab ? (
        <InputMode
          loading={isSearchLoading}
          autocompleteResults={searchResults}
          error={searchError}
        />
      ) : tab ? (
        <SubmitMode
          data={isRetro || isPast ? retroResults : searchResults}
          loading={isRetro || isPast ? isRetroLoading : isSearchLoading}
          error={isRetro || isPast ? retroError : searchError}
          fetchNextPage={isRetro || isPast ? retroFetchNextPage : fetchNextPage}
          hasNextPage={isRetro || isPast ? retroHasNextPage : hasNextPage}
        />
      ) : (
        <EmptyState
          title={"No results found"}
          description={"You can search for other events, artists or venues"}
        />
      )}
      {isOpen && <RetroAddFlow />}
    </SearchContainer>
  );
}
