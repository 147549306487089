import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getUserNotifications,
} from "../../../api/profile";

export const useUserNotifications = ({ userId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["notifications", userId.toString()],
    queryFn: async () => {
      const notifications = await getUserNotifications(userId);
      return notifications?.data ?? [];
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch notif every 1 min,
  });
};