import { getPerformanceMoments } from "../../api/performances";

export const performanceMomentsLoader = async (performanceId, date) => {
  const loggedInUserId = localStorage.getItem("loggedInUserID");

  const performanceMoments = await getPerformanceMoments(
    performanceId,
    loggedInUserId,
    date
  );

  if (!performanceMoments) {
    throw new Error("Failed to fetch performance moments");
  }

  return performanceMoments;
};
