import PropTypes from "prop-types";
import styled from "styled-components";
import MicIcon from "../../assets/svg/mic.svg?react";
import { theme } from "../../utils/theme";

const { colors } = theme;

const ArtistIconImage = styled.img`
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const ArtistIconPlaceholder = styled(ArtistIconImage)`
  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(200px);
  -webkit-backdrop-filter: blur(200px);
`;

export default function ArtistIcon({ imageUrl }) {
  if (imageUrl) {
    return <ArtistIconImage src={imageUrl} alt="artist" />;
  }

  return (
    <ArtistIconPlaceholder as="div">
      <MicIcon stroke={colors.grey} height={14.5} width={14.5} />
    </ArtistIconPlaceholder>
  );
}

ArtistIcon.propTypes = {
  imageUrl: PropTypes.string,
};
