import PropTypes from "prop-types";
import { BodyText, ButtonGhost } from "../../../GlobalStyles";
import ThreeDots from "../../../assets/svg/three-dots.svg?react";
import Trash from "../../../assets/svg/trash.svg?react";
import { useEffect, useRef, useState } from "react";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import DeleteMomentModal from "../../Modal/DeleteMomentModal";
import { DropdownItem } from "../Dropdown-Styles";
import Dropdown from "..";

export default function MomentDropdown({ momentId, userId }) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const dropdownRef = useRef();
  const { setCenterModalContent, toggleCenterModal } = useGlobalModal();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownItemClick = () => {
    setOpen(!open);
  };

  const handleDeleteMomentClick = () => {
    setCenterModalContent(
      <DeleteMomentModal userId={userId} momentId={momentId} />
    );
    toggleCenterModal();
    handleDropdownItemClick();
  };

  return (
    <Dropdown
      button={
        <ButtonGhost onClick={handleDropdownItemClick} ref={buttonRef}>
          <ThreeDots />
        </ButtonGhost>
      }
    >
      <DropdownItem onClick={handleDeleteMomentClick}>
        <Trash stroke={theme.colors.red} height={16} width={16} />
        <BodyText style={{ textWrap: "nowrap" }}>Delete Moment</BodyText>
      </DropdownItem>
    </Dropdown>
  );
}

MomentDropdown.propTypes = {
  momentId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};
