import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-blend-mode: multiply;
  border-radius: 0 0 20px 20px;
  flex: 1;
  overflow-y: scroll;
`;

export const CountryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CountryName = styled(BodyText)`
  color: ${({ theme, color }) => color || theme.colors.grey};
  margin-bottom: 8px;
`;

export const CitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 8px;
`;
