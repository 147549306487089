import styled from "styled-components";
import PropTypes from "prop-types";
import { NavBarTypes } from "../constants/constants";
import { Title } from "../GlobalStyles";
import { useEffect, useState } from "react";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 16px 20px;
  background: ${({ blur }) => (!blur ? "transparent" : "rgba(0, 0, 0, 0.5)")};
  backdrop-filter: ${({ blur }) => (!blur ? "none" : "blur(5px)")};
  -webkit-backdrop-filter: ${({ blur }) => (!blur ? "none" : "blur(5px)")};
  transition: 0.3s ease;
  border-bottom: ${({ type }) =>
    type == NavBarTypes.BADGES ? "1px solid rgba(255, 255, 255, 0.1)" : "none"};
`;

const NavItemsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavImage = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  align-self: start;
  margin-right: 16px;
`;

/**
 * Top navigation bar component
 * @param {string} type - The type of navigation bar
 * @returns {React.JSX.Element} - The top navigation bar
 */
export default function TopNav({ type, img, onBack, rightContent }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const navigate = useNavigate();

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    setPrevScrollPos(scrollTop);
  };

  const handleBack = () => {
    onBack ? onBack() : navigate(-1);
  };

  // Effect to add and remove scroll event listener
  useEffect(() => {
    const rootElement = document.querySelector("#root");
    rootElement.addEventListener("scroll", handleScroll);

    return () => {
      rootElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Return the navigation bar components based on the type and profile ownership
  return (
    <NavContainer blur={prevScrollPos > 5} type={type}>
      <NavItemsContainer>
        {type != NavBarTypes.CREATE_PROFILE && (
          <BackButton onClick={handleBack} />
        )}
        {img && <NavImage src={img} />}
        <Title>{type}</Title>
      </NavItemsContainer>
      {rightContent}
    </NavContainer>
  );
}

TopNav.propTypes = {
  type: PropTypes.any,
  img: PropTypes.string,
  onBack: PropTypes.func,
  rightContent: PropTypes.any,
};
