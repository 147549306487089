import { XP_ACTION_TYPES } from "../constants/constants";
import Bookmark from "../assets/svg/bookmark.svg?react";
import PhotoPlus from "../assets/svg/photo-plus.svg?react";
import { Heart } from "@phosphor-icons/react";
import BellCheck from "../assets/svg/bell-check.svg?react";
import Ticket from "../assets/svg/mint-moment-icon.svg?react";
import Invite from "../assets/svg/user-plus.svg?react";
import Mail from "../assets/svg/mail-plus.svg?react";
import Pin from "../assets/svg/pin-white.svg?react";
import Flag from "../assets/svg/flag.svg?react";
import Mic from "../assets/svg/mic.svg?react";
import { theme } from "./theme";
import { BodyText } from "../GlobalStyles";

export const handleXPActionText = (action) => {
  const {
    LIKE,
    SUBSCRIBE_ARTIST,
    INVITE_FRIEND,
    EVENT_CHECKIN,
    ADD_MOMENT_PHOTO,
    COLLECT_MOMENT,
    LINK_EMAIL,
    ADD_LOCATION,
    ADD_MOMENT_PHOTO_SUBTRACT,
    DATA_REPORT,
    ADD_FAV_ARTIST,
  } = XP_ACTION_TYPES;

  switch (action) {
    case LIKE:
      return "Received a like on your photo";
    case SUBSCRIBE_ARTIST:
      return "Subscribed to an artist";
    case INVITE_FRIEND:
      return "Invited a friend";
    case EVENT_CHECKIN:
      return "Checked in at an event";
    case ADD_MOMENT_PHOTO:
      return "Added a photo";
    case COLLECT_MOMENT:
      return "Collected a Moment";
    case LINK_EMAIL:
      return "Connected your email";
    case ADD_LOCATION:
      return "Added your home location";
    case ADD_MOMENT_PHOTO_SUBTRACT:
      return "Added a photo to a past Moment";
    case DATA_REPORT:
      return "Reported incorrect event data";
    case ADD_FAV_ARTIST:
      return "Added a favorite artist";
    default:
      return "XP added";
  }
};

export const handleXPActionIcon = (action) => {
  const {
    LIKE,
    SUBSCRIBE_ARTIST,
    INVITE_FRIEND,
    EVENT_CHECKIN,
    ADD_MOMENT_PHOTO,
    COLLECT_MOMENT,
    LINK_EMAIL,
    ADD_LOCATION,
    ADD_MOMENT_PHOTO_SUBTRACT,
    DATA_REPORT,
    ADD_FAV_ARTIST,
  } = XP_ACTION_TYPES;

  const iconSize = 14;

  switch (action) {
    case LIKE:
      return (
        <Heart
          height={iconSize + 1}
          width={iconSize + 1}
          color={theme.colors.grey}
        />
      );
    case SUBSCRIBE_ARTIST:
      return <BellCheck height={iconSize} width={iconSize} />;
    case INVITE_FRIEND:
      return <Invite height={iconSize} width={iconSize} />;
    case EVENT_CHECKIN:
      return <Bookmark height={iconSize} width={iconSize} />;
    case ADD_MOMENT_PHOTO:
      return <PhotoPlus height={iconSize} width={iconSize} />;
    case COLLECT_MOMENT:
      return <Ticket height={iconSize} width={iconSize} />;
    case LINK_EMAIL:
      return <Mail height={iconSize} width={iconSize} />;
    case ADD_LOCATION:
      return <Pin height={iconSize} width={iconSize} />;
    case ADD_MOMENT_PHOTO_SUBTRACT:
      return <PhotoPlus height={iconSize} width={iconSize} />;
    case DATA_REPORT:
      return <Flag height={iconSize} width={iconSize} />;
    case ADD_FAV_ARTIST:
      return <Mic height={iconSize} width={iconSize} />;
    default:
      return (
        <BodyText
          color={theme.colors.grey}
          style={{
            paddingLeft: "2px",
            width: iconSize,
          }}
        >
          +
        </BodyText>
      );
  }
};
