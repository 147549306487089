import styled from "styled-components";
import { ButtonRect, Section } from "../../GlobalStyles";
import {
  BACKGROUND_IMAGES,
  BottomNavBarHeight,
} from "../../constants/constants";

export const LoginSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  background: url(${BACKGROUND_IMAGES.LOGIN});
  background-size: cover;
  background-position: center;
`;

export const Navbar = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LogoImage = styled.img`
  height: 32px;
  background: transparent;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: calc(${BottomNavBarHeight} - 8px) 20px 0;
  gap: 195px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const LoginButton = styled(ButtonRect)`
  background: ${({ theme }) => theme.colors.green};
  min-width: 160px;
`;
