import { IconWithText } from "../../../GlobalStyles";
import { Container } from "./Tip-Styles";
import PropTypes from "prop-types";

export default function Tip({ icon, text, style }) {
  return (
    <Container style={style}>
      <IconWithText>
        {icon}
        {text}
      </IconWithText>
    </Container>
  );
}

Tip.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  style: PropTypes.object,
};
