import { NavBarTypes } from "../../../constants/constants";
import {
  Container,
  ContentContainer,
  PrimaryWalletContainer,
} from "../../../routes/Wallets/Wallets-Styles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import TopNav from "../../TopNav";

export default function WalletsSkeleton() {
  return (
    <Container>
      <TopNav type={NavBarTypes.WALLET} />
      <ContentContainer>
        <PrimaryWalletContainer>
          <SkeletonElement height="20px" width="120px" />
          <SkeletonElement height="16px" />
          <SkeletonElement height="16px" width="50%" />
          <SkeletonElement height="208px" radius="10px" />
        </PrimaryWalletContainer>
        <SkeletonElement height="20px" width="120px" />
        <SkeletonElement height="153px" radius="10px" />
        <SkeletonElement height="153px" radius="10px" />
        <SkeletonElement height="153px" radius="10px" />
      </ContentContainer>
    </Container>
  );
}
