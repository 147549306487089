import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addEventParticipant,
  insertEventPageClick,
  removeEventParticipant,
} from "../../../api/events";

export const useToggleInterested = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["toggleInterested"],
    mutationFn: async ({
      interested,
      eventId,
      loggedInUser,
      errorHandling,
      event,
    }) => {
      if (interested)
        removeEventParticipant(eventId, loggedInUser, "interested").catch(
          errorHandling
        );
      // If user is not interested, add them to the list
      else {
        const room = await addEventParticipant(
          eventId,
          loggedInUser,
          "interested",
          event.headline_artist,
          event.combined_artist_ids ?? [],
        ).catch(errorHandling);

        room.total_members =
          room?.total_members < 0 || room?.total_members == null
            ? 0
            : room.total_members;

        if (
          queryClient.getQueryData([
            "event",
            eventId.toString(),
            loggedInUser?.id,
          ]) != null
        )
          queryClient.setQueryData(
            ["event", eventId.toString(), loggedInUser?.id],
            (oldData) => {
              return {
                ...oldData,
                room: room, // Update the room data
              };
            }
          );
      }
    },
  });
};


export const useLogEventClick = () => {

  return useMutation({
    mutationKey: ["event-page-click"],
    mutationFn: ({
      user_id,
      combined_artist_ids,
      event_id,
      horizontal_position,
      vertical_position,
      header,
      country,
    }) => insertEventPageClick({user_id,
                          combined_artist_ids,
                          event_id,
                          horizontal_position,
                          vertical_position,
                          header,
                          country
                          })
    });
};
