import { Button } from "./SelectButton-Styles";
import CheckGreen from "../../../assets/svg/check-green.svg?react";
import PropTypes from "prop-types";

export default function SelectButton({ selected, handleClick, style }) {
  return (
    <Button selected={selected} onClick={handleClick} style={style}>
      {selected && <CheckGreen />}
    </Button>
  );
}

SelectButton.propTypes = {
  selected: PropTypes.bool,
  handleClick: PropTypes.func,
  style: PropTypes.object,
};
