import PropTypes from "prop-types";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { theme } from "../../../utils/theme";
import {
  Container,
  Content,
  Header,
  ModalButton,
} from "../SubscribeModal/SubscribeModal-Styles";
import FlagIcon from "../../../assets/svg/flag.svg?react";
import { BodyText, HeaderL } from "../../../GlobalStyles";
import { useNavigate } from "react-router-dom";

const { colors } = theme;

export default function ReportModal({ isSuccess }) {
  const { toggleSheetModal } = useGlobalModal();
  const navigate = useNavigate();

  const handleOnClick = async () => {
    toggleSheetModal();

    if (isSuccess) navigate(-1);
  };

  return (
    <Container>
      <Header>
        <FlagIcon height={24} width={24} stroke={colors.white} />
        <HeaderL style={{ textTransform: "uppercase" }}>
          {isSuccess ? "We Received Your Report" : "Something Went Wrong"}
        </HeaderL>
      </Header>
      <Content>
        {isSuccess ? (
          <BodyText color={colors.grey}>
            Thank you for making Momentify a better place. We’ll award you with{" "}
            <BodyText color={colors.green}>10xp</BodyText> upon approval.
          </BodyText>
        ) : (
          <BodyText color={colors.grey}>
            Woops, something went wrong. Can you try submitting your report
            again?
          </BodyText>
        )}
        <ModalButton onClick={handleOnClick}>
          <BodyText weight="600">Confirm</BodyText>
        </ModalButton>
      </Content>
    </Container>
  );
}

ReportModal.propTypes = {
  isSuccess: PropTypes.bool,
};
