import { BodyText, Section } from "../GlobalStyles";
import { styled } from "styled-components";
import BackButton from "./BackButton";
import PropTypes from "prop-types";

const ProfileSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 1rem;
  gap: 20px;
  position: relative;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export default function ErrorPage({ errorMessage }) {
  return (
    <ProfileSection>
      <BackButtonContainer>
        <BackButton />
      </BackButtonContainer>
      <BodyText data-id="error-page-message">{errorMessage}</BodyText>
    </ProfileSection>
  );
}

ErrorPage.propTypes = {
  errorMessage: PropTypes.string,
};
