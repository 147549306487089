import PropTypes from "prop-types";
import styled from "styled-components";
import CheckGreen from "../../assets/svg/check-green.svg?react";
import MomentifyLogo from "../../assets/svg/momentify.svg?react";
import { StockImage } from "../../constants/constants";
import { BodyText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";

const { fontSizes } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  position: relative;
  overflow: visible;
`;

const Border = styled.div`
  height: 160px;
  min-height: 160px;
  max-height: 160px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  padding: 4px;
  background: linear-gradient(
    rgba(180, 180, 180, 0.15),
    rgba(255, 255, 255, 0.15),
    rgba(175, 175, 175, 0.15)
  );
  backdrop-filter: blur(90px);
  -webkit-backdrop-filter: blur(90px);
  border: 1.2px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  gap: 12px;
`;

const Logo = styled(MomentifyLogo)`
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 1;
  height: 15px;
  width: 15px;
  stroke: white;
  fill: white;
`;

const MomentImage = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  border-radius: 4px;
  background: ${({ src }) =>
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${src})`};
  background-size: cover;
  background-position: center;
`;

const MomentName = styled(BodyText)`
  /* Make Text two lines then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
`;

export const SelectedButton = styled.button`
  height: 24px;
  width: 24px;
  border-radius: 8px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.green : "rgba(255, 255, 255, 0.15)"};
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(0deg, #42fbae1a, #42fbae21)"
      : "rgba(17, 17, 31, 0.2)"};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(30%, -30%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function PerformanceItem({
  performance,
  event,
  onClick,
  selected,
}) {
  const image = performance?.ai_image_url ?? event?.image ?? StockImage;

  return (
    <Container onClick={onClick}>
      <SelectedButton selected={selected}>
        {selected && <CheckGreen />}
      </SelectedButton>
      <Border>
        <ImageContainer>
          <Logo />
          <MomentImage src={image} alt="performance" />
        </ImageContainer>
      </Border>
      <MomentName size={fontSizes.mobile.body}>{performance.artist}</MomentName>
    </Container>
  );
}

PerformanceItem.propTypes = {
  performance: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};
