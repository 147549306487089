import styled from "styled-components";
import { Subheader } from "../../../GlobalStyles";

export const MomentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: flex-end;
  z-index: 2;
  width: 100%;
  padding: 20px;
  gap: 16px;
`;

export const MomentInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
`;

export const MomentInfoText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UserAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  transform: translateX(-8px);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const EventDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EventName = styled(Subheader)`
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
