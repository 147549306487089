import { erc20Abi, formatUnits } from "viem";
import { useReadContracts } from "wagmi";
import { formatPrice } from "../utils/utils";
import useAuth from "./useAuth";
import { useLinkedWallets } from "./useLinkedWallets";
import { useMemo } from "react";
import { USDC_ADDRESS } from "../constants/constants";

export const usePrimaryWalletBalance = () => {
  const { loggedInUser } = useAuth();
  const { linkedWallets } = useLinkedWallets();
  const { embedded_wallet_id } = loggedInUser || {};

  const primaryWallet = useMemo(() => {
    return (
      (linkedWallets || linkedWallets?.length) &&
      linkedWallets.find((wallet) => wallet.address === embedded_wallet_id)
    );
  }, [linkedWallets, embedded_wallet_id]);

  const { address } = primaryWallet || {};

  const { data, isLoading, error } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: "decimals",
      },
    ],
  });

  if (!primaryWallet) return { balance: "0.00", isLoading: false };

  const { 0: balance, 1: decimals } = data || {};

  const formattedBalance =
    data && !isLoading && formatUnits(balance?.result, decimals?.result); // USDC has 6 decimal places

  const formattedPrice = formatPrice(formattedBalance, false);

  return { balance: formattedPrice, isLoading, error };
};
