import { getOneEvent } from "../../api/events";
import {
  isCinnyAuthenticated,
  isCinnySupported,
} from "../../utils/cinny-utils";
import { roomURLHandler } from "../../utils/matrix-utils";

export const eventLoader = async (eventId, userId, date) => {
  let event = await getOneEvent(eventId, userId, date);

  if (isCinnySupported() && isCinnyAuthenticated()) {
    event = roomURLHandler(event);
  }

  return event;
};
