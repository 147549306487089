import { createContext, useState } from "react";
import { LocationDispatchEvents } from "../constants/dispatchEvents";

export const LocationContext = createContext({});

const initialState = {
  currentLocation: null,
  currentCountry: null,
  currentCity: null,
  isGeolocationPermitted: false,
  permissionState: null,
  isLoading: false,
  error: null,
  isLocationSkipped: false,
};

const LocationContextProvider = (params) => {
  const { children } = params;
  const [context, setContext] = useState(initialState);

  function dispatch(actionType, payload) {
    switch (actionType) {
      case LocationDispatchEvents.TOGGLE_LOADING:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: context.isLoading,
          }));
        }
        break;
      case LocationDispatchEvents.SET_LOCATION:
        {
          setContext((prev) => ({
            ...prev,
            currentLocation: payload,
            error: null,
          }));
        }
        break;
      case LocationDispatchEvents.SET_COUNTRY:
        {
          setContext((prev) => ({
            ...prev,
            currentCountry: payload,
            error: null,
          }));
        }
        break;
      case LocationDispatchEvents.SET_CITY:
        {
          setContext((prev) => ({
            ...prev,
            currentCity: payload,
            error: null,
          }));
        }
        break;
      case LocationDispatchEvents.HAS_PERMISSION:
        {
          setContext((prev) => ({
            ...prev,
            isGeolocationPermitted: true,
            error: null,
          }));
        }
        break;
      case LocationDispatchEvents.NOT_SUPPORTED:
        {
          setContext((prev) => ({
            ...prev,
            isGeolocationPermitted: false,
            error: "Geolocation is not supported by this browser.",
          }));
        }
        break;
      case LocationDispatchEvents.DENIED:
        {
          setContext((prev) => ({
            ...prev,
            isGeolocationPermitted: false,
            permissionState: "denied",
            error:
              "Looks like you denied permission to check your location, go to browser settings to check for nearby events so you do not miss out on exciting stuff!",
          }));
        }
        break;
      case LocationDispatchEvents.SET_PERMISSION_STATE:
        {
          setContext((prev) => ({
            ...prev,
            permissionState: payload,
          }));
        }
        break;
      case LocationDispatchEvents.SET_ERROR_MESSAGE:
        {
          setContext((prev) => ({
            ...prev,
            error: payload,
          }));
        }
        break;
      case LocationDispatchEvents.RESET_LOCATION:
        {
          setContext((prev) => ({
            ...prev,
            currentLocation: null,
          }));
        }
        break;
    }
  }

  return (
    <LocationContext.Provider value={{ context, dispatch }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContextProvider;
