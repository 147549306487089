import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import {
  deleteFanMomentPhoto,
  uploadFanMomentPhoto,
  uploadFanMomentVideo,
} from "../api/moment";
import { removeUserAction, setUserAction } from "../api/profile";
import { MomentUploadDispatchEvents } from "../constants/dispatchEvents";
import { MomentUploadContext } from "../contexts/MomentUploadContext";
import useGlobalModal from "./useGlobalModal";
import { readFile } from "../utils/canvas-utils";
import { useImageEditorStore } from "../components/ImageEditor/imageEditorStore";
import { useVideoEditorStore } from "../components/VideoEditor/videoEditorStore";

function useMomentPhotoUpload() {
  const { context, dispatch } = useContext(MomentUploadContext);
  const {
    setCenterModalContent,
    toggleCenterModal,
    isCenterModalOpen,
    isSheetModalOpen,
    toggleSheetModal,
    setSheetModalContent,
  } = useGlobalModal();
  const { fanMedia, loading, moment, error } = context;
  const queryClient = useQueryClient();
  const { setIsOpen, setImage, imageToBeUploaded, reset } =
    useImageEditorStore();
  const {
    videoToUpload,
    reset: resetVideo,
    setIsVideoUploading,
    resetKeepOpen,
    setVideo,
    setOutputVideo,
    isOpen: isVideoEditorOpen,
    setIsOpen: setIsVideoEditorOpen,
  } = useVideoEditorStore();
  const isVideo = moment?.fan_photo_urls?.some((url) => url.includes("mp4"));

  const handleMediaChange = async (e, type) => {
    if (loading) return;
    if (type === "image") {
      const imageToBeUploaded = e.target.files[0];

      if (!imageToBeUploaded) return;

      const image = await readFile(imageToBeUploaded);

      setImage(image);
      setIsOpen(true);
    } else {
      const file = e.target.files[0];

      if (file) {
        resetKeepOpen();
        if (!isVideoEditorOpen) setIsVideoEditorOpen(true);
        setVideo(URL.createObjectURL(file));
        setOutputVideo(null);
      }
    }
  };

  const handleRemoveMediaFromSelection = async () => {
    if (loading) return;

    dispatch(MomentUploadDispatchEvents.SET_LOADING, true);

    dispatch(MomentUploadDispatchEvents.SET_FAN_MEDIA, null);

    dispatch(MomentUploadDispatchEvents.SET_LOADING, false);
  };

  const handleRemoveMediaFromMoment = async () => {
    toast.dismiss();

    if (loading || !moment) return;

    dispatch(MomentUploadDispatchEvents.SET_LOADING, true);

    const { moment_id, user_id, type } = moment || {};

    toggleCenterModal();
    setCenterModalContent(null);

    await toast.promise(
      async () => {
        await deleteFanMomentPhoto(moment_id, user_id);

        const isRetro = type === "retro";

        await removeUserAction({
          action_type:
            isRetro && isVideo
              ? "add_moment_video_subtract"
              : isRetro && !isVideo
                ? "add_moment_photo_subtract"
                : !isRetro && isVideo
                  ? "add_moment_video"
                  : "add_moment_photo",
          user_id: user_id,
          target_type: isVideo ? "moment.video" : "moment.photo",
          target_id: moment_id ?? null,
        });

        dispatch(MomentUploadDispatchEvents.SET_LOADING, false);

        queryClient.invalidateQueries({
          queryKey: ["moment", moment_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["profile", user_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["stats", user_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["moments-feed", user_id],
        });
        queryClient.invalidateQueries({
          queryKey: ["events-feed", user_id],
        });
      },
      {
        pending: `Deleting ${isVideo ? "video" : "image"}...`,
        success: `${isVideo ? "Video" : "Image"} deleted`,
        error: `Error deleting ${isVideo ? "video" : "image"}!`,
      }
    );
  };

  const handleSubmitMedia = async () => {
    toast.dismiss();

    if (loading || !moment) return;

    dispatch(MomentUploadDispatchEvents.SET_LOADING, true);

    const image = fanMedia ?? imageToBeUploaded;

    if (!image) {
      dispatch(MomentUploadDispatchEvents.SET_LOADING, false);
      return;
    }

    const { moment_id, user_id, type, performance_artist_id } = moment || {};

    isCenterModalOpen && toggleCenterModal();
    isSheetModalOpen && toggleSheetModal();

    setCenterModalContent(null);
    setSheetModalContent(null);

    setIsOpen(false);
    reset();

    await toast.promise(
      async () => {
        await uploadFanMomentPhoto(moment_id, user_id, image);

        dispatch(MomentUploadDispatchEvents.SET_FAN_MEDIA, null);

        dispatch(MomentUploadDispatchEvents.SET_LOADING, false);

        const isRetro = type === "retro";

        await setUserAction({
          action_type: isRetro
            ? "add_moment_photo_subtract"
            : "add_moment_photo",
          user_id: user_id,
          target_type: "moment.photo",
          target_id: moment_id ?? null,
          artist_id: performance_artist_id,
        });

        queryClient.invalidateQueries({
          queryKey: ["moments", user_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["stats", user_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["moment", moment_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["moments-feed", user_id],
        });
        queryClient.invalidateQueries({
          queryKey: ["events-feed", user_id],
        });
      },
      {
        pending: "Uploading photo...",
        success: `Photo uploaded.`,
        error: "Error uploading photo.",
      }
    );

    setTimeout(() => {
      // Scroll to left side
      const carousel = document.getElementById("moment-carousel");

      if (!carousel) return;

      carousel.scrollTo({
        top: 0,
        left: 0,
      });
    }, 2000);
  };

  const uploadVideo = async () => {
    if (!moment || !videoToUpload) return;

    const { moment_id, user_id, performance_artist_id, type } = moment || {};

    setIsVideoUploading(true);
    setIsVideoEditorOpen(false);

    isCenterModalOpen && toggleCenterModal();
    isSheetModalOpen && toggleSheetModal();

    setCenterModalContent(null);
    setSheetModalContent(null);

    await toast.promise(
      async () => {
        await uploadFanMomentVideo(moment_id, user_id, videoToUpload);
        const isRetro = type === "retro";

        await setUserAction({
          action_type: isRetro
            ? "add_moment_video_subtract"
            : "add_moment_video",
          user_id: user_id,
          target_type: "moment.video",
          target_id: moment_id ?? null,
          artist_id: performance_artist_id,
        });

        queryClient.invalidateQueries({
          queryKey: ["moments", user_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["stats", user_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["moment", moment_id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: ["moments-feed", user_id],
        });
        queryClient.invalidateQueries({
          queryKey: ["events-feed", user_id],
        });
      },
      {
        pending: "Uploading video...",
        success: `Video uploaded.`,
        error: "Error uploading Moment video.",
      }
    );

    resetVideo();
    setIsVideoUploading(false);
  };

  const setMoment = (moment) => {
    dispatch(MomentUploadDispatchEvents.SET_MOMENT, moment);
  };

  const setError = (error) => {
    dispatch(MomentUploadDispatchEvents.SET_ERROR, error);
  };

  const setReset = () => {
    dispatch(MomentUploadDispatchEvents.SET_RESET);
  };

  return {
    fanMedia,
    loading,
    moment,
    error,
    handleMediaChange,
    handleRemoveMediaFromMoment,
    handleRemoveMediaFromSelection,
    handleSubmitMedia,
    uploadVideo,
    setMoment,
    setError,
    setReset,
  };
}

export default useMomentPhotoUpload;
