import * as jose from "jose";

/**
 *
 * @param {String} userId
 * @returns {Object} matrix register response (user_token, user_mxid)
 */
export async function registerMatrixUser(URL, userId) {
  // const matrixRegisterToken = import.meta.env.VITE_MATRIX_REGISTRATION_TOKEN
  const { session } = await initRegister(URL);
  try {
    const result = await fetch(`${URL}/register?kind=user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: userId,
        password: "random_password",
        auth: {
          type: "m.login.dummy",
          session: session,
        },
        initial_device_display_name: "Cinny Web",
      }),
    });

    return result.json();
  } catch (err) {
    console.error({ registerMatrixUserError: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function initRegister(URL) {
  try {
    const result = await fetch(`${URL}/register?kind=user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: true,
      }),
    });

    return result.json();
  } catch (err) {
    console.error({ initRegisterError: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

// /**
//  *
//  * @param {String} token JWT from  generateMatrixAuthToken
//  * @returns {Object} matrix session data
//  */
// export async function loginMatrixUser(URL, token) {
//   try {
//     const result = await fetch(`${URL}/login`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         type: "org.matrix.login.jwt",
//         token: token,
//       }),
//     });

//     // sample return object from Promise
//     // {
//     //   "user_id": "@clo8kp3ed0071mo0fjthl8t1c:matrix.momentify.xyz",
//     //   "access_token": "syt_Y2xvOGtwM2VkMDA3MW1vMGZqdGhsOHQxYw_FStUmStjndQoAyNwpkpA_0sWaJ8",
//     //   "home_server": "matrix.momentify.xyz",
//     //   "device_id": "YYBPSOYMDV"
//     // }

//     return result.json();
//   } catch (err) {
//     console.error({ loginMatrixUserErr: err });
//   }
// }

/**
 *
 * @param {String} mxid matrix user id fetched from registerMatrixUser
 * @returns {string} JWT token signed using the shared secret
 */
export async function generateMatrixAuthToken(
  secret,
  authExpiration = "2h",
  mxid
) {
  const sharedSecret = new TextEncoder().encode(secret);
  const payload = { sub: mxid };

  const jwt = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setExpirationTime(authExpiration)
    .sign(sharedSecret);

  return jwt;
}

export async function checkRegisterAvailabilityByUserID(URL, matrixUsername) {
  try {
    const result = await fetch(
      `${URL}/register/available?username=${matrixUsername}`
    );
    return result.json();
  } catch (err) {
    console.error({ checkRegisterAvailabilityByUserIDErr: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function setDisplayName(
  URL,
  bearer,
  matrixUserId,
  momentifyUsername
) {
  try {
    const result = await fetch(`${URL}/profile/${matrixUserId}/displayname`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify({
        displayname: momentifyUsername,
      }),
    });
    return await result.json();
  } catch (err) {
    console.error({ setDisplayNameErr: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function setMatrixAccountAvatar(
  URL,
  bearer,
  matrixUserId,
  avatarURL
) {
  try {
    const result = await fetch(`${URL}/profile/${matrixUserId}/avatar_url`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearer}`,
      },
      body: JSON.stringify({
        avatar_url: avatarURL,
      }),
    });
    return await result.json();
  } catch (err) {
    console.error({ setMatrixAccountAvatarErr: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function createOrUpdateRoomByPreEvent(
  URL,
  accessToken,
  eventID,
  headlineAristName
) {
  try {
    const result = await fetch(
      `${URL}/matrix/rooms/preEvents/createOrUpdate?matrixAuthToken=${accessToken}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: eventID,
          headline_artist_name: headlineAristName,
        }),
      }
    );
    return result.json();
  } catch (err) {
    console.error({ createOrUpdateRoomByPreEventError: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}
export async function decreasePreEventTotalMember(URL, accessToken, eventID) {
  try {
    const result = await fetch(
      `${URL}/matrix/rooms/preEvents/decreaseTotalMember?matrixAuthToken=${accessToken}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: eventID,
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error({ decreasePreEventTotalMemberError: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}
export async function createOrUpdateRoomByPostEvent(
  URL,
  accessToken,
  eventID,
  eventDate,
  performanceID
) {
  try {
    const result = await fetch(
      `${URL}/matrix/rooms/postEvents/createOrUpdate?matrixAuthToken=${accessToken}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: eventID,
          event_date: eventDate,
          performance_id: performanceID,
        }),
      }
    );

    return result.json();
  } catch (err) {
    console.error({ createOrUpdateRoomByPostEventError: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function getRoomByRoomID(URL, accessToken, roomId) {
  if (!roomId) return false;
  try {
    const result = await fetch(
      `${URL}/matrix/room/${roomId}?matrixAuthToken=${accessToken}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return result.json();
  } catch (err) {
    console.error({ getRoomByRoomID: err });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function loginUserWithUsernameAndPassword(
  URL,
  username,
  password
) {
  try {
    const payload = {
      type: "m.login.password",
      identifier: {
        type: "m.id.user",
        user: username,
      },
      password: password,
      initial_device_display_name: "Cinny Web",
    };

    let result = await fetch(`${URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate",
      },
      body: JSON.stringify(payload),
    });
    result = await result.json();
    if (!!result?.errcode) {
      console.error(`Matrix Server Error: ${result.error}`);
      throw new Error("Something went wrong during matrix login");
    }
    return result;
  } catch (error) {
    console.error({ loginUserWithUsernameAndPassword: error });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}

export async function logoutUserByAccessToken(URL, accessToken) {
  try {
    let result = await fetch(`${URL}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    result = await result.json();
    if (!!result?.errcode) {
      console.error(`Matrix Server Error: ${result.error}`);
      throw new Error("Something went wrong during matrix logout");
    }
    return result;
  } catch (error) {
    console.error({ logoutUserByAccessToken: error });
    return {
      error: new Error(
        "Something went wrong while requesting to matrix server."
      ),
    };
  }
}
