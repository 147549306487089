import { Link } from "react-router-dom";
import { BodyText } from "../../../GlobalStyles";
import Pin from "../../../assets/svg/pin-white.svg?react";
import {
  GlassmorphPlaceholder,
  VenuePlaceholder,
} from "../../../constants/constants";
import { theme } from "../../../utils/theme";

export const VenueCard = ({ venue }) => {
  const { colors } = theme;
  const { venue_name, city, country, image, distance, id } = venue;

  return (
    <Link
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 12,
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      to={`/venues/${id}/${venue_name}`}
    >
      <img
        style={{
          minWidth: 58,
          minHeight: 58,
          maxWidth: 58,
          maxHeight: 58,
          borderRadius: 10,
        }}
        src={image ?? VenuePlaceholder}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          flex: 1,
          overflow: "hidden",
        }}
      >
        <BodyText
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: "rgba(247, 247, 247, 1)",
            lineHeight: "normal",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {venue_name}
        </BodyText>
        <BodyText
          style={{
            fontWeight: 400,
            fontSize: 12,
            color: "rgba(247, 247, 247, 1)",
            opacity: 0.6,
            lineHeight: "normal",
          }}
        >
          {city}
        </BodyText>
        <BodyText
          style={{
            fontWeight: 400,
            fontSize: 12,
            color: "rgba(247, 247, 247, 1)",
            opacity: 0.6,
            lineHeight: "normal",
          }}
        >
          {country}
        </BodyText>
      </div>
      {distance && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Pin
            style={{ position: "relative", bottom: "1px" }}
            stroke={"rgba(153, 153, 157, 1)"}
            height={12}
            width={12}
          />
          <BodyText
            style={{
              fontWeight: 400,
              fontSize: 12,
              color: "rgba(247, 247, 247, 1)",
              opacity: 0.6,
              lineHeight: "normal",
            }}
          >
            {distance.toFixed(1) > 1000
              ? `${(distance.toFixed(1) / 1000).toFixed(1)}km`
              : `${parseInt(distance.toFixed(1)).toFixed(0)}m`}
          </BodyText>
        </div>
      )}
    </Link>
  );
};
