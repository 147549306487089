import { BodyText } from "../../../GlobalStyles";
import Pin from "../../../assets/svg/pin-white.svg?react";
import { theme } from "../../../utils/theme";

export const SmallHeader = ({ headerText, distance = undefined }) => {
  const { colors } = theme;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        gap: "8px",
        alignItems: "center",
      }}
    >
      <BodyText
        style={{
          fontWeight: 600,
        }}
      >
        {headerText}
      </BodyText>
      {distance != undefined && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            border: "1px solid #FFFFFF26",
            borderRadius: 8,
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "74.75px",
            height: 26,
            position: "relative",
            gap: 8,
          }}
        >
          <Pin
            style={{ position: "relative", bottom: "1px" }}
            stroke={colors.white}
            opacity={0.5}
            height={12}
            width={10.5}
          />
          <BodyText
            style={{
              fontWeight: 400,
              fontSize: 12,
              lineHeight: null,
            }}
          >
            {distance}km
          </BodyText>
        </div>
      )}
    </div>
  );
};
