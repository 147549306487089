import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { BodyText, Subheader } from "../GlobalStyles";
import { getVersion } from "../utils/version";
import { theme } from "../utils/theme";

const LoadingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensure space between items */
  min-height: ${({ fullscreen }) => (fullscreen ? "100svh" : "auto")};
  min-width: ${({ fullscreen }) => (fullscreen ? "100vw" : "auto")};
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  background: linear-gradient(
    rgba(4, 15, 11, 0) 0%,
    rgba(4, 15, 11, 0.27) 50.52%,
    rgba(4, 15, 11, 0.72) 84.38%
  );
  background-color: rgba(17, 17, 31, 1);
  background-blend-mode: multiply;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  flex-direction: column;
  gap: 30px;
  img {
    backdrop-filter: none !important;
    -webkit-backdrop-filter: none !important;
  }
  margin-bottom: 40px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

// Dynamically import all images from the assets folder
const images = import.meta.glob("../assets/*.svg", { eager: true });

const preloadImages = (imageModules) => {
  Object.values(imageModules).forEach((mod) => {
    const img = new Image();
    img.src = mod.default;
  });
};

export function LoadingScreenV2({ fullScreen, loading = false }) {
  const loadingTexts = [
    "Earn rewards",
    "Connect with fans",
    "Find more gigs",
    "Capture memories",
  ];

  const randomIndex = Math.floor(Math.random() * loadingTexts.length);
  const [loadingText] = useState(loadingTexts[randomIndex]);

  useEffect(() => {
    preloadImages(images);
  }, []);

  return (
    <LoadingSection fullscreen={fullScreen ? 1 : 0}>
      <TextContainer />
      <ImageContainer>
        <img
          src={images["../assets/momentify-loading.svg"].default}
          style={{
            background: "none",
            height: "97.15px",
            width: "97.15px",
          }}
        />
        <img
          src={images["../assets/momentify-loading-text.svg"].default}
          style={{
            background: "none",
            width: "50%",
          }}
        />
        <Subheader color={theme.colors.grey} weight="400">
          {loading ? "Updating..." : loadingText}
        </Subheader>
        <BodyText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            position: "relative",
            bottom: "149px",
            left: "123px",
            color: "#6EDBBB",
            background: "#2C2C3D",
            padding: "4px 8px",
            borderRadius: "12px",
          }}
        >
          Beta
        </BodyText>
      </ImageContainer>

      <TextContainer>
        <BodyText color={theme.colors.grey}>v{getVersion()}</BodyText>
      </TextContainer>
    </LoadingSection>
  );
}

LoadingScreenV2.propTypes = {
  fullScreen: PropTypes.bool,
  loading: PropTypes.bool,
};
