import styled from "styled-components";
import PropTypes from "prop-types";
import { FooterText, Subheader } from "../../GlobalStyles";
import { theme } from "../../utils/theme";

const { colors } = theme;

const ModalContainer = styled.div`
  border-radius: 16px;
  border: 0.5px solid ${({ theme }) => theme.colors.border};
  display: flex;
  flex-direction: column;
  max-width: 316px;
  background: ${({ theme }) => theme.colors.background};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: none;
  padding: 9px 16px;
  border-right: ${({ last }) =>
    last ? "none" : "1px solid rgba(255, 255, 255, 0.15)"};
`;

export default function PromptModal({ title, desc, onConfirm, onCancel }) {
  return (
    <ModalContainer>
      <ModalContent>
        {/* Title */}
        <Subheader weight="400">{title}</Subheader>
        {/* Description */}
        <FooterText>{desc}</FooterText>
      </ModalContent>
      {/* Buttons */}
      <ButtonsContainer>
        <Button onClick={onCancel?.func}>
          <Subheader weight="400" color={colors.red}>
            {onCancel?.text}
          </Subheader>
        </Button>
        <Button onClick={onConfirm?.func} last>
          <Subheader weight="400" color={colors.green}>
            {onConfirm?.text}
          </Subheader>
        </Button>
      </ButtonsContainer>
    </ModalContainer>
  );
}

PromptModal.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  onConfirm: PropTypes.object,
  onCancel: PropTypes.object,
};
