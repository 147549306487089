import styled from "styled-components";
import { Button } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 24px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Content = styled(Header)`
  flex-direction: column;
  width: 100%;
`;

export const ModalButton = styled(Button)`
  background: rgba(255, 255, 255, 0.12);
  margin-top: 8px;
  padding: 8px 16px;
  border: none;
  min-width: 120px;
  min-height: 40px;
`;
