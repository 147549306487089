import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "styled-components";
import { theme } from "../utils/theme";
import { BodyText } from "../GlobalStyles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

const TabsContainer = styled.div`
  display: flex;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: ${({ active }) => (active ? "white" : theme.colors.grey)};
  cursor: pointer;
  transition: 0.2s ease-in-out;
  flex-wrap: nowrap;
  flex: 1;
  border: none;
  padding: 12px;
  gap: 12px;
  border-bottom: 1px solid
    ${({ active }) => (active ? "white" : "rgba(255, 255, 255, 0.15)")};

  svg {
    stroke: ${({ active }) => (active ? "white" : theme.colors.grey)};
    fill: ${({ active }) => (active ? "white" : "none")};
  }
`;

const TabComponentContainer = styled.div`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  gap: 12px;
  flex: 1;
  padding-bottom: 16px;
`;

function CommonTabs({ tabs, defaultActiveTab }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Container>
      <TabsContainer>
        {tabs.map((tab) => (
          <Tabs
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
            active={activeTab === tab.id ? true : false}
          >
            {tab.icon}
            {tab.label && (
              <BodyText
                color={activeTab === tab.id ? "white" : theme.colors.grey}
              >
                {tab.label}
              </BodyText>
            )}
          </Tabs>
        ))}
      </TabsContainer>
      {tabs.map((tab) => (
        <TabComponentContainer
          key={tab.id}
          active={activeTab === tab.id ? true : false}
        >
          {tab.component}
        </TabComponentContainer>
      ))}
    </Container>
  );
}

CommonTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      component: PropTypes.element,
    })
  ).isRequired,
  defaultActiveTab: PropTypes.string,
};

export default CommonTabs;
