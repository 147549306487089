import styled from "styled-components";
import { ButtonRect } from "../../../GlobalStyles";

export const CheckInContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 90px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 16px;
  gap: 16px;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);

  /* Center background */
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  z-index: 99;
`;

export const EventDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 0 16px;
`;

export const EventImage = styled.img`
  min-height: 58px;
  min-width: 58px;
  height: 58px;
  width: 58px;
  border-radius: 10px;
  object-fit: cover;
`;

export const EventTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckInButton = styled(ButtonRect)`
  background: black;
  backdrop-filter: blur(10px);
`;

export const NearbyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CloseButton = styled.button`
  color: white;
  background: transparent;
  border: none;
  position: absolute;
  top: 15px;
  right: 10px;
`;
