import { getUserFollowing } from "../../api/profile";

export const followingLoader = async ({ params }) => {
  const userId = params.id;

  const loggedInUserId = localStorage.getItem("loggedInUserID");

  const following = await getUserFollowing(userId, loggedInUserId);

  if (!following.success) {
    return { error: following.error };
  }

  return following.data;
};
