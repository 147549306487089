import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  MarksContainer,
  ThumbnailTrack,
  TimelineContainer,
  TrimmerContainer,
  TrimmerHandle,
} from "./VideoTrimmer-Styles";
import Draggable from "react-draggable";
import { getTimeBasedOnDrag } from "../../../utils/video-editor";
import { MAX_VIDEO_DURATION } from "../../../constants/constants";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { useVideoEditorStore } from "../videoEditorStore";
import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { useDebouncedCallback } from "use-debounce";

const { colors } = theme;

export default function VideoTrimmer({ trimVideo }) {
  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    videoDuration,
    isVideoProcessing,
    isVideoUploading,
    marks,
    thumbnails,
    setIsLoading,
    isNotAllowed,
    setIsNotAllowed,
  } = useVideoEditorStore();
  const trimmerRef = useRef(null);
  const isDisabled = isVideoProcessing || isVideoUploading;
  const [defaultRightPosition, setDefaultRightPosition] = useState(0);
  const [leftDragPosition, setLeftDragPosition] = useState(0);
  const [rightDragPosition, setRightDragPosition] = useState(0);

  useEffect(() => {
    if (!trimmerRef.current) return;

    const defaultRightPosition = trimmerRef.current.offsetWidth - 30;

    setDefaultRightPosition(defaultRightPosition);
    setRightDragPosition(defaultRightPosition);
  }, [trimmerRef.current]);

  const debounceTrimmer = useDebouncedCallback(() => {
    trimVideo();
  }, 700);

  const handleLeftDrag = (e, data) => {
    setLeftDragPosition(data.x);

    if (data.x >= rightDragPosition) {
      setIsNotAllowed(true);
    }

    const newStartTime = getTimeBasedOnDrag(
      data.x,
      trimmerRef.current.offsetWidth,
      videoDuration
    );

    if (
      endTime - newStartTime > MAX_VIDEO_DURATION ||
      endTime - newStartTime < 0
    )
      return setIsNotAllowed(true);

    setIsNotAllowed(false);
  };

  const handleRightDrag = (e, data) => {
    setRightDragPosition(data.x);
    if (data.x <= leftDragPosition) {
      setIsNotAllowed(true);
    }

    const newEndTime = getTimeBasedOnDrag(
      data.x,
      trimmerRef.current.offsetWidth,
      videoDuration
    );

    if (
      newEndTime - startTime > MAX_VIDEO_DURATION ||
      newEndTime - startTime < 0
    )
      return setIsNotAllowed(true);

    setIsNotAllowed(false);
  };

  const handleLeftStop = (e, data) => {
    let newStartTime = getTimeBasedOnDrag(
      data.x,
      trimmerRef.current.offsetWidth,
      videoDuration
    );

    setStartTime(newStartTime);

    if (data.x >= rightDragPosition) setIsNotAllowed(true);
    else setIsNotAllowed(false);

    if (
      endTime - newStartTime > MAX_VIDEO_DURATION ||
      endTime - newStartTime < 0
    ) {
      setIsNotAllowed(true);
      setIsLoading(false);
      return;
    }

    debounceTrimmer();
  };

  const handleRightStop = (e, data) => {
    let newEndTime = getTimeBasedOnDrag(
      data.x,
      trimmerRef.current.offsetWidth,
      videoDuration
    );

    if (data.x <= leftDragPosition) setIsNotAllowed(true);
    else setIsNotAllowed(false);

    setEndTime(newEndTime);

    if (
      newEndTime - startTime > MAX_VIDEO_DURATION ||
      newEndTime - startTime < 0
    ) {
      setIsNotAllowed(true);
      setIsLoading(false);
      return;
    }

    debounceTrimmer();
  };
  return (
    <TrimmerContainer>
      <TimelineContainer ref={trimmerRef}>
        <ThumbnailTrack>
          {thumbnails.map((thumb, index) => (
            <img key={index} src={thumb} alt={`Thumbnail ${index}`} />
          ))}
        </ThumbnailTrack>
        {trimmerRef.current && (
          <>
            {/* Draggable start handle */}
            <Draggable
              axis="x"
              onStart={() => setIsLoading(true)}
              onDrag={handleLeftDrag}
              onStop={handleLeftStop}
              bounds="parent"
              disabled={isDisabled}
              defaultPosition={{ x: 0, y: 0 }}
            >
              <TrimmerHandle isNotAllowed={isNotAllowed} disabled={isDisabled}>
                <ArrowLeft size={24} color={colors.black} />
              </TrimmerHandle>
            </Draggable>
            {/* Draggable end handle */}
            <Draggable
              axis="x"
              onStart={() => setIsLoading(true)}
              onDrag={handleRightDrag}
              onStop={handleRightStop}
              bounds="parent"
              disabled={isDisabled}
              defaultPosition={{
                x: defaultRightPosition,
                y: 0,
              }}
            >
              <TrimmerHandle isNotAllowed={isNotAllowed} disabled={isDisabled}>
                <ArrowRight size={24} color={colors.black} />
              </TrimmerHandle>
            </Draggable>
          </>
        )}
      </TimelineContainer>
      {marks?.length > 0 && (
        <MarksContainer>
          {marks.map((mark, index) => (
            <FooterText key={index}>{mark}</FooterText>
          ))}
        </MarksContainer>
      )}
    </TrimmerContainer>
  );
}

VideoTrimmer.propTypes = {
  trimVideo: PropTypes.func.isRequired,
};
