import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GlassmorphPlaceholder } from "../../constants/constants";
import { BodyText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import InterestedButton from "./InterestedButton";
import { useLogEventClick } from "./hooks/interestedQueries";
import useAuth from "../../hooks/useAuth";
import { useUserStore } from "../../routes/Profile/store/userStore";

const { fontSizes, colors } = theme;

const EventCard = styled(Link)`
  width: 142px;
  max-width: 142px;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff14;
`;

export const EventImageContainer = styled.div`
  height: 126px;
  width: 126px;
  max-width: 126px;
  max-height: 126px;
  position: relative;
  border-radius: 5px;
`;

export const EventImage = styled.img`
  height: 126px;
  width: 126px;
  border-radius: 5px;
  object-fit: cover;
  position: relative;
`;

export const InterestedButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const EventInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

const EventDateVenueContainer = styled(EventInfoContainer)`
  gap: 4px;
  justify-content: flex-end;
`;

export const EventText = styled(BodyText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EventVenueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EventDateContainer = styled.div`
  display: flex;
  gap: 8px;
  width: "100%";
  text-align: left;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
`;

/**
 * The event card item component.
 * @param {object} event The event
 * @returns {React.JSX.Element} The event card item component
 */
export default function EventCardItem({
  event,
  isArtist,
  isEvent,
  vertical_position = undefined,
  horizontal_position = undefined,
  header = undefined,
}) {
  const { event_id, combined_artist_ids, artist_id } = event;
  const logEventClick = useLogEventClick();
  const { loggedInUser } = useAuth();
  const { userCountry } = useUserStore();

  // Returns the event image or the stock image if the event has no image
  const eventImage = (event) => {
    const artistImage =
      !event?.artist_image || event?.artist_image == null
        ? null
        : event?.artist_image;

    const eventImage =
      !event?.image || event?.image == null
        ? GlassmorphPlaceholder
        : event?.image;

    return isArtist && artistImage ? artistImage : eventImage;
  };

  return (
    // The event card component
    <EventCard
      // The link to the event page
      to={`/events/${event?.event_id}?date=${event?.date}`}
      onClick={() => {
        if (isEvent)
          logEventClick.mutate({
            user_id: loggedInUser?.id,
            combined_artist_ids: combined_artist_ids ?? [artist_id],
            event_id: event_id,
            horizontal_position: horizontal_position,
            vertical_position: vertical_position,
            header: header,
            country: userCountry,
          });
      }}
    >
      <EventImageContainer>
        {/* The event image */}
        <EventImage
          loading="lazy"
          src={eventImage(event)}
          onError={(e) => (e.target.src = GlassmorphPlaceholder)}
        />
        {/* The interested button container */}
        <InterestedButtonContainer>
          {/* The interested button */}
          <InterestedButton event={event} size={20} />
        </InterestedButtonContainer>
      </EventImageContainer>
      {/* The event info container */}
      <EventInfoContainer>
        {/* The event headline artist */}
        <EventText weight="600">
          {isArtist && event.artist_name
            ? event?.artist_name
            : event.headline_artist}
        </EventText>
        <EventDateVenueContainer>
          {/* The event venue container */}
          <EventVenueContainer>
            {/* The event venue name */}
            <EventText style={{ opacity: 0.6 }} size={fontSizes.mobile.footer}>
              {event.venue_name}
            </EventText>
            {/* The event city */}
            <EventText style={{ opacity: 0.6 }} size={fontSizes.mobile.footer}>
              {event.city}
            </EventText>
          </EventVenueContainer>
          {/* The event date and time */}
          <EventDateContainer>
            <EventText size={fontSizes.mobile.footer} color={colors.softGreen}>
              {moment(event.date).format("ddd D MMM")}
            </EventText>
            <EventText size={fontSizes.mobile.footer} color={colors.grey}>
              {moment(event.date).format("LT")}
            </EventText>
          </EventDateContainer>
        </EventDateVenueContainer>
      </EventInfoContainer>
    </EventCard>
  );
}

EventCardItem.propTypes = {
  event: PropTypes.object,
  isArtist: PropTypes.bool,
  isEvent: PropTypes.bool,
  vertical_position: PropTypes.number,
  horizontal_position: PropTypes.number,
  header: PropTypes.string,
};
