import { useParams, useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import ShareButton from "../../components/ShareButton";
import PerformanceEvent from "../../components/Performance/PerformanceEvent";
import PerformanceMoment from "../../components/Performance/PerformanceMoment/index.jsx";
import MomentIcon from "../../assets/svg/moments.svg?react";
import PerformancePageHeader from "../../components/Performance/PerformancePageHeader.jsx";
import { usePerformanceQuery } from "./hooks/performanceQueries.jsx";
import {
  Container,
  DetailsContainer,
  Header,
  LeftHeader,
  MomentsGrid,
} from "./Performance-Styles.jsx";
import { useRetroFlowStore } from "../Search/hooks/searchStore.jsx";
import RetroAddFlow from "../../components/Retro/RetroAddFlow/index.jsx";
import AddToMomentsButton from "../../components/Performance/AddToMomentsButton/index.jsx";
import { useMemo } from "react";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style.js";
import ErrorPage from "../Error/index.jsx";
import EmptyState from "../../components/EmptyState/index.jsx";

export default function PerformanceMoments() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const { isOpen } = useRetroFlowStore();
  const { data, error, isLoading, isFetched } = usePerformanceQuery({
    id,
    date,
  });
  const { performances, claimedMoment } = data || {};
  const performance = performances?.length > 0 ? performances[0] : null;
  const withFanPhotos = useMemo(() => {
    return performances?.length > 0
      ? performances.filter(
          (performance) =>
            performance.fan_photo_urls && performance.fan_photo_urls?.length > 0
        )
      : [];
  }, [performances]);

  if (error) return <ErrorPage />;

  if (!performance && isFetched)
    return <ErrorPage description="We can't find this performance." />;

  return (
    <Container>
      <Header>
        <LeftHeader>
          <BackButton />
          <PerformancePageHeader
            performance={performance}
            isLoading={isLoading}
          />
        </LeftHeader>
        <ShareButton
          url={window.location.href}
          title={`${performance?.artist}'s Moments`}
          text={`Check out ${performance?.artist}'s moments at ${performance?.venue}!`}
        />
      </Header>
      <DetailsContainer>
        <PerformanceEvent performance={performance} isLoading={isLoading} />
      </DetailsContainer>
      {isLoading ? (
        <SkeletonElement
          height="32px"
          width="177px"
          margin="0 0 16px 0"
          radius="10px"
          style={{ alignSelf: "center" }}
        />
      ) : (
        <AddToMomentsButton
          performance={performance}
          claimedMoment={claimedMoment}
        />
      )}
      {isLoading ? (
        <MomentsGrid>
          {Array.from({ length: 6 }).map((_, index) => (
            <SkeletonElement
              height="243px"
              width="100%"
              radius="8px"
              key={index}
            />
          ))}
        </MomentsGrid>
      ) : withFanPhotos?.length > 0 ? (
        <MomentsGrid>
          {withFanPhotos.map((performance) => (
            <PerformanceMoment
              key={performance?.moment_id}
              moment={performance}
            />
          ))}
        </MomentsGrid>
      ) : (
        <EmptyState
          icon={<MomentIcon height={25} width={26.32} stroke="white" />}
          title={`No Moments Here... Yet!`}
          description={`Try Retrospectively adding a Moment of this Event.`}
        />
      )}
      {isOpen && <RetroAddFlow />}
    </Container>
  );
}
