import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyText, Title } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import moment from "moment";
import IconWithText from "../IconWithText";
import Globe from "../../assets/svg/globe.svg?react";
import Calendar from "../../assets/svg/calendar.svg?react";
import ExpandableText from "../ExpandableText";
import EventCountdown from "../Events/EventCountdown";

const { colors } = theme;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
`;

const BadgeIcon = styled.img`
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
`;

export default function FestivalDetails({ festival, nextEvent }) {
  const { name, venue, description, events, badges } = festival;
  const currentEventDate = events[0]?.date ?? events[0]?.event_date;
  const nextEventDate = nextEvent?.date ?? nextEvent?.event_date;
  const lastEventDate =
    events[events.length - 1]?.date ?? events[events.length - 1]?.event_date;

  return (
    <FlexContainer direction="column">
      <Title>{name.toUpperCase()}</Title>
      {/* Venue, Date and Countdown */}
      <FlexContainer direction="column" padding="14px 0 20px 0" gap="8px">
        <IconWithText
          icon={<Globe stroke={colors.green} height={14} width={14} />}
          text={venue}
        />
        {events.length > 0 && (
          <FlexContainer direction="column" gap="4px">
            <IconWithText
              icon={<Calendar height={14} width={14} stroke="white" />}
              text={`${moment(currentEventDate).format("ddd MMM D")} - ${moment(
                lastEventDate
              ).format("ddd MMM D")}`}
            />
            <FlexContainer align="center" justify="space-between">
              <FlexContainer align="center" gap="4px">
                <BodyText color={colors.grey}>Next:</BodyText>
                <BodyText weight="600">
                  {moment(nextEventDate).format("ddd MMM D")}
                </BodyText>
              </FlexContainer>
              <EventCountdown date={currentEventDate} />
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
      {/* Description */}
      <ExpandableText text={description} />
      {/* Badges */}
      {badges?.length > 0 && (
        <FlexContainer direction="column" padding="20px 0 0 0" gap="8px">
          <FlexContainer gap="4px" align="center">
            {badges.map((badge, index) => {
              return (
                <BadgeIcon src={badge.image} alt={badge.name} key={index} />
              );
            })}
          </FlexContainer>
          <BodyText color={colors.grey}>Collect these badges</BodyText>
        </FlexContainer>
      )}
    </FlexContainer>
  );
}

FestivalDetails.propTypes = {
  festival: PropTypes.object.isRequired,
  nextEvent: PropTypes.object,
};
