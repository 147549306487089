import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { ScrollableEventsContainer } from "../../../routes/Events/Events-Styles";

export const SkeletonElements = () => {
  return (
    <>
      <SkeletonElement
        backgroundColor="#ffffff14"
        width="100px"
        height="21px"
        radius="16px"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          overflow: "scroll",
          marginRight: "-20px",
          paddingRight: 20,
          marginLeft: "-20px",
          paddingLeft: 20,
        }}
      >
        {Array.from({ length: 12 }).map((_, index) => (
          <SkeletonElement
            key={index}
            width="142px"
            style={{ minWidth: "142px" }}
            height="237px"
            radius="10px"
            backgroundColor="#ffffff14"
          />
        ))}
      </div>
    </>
  );
};
