import PropTypes from "prop-types";
import styled from "styled-components";
import CloseIcon from "../../../assets/svg/close.svg?react";
import SearchIcon from "../../../assets/svg/search.svg?react";

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  color: white;
  font-size: 14px;

  &:focus,
  &:active {
    outline: none;
  }
`;

export const SearchBar = ({ search, setSearch }) => {
  const clearSearch = () => {
    setSearch("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "8px 16px",
        border: "1px solid var(--Button-stroke, #FFFFFF1F)",
        borderRadius: "10px",
        gap: "16px",
      }}
    >
      <SearchIcon stroke="#FFFFFF" height={16} width={16} />
      <SearchInput
        type="search"
        placeholder={`Search Artist`}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      {search != "" && (
        <CloseIcon
          stroke="white"
          height={16}
          width={16}
          onClick={clearSearch}
        />
      )}
    </div>
  );
};

SearchBar.propTypes = {
  setSearch: PropTypes.func,
  search: PropTypes.string,
};
