import styled from "styled-components";
import { BodyText, Title } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import Globe from "../../assets/svg/globe.svg?react";

import PropTypes from "prop-types";
import { handleMintErrorMessages } from "../../utils/mint-error-handler";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  padding-top: 0;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export default function MintError({ error }) {
  return (
    <Container style={{ paddingTop: 0 }}>
      <Header>
        <Globe height={35} width={35} stroke={colors.red} />
        <Title>An error occurred</Title>
      </Header>
      <BodyText>Something went wrong when claiming your Moment.</BodyText>
      <BodyText color={colors.red}>
        Reason: {handleMintErrorMessages(error)}
      </BodyText>
    </Container>
  );
}

MintError.propTypes = {
  error: PropTypes.string,
};
