import { useEffect, useState } from "react";
import { usePopularInArea } from "../../routes/Events/hooks/eventsQueries";
import { useUserStore } from "../../routes/Profile/store/userStore";
import { SmallHeader } from "./components/SmallHeader";
import { BigEventCard } from "./components/BigEventCard";
import { getRandomData } from "../../utils/event";
import { BigSkeleton } from "./components/BigSkeleton";

export const PopularInArea = () => {
  const { userCountry } = useUserStore();
  const [events, setEvents] = useState(null);

  const popularInAreaQuery = usePopularInArea({
    userCountry: userCountry,
  });

  const popularInUKQuery = usePopularInArea({
    userCountry: "United Kingdom",
  });

  const headerText =
    popularInAreaQuery?.data?.length > 0
      ? `Popular in ${userCountry}`
      : "Popular in United Kingdom";
  const data =
    popularInAreaQuery?.data?.length > 0
      ? popularInAreaQuery?.data
      : popularInUKQuery?.data;

  useEffect(() => {
    if (data) setEvents(getRandomData(data, 4));
    else setEvents([]);
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}
    >
      {popularInAreaQuery.isLoading ||
      popularInUKQuery.isLoading ||
      events == null ? (
        <BigSkeleton />
      ) : events.length > 0 ? (
        <>
          <SmallHeader headerText={headerText} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              gap: "8px",
            }}
          >
            {events?.map((event, index) => (
              <BigEventCard
                event={event}
                key={event.event_id}
                isEvent={true}
                vertical_position={0}
                horizontal_position={index}
                header={headerText}
              />
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
