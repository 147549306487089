import { styled } from "styled-components";
import PropTypes from "prop-types";
import { BodyText } from "../../GlobalStyles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 9.5px 2px;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  gap: 6px;
`;

const SettingsOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`;

const SettingsOptionNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default function SettingsOption({
  option,
  optionIcon,
  onClick,
  rightIcon,
  bottomText,
}) {
  return (
    <Container>
      <SettingsOptionContainer onClick={onClick} clickable={onClick}>
        <SettingsOptionNameContainer>
          {optionIcon} <BodyText>{option}</BodyText>
        </SettingsOptionNameContainer>
        {rightIcon}
      </SettingsOptionContainer>
      {bottomText}
    </Container>
  );
}

SettingsOption.propTypes = {
  option: PropTypes.any,
  optionIcon: PropTypes.element,
  onClick: PropTypes.func,
  rightIcon: PropTypes.element,
  bottomText: PropTypes.any,
};
