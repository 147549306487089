import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TypePicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const ArrowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
  }
`;

export const Dates = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
`;

export const DateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.searchGrey : "transparent"};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
