import PropTypes from "prop-types";
import { theme } from "../../../utils/theme";
import _moment from "moment";
import MomentThumbnailCard from "../MomentThumbnailCard";
import RetroThumbnailCard from "../../Retro/RetroThumbnailCard";
import { MOMENT_TYPES } from "../../../constants/constants";
import {
  DetailsContainer,
  MomentName,
  ThumbnailContainer,
} from "./MomentThumbnail-Styles";
import { FooterText } from "../../../GlobalStyles";
import { useRetroFlowStore } from "../../../routes/Search/hooks/searchStore";

/**
 * Generates a thumbnail for a given moment, including event date and performance or headline artist.
 *
 * @param {object} moment - The moment object containing moment_id, event_date, performance_artist, and headline_artist
 * @return {React.JSX.Element} A thumbnail component for the given moment
 */
export default function MomentThumbnail({ moment }) {
  const {
    moment_id,
    user_id,
    performance_artist,
    headline_artist,
    type,
    id,
    event_date,
    fan_photo_urls,
  } = moment;

  const { isSuccess } = useRetroFlowStore();

  return (
    <ThumbnailContainer
      to={`/profile/${user_id}/moments/${moment_id ?? id}`}
      isRetroFlow={isSuccess}
    >
      {type === MOMENT_TYPES.RETRO && !fan_photo_urls?.length ? (
        <RetroThumbnailCard />
      ) : (
        <MomentThumbnailCard moment={moment} />
      )}
      <DetailsContainer>
        <FooterText color={theme.colors.grey} truncate>
          {_moment(event_date).utc().format("ddd • D MMM YYYY")}
        </FooterText>
        <MomentName>{performance_artist ?? headline_artist}</MomentName>
      </DetailsContainer>
    </ThumbnailContainer>
  );
}

MomentThumbnail.propTypes = {
  moment: PropTypes.object.isRequired,
};
