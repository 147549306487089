import styled from "styled-components";
import PropTypes from "prop-types";
import BackButton from "../BackButton";
import FollowButton from "../FollowButton";
import SubscribeButton from "./SubscribeButton";
import { BUTTON_SIZES, GlassmorphPlaceholder } from "../../constants/constants";
import { useEffect, useState } from "react";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import useGlobalModal from "../../hooks/useGlobalModal";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import {
  useFollowMutate,
  useSubscribeMutate,
} from "../../routes/Artist/hooks/artistQueries";
import SubscribeModal from "../Modal/SubscribeModal";
import { useUserStore } from "../../routes/Profile/store/userStore";

const Container = styled.div`
  min-height: 268px;
  width: 100%;
  position: relative;
`;

const ArtistImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(12, 13, 23, 0.1) 0%,
    rgba(12, 13, 23, 0) 50%,
    rgba(12, 13, 23, 0.1) 100%
  );
  z-index: 1;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 2;
  cursor: pointer;
`;

const FollowAndSubscribeContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 20px;
  z-index: 2;
  display: flex;
  gap: 12px;
`;

export default function ArtistHeader({ artist, isLoading }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const {
    addUserFollowedArtist,
    removeUserFollowedArtist,
    userFollowedArtists,
    userSubscriptions,
    addUserSubscriptions,
    removeUserSubscriptions,
  } = useUserStore();

  const [isFollowing, setIsFollowing] = useState(
    userFollowedArtists.some((art) => art?.id == artist?.artist_id) ?? false
  );

  const [isSubscribed, setIsSubscribed] = useState(
    userSubscriptions.some((art) => art?.id == artist?.artist_id) ?? false
  );

  const followMutation = useFollowMutate({
    loggedInUserId: loggedInUser?.id,
    artistId: artist?.artist_id,
  });
  const subscribeMutation = useSubscribeMutate({
    loggedInUserId: loggedInUser?.id,
    artistId: artist?.artist_id,
  });

  useEffect(() => {
    setIsFollowing(
      userFollowedArtists.some((art) => art?.id == artist?.artist_id) ?? false
    );
    setIsSubscribed(
      userSubscriptions.some((art) => art?.id == artist?.artist_id) ?? false
    );
  }, [artist, userFollowedArtists, userSubscriptions]);

  const handleFollowClick = async () => {
    try {
      if (!loggedInUser) return login();

      followMutation.mutate({ isFollowing });
      artist.id = artist.artist_id;

      if (isFollowing) {
        removeUserFollowedArtist(artist);
      } else {
        addUserFollowedArtist(artist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const showSubscribeButton = () => {
    if (!loggedInUser) return login();

    setSheetModalContent(
      <SubscribeModal
        artist={artist}
        handleSubscribeClick={handleSubscribeClick}
      />
    );
    toggleSheetModal();
  };

  const handleSubscribeClick = async () => {
    try {
      if (!loggedInUser) return login();

      subscribeMutation.mutate({ isSubscribed, isFollowing });

      artist.id = artist.artist_id;

      if (!isFollowing && !isSubscribed) {
        addUserFollowedArtist(artist);
        addUserSubscriptions(artist);
      } else if (!isSubscribed) {
        addUserSubscriptions(artist);
      } else if (isSubscribed) {
        removeUserSubscriptions(artist);
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <SkeletonElement height="268px" />
        <BackButtonContainer>
          <BackButton />
        </BackButtonContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ArtistImage
        src={artist?.banner_img ?? artist?.event_image ?? GlassmorphPlaceholder}
        alt={artist?.name}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />
      <Overlay />
      <BackButtonContainer>
        <BackButton />
      </BackButtonContainer>
      <FollowAndSubscribeContainer>
        <FollowButton
          isFollowing={isFollowing}
          handleClick={handleFollowClick}
          size={BUTTON_SIZES.SMALL}
        />
        <SubscribeButton
          isSubscribed={isSubscribed}
          handleClick={
            !isSubscribed ? showSubscribeButton : handleSubscribeClick
          }
        />
      </FollowAndSubscribeContainer>
    </Container>
  );
}

ArtistHeader.propTypes = {
  artist: PropTypes.object,
  isLoading: PropTypes.bool,
};
