import { ButtonRectXs, FooterText, FooterTextSm } from "../../../GlobalStyles";
import { Container, CopyButtonContainer } from "./WalletCardFooter-Styles";
import CopyIcon from "../../../assets/svg/copy.svg?react";
import { handleCopyText } from "../../../utils/utils";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import { usePrivy } from "@privy-io/react-auth";

export default function WalletCardFooter({ wallet }) {
  const { exportWallet, connectWallet } = usePrivy();
  const { address, walletClientType, connected } = wallet || {};
  const isPrivy = walletClientType === "privy";

  return (
    <Container>
      <FooterText color={theme.colors.grey} truncate>
        {address}
      </FooterText>
      <CopyButtonContainer style={{ gap: "16px" }}>
        {isPrivy && (
          <ButtonRectXs onClick={exportWallet}>
            <FooterTextSm weight="600">Export</FooterTextSm>
          </ButtonRectXs>
        )}
        {!connected && (
          <ButtonRectXs
            onClick={() => connectWallet({ suggestedAddress: address })}
          >
            <FooterTextSm weight="600">Connect</FooterTextSm>
          </ButtonRectXs>
        )}
        <CopyButtonContainer>
          <CopyIcon
            height={16}
            width={16}
            stroke="white"
            onClick={() => handleCopyText(address)}
          />
        </CopyButtonContainer>
      </CopyButtonContainer>
    </Container>
  );
}

WalletCardFooter.propTypes = {
  wallet: PropTypes.object,
};
