import PropTypes from "prop-types";
import { BodyText, HeaderXl } from "../../../GlobalStyles";

export const HeadingText = ({ isConnected }) => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 0,
      }}
    >
      <HeaderXl>
        {isConnected ? "YOUR ARTISTS" : "IMPORT ARTISTS YOU FOLLOW"}
      </HeaderXl>

      <BodyText style={{ color: "#99999D", padding: 0 }}>
        {isConnected
          ? "Subscribing allows you to receive messages, updates and gifts from the artists by sharing your info to them."
          : "Add the artists you follow from Spotify to your profile."}
      </BodyText>
    </div>
  );
};

HeadingText.propTypes = {
  isConnected: PropTypes.bool,
};
