import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`
  position: relative;
  max-height: 260px;
  width: 100%;
  aspect-ratio: 3/4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.glass};
  padding: 8px;
  overflow: hidden;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const MomentDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  position: relative;
  z-index: 1;
`;
