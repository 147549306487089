import { wrapCreateBrowserRouter } from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";
import EventsError from "./components/Events/EventsError";
import Artist from "./routes/Artist";
import Chat from "./routes/Chat";
import ConnectSpotify from "./routes/ConnectSpotify";
import CreateProfile from "./routes/CreateProfile";
import EditProfile from "./routes/EditProfile";
import ErrorPage from "./routes/Error";
import Event from "./routes/Event";
import Festival from "./routes/Festival";
import festivalLoader from "./routes/Festival/loader";
import Followers from "./routes/Followers";
import Following from "./routes/Following";
import LandingPage from "./routes/LandingPage";
import landingPageLoader from "./routes/LandingPage/loader";
import Login from "./routes/Login";
import Messages from "./routes/Messages";
import MessagesView from "./routes/MessagesView";
import Moment from "./routes/Moment";
import MomentsFeed from "./routes/MomentsFeed";
import NotificationCenter from "./routes/NotificationCenter";
import PerformanceMoments from "./routes/Performance";
import Profile from "./routes/Profile";
import Root from "./routes/Root";
import Search from "./routes/Search";
import SelectFavoriteArtists from "./routes/SelectFavoriteArtists";
import Settings from "./routes/Settings";
import UserBadges from "./routes/UserBadges";
import Venue from "./routes/Venue";
import Wallets from "./routes/Wallets";
import Report from "./routes/Report";
import Collectible from "./routes/Collectible";
import XPHistory from "./routes/XPHistory";
import EventsV2 from "./routes/EventsV2";
import VideoEditor from "./components/VideoEditor";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <MomentsFeed />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/events",
        element: <EventsV2 />,
        errorElement: <EventsError />,
      },
      {
        path: "/eventsV2",
        element: <EventsV2 />,
        errorElement: <EventsError />,
      },
      {
        path: "/events/:id",
        element: <Event />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/artists/:id",
        element: <Artist />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/venues/:id/:name",
        element: <Venue />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/festivals/:id",
        element: <Festival />,
        loader: festivalLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "/chat",
        element: <Chat />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/search",
        element: <Search />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/:id",
        element: <Profile />,
        errorElement: (
          <ErrorPage
            errorMessage={
              "Woops! Looks like I cannot find anything about this user"
            }
          />
        ),
      },
      {
        path: "/profile/:id/following",
        element: <Following />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/:id/followers",
        element: <Followers />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/:id/badges",
        element: <UserBadges />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/:id/xp-history",
        element: <XPHistory />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/:profileId/moments/:momentId",
        element: <Moment />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/:profileId/collectibles/:collectibleId",
        element: <Collectible />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/settings",
        element: <Settings />,
      },
      {
        path: "/profile/wallets",
        element: <Wallets />,
      },
      {
        path: "/profile/notifications",
        element: <NotificationCenter />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/messages",
        element: <Messages />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/messages/:senderId",
        element: <MessagesView />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/profile/edit",
        element: <EditProfile />,
      },
      {
        path: "/profile/create",
        element: <CreateProfile />,
      },
      {
        path: "/profile/favorite",
        element: <SelectFavoriteArtists />,
      },
      {
        path: "/profile/spotify",
        element: <ConnectSpotify />,
      },
      {
        path: "/profile/invitation/:inviteId",
        loader: landingPageLoader,
        element: <LandingPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/performances/:id",
        element: <PerformanceMoments />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/report",
        element: <Report />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/video-editor",
        element: <VideoEditor />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/*",
        element: <ErrorPage type="404" />,
      },
    ],
  },
]);

export default router;
