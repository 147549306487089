import { BodyText } from "../../GlobalStyles";
import useAuth from "../../hooks/useAuth";
import { useEventsFriendsInterested } from "../../routes/Events/hooks/eventsQueries";
import { EventCard } from "./components/EventCard";
import { NoBoxEventCard } from "./components/NoBoxEventCard";
import { SkeletonElements } from "./components/SkeletonElements";
import { SmallHeader } from "./components/SmallHeader";
import { ThinSkeletonElements } from "./components/ThinSkeletonElements";

export const FansPanel = () => {
  const { loggedInUser } = useAuth();
  const fanQuery = useEventsFriendsInterested({
    userId: loggedInUser?.id,
  });
  const allFansQuery = useEventsFriendsInterested({ userId: null });

  const data = fanQuery?.data?.length > 0 ? fanQuery?.data : allFansQuery?.data;
  const headerText =
    fanQuery?.data?.length > 0 && loggedInUser?.id != null
      ? "FROM FANS YOU FOLLOW"
      : "TRENDING WITH OTHER FANS";
  const subText =
    fanQuery?.data?.length > 0 && loggedInUser?.id != null
      ? "Your friends are also interested in..."
      : "Fans around the world are also interested in...";

  return (
    <>
      <div
        style={{
          padding: "16px 20px",

          margin: "4px -20px",
          display: "flex",
          flexDirection: "column",
          gap: 12,
          backgroundColor: "#2525434D",
          borderRadius: 12,
        }}
      >
        {fanQuery?.isLoading || allFansQuery?.isLoading ? (
          <ThinSkeletonElements />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <BodyText
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: null,
                }}
              >
                {headerText}
              </BodyText>
              <BodyText
                style={{
                  color: "#99999D",
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: null,
                }}
              >
                {subText}
              </BodyText>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                overflow: "scroll",
                marginRight: "-20px",
                paddingRight: 20,
                marginLeft: "-20px",
                paddingLeft: 20,
              }}
            >
              {data?.map((event, index) => (
                <NoBoxEventCard
                  event={event}
                  key={event.event_id}
                  isEvent={true}
                  vertical_position={0}
                  horizontal_position={index}
                  header={"headerText"}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
