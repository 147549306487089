import PropTypes from "prop-types";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";

export const FollowingSkeleton = ({ isArtist = false }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        alignItems: "center",
      }}
    >
      <SkeletonElement
        style={{ minWidth: "40px" }}
        width="40px"
        height="40px"
        radius="50%"
      />
      <SkeletonElement width="100px" height="20px" />
      <SkeletonElement
        style={{ marginLeft: "auto" }}
        width="81px"
        height="20px"
      />
      {isArtist && <SkeletonElement width="32px" height="32px" radius="10px" />}
    </div>
  );
};

FollowingSkeleton.propTypes = {
  isArtist: PropTypes.bool,
};
