import { BodyText, HeaderL, FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import PropTypes from "prop-types";
import useAccountKit from "../../../hooks/useAccountKit";
import { useState } from "react";
import YourMoment from "../YourMomentModal";
import { MOMENT_CLAIM_STATUS } from "../../../constants/constants";
import MintError from "../MintError";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import {
  ConfirmButton,
  Container,
  Footer,
  Header,
} from "./CollectMomentModal-Styles";
import { useQueryClient } from "@tanstack/react-query";

const { colors } = theme;

export default function CollectMomentModal({
  hasImage,
  within48Hours,
  isPublic,
  moment,
}) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { mintMomentOnChain, claimStatus, currentPendingMessage } =
    useAccountKit();
  const { toggleSheetModal, setSheetModalContent, isSheetModalOpen } =
    useGlobalModal();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleConfirm = async () => {
    if (!loggedInUser) {
      return login();
    }
    setLoading(true);
    // Place blockchain minting logic here
    const result = await mintMomentOnChain(moment);

    setLoading(false);

    if (isSheetModalOpen) toggleSheetModal();

    setTimeout(() => {
      if (!result.success) {
        setSheetModalContent(<MintError error={result.error} />);
        toggleSheetModal();
        return;
      }

      setSheetModalContent(
        <YourMoment moment={moment} txnHash={result.txnHash} />
      );
      toggleSheetModal();

      queryClient.invalidateQueries({
        queryKey: ["moment", moment?.moment_id?.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: ["moments", loggedInUser?.id?.toString()],
      });
    }, 1000);
  };

  return (
    <Container style={{ paddingTop: 0 }}>
      <Header>
        <HeaderL color="white" style={{ textTransform: "uppercase" }}>
          Collect your Moment
        </HeaderL>
      </Header>
      <BodyText color={colors.grey}>
        Upgrade your Moment to a{" "}
        <BodyText color={colors.green}>Universal Pass</BodyText> you can use in
        other platforms like Ticketmaster.
      </BodyText>
      <BodyText color={colors.grey}>
        Earn <span style={{ color: colors.green }}>XP</span> and improve your
        app experience!
      </BodyText>
      <ConfirmButton
        onClick={handleConfirm}
        disabled={loading || claimStatus === MOMENT_CLAIM_STATUS.PENDING}
      >
        <BodyText weight="600">
          {loading || claimStatus === MOMENT_CLAIM_STATUS.PENDING
            ? "Collecting..."
            : "Confirm"}
        </BodyText>
      </ConfirmButton>
      {currentPendingMessage && (
        <FooterText>{currentPendingMessage}</FooterText>
      )}
      <Footer>
        <FooterText style={{ color: colors.grey }}>
          {within48Hours && hasImage
            ? "You will not be able to update your image after this action."
            : within48Hours && !hasImage
              ? "By confirming, you are going to Collect a Moment without photo."
              : !within48Hours && hasImage
                ? "You will not be able to update your image after this action."
                : ""}
        </FooterText>
        {!hasImage && !within48Hours && (
          <>
            <FooterText color={colors.grey}>
              You will not be able to update your image after this action.
            </FooterText>
            <FooterText color={colors.grey}>
              {isPublic
                ? "Your photo will be visible publicly."
                : "Your photo will NOT be visible publicly."}
            </FooterText>
          </>
        )}
      </Footer>
    </Container>
  );
}

CollectMomentModal.propTypes = {
  hasImage: PropTypes.bool,
  within48Hours: PropTypes.bool,
  isPublic: PropTypes.bool,
  moment: PropTypes.object,
};
