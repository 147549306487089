import useAuth from "./useAuth";
import { isProd } from "../utils/utils";
import {
  identifyTrackableUser,
  initTracker,
  sendTrackingData,
} from "../utils/mixpanel";

function useMixpanelTracker() {
  const { loggedInUser } = useAuth();

  const handleTracking = async (type, payload, userId) => {
    if (!isProd) return;
    const user_id = userId ?? loggedInUser.id;
    if (!user_id) return;
    if (!type || !payload) return;

    initTracker();
    identifyTrackableUser(user_id);
    sendTrackingData(type, payload);
  };

  return {
    handleTracking,
  };
}

export default useMixpanelTracker;
