import {
  BodyText,
  ButtonRectSm,
  FooterText,
  IconWithText,
  Line,
} from "../../../GlobalStyles";
import { useCheckoutStore } from "../checkoutStore";
import {
  ButtonWithMethod,
  Container,
  TopUpContainer,
} from "./PaymentMethod-Styles";
import CreditCardIcon from "../../../assets/svg/credit-card.svg?react";
import WalletIcon from "../../../assets/svg/wallet.svg?react";
import SendMoneyIcon from "../../../assets/svg/send-money.svg?react";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import { PAYMENT_METHODS } from "../../../constants/constants";
import SelectButton from "../../Buttons/SelectButton";
import { usePrimaryWalletBalance } from "../../../hooks/useWalletBalance";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { useEffect } from "react";

const { colors } = theme;
const { CREDIT_CARD, WALLET, NEW_CARD } = PAYMENT_METHODS;

export default function PaymentMethod({ method }) {
  const {
    paymentMethod,
    setPaymentMethod,
    total,
    handleTotal,
    hasEnoughWalletBalance,
    setHasEnoughWalletBalance,
    cardPaymentMethod,
    setCardPaymentMethod,
  } = useCheckoutStore();

  const { balance, isLoading } = usePrimaryWalletBalance();

  useEffect(() => {
    if (balance <= total) setHasEnoughWalletBalance(false);
    else if (balance > total) setHasEnoughWalletBalance(true);
  }, [balance, total]);

  const handlePaymentMethod = (method) => {
    if (paymentMethod === method) {
      if (method === CREDIT_CARD) handleTotal(-1);
      setPaymentMethod(null);
    } else {
      method === CREDIT_CARD
        ? handleTotal(1)
        : paymentMethod === CREDIT_CARD && method === WALLET && handleTotal(-1);
      setPaymentMethod(method);
    }

    if (method === CREDIT_CARD && cardPaymentMethod) {
      setCardPaymentMethod(null);
    }
  };

  const handleCardPaymentMethod = (method) => {
    if (cardPaymentMethod === method) {
      setCardPaymentMethod(null);
    } else {
      setCardPaymentMethod(method);
    }
  };

  if (method === NEW_CARD) {
    return (
      <Container onClick={() => handleCardPaymentMethod(method)}>
        <ButtonWithMethod>
          <IconWithText>
            <SelectButton selected={cardPaymentMethod === method} />
            <BodyText>{method}</BodyText>
            <CreditCardIcon stroke={colors.white} height={16} width={16} />
            <CreditCardIcon stroke={colors.white} height={16} width={16} />
            <CreditCardIcon stroke={colors.white} height={16} width={16} />
            <CreditCardIcon stroke={colors.white} height={16} width={16} />
          </IconWithText>
        </ButtonWithMethod>
      </Container>
    );
  }

  if (method === WALLET) {
    return (
      <AnimatedDropdown
        header={
          <Container
            style={{ background: "none", padding: 0 }}
            onClick={() => handlePaymentMethod(method)}
          >
            <ButtonWithMethod>
              <IconWithText>
                <SelectButton selected={paymentMethod === method} />
                <WalletIcon stroke={colors.white} height={16} width={16} />
                <BodyText>{method}</BodyText>
                {isLoading ? (
                  <SkeletonElement height="17px" width="80px" />
                ) : (
                  <>
                    <BodyText
                      color={hasEnoughWalletBalance ? colors.grey : colors.red}
                    >
                      ${balance}
                    </BodyText>
                    <USDCIcon height={16} width={16} />
                    <BodyText secondary>(No fees)</BodyText>
                  </>
                )}
              </IconWithText>
            </ButtonWithMethod>
          </Container>
        }
        content={
          !hasEnoughWalletBalance && (
            <>
              <Line />
              <TopUpContainer>
                <ButtonRectSm primary>
                  <SendMoneyIcon height={16} width={16} stroke={colors.white} />
                  <FooterText weight="600">Top up</FooterText>
                </ButtonRectSm>
                <FooterText color={colors.red}>
                  Insufficient balance.
                </FooterText>
              </TopUpContainer>
            </>
          )
        }
        noArrow
        isClosed={paymentMethod !== WALLET}
      />
    );
  }

  return (
    <Container onClick={() => handlePaymentMethod(method)}>
      <ButtonWithMethod>
        <IconWithText>
          <SelectButton selected={paymentMethod === method} />
          <CreditCardIcon stroke={colors.white} height={16} width={16} />
          <BodyText>{method}</BodyText>
        </IconWithText>
      </ButtonWithMethod>
    </Container>
  );
}

PaymentMethod.propTypes = {
  method: PropTypes.string.isRequired,
};
