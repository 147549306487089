import { useQuery } from "@tanstack/react-query";
import {
  getSetlistByPerformanceId,
  searchSetlists,
} from "../../../api/setlistfm";
import moment from "moment";

export const useSetlistIdQuery = ({ performanceId, date }) => {
  return useQuery({
    queryKey: ["setlist", performanceId],
    queryFn: () => {
      date = moment(date).utc().format("YYYY-MM-DD");
      return getSetlistByPerformanceId({ performanceId, date });
    },
    refetchOnMount: false,
  });
};

export const useSetlistSearchQuery = ({
  artistName,
  venueName,
  date,
  idQueryResult,
  idQueryLoading,
}) => {
  return useQuery({
    queryKey: ["setlist", artistName, venueName, date],
    queryFn: () => {
      date = moment(date).utc().format("DD-MM-YYYY");
      return searchSetlists({ artistName, venueName, date });
    },
    enabled: !idQueryResult && !idQueryLoading,
    refetchOnMount: false,
  });
};
