export const getPerformanceMoments = async (performanceId, userId, date) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/performances/${performanceId}?userId=${userId}&date=${date}`
    );

    if (!response.ok) {
      console.warn(`${response.status} ${response.statusText}`);
      return false;
    }

    const result = await response.json();

    if (!result.success) {
      console.warn(response.message);
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const searchPastPerformances = async (
  query,
  artistId,
  year,
  month,
  country,
  city,
  signal,
  limit = 20,
  offset = 0
) => {
  try {
    year = year || new Date().getFullYear();

    // Fetch nearby events
    let performances = await fetch(
      `${import.meta.env.VITE_API_URL}/search/past-performances?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          artistId,
          year,
          month,
          country,
          city,
        }),
        signal,
      }
    );

    // Parse response
    performances = await performances.json();

    return {
      performances,
      hasMore: performances.length >= limit,
      year,
    };
  } catch (err) {
    if (err === "AbortError") return "aborted";
    throw new Error(err);
  }
};
