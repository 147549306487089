import { BodyText, FooterText, HeaderXl } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";

export const HeadingText = () => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <HeaderXl>SELECT FAVOURITE ARTIST</HeaderXl>

      <BodyText>
        <BodyText style={{ color: "#99999D" }}>
          Add up to 3 artists so that we can personalise your experience. Earn{" "}
        </BodyText>
        <FooterText color={theme.colors.green}>+50xp</FooterText>
      </BodyText>
    </div>
  );
};
