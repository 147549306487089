import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { BodyText } from "../../../GlobalStyles";
import BackIcon from "../../../assets/svg/back-no-tail.svg?react";
import CloseIcon from "../../../assets/svg/close.svg?react";
import Pin from "../../../assets/svg/pin-white.svg?react";
import LOCATIONS from "../../../constants/locations.json";
import {
  SEARCH_DROP_DOWN_LOCATIONS,
  SEARCH_PARAMS,
} from "../../../constants/search";
import useAuth from "../../../hooks/useAuth";
import useGeolocation from "../../../hooks/useGeolocation";
import {
  Form,
  InputContainer,
  SearchContainer,
  SearchInput,
} from "../../../routes/Search/Search-Styles";
import { useSearchParamsHook } from "../../../routes/Search/hooks/searchParams";
import { useAllLocations } from "../../Settings/hooks/locationQueries";
import {
  CitiesContainer,
  CountryContainer,
  CountryName,
  LocationsContainer,
} from "./LocationPicker-Styles";
import { useUserStore } from "../../../routes/Profile/store/userStore";

const { TAB } = SEARCH_PARAMS;

export default function LocationPicker() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { register, handleSubmit, reset, setError } = useForm();
  const { data } = useAllLocations();
  const { onChange, onBlur, ref, name } = register("searchLocation");
  const [results, setResults] = useState([]);
  const { loggedInUser } = useAuth();
  const savedLocation = loggedInUser?.location?.split(",") ?? [];
  const { currentCountry, currentCity } = useGeolocation();
  const { tab, isLocationPickerOpen } = useSearchParamsHook({
    searchParams,
  });
  const { venueCountry, venueCity } = useUserStore();

  const toggleLocationPicker = () => {
    searchParams.set("locationPicker", !isLocationPickerOpen);
    setSearchParams(searchParams);
  };

  // Debounced search
  const debounceSearch = useDebouncedCallback((query) => {
    if (query === undefined) return;

    if (query.length > 0) {
      handleSubmit(onSubmit(query));
    }

    if (query.length === 0) {
      resetSearch();
    }
  }, 300);

  const handleOnChange = (e) => {
    onChange(e);

    debounceSearch(e.target.value);
  };

  const handleLocationPicked = (location) => {
    // Set search params
    searchParams.set("country", location.country);
    searchParams.set("city", location.city);
    !tab && searchParams.set(TAB, "event");

    setSearchParams(searchParams);
    toggleLocationPicker();
  };

  const handleCloseSearch = () => {
    toggleLocationPicker();
    resetSearch();
  };

  const handleError = (error) => {
    setError(error);
  };

  const searchLocations = (query) => {
    const locations = data?.length > 0 ? data : LOCATIONS;

    const searchQuery = query.toLowerCase();

    const matchingLocations = locations.filter(
      (location) =>
        location.country.toLowerCase().includes(searchQuery) ||
        location.cities.some(
          (city) => city && city.toLowerCase().includes(query.toLowerCase())
        )
    );

    return matchingLocations.map((location) => ({
      country: location.country,
      cities: location.cities.filter(
        (city) => city && city.toLowerCase().includes(query.toLowerCase())
      ),
    }));
  };

  const onSubmit = async (query) => {
    handleError(null);

    const foundCities = searchLocations(query);

    if (!foundCities || foundCities.length === 0) return;

    setResults(foundCities);
  };

  const resetSearch = () => {
    reset();
    setError(null);
    setResults([]);

    // Focus on input field
    document.querySelector("input[name=searchLocation]").focus();

    handleError(null);
  };

  return (
    <SearchContainer style={{ position: "fixed", padding: "16px 20px" }}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flexDirection: "row", alignItems: "center" }}
        action="."
      >
        <BackIcon
          stroke="white"
          height={12}
          width={12}
          onClick={handleCloseSearch}
        />
        <InputContainer>
          <Pin stroke="rgba(255, 255, 255, 0.12)" height={16} width={16} />
          <SearchInput
            type="search"
            enterKeyHint="search"
            placeholder="Search for a country or city"
            name={name}
            onChange={handleOnChange}
            onBlur={onBlur}
            ref={ref}
            autoFocus
          />
          <CloseIcon
            stroke="white"
            height={16}
            width={16}
            onClick={resetSearch}
          />
        </InputContainer>
      </Form>
      <LocationsContainer>
        {results.length > 0 &&
          results.map((location, index) => (
            <CountryContainer key={index}>
              <CountryName
                onClick={() =>
                  handleLocationPicked({
                    country: location.country,
                    city: "",
                  })
                }
              >
                {location.country}
              </CountryName>
              <CitiesContainer>
                {location.cities.map((city, index) => (
                  <BodyText
                    key={index}
                    onClick={() =>
                      handleLocationPicked({
                        country: location.country,
                        city,
                      })
                    }
                  >
                    {city}
                  </BodyText>
                ))}
              </CitiesContainer>
            </CountryContainer>
          ))}
        {results.length === 0 &&
          ((currentCountry && currentCity) ||
            savedLocation.length == 2 ||
            (venueCountry && venueCity)) && (
            <CountryContainer>
              <CountryName>Your current location</CountryName>
              <CitiesContainer>
                <BodyText
                  onClick={() =>
                    handleLocationPicked({
                      country: venueCountry
                        ? venueCountry
                        : savedLocation.length > 1
                          ? savedLocation[1]?.trim()
                          : currentCountry,
                      city: venueCity
                        ? venueCity
                        : savedLocation.length > 1
                          ? savedLocation[0]?.trim()
                          : currentCity,
                    })
                  }
                >
                  {venueCountry
                    ? venueCountry
                    : savedLocation.length > 1
                      ? savedLocation[0]?.trim()
                      : currentCity}
                  ,{" "}
                  {venueCity
                    ? venueCity
                    : savedLocation.length > 1
                      ? savedLocation[1]?.trim()
                      : currentCountry}
                </BodyText>
              </CitiesContainer>
            </CountryContainer>
          )}
        {results.length === 0 &&
          SEARCH_DROP_DOWN_LOCATIONS.map((location, index) => (
            <CountryContainer key={index}>
              <CountryName
                onClick={() =>
                  handleLocationPicked({
                    country: location.country,
                    city: "",
                  })
                }
              >
                {location.country}
              </CountryName>
              <CitiesContainer>
                {location.cities.map((city, index) => (
                  <BodyText
                    key={index}
                    onClick={() =>
                      handleLocationPicked({
                        country: location.country,
                        city,
                      })
                    }
                  >
                    {city}
                  </BodyText>
                ))}
              </CitiesContainer>
            </CountryContainer>
          ))}
      </LocationsContainer>
    </SearchContainer>
  );
}

LocationPicker.propTypes = {
  toggleLocationPicker: PropTypes.func,
};
