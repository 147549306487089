import { init } from "@fullstory/browser";
import { getUsernameByType } from "./username";
import { UsernameTypes } from "../constants/constants";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

const orgId = import.meta.env.VITE_FULLSTORY_ORG_ID;

export const initFullStory = () => {
  if (!orgId) return;

  init({ orgId });
};

export const identifyUser = (user) => {
  if (!window?.FS) return;

  if (!user) {
    // Create anonymous user using session ID
    const sessionId = Cookies.get("session-id");

    if (!sessionId)
      Cookies.set("session-id", uuidv4(), { secure: true, expires: 365 });

    window.FS.identify(sessionId, {
      displayName: "Guest",
      email: "",
    });

    return;
  }

  window.FS.identify(user.id, {
    privyID: user.privy_id,
    displayName: getUsernameByType(user, UsernameTypes.USERNAME),
    email: getUsernameByType(user, UsernameTypes.EMAIL),
    isPrivate: user.private,
    wallet: user.embedded_wallet_id,
  });
};
