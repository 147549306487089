import styled from "styled-components";
import { Section } from "../../GlobalStyles";

export const Container = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

export const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  padding: 0 20px 16px;
`;

export const MomentsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  padding: 0 20px 16px;
  gap: 8px;
`;
