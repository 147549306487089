import { create } from "zustand";

export const useCheckoutStore = create((set) => ({
  paymentMethod: null,
  setPaymentMethod: (method) => set({ paymentMethod: method }),
  cardPaymentMethod: null,
  setCardPaymentMethod: (method) => set({ cardPaymentMethod: method }),
  hasEnoughWalletBalance: true,
  setHasEnoughWalletBalance: (hasEnough) =>
    set({ hasEnoughWalletBalance: hasEnough }),
  total: 0,
  handleTotal: (price, isSubtract) => {
    if (isSubtract) {
      set((state) => ({ total: state.total - price }));
    } else {
      set((state) => ({ total: state.total + price }));
    }
  },
  reset: () => set({ paymentMethod: null, cardPaymentMethod: null, total: 0 }),
}));
