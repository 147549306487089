import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { BodyText } from "../GlobalStyles";
import Events from "../assets/svg/bookmark.svg?react";
import Moments from "../assets/svg/moments.svg?react";
import Profile from "../assets/svg/profile.svg?react";
import SearchIcon from "../assets/svg/search.svg?react";
import { AvatarPlaceholder } from "../constants/constants";
import useAuth from "../hooks/useAuth";
import { useRetroFlowStore } from "../routes/Search/hooks/searchStore";

const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: linear-gradient(
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 999;
  position: ${({ isMomentView }) => (isMomentView ? "fixed" : "sticky")};
  bottom: 0;
  width: 100%;
  padding: 10px 24px 17px;
  min-height: 82px;
`;

const FooterLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  /* padding: 10px 0 16px 0; */
  transition: 0.2s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0.5)};

  span {
    color: ${({ active }) => active && "white"};
    font-weight: ${({ active }) => active && 600};
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-size: cover;
  background-position: center;
  opacity: ${({ loggedIn }) => (loggedIn ? 1 : 0.6)};
  object-fit: cover;
`;

export default function Navbar() {
  const { loggedInUser } = useAuth();
  const location = useLocation();
  const { setIsOpen } = useRetroFlowStore();

  const handleRetroAddFlow = () => {
    setIsOpen(false);
  };

  return (
    <FooterContainer
      isMomentView={
        location.pathname == "/" || location.pathname.includes("/moments")
          ? true
          : false
      }
    >
      <FooterLink
        to={`/`}
        active={location.pathname == "/" ? true : false}
        onClick={handleRetroAddFlow}
      >
        <Moments
          stroke="white"
          fill={location.pathname == "/" ? "white" : "none"}
          height={16}
          width={16}
        />
        <BodyText>Moments</BodyText>
      </FooterLink>
      <FooterLink
        to={`/events`}
        active={location.pathname.startsWith("/events") ? true : false}
        onClick={handleRetroAddFlow}
      >
        <Events
          stroke="white"
          fill={location.pathname.startsWith("/events") ? "white" : "none"}
          strokeWidth={1.5}
          height={16}
          width={16}
        />
        <BodyText>Events</BodyText>
      </FooterLink>
      <FooterLink
        to={`/search`}
        active={location.pathname.startsWith("/search") ? true : false}
        onClick={handleRetroAddFlow}
      >
        <SearchIcon stroke="white" height={16} width={16} />
        <BodyText>Search</BodyText>
      </FooterLink>
      <FooterLink
        to={loggedInUser ? `/profile/${loggedInUser.id}` : "/login"}
        active={location.pathname.startsWith("/profile") ? true : false}
        onClick={handleRetroAddFlow}
      >
        {loggedInUser ? (
          <ProfileImage
            src={loggedInUser?.avatar ?? AvatarPlaceholder}
            loggedIn={loggedInUser}
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
        ) : (
          <Profile
            stroke="white"
            strokeWidth={1.5}
            fill={
              location.pathname.startsWith("/profile") ||
              location.pathname.startsWith("/login")
                ? "white"
                : "none"
            }
            height={16}
          />
        )}
        <BodyText>Profile</BodyText>
      </FooterLink>
    </FooterContainer>
  );
}
