import { useSearchParams } from "react-router-dom";
import LocationPicker from "../LocationPicker";
import QuickSearch from "../QuickSearch";
import RecentSearches from "../RecentSearches";
import { SearchHomeContainer } from "./SearchHome-Styles";
import RetroCard from "../../Retro/RetroCard";
import { useSearchParamsHook } from "../../../routes/Search/hooks/searchParams";

export default function SearchHome() {
  const [searchParams] = useSearchParams();
  const { isLocationPickerOpen } = useSearchParamsHook({ searchParams });

  if (isLocationPickerOpen) return <LocationPicker />;

  return (
    <SearchHomeContainer>
      <QuickSearch />
      <RetroCard />
      <RecentSearches />
    </SearchHomeContainer>
  );
}
