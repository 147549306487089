import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import styled from "styled-components";
import { checkUserInterestByUserId } from "../../api/events";
import { getUserXp, setUserAction } from "../../api/profile";
import CheckGreen from "../../assets/svg/check-green.svg?react";
import { BodyText, ButtonRect, Section, Title } from "../../GlobalStyles";
import useAuth from "../../hooks/useAuth";
import useCheckIn from "../../hooks/useCheckIn";
import { theme } from "../../utils/theme";
import PerformanceItem from "./PerformanceItem";

const { colors } = theme;

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99999;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 9px;
`;

export const SelectAllButton = styled.button`
  height: 24px;
  width: 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border: 1px solid
    ${({ filled, theme }) =>
      filled ? theme.colors.green : "rgba(255, 255, 255, 0.15)"};
  background: ${({ filled }) =>
    filled
      ? "linear-gradient(0deg, #42fbae1a, #42fbae21)"
      : "rgba(17, 17, 31, 0.2)"};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PerformanceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 130px));
  gap: 21px 15px;
  padding: 0 16px;
  padding-bottom: 150px;
`;

const ClaimContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.27) 0%,
    rgb(0, 0, 0) 20%,
    rgb(0, 0, 0) 84.38%
  );
  gap: 8px;
  padding: 20px 16px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ClaimButton = styled(ButtonRect)`
  background: transparent;
  background-color: ${({ momentOnly }) =>
    momentOnly ? "" : "rgba(255, 255, 255, 0.12)"};
  width: 213px;
  border: none;
`;

export default function ClaimMoments() {
  const {
    performances,
    currentEvent,
    currentCheckIn,
    handleMomentAdd,
    handlePostEventRoomCreation,
    setMomentsCollected,
    setSelectedPerformances: _setSelectedPerformances,
    setMomentNeedsLogin,
    setSuccessfullyClaimedMoment,
    setEarnedXp,
    handleBadgesAttained,
  } = useCheckIn();
  const { loggedInUser } = useAuth();
  const [selectedPerformances, setSelectedPerformances] = useState([]);
  const [claiming, setClaiming] = useState(false);
  const [hasSubscribedClaiming, setHasSubscribedClaiming] = useState(false);
  const selectedAll = selectedPerformances.length === performances.length;
  const queryClient = useQueryClient();

  const handleSelect = (performanceId) => {
    if (selectedPerformances.includes(performanceId)) {
      setSelectedPerformances(
        selectedPerformances.filter((id) => id !== performanceId)
      );
    } else {
      setSelectedPerformances([...selectedPerformances, performanceId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedAll) setSelectedPerformances([]);
    else
      setSelectedPerformances(
        performances.map((performance) => performance.performance_id)
      );
  };

  const handleClaim = async (willSubscribe = false) => {
    if (claiming || hasSubscribedClaiming) return;

    if (selectedPerformances.length === 0) return;

    if (willSubscribe) {
      setHasSubscribedClaiming(true);
    } else {
      setClaiming(true);
    }

    // Get performances from selected performances
    const _selectedPerformances = performances.filter((performance) =>
      selectedPerformances.includes(performance.performance_id)
    );

    _setSelectedPerformances(_selectedPerformances);

    if (!loggedInUser) return setMomentNeedsLogin();

    const momentsCollected = [];

    const userActionArtistIds = [];

    const promises = selectedPerformances.map(async (performanceId) => {
      // Get selected performance object by performanceId
      const performance = performances.find(
        (performance) => performance.performance_id === performanceId
      );

      const moment = await handleMomentAdd(
        currentCheckIn.id,
        currentEvent.id ?? currentEvent.event_id,
        performance.artist,
        performance.artist_id,
        performance.performance_id,
        currentCheckIn?.official_checkin_number,
        currentEvent?.combined_artist_ids
      );

      if (!moment || !moment?.success || !moment.data) {
        throw new Error("Moment creation failed");
      }

      momentsCollected.push(moment.data);
      userActionArtistIds.push(moment.data.performance_artist_id);

      await handlePostEventRoomCreation(moment?.data);
    });

    // Wait for all promises to resolve
    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Error in processing performances:", error);
    }

    const checkedUserInterested = await checkUserInterestByUserId(
      currentEvent.id ?? currentEvent.event_id ?? null,
      loggedInUser?.id
    );
    const actionResult = await setUserAction({
      action_type: "event_checkin",
      user_id: loggedInUser?.id,
      target_type: "event",
      target_id: currentEvent.id ?? currentEvent.event_id ?? null,
      hasInterested: checkedUserInterested?.length >= 1 ? true : false,
      performersClaimed: selectedPerformances?.length ?? 0,
      hasSubscribed: willSubscribe ? true : false,
      artist_ids: userActionArtistIds,
    });
    if (actionResult?.success) {
      // toast.success(`Check in verified. Moments claimed. +${actionResult?.data?.xp?.xp} XP`, {
      //   toastId: "checkin-action-success",
      // });
      const userXp = await getUserXp(loggedInUser?.id);
      setEarnedXp(actionResult?.data?.xp?.xp, userXp?.data?.total_xp);
    }

    queryClient.removeQueries({
      queryKey: ["profile", loggedInUser?.id.toString()],
    });

    setClaiming(false);
    setHasSubscribedClaiming(false);
    setMomentsCollected(momentsCollected);

    setSuccessfullyClaimedMoment();
    handleBadgesAttained();
  };

  return (
    <Container>
      <Nav>
        <Title>Claim Moments</Title>
        <SelectAllContainer onClick={toggleSelectAll}>
          <BodyText>Select All ({performances.length})</BodyText>
          <SelectAllButton filled={selectedAll}>
            {selectedAll && <CheckGreen />}
          </SelectAllButton>
        </SelectAllContainer>
      </Nav>
      <PerformanceContainer>
        {performances.map((performance, index) => {
          return (
            <PerformanceItem
              key={index}
              id={performance.performance_id}
              performance={performance}
              event={currentEvent}
              onClick={() => handleSelect(performance.performance_id)}
              selected={selectedPerformances.includes(
                performance.performance_id
              )}
            />
          );
        })}
      </PerformanceContainer>
      <ClaimContainer>
        <ClaimButton
          onClick={() => handleClaim(true)}
          disabled={
            claiming ||
            hasSubscribedClaiming ||
            selectedPerformances.length === 0
          }
        >
          <BodyText weight="600">
            {hasSubscribedClaiming
              ? "Claiming Moments..."
              : "Claim + Subscribe"}
          </BodyText>
        </ClaimButton>
        <ClaimButton
          momentOnly={true}
          onClick={() => handleClaim(false)}
          disabled={
            claiming ||
            hasSubscribedClaiming ||
            selectedPerformances.length === 0
          }
        >
          <BodyText color={colors.grey}>
            {claiming ? "Claiming Moments..." : "Claim only"}
          </BodyText>
        </ClaimButton>
      </ClaimContainer>
    </Container>
  );
}
