import styled from "styled-components";
import PropTypes from "prop-types";
import { BodyText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import moment from "moment";
import { PROFILE_BADGE_TYPES } from "../../constants/constants";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const BadgeImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

const BadgeInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const BadgeNameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BadgeDate = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 75px;
  text-align: right;
`;

export default function BadgeItem({ badge, type }) {
  const { title, description, descriptionSuffix, image, created_at } = badge;

  return (
    <Container>
      <BadgeImage src={image} />
      <BadgeInfo>
        <BadgeNameAndDescription>
          <BodyText weight="600">{title}</BodyText>
          <BodyText>
            {description}{" "}
            <BodyText color={colors.green}>{descriptionSuffix}</BodyText>
          </BodyText>
        </BadgeNameAndDescription>
        {type === PROFILE_BADGE_TYPES.ATTAINED && (
          <BadgeDate>
            <BodyText color={colors.grey}>
              {moment(created_at).format("D MMM YYYY")}
            </BodyText>
            <BodyText color={colors.grey}>
              {moment(created_at).format("h:mm A")}
            </BodyText>
          </BadgeDate>
        )}
      </BadgeInfo>
    </Container>
  );
}

BadgeItem.propTypes = {
  badge: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
