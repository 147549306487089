import { useQuery } from "@tanstack/react-query";
import { fetchNearbyArtists } from "../../../api/artist";
import {
  fetchComingSoon,
  fetchEventsFriendsInterested,
  fetchNearbyEvents,
  fetchNewlyAppended,
  fetchNewlyAppendedPopular,
  fetchPopularInArea,
  fetchRecommendEvents,
  getAllInterestedEvents,
  getOneEventWithLocation,
} from "../../../api/events";
import { useUserStore } from "../../Profile/store/userStore";
import { getNearbyVenues } from "../../../api/venues";

export const useInterestedEvents = ({ userId }) => {
  return useQuery({
    queryKey: ["interested_events", userId],
    queryFn: async () => {
      if (!userId) {
        return []; // Return an empty array or appropriate value when no userId
      }
      const interestedEvents = await getAllInterestedEvents(userId);
      return interestedEvents?.data;
    },
    enabled: !!userId, // Only run the query if userId is available
    staleTime: 1000 * 60 * 3,
  });
};

export const useNearbyEvents = ({
  latitude = undefined,
  longitude = undefined,
  userId = undefined,
  enabled = true,
}) => {
  const { userCountry } = useUserStore();
  return useQuery({
    queryKey: ["nearby_events", userId.toString()],
    queryFn: async () => {
      let fetchedEvents = [];

      if (latitude != undefined && longitude != undefined) {
        fetchedEvents = await fetchNearbyEvents(
          latitude,
          longitude,
          25000,
          userId,
          userCountry
        );
      }

      if (
        latitude != undefined &&
        longitude != undefined &&
        fetchedEvents == false
      ) {
        fetchedEvents = await fetchNearbyEvents(
          latitude,
          longitude,
          25000,
          userId
        );
      }

      return fetchedEvents ? fetchedEvents : [];
    },
    enabled: latitude != null && longitude != null && enabled,
    staleTime: 1000000,
    // refetchInterval: 10000 * 1 * 1,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};

export const useLondonEvents = () => {
  return useQuery({
    queryKey: ["london_events"],
    queryFn: async () => {


        const fetchedEvents = await fetchNearbyEvents(
          null,
          null,
          2000000,
          null,
          "United Kingdom"
        );
      

      return fetchedEvents;
    },
    staleTime: 1000000,
  });
};

export const useOneEvent = ({
  latitude = undefined,
  longitude = undefined,
  event_id,
  date,
}) => {
  return useQuery({
    queryKey: ["one_event", event_id],
    queryFn: async () => {
      const eventWithLocation = await getOneEventWithLocation(
        event_id,
        latitude,
        longitude,
        date
      );

      return eventWithLocation ?? [];
    },
    enabled: latitude != null && longitude != null && event_id != null,
    staleTime: 10000 * 6 * 3,
    // refetchInterval: 10000 * 1 * 1,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};

export const useNearbyArtists = ({
  latitude = undefined,
  longitude = undefined,
  userId = undefined,
  enabled = false,
  setNearbyArtists,
}) => {
  const { userCountry } = useUserStore();
  return useQuery({
    queryKey: ["nearby_artists", userId, !!latitude, !!longitude],
    queryFn: async () => {
      let fetchedEvents = [];

      fetchedEvents = await fetchNearbyArtists(
        latitude,
        longitude,
        userCountry,
        userId
      );

      if (
        fetchedEvents.events.length == 0 &&
        latitude != null &&
        longitude != null
      ) {
        fetchedEvents = await fetchNearbyArtists(
          latitude,
          longitude,
          "United Kingdom",
          userId
        );
      }

      setNearbyArtists(fetchedEvents);
      return fetchedEvents ? fetchedEvents : [];
    },
    enabled:
      latitude != undefined ||
      longitude != undefined ||
      userId == null ||
      userId != undefined ||
      enabled,
    staleTime: 1000000,
    // refetchInterval: 10000 * 1 * 1,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export const useComingSoon = ({
  latitude = undefined,
  longitude = undefined,
  userId = undefined,
  enabled = true,
}) => {
  const { userCountry } = useUserStore();
  return useQuery({
    queryKey: ["coming_soon", userId.toString()],
    queryFn: async () => {
      let fetchedEvents = [];

      if (latitude != undefined && longitude != undefined) {
        fetchedEvents = await fetchComingSoon(
          latitude,
          longitude,
          25000,
          userId,
          userCountry
        );

        if (fetchedEvents == false)
          fetchedEvents = await fetchComingSoon(
            null,
            null,
            25000,
            userId,
            userCountry
          );

        if (fetchedEvents == false)
          fetchedEvents = await fetchComingSoon(
            null,
            null,
            25000,
            userId,
            "United Kingdom"
          );
      }

      return fetchedEvents ? fetchedEvents : [];
    },
    enabled: latitude != null && longitude != null && enabled,
    staleTime: 1000000,
    // refetchInterval: 10000 * 1 * 1,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};

export const useRecommendEvents = ({ userId = undefined, userCountry }) => {
  return useQuery({
    queryKey: ["recommended_events", userId, userCountry],
    queryFn: async () => {
      let fetchedEvents = [];

      fetchedEvents = await fetchRecommendEvents(userId, userCountry);

      if (fetchedEvents == false)
        fetchedEvents = await fetchRecommendEvents(userId, null);

      return fetchedEvents ? fetchedEvents : [];
    },
    staleTime: 1000000,
    // refetchInterval: 10000 * 1 * 1,
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};


export const useNewlyAppended = ({
  latitude = undefined,
  longitude = undefined,
  userId = undefined,
}) => {
  const { userCountry } = useUserStore();
  return useQuery({
    queryKey: ["newly_appended", userId?.toString()],
    queryFn: async () => {
      let fetchedEvents = [];

      if (latitude != undefined && longitude != undefined) {
        fetchedEvents = await fetchNewlyAppended(
          latitude,
          longitude,
          userId,
          userCountry != "" ? userCountry : null
        );
      }
      return fetchedEvents ? fetchedEvents : [];
    },
    enabled: latitude != null && longitude != null,
    staleTime: 1000000,
  });
};

export const useNewlyAppendedPopular = () => {
  return useQuery({
    queryKey: ["newly_appended-popular"],
    queryFn: async () => {
      let fetchedEvents = [];

        fetchedEvents = await fetchNewlyAppendedPopular(
        );
         return fetchedEvents ? fetchedEvents : [];
    },
    staleTime: 1000000,
  });
};

export const usePopularInArea = ({
  userCountry = "United Kingdom",
}) => {
  return useQuery({
    queryKey: ["popular_in_area", userCountry],
    queryFn: async () => {
      let fetchedEvents = [];
      
      fetchedEvents = await fetchPopularInArea(userCountry != "" ? userCountry : null);
    
      return fetchedEvents ? fetchedEvents : [];
    },
    enabled: userCountry != "",
    staleTime: 1000000,
  });
};

export const useEventsFriendsInterested = ({
  userId = null,
}) => {
  return useQuery({
    queryKey: ["events_friends_interested", userId?.toString()],
    queryFn: async () => {
      let fetchedEvents = [];

        fetchedEvents = await fetchEventsFriendsInterested(
          userId,
        );
    
      return fetchedEvents ? fetchedEvents : [];
    },
    staleTime: 1000000,
  });
};

export const useNearbyVenues = ({
  country_param = null,
}) => {
  return useQuery({
    queryKey: ["nearby_venues", country_param],
    queryFn: async () => {
      let fetchedVenues = [];
      
      fetchedVenues = await getNearbyVenues(
        country_param,
        );
        
      return fetchedVenues.data ? fetchedVenues.data : [];
    },
    staleTime: 1000000,
  });
};