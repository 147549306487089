import { useParams } from "react-router-dom";
import TopNav from "../../components/TopNav";
import { NavBarTypes } from "../../constants/constants";
import { BodyText, FooterText, Section } from "../../GlobalStyles";
import { useXpHistoryQuery } from "./xpHistoryQueries";
import {
  RecordContainer,
  RecordsContainer,
} from "../../components/XP/XPModal/XPModal-Styles";
import XPAction from "../../components/XP/XPAction";
import { theme } from "../../utils/theme";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";
import { useMemo } from "react";

const { colors } = theme;

export default function XPHistory() {
  const { id: userId } = useParams();
  const { data, isLoading } = useXpHistoryQuery({ userId });

  const { totalEarned, totalAvailable } = useMemo(() => {
    if (!data) return { totalEarned: 0, totalAvailable: 0 };

    const records = data.map((record) => record.xp);

    // Total earned by removing negatives
    const totalEarned = records
      .filter((record) => record >= 0)
      .reduce((a, b) => a + b, 0);

    // Total available
    const totalAvailable = records.reduce((a, b) => a + b, 0);

    return { totalEarned, totalAvailable };
  }, [data]) || { totalEarned: 0, totalAvailable: 0 };

  if (isLoading) {
    return (
      <Section>
        <TopNav type={NavBarTypes.XP_HISTORY} />
        <RecordsContainer>
          <RecordsContainer>
            {Array.from({ length: 25 }).map((_, index) => (
              <RecordContainer key={index}>
                <SkeletonElement height="17px" width="60%" />
                <SkeletonElement height="17px" width="15%" />
              </RecordContainer>
            ))}
          </RecordsContainer>
        </RecordsContainer>
      </Section>
    );
  }

  return (
    <Section>
      <TopNav
        type={NavBarTypes.XP_HISTORY}
        rightContent={
          <RecordContainer style={{ gap: "8px", width: "fit-content" }}>
            <BodyText>Total:</BodyText>
            <BodyText color={colors.green} weight="600">
              {totalEarned}xp
            </BodyText>
            <BodyText color={colors.green}>({totalAvailable}xp)</BodyText>
          </RecordContainer>
        }
      />
      <RecordsContainer>
        {data &&
          data?.length > 0 &&
          data.map((record) => {
            const isPositive = record.xp > 0;
            return (
              <RecordContainer key={record.id}>
                <RecordContainer
                  style={{
                    width: "fit-content",
                  }}
                >
                  <XPAction action={record?.action_type} />
                </RecordContainer>
                <FooterText color={isPositive ? colors.green : colors.red}>
                  {isPositive && "+"}
                  {record?.xp}
                  xp
                </FooterText>
              </RecordContainer>
            );
          })}
      </RecordsContainer>
    </Section>
  );
}
