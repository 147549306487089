import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.ephemeralBg};
  font-size: ${({ theme }) => theme.fontSizes.mobile.footer};
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  color: ${({ theme }) => theme.colors.grey};
`;
