import useMomentPhotoUpload from "../../../hooks/useMomentPhotoUpload";
import { BodyText, Header } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import {
  ButtonsContainer,
  Container,
  PrimaryButton,
  SecondaryButton,
} from "../UploadMediaModal/UploadMediaModal-Styles";

const { colors } = theme;

export default function DeletePhotoModal() {
  const { handleRemoveMediaFromMoment, loading, moment } =
    useMomentPhotoUpload();
  const { fan_photo_urls: fanPhotos } = moment || {};
  const { toggleCenterModal } = useGlobalModal();

  const isVideo = fanPhotos?.length > 0 && fanPhotos[0].includes(".mp4");

  return (
    <Container
      style={{
        padding: "16px",
      }}
    >
      <Header style={{ textTransform: "uppercase" }}>
        Remove {isVideo ? "Video" : "Photo"}?
      </Header>
      <BodyText color={colors.grey} style={{ margin: "12px 0 24px 0" }}>
        This can’t be undone and will remove this {isVideo ? "video" : "photo"}{" "}
        from your Moments and the feed of any accounts.
      </BodyText>
      <ButtonsContainer>
        <SecondaryButton
          onClick={handleRemoveMediaFromMoment}
          disabled={loading}
        >
          <BodyText weight="600">
            {loading ? "Deleting..." : "Confirm"}
          </BodyText>
        </SecondaryButton>
        <PrimaryButton onClick={toggleCenterModal} last disabled={loading}>
          <BodyText weight="600">Back</BodyText>
        </PrimaryButton>
      </ButtonsContainer>
    </Container>
  );
}
