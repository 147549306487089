import { create } from "zustand";

export const useRetroFlowStore = create((set) => ({
  // Retro flow state
  isOpen: false,
  setIsOpen: (value) => set(() => ({ isOpen: value })),
  // Current performance
  performance: null,
  setPerformance: (value) => set(() => ({ performance: value })),
  // Retro Moment
  retroMoment: null,
  setRetroMoment: (value) => set(() => ({ retroMoment: value })),
  // Loading state
  isLoading: false,
  setIsLoading: (value) => set(() => ({ isLoading: value })),
  // Success state
  isSuccess: false,
  setIsSuccess: (value) => set(() => ({ isSuccess: value })),
  // Error state
  isError: false,
  setIsError: (value) => set(() => ({ isError: value })),
}));
