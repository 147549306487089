import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import moment from "moment";
import { theme } from "../../../utils/theme";
import InterestedButton from "../../Events/InterestedButton";
import { Link } from "react-router-dom";
import {
  ArtistEventButtons,
  ArtistEventContainer,
  ArtistEventDate,
  ArtistEventDetailsContainer,
  ArtistEventDetais,
  ArtistEventVenue,
  OneLineText,
  TimeContainer,
} from "./ArtistEventDateBox-Styles";

const { colors } = theme;

/**
 * Renders the artist event component.
 *
 * @param {Object} event - The event object
 * @return {React.JSX.Element} The rendered artist event component
 */
export default function ArtistEventDateBox({ event }) {
  // Remove everything after + in date
  const date = event?.event_date && event?.event_date.split("+")[0];
  // Render the artist event component
  return (
    <ArtistEventContainer
      as={Link}
      to={`/events/${event.event_id}?date=${date}`}
    >
      <ArtistEventDetailsContainer>
        <ArtistEventDate>
          {/* Render the month */}
          <FooterText color={colors.green}>
            {moment(event.event_date).utc().format("ddd")}
          </FooterText>
          {/* Render the day */}
          <FooterText color={colors.green} weight="600">
            {moment(event.event_date).utc().format("DD MMM")}
          </FooterText>
        </ArtistEventDate>
        <ArtistEventDetais>
          <ArtistEventVenue>
            {/* Render the venue and city */}
            <OneLineText>{event.venue}</OneLineText>
            {/* Render the time */}
            <TimeContainer>
              <OneLineText color={colors.grey}>{event.country}</OneLineText>
              <OneLineText color={colors.grey} style={{ marginBottom: "2px" }}>
                •
              </OneLineText>
              <OneLineText color={colors.grey}>
                {moment(event.event_date).utc().format("h:mm a")}
              </OneLineText>
            </TimeContainer>
          </ArtistEventVenue>
        </ArtistEventDetais>
      </ArtistEventDetailsContainer>
      {/* Render the interested button */}
      <ArtistEventButtons>
        <InterestedButton event={event} size={20} />
      </ArtistEventButtons>
    </ArtistEventContainer>
  );
}

ArtistEventDateBox.propTypes = {
  event: PropTypes.object,
};
