import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 42px;
  right: 0;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
`;

export const DropdownItem = styled.div`
  position: relative;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${({ theme }) => theme.colors.background};
  position: absolute;
  top: -5px;
  right: 10px;
`;
