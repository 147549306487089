import { getOneArtist } from "../../api/artist";

export const artistLoader = async ({ artistId, userId }) => {
  const { data, error } = await getOneArtist(artistId, userId);
  
  if (error) {
    return { error };
  }
 
  return data;
};
