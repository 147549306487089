import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../GlobalStyles";
import ChatIcon from "../../assets/svg/chat.svg?react";
import { useNavigate } from "react-router-dom";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";
import { FESTIVAL_ROOM } from "../../routes/Chat/constants";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";

const ChatButton = styled(Button)`
  padding: 7px;
`;

/**
 * Handles the click event for the EventRoomButton component.
 *
 * @param {boolean} dark - Indicates the dark mode status
 * @return {void}
 */
export default function EventRoomButton({ dark }) {
  const navigate = useNavigate();
  const { loggedInUser, login } = useMomentifyLogin();
  const { handleTracking } = useMixpanelTracker();

  /**
   * Handles the click event for the button.
   *
   * @param {Object} e - The click event object
   * @return {React.JSX.Element} The rendered EventRoomButton component
   */
  const handleClick = async (e) => {
    e.preventDefault();

    // Check if user is logged in, if not, redirect to login
    if (!loggedInUser) return login();

    // Define the festival room address
    const postEventRoom = FESTIVAL_ROOM;

    // Track the event using Mixpanel
    handleTracking(
      "Enter Festival Room",
      {
        "Room data": JSON.stringify({
          room_address: postEventRoom,
          room_type: "festival room",
        }),
      },
      loggedInUser.id
    );

    // Navigate to the chat room
    navigate(`/chat?roomID=${postEventRoom}`);
  };

  // Assign the handleClick function to handleOnClick
  const handleOnClick = handleClick;

  // Render the button component
  return (
    <ChatButton onClick={handleOnClick} dark={dark}>
      <ChatIcon stroke="white" height={16} width={16} />
    </ChatButton>
  );
}

EventRoomButton.propTypes = {
  event: PropTypes.object,
  moment: PropTypes.object,
  dark: PropTypes.bool,
};
