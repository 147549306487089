import styled from "styled-components";
import { DESKTOP_IMAGES } from "../../constants/constants";

export const Section = styled.section`
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 80px;
  padding: 0 40px;
  background: url(${DESKTOP_IMAGES.GRAINY_BG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  width: 100%;
`;

export const Video = styled.video`
  aspect-ratio: auto;
  object-fit: cover;
  max-height: 656px;
  border-radius: 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

export const TextContainer = styled(ContentContainer)`
  gap: 40px;
`;

export const Header = styled.h1`
  font-family: ${({ theme }) => theme.fontStyles.druk};
  font-weight: 900;
  font-size: 60px;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const InstructionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const QRCodeImage = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 8px;
  background: none;
`;

export const InstructionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Text = styled.p`
  color: ${({ theme, color }) => color || theme.colors.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
`;

export const InstructionHeader = styled(Text)`
  font-size: 24px;
  font-weight: 600;
`;

export const DownloadInfoContainer = styled.div`
  display: flex;
  gap: 26px;
`;
