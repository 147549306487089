import { useNearbyArtists } from "../../routes/Events/hooks/eventsQueries";
import { useEventStore } from "../../routes/Events/stores/eventStore";
import useAuth from "../../hooks/useAuth";
import useGeolocation from "../../hooks/useGeolocation";
import { SkeletonElements } from "./components/SkeletonElements";
import { SmallHeader } from "./components/SmallHeader";
import { EventCard } from "./components/EventCard";
import { ArtistCard } from "./components/ArtistCard";
import { ArtistCardSkeleton } from "./components/ArtistCardSkeleton";

export const ArtistsPanel = () => {
  const { loggedInUser } = useAuth();
  const { setNearbyArtists } = useEventStore();
  const { currentLocation } = useGeolocation();

  const nearbyArtistsQuery = useNearbyArtists({
    latitude: currentLocation?.coords?.latitude ?? null,
    longitude: currentLocation?.coords?.longitude ?? null,
    userId: loggedInUser?.id ?? null,
    setNearbyArtists,
  });

  const artists = nearbyArtistsQuery?.data?.events;
  const headerText = nearbyArtistsQuery?.data?.status;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      {nearbyArtistsQuery?.isLoading ? (
        <ArtistCardSkeleton />
      ) : (
        <>
          <SmallHeader headerText={headerText} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 16,
              overflow: "scroll",
              marginRight: "-20px",
              paddingRight: 20,
              marginLeft: "-20px",
              paddingLeft: 20,
            }}
          >
            {artists.map((artist) => (
              <ArtistCard artist={artist} key={artist.artist_id} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
