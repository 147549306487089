import PropTypes from "prop-types";
import styled from "styled-components";
import { GlassmorphPlaceholder } from "../../constants/constants";
import { FooterText } from "../../GlobalStyles";
import useAuth from "../../hooks/useAuth";
import { setImageOfEvent } from "../../utils/utils";
import BackButton from "../BackButton";
import InterestedButton from "../Events/InterestedButton";
import RoomButton from "../RoomButton";

const HeaderContainer = styled.div`
  width: 100%;
  min-height: 268px;
  border: none;
  position: relative;
`;

const EventImage = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BackButtonContainer = styled(ButtonContainer)`
  top: 16px;
  left: 20px;
`;

const RoomChatButtonContainer = styled(ButtonContainer)`
  top: 16px;
  right: 20px;
  gap: 2px;
`;

const InterestedButtonContainer = styled(ButtonContainer)`
  bottom: 16px;
  right: 20px;
  text-align: center;
  gap: 2px;
`;

export default function EventHeader({
  event,
  room,
  interested,
  participantsCount,
  handleParticipantCount,
}) {
  const { loggedInUser } = useAuth();
  const handleInterestedButtonVisibility = () => {
    const presentDate = new Date();
    const eventDate = new Date(event.date);

    if (presentDate > eventDate) return false;

    if (loggedInUser) return true;

    return false;
  };

  return (
    <HeaderContainer>
      <EventImage
        src={setImageOfEvent(event)}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />
      <BackButtonContainer>
        <BackButton />
      </BackButtonContainer>
      {room && interested && (
        <RoomChatButtonContainer>
          <RoomButton event={event} dark />
          <FooterText>{room.total_members}</FooterText>
        </RoomChatButtonContainer>
      )}
      {handleInterestedButtonVisibility() && (
        <InterestedButtonContainer>
          <FooterText>{participantsCount}</FooterText>
          <div onClick={handleParticipantCount}>
            <InterestedButton event={event} size={24} />
          </div>
        </InterestedButtonContainer>
      )}
    </HeaderContainer>
  );
}

EventHeader.propTypes = {
  event: PropTypes.object,
  room: PropTypes.object,
  interested: PropTypes.bool,
  participantsCount: PropTypes.number,
  handleParticipantCount: PropTypes.func,
};
