import PropTypes from "prop-types";
import Mic from "../../assets/svg/mic.svg?react";
import Profile from "../../assets/svg/profile.svg?react";
import CommonTabs from "../CommonTabs";
import ArtistFollowing from "./ArtistFollowing";
import FanFollowing from "./FanFollowing";

export default function FollowingTabs({ artists, fans, isLoading = true }) {
  const tabs = [
    {
      id: "artists",
      icon: <Mic height="16px" stroke="white" />,
      label: `Artists`,
      component: <ArtistFollowing artists={artists} isLoading={isLoading} />,
    },
    {
      id: "fans",
      icon: <Profile stroke="white" strokeWidth={1.5} />,
      label: `Fans`,
      component: <FanFollowing fans={fans} isLoading={isLoading} />,
    },
  ];

  return <CommonTabs tabs={tabs} defaultActiveTab="artists" />;
}

FollowingTabs.propTypes = {
  artists: PropTypes.array,
  fans: PropTypes.array,
  isLoading: PropTypes.bool,
};
