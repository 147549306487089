import styled from "styled-components";

export const MomentsFeedContainer = styled.div`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  background: transparent;
  gap: 30px;
`;

export const NavOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 70%;
`;

export const NavOption = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
  gap: 6px;
  position: relative;
`;

export const NavLine = styled.div`
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 30%;
  background: white;
  border-radius: 2px;
`;
