import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { BodyText, Title } from "../../GlobalStyles";
import BackButton from "../../components/BackButton";
import EventItem from "../../components/Events/EventItem";
import ShareButton from "../../components/ShareButton";
import { EventItemTypes, StockImage } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";
import {
  ColumnFlex,
  RowFlex,
} from "../../components/Events/YourEvent/YourEvent-Style";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";
import { theme } from "../../utils/theme";
import {
  BackButtonContainer,
  SeeAllEvents,
  VenueDetailsContainer,
  VenueEventsContainer,
  VenueHeaderContainer,
  VenueImage,
  VenueNameAndAddressContainer,
  VenueSection,
} from "./Venue-Styles";
import { useVenue } from "./hooks/venueQueries";
import ErrorPage from "../Error";

export default function Venue() {
  const { loggedInUser } = useAuth();
  const { id: venueId, name: venueName } = useParams();
  const { data, error, isPending } = useVenue({
    venueId,
    venueName,
    userId: loggedInUser?.id,
  });
  const { venueDetails } = data || {};
  const { venue, events } = venueDetails || {};

  const [showAllEvents, setShowAllEvents] = useState(false);
  const [venueImage, setVenueImage] = useState();
  const [displayedEvents, setDisplayedEvents] = useState();

  useEffect(() => {
    if (!venueDetails || isPending) return;

    // Set venueImage to the first event's image or to a stock image if no events
    setVenueImage(events?.[0]?.image ?? StockImage);

    // Set initial state for showing all events
    setDisplayedEvents(events?.slice(0, 3));
  }, [venueDetails, isPending]);

  const handleSeeAllEvents = () => {
    const { events } = venueDetails;

    setShowAllEvents(!showAllEvents);

    if (!showAllEvents) {
      setDisplayedEvents(events);
    } else {
      setDisplayedEvents(events?.slice(0, 3));
    }
  };

  if (isPending)
    return (
      <VenueSection>
        <SkeletonElement width="100%" height="268px">
          <BackButtonContainer>
            <BackButton />
          </BackButtonContainer>
        </SkeletonElement>
        <VenueDetailsContainer>
          <VenueHeaderContainer>
            <VenueNameAndAddressContainer>
              <SkeletonElement
                width="160px"
                height="24px"
                style={{ marginBottom: 6 }}
              />
              <SkeletonElement
                width="200px"
                height="14px"
                style={{ marginBottom: 4 }}
              />
            </VenueNameAndAddressContainer>
            {/* Render venue name */}
            <SkeletonElement width="28px" height="28px" />
          </VenueHeaderContainer>
          <SkeletonElement
            width="120px"
            height="14px"
            style={{ marginBottom: 4 }}
          />

          <VenueEventsContainer>
            {Array.from({ length: 3 }).map((_, index) => (
              <RowFlex key={index}>
                <SkeletonElement width="58px" height="58px" radius="10px" />
                <ColumnFlex style={{ gap: 4 }}>
                  <SkeletonElement
                    width="100px"
                    height="14px"
                    style={{ marginBottom: 8 }}
                  />
                  <SkeletonElement
                    width="100px"
                    height="10px"
                    style={{ marginBottom: 2 }}
                  />
                  <SkeletonElement width="120px" height="10px" />
                </ColumnFlex>
                <SkeletonElement
                  width="28px"
                  height="28px"
                  style={{ marginLeft: "auto", marginRight: 4 }}
                />
                <SkeletonElement width="28px" height="28px" />
              </RowFlex>
            ))}
          </VenueEventsContainer>
        </VenueDetailsContainer>
      </VenueSection>
    );

  if (!venueDetails || error)
    return <ErrorPage description="We can't find this venue." />;

  return (
    <VenueSection>
      <VenueImage image={venueImage}>
        <BackButtonContainer>
          <BackButton />
        </BackButtonContainer>
      </VenueImage>
      <VenueDetailsContainer>
        <VenueHeaderContainer>
          <VenueNameAndAddressContainer>
            <Title>{venue?.venue_name}</Title>
            <BodyText>
              {venue?.city}, {venue?.country}
            </BodyText>
          </VenueNameAndAddressContainer>
          {/* Render venue name */}
          <ShareButton
            url={window.location.href}
            title={venue?.venue_name}
            text={`Check out this venue - ${venue?.venue_name}`}
          />
        </VenueHeaderContainer>
        {/* Render no upcoming events message if no events, otherwise render upcoming events */}
        {!events || events.length === 0 ? (
          <BodyText weight="600" color={theme.colors.grey}>
            No upcoming events
          </BodyText>
        ) : (
          <VenueEventsContainer>
            <BodyText weight="600" color={theme.colors.grey}>
              Upcoming Events
            </BodyText>
            {/* Map through and render each displayed event */}
            {displayedEvents?.map((event) => {
              return (
                <EventItem
                  key={event.id}
                  event={event}
                  isOwnProfile={loggedInUser && true}
                  type={EventItemTypes.VENUE}
                />
              );
            })}
            {/* Render 'See more' or 'Show less' button based on the number of events */}
            {events.length > 3 && (
              <SeeAllEvents onClick={handleSeeAllEvents}>
                {showAllEvents ? "Show less" : "See more"}
              </SeeAllEvents>
            )}
          </VenueEventsContainer>
        )}
      </VenueDetailsContainer>
    </VenueSection>
  );
}
