import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  BodyText,
  ButtonRect,
  FooterText,
  FooterTextSm,
} from "../../../GlobalStyles";
import useAuth from "../../../hooks/useAuth";
import { theme } from "../../../utils/theme";
import {
  useGetAndCreateArtists,
  useSpotifySubscribe,
} from "../../../routes/Artist/hooks/artistQueries";

const BottomContainer = styled.div`
  background: ${({ isConnected }) =>
    isConnected
      ? "linear-gradient(rgba(0, 0, 0, 0.27) 0%, rgb(0, 0, 0) 20%, rgb(0, 0, 0) 84.38%)"
      : "transparent"};
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 20px 60px 40px 60px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 10;
`;

export default BottomContainer;

export const BottomView = ({
  isConnected,
  count,
  countTotal,
  subscribedList,
  spotifyArtists,
}) => {
  const navigate = useNavigate();
  const { loggedInUser } = useAuth();
  const createGetArtistsMutation = useGetAndCreateArtists();
  const spotifySubscribe = useSpotifySubscribe();

  const onSubscribe = async () => {
    const subscribedArtists = spotifyArtists.filter((artist) =>
      subscribedList.includes(artist.href)
    );
    const artistIDs =
      await createGetArtistsMutation.mutateAsync(subscribedArtists);

    await spotifySubscribe.mutateAsync({
      loggedInUserId: loggedInUser.id,
      artistIDs: artistIDs,
    });
    navigate(`/profile/${loggedInUser.id}`);
  };

  const onSkip = () => {
    navigate(`/profile/${loggedInUser.id}`);
  };

  return (
    <BottomContainer isConnected={isConnected}>
      {/* Count */}
      {isConnected && (
        <FooterTextSm
          style={{ color: "#99999D" }}
        >{`${count} out of ${countTotal} artists selected`}</FooterTextSm>
      )}
      {/* Button */}
      {isConnected && (
        <ButtonRect
          style={{
            borderRadius: "10px",
            backgroundColor: "#F7F7F71F",
            border: "none",
            width: "100%",
          }}
          onClick={onSubscribe}
        >
          <BodyText
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 12,
              alignItems: "center",
            }}
          >
            <BodyText style={{ color: "#F7F7F7" }}>
              Confirm & Subscribe
            </BodyText>
            <FooterText
              color={theme.colors.green}
            >{`+${count * 2}xp`}</FooterText>
          </BodyText>
        </ButtonRect>
      )}

      {/* Skip */}
      <BodyText onClick={onSkip} style={{ color: "#F7F7F7", opacity: 0.6 }}>
        {isConnected ? "Skip (0xp)" : "Skip"}
      </BodyText>
    </BottomContainer>
  );
};

BottomView.propTypes = {
  isConnected: PropTypes.bool,
  count: PropTypes.number,
  countTotal: PropTypes.number,
  subscribedList: PropTypes.array,
  spotifyArtists: PropTypes.array,
};
