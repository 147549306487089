import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 16px;
  max-height: 50svh;
  overflow-y: auto;
  width: 100%;
  gap: 24px;
`;
