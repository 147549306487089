import styled from "styled-components";

export const TabsAndFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  flex: 1;
  filter: ${({ blurred }) => (blurred ? "blur(10px) brightness(70%)" : "none")};
`;

export const DatePickerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
`;
