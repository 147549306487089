import styled from "styled-components";
// import ProfileNav from "../../components/Profile/ProfileNav";
import { Section } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import TopNav from "../../components/TopNav";
import MessageViewItem from "../../components/MessagesView/MessageViewItem";
import { useEffect } from "react";
import { readUserLatestMessage, readUserMessage } from "../../api/profile";
import { useUserArtistMessages } from "./hooks/messagesViewQueries";
import { useUserMessages } from "../Messages/hooks/messagesQueries";
import { MomentifyLogo2 } from "../../constants/constants";
const { fontSizes, colors } = theme;

const BadgesSection = styled(Section)`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  // padding: 16px;
  align-items: ${({ hasBadges }) => !hasBadges && "center"};
  justify-content: ${({ hasBadges }) => !hasBadges && "center"};
  flex: ${({ hasBadges }) => !hasBadges && "1"};
`;

/**
 * Function to render the user badges section based on the attained and next attainable badges.
 *
 * @return {React.JSX.Element} The user badges section component
 */
export default function MessagesView() {
  // Get the badges data from the loader
  const { senderId } = useParams()
  const { loggedInUser } = useAuth()
  // const messages = useLoaderData();
  const messages = useUserArtistMessages({
    userId: loggedInUser?.id ?? "",
    artistId: senderId ?? "",
    isOwnProfile: true,
  })
  const latestMessages = useUserMessages({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: true,
  });

  useEffect(() => {
    return () => {
      if (messages?.data?.messages.length > 0) {
        readUserMessage(messages?.data?.notif_id).then(_ => {
          latestMessages.refetch()
        })
      }
    }
  }, [])

  // Render the user notification center
  return (
    <BadgesSection>
      {/* Render the profile navigation for badges */}
      <TopNav type={messages?.data?.sender_name} img={messages?.data?.sender_name == 'Momentify' ? MomentifyLogo2 : messages?.data?.img} />
      {/* Render the badges container based on the existence of attained or next attainable badges */}
      <BadgesContainer hasBadges={true}>
        {
          messages?.isLoading
            ? (
              <>
                {Array.from({ length: 12 }).map((_, index) => (
                  <>
                    <div style={{
                      borderTop: `1px solid ${theme.colors.border}`
                    }}></div>
                    <MessageViewItem
                      key={index}
                      img={messages?.data?.img}
                      sender_name={messages?.data?.sender_name}
                      message={{
                        message: "",
                        message_img: "",
                        message_link: "",
                        message_link_cta: "",
                        created_at: "",
                        id: "",
                        title: "",
                      }}
                      isLoading={true}
                    />
                  </>
                ))}
              </>
            )
            : (
              <>
                {
                  loggedInUser?.id && messages?.data?.messages?.map((message, index) => (
                    <>
                      <div style={{
                        borderTop: `1px solid ${theme.colors.border}`
                      }}></div>
                      <MessageViewItem
                        key={index}
                        sender_name={messages?.data?.sender_name}
                        img={messages?.data?.img}
                        message={message}
                        isLoading={false}
                      />
                    </>
                  ))
                }
              </>
            )
        }
      </BadgesContainer>
    </BadgesSection>
  );
}
