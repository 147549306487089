import styled from "styled-components";
import { BodyText, Button, ButtonGhost, FooterText, FooterTextSm } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import MessageDropdownContent from "../Dropdown/MessageDropdownContent";
import ThreeDots from "../../assets/svg/three-dots.svg?react"
import { readUserLatestMessage, readUserMessage } from "../../api/profile";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import rehypeSanitize from "rehype-sanitize";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MomentifyLogo2 } from "../../constants/constants";

const { colors } = theme;

const Container = styled.div` 
  background: ${({ isUnread }) => isUnread ? theme.colors.blueShade : 'none'};
  border-left: ${({ isUnread }) => isUnread ? `2px solid ${theme.colors.green}` : 'none'};
  padding: 8px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const ContainerContent = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const BadgeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const BadgeImageWrapper = styled.div`
  height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  align-self: start;
  overflow: hidden;
`;

const BadgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // align-items: center;
  // justify-content: space-between;
  gap: 8px;
  position: relative;
`;

const BadgeNameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BadgeNameAndDatetime = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const BadgeDate = styled.div`
  display: flex;
  align-items: center;
  min-width: 75px;
  // gap: 5px;
  // text-align: right;
`;

const DotsButton = styled(ButtonGhost)`
  border: none;
  background: transparent;
  padding: 0 3px 3px 3px;
  // position: absolute;
  // top: 0;
  // right: 0;
`;

const ActionButton = styled(Button)`
  // padding: 0 3px 3px 3px;
  background: ${theme.colors.black};
  padding: 7px 15px;
  align-self: flex-start;
`;

const MessageSpan = styled(BodyText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
`

const MessageSpanBold = styled(BodyText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  font-weight: 600;
`

export default function MessageItem({ message: _message, viewMessageOnClick, userId, handlePopSelectedItem, isLoading, refetch }) {
  const { sender_id, sender_name, message, img, created_at, read_at, message_id } = _message;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef();
  const [isItemRead, setIsItemRead] = useState(!!read_at)

  const handleDropdownItemClick = (event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return
    }

    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleReadClick = async () => {
    await readUserMessage(message_id)
    refetch()
    if (!isItemRead) {
      setIsItemRead(true)
      setIsDropdownOpen(!isDropdownOpen)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container isUnread={!isItemRead}>
      <ContainerContent onClick={viewMessageOnClick}>
        {
          isLoading
            ? (
              <>
                <BadgeImageWrapper>
                  <SkeletonElement
                    width="40px"
                    height="40px"
                    radius="50%"
                    style={{ minWidth: "40px", minHeight: "40px" }} />
                </BadgeImageWrapper>
                <BadgeInfo>
                  <BadgeNameAndDatetime style={{
                    paddingRight: '30px'
                  }}>
                    <MessageSpanBold>
                      <SkeletonElement
                        width="100%"
                        height="16px"
                        // radius="50%"
                        style={{ minWidth: "120px", minHeight: "16px" }} />
                    </MessageSpanBold>
                    <BadgeDate>
                      <SkeletonElement
                        width="100%"
                        height="10px"
                        // radius="50%"
                        style={{ minWidth: "80px", minHeight: "10px" }} />
                    </BadgeDate>
                  </BadgeNameAndDatetime>
                  <BadgeNameAndDescription>
                    <BodyText>
                      <SkeletonElement
                        width="100%"
                        height="16px"
                        // radius="50%"
                        style={{ minWidth: "40px", minHeight: "16px" }} />
                    </BodyText>
                  </BadgeNameAndDescription>
                </BadgeInfo>
              </>
            )
            : (
              <>
                <BadgeImageWrapper>
                  <BadgeImage src={sender_name == 'Momentify' ? MomentifyLogo2 : img} />
                </BadgeImageWrapper>
                <BadgeInfo>
                  <BadgeNameAndDatetime style={{
                    paddingRight: '30px'
                  }}>
                    <MessageSpanBold>
                      {sender_name}
                    </MessageSpanBold>
                    <BadgeDate>
                      <FooterText color={colors.grey}>
                        {moment(created_at).fromNow(true)}
                      </FooterText>
                    </BadgeDate>
                  </BadgeNameAndDatetime>
                  <BadgeNameAndDescription style={{
                    fontSize: '14px',
                    fontFamily: 'Suisse Intl'
                  }}>
                    <MarkdownPreview className="react-md" source={message} rehypePlugins={[rehypeSanitize]} styles={{ whiteSpace: 'pre-wrap' }} />
                  </BadgeNameAndDescription>
                </BadgeInfo>
              </>
            )
        }
      </ContainerContent>

      {
        isLoading
          ? <DotsButton>
            <SkeletonElement
              width="16px"
              height="16px"
              radius="50%"
              style={{ minWidth: "16px", minHeight: "16px" }} />
          </DotsButton>
          : (
            <DotsButton onClick={handleDropdownItemClick} index={message_id}>
              <ThreeDots height={16} width={16} />
              <span ref={dropdownRef} style={{
                position: 'relative'
              }}>
                <MessageDropdownContent isOpen={isDropdownOpen} isRead={isItemRead} setIsDropdownOpen={setIsDropdownOpen} messageId={message_id} handleReadClick={handleReadClick} handlePopSelectedItem={handlePopSelectedItem} userId={userId} sender_id={sender_id} refetch={refetch} />
              </span>
            </DotsButton>
          )
      }
    </Container>
  );
}