import { BodyText, FooterText } from "../../../GlobalStyles";
import { SubButton } from "../../Artist/SubscribeButton";
import Bell from "../../../assets/svg/bell.svg?react";
import BellCheck from "../../../assets/svg/bell-check.svg?react";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";

export const ArtistBox = ({
  image,
  name,
  url,
  subscribedList,
  setSubscribedList,
}) => {
  const isSubscribed = subscribedList.find((artist) => artist === url);
  const { colors } = theme;

  const subscribe = () => {
    if (isSubscribed) {
      // If already subscribed, remove the artist from the subscribedList
      setSubscribedList(subscribedList.filter((artist) => artist !== url));
    } else {
      // If not subscribed, add the artist to the subscribedList
      setSubscribedList([...subscribedList, url]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        width: "100%",
      }}
    >
      <img
        src={image}
        style={{
          width: 40,
          height: 40,
          borderRadius: 1000,
        }}
      />
      <BodyText
        style={{
          flexGrow: 1,
          fontWeight: 400,
          fontSize: 14,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </BodyText>

      <SubButton
        isSubscribed={isSubscribed}
        style={{
          width: 122,
          height: 32,
          maxWidth: 122,
          minWidth: 122,
        }}
        onClick={subscribe}
      >
        {isSubscribed ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 8,
            }}
          >
            <BellCheck height={14} width={14} stroke={colors.green} />
            <FooterText
              style={{
                fontWeight: 600,
              }}
            >
              Subscribed
            </FooterText>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 8,
            }}
          >
            <Bell
              style={{
                position: "relative",
                bottom: 1,
              }}
              height={14}
              width={14}
              stroke="white"
            />
            <FooterText
              style={{
                fontWeight: 600,
              }}
            >
              Subscribe
            </FooterText>
          </div>
        )}
      </SubButton>
    </div>
  );
};

ArtistBox.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  subscribedList: PropTypes.array,
  setSubscribedList: PropTypes.func,
};
