import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  BodyText,
  ButtonRect,
  ButtonRectSm,
  FooterText,
} from "../../GlobalStyles";
import ChatIcon from "../../assets/svg/chat.svg?react";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import { getRoom } from "../../utils/matrix-utils";
import { ChatButton } from "./RoomButton-Styles";

/**
 * EventRoomButton component renders a button for entering pre or post event chat room
 * @param {Object} event - The event object
 * @param {Object} moment - The moment object
 * @param {boolean} dark - Indicates if the button should be rendered in dark mode
 * @param {string} type - The type of button to be rendered
 * @return {React.JSX.Element} The rendered RoomButton component
 */
export default function RoomButton({ event, moment, dark, size, shape }) {
  const navigate = useNavigate();
  const { loggedInUser, login } = useMomentifyLogin();
  const { handleTracking } = useMixpanelTracker();

  /**
   * Handle the click event for entering the post-event room
   * @param {Event} e - The click event
   */
  const handleMomentClick = async (e) => {
    e.preventDefault();

    // Get the post-event room data
    let postEventRoom = moment.room;
    if (!postEventRoom) return;

    // Track the user's entry into the post-event room
    handleTracking(
      "Enter Post-event Room",
      {
        "Room data": JSON.stringify({
          room_id: postEventRoom.id,
          room_type: "post event room",
        }),
      },
      loggedInUser.id
    );

    // Navigate to the post-event room chat
    navigate(`/chat?roomID=${postEventRoom.room_address}`);
  };

  /**
   * Handle the click event for entering the pre-event room
   * @param {Event} e - The click event
   */
  const handleEventClick = async () => {
    if (!loggedInUser) return login();

    // Get the pre-event room data
    let preEventRoom = await getRoom(event.room);
    if (!preEventRoom) return;

    // Track the user's entry into the pre-event room
    handleTracking(
      "Enter Pre-event Room",
      {
        "Room data": JSON.stringify({
          room_id: preEventRoom.id,
          room_type: "pre event room",
        }),
      },
      loggedInUser.id
    );

    // Navigate to the pre-event room chat
    navigate(`/chat?roomID=${preEventRoom.room_address}`);
  };

  // Determine which click handler to use based on the moment's existence
  const handleOnClick = (e) => {
    if (!loggedInUser) return login();

    if (moment) handleMomentClick(e);
    else handleEventClick(e);
  };

  if (shape === "rect" && size === "small") {
    return (
      <ButtonRectSm onClick={handleOnClick}>
        <ChatIcon stroke="white" height={14} width={14} />
        <FooterText weight="600">Chat</FooterText>
      </ButtonRectSm>
    );
  }

  if (shape === "rect" && size !== "small") {
    return (
      <ButtonRect onClick={handleOnClick}>
        <ChatIcon stroke="white" height={14} width={14} />
        <BodyText weight="600">Chat</BodyText>
      </ButtonRect>
    );
  }

  // Render the chat button with appropriate props
  return (
    <ChatButton onClick={handleOnClick} dark={dark}>
      <ChatIcon stroke="white" height={16} width={16} />
    </ChatButton>
  );
}

RoomButton.propTypes = {
  event: PropTypes.object,
  moment: PropTypes.object,
  dark: PropTypes.bool,
  size: PropTypes.string,
  shape: PropTypes.string,
};
