import styled from "styled-components";
import { theme } from "../../utils/theme";
import { StyledPrimaryButton } from "../../styles/modal.styles";
import useGlobalModal from "../../hooks/useGlobalModal";
import { BodyText, HeaderL, HeaderXxxl, Section } from "../../GlobalStyles";
import BackButton from "../../components/BackButton";
import { useLoaderData, useNavigate } from "react-router-dom";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import LoadingScreen from "../../components/LoadingScreen";
import { useEffect } from "react";
import { AvatarPlaceholder, StockImage } from "../../constants/constants";
import ErrorPage from "../Error";
import { isCinnyAuthenticated, isCinnySupported } from "../../utils/cinny-utils";

const bgURL =
  "https://mediacontent.momentify.xyz/media/backgrounds/ghost-ten.webp";
const fullLogoURL = "https://mediacontent.momentify.xyz/media/full-logo.png";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  // background-blend-mode: multiply;
  color: white;
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  text-align: left;
  // padding: 35px 27px;
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin-top: 60px;
`;

const PfpContainer = styled.div`
  border-radius: 16px;
  width: 160px;
  height: 160px;
  // background: rgba(17, 17, 31, 1);
  overflow: hidden;
`;

const Pfp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-blend-mode: multiply;
  background: ${theme.colors.glass2};
`;

const PfpImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  gap: ${({ gap }) => gap};
  text-align: center;
  z-index: 1;
`;

const Button = styled(StyledPrimaryButton)`
  background-color: ${theme.colors.softGreen};
  padding: 8px 24px;
  min-width: 135px;
  cursor: pointer;
`;

const NotFoundSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const OverLayImage = styled.div`
  position: absolute;
  display: flex;
  width: 130%;
  height: 96%;
  opacity: 20%;
  filter: blur(8px);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 0;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const LogoImage = styled.img`
  height: 40px;
  background: transparent;
`;

export default function LandingPage() {
  const { inviteLink, invitor, artist } = useLoaderData();
  const { ready, authenticated, user, loggedInUser, login } = useMomentifyLogin(
    inviteLink?.id ?? null,
    invitor?.id ?? null
  );
  const navigate = useNavigate();
  const { openSheetPWAModal } = useGlobalModal();

  useEffect(() => {
    openSheetPWAModal();
  }, []);

  useEffect(() => {
    const redirectToProfile = async () => {
      if (authenticated && user && loggedInUser) {
        navigate(`/profile/${loggedInUser.id}`);
      }
    };

    if (isCinnySupported() && !isCinnyAuthenticated() && !!user?.id) {
      import("../../utils/federated-cinny-utils").then(async (module) => {
        const _user = {
          ...user,
          privy_id: user.id
        }
        await module.initMatrix(_user);
      });
    }

    redirectToProfile();
  }, [authenticated, user, loggedInUser, navigate]);

  if (authenticated && user && loggedInUser) {
    return <LoadingScreen />;
  }

  if (!inviteLink || !invitor) {
    return <ErrorPage />;
  }

  if (inviteLink?.confirmed) {
    return (
      <NotFoundSection>
        <BackButtonContainer>
          <BackButton />
        </BackButtonContainer>
        <HeaderL>This invite link was already used</HeaderL>
      </NotFoundSection>
    );
  }

  const notFullyReady = !ready || (authenticated && !loggedInUser);
  if (notFullyReady) {
    return <LoadingScreen />;
  }

  return (
    <NotFoundSection>
      <BackButtonContainer>
        <LogoImage src={fullLogoURL} />
      </BackButtonContainer>

      <Container>
        <FlexContainer gap="24px" direction="column" align="center">
          <FlexContainer
            gap="16px"
            direction="column"
            align="center"
            style={{ width: "100%" }}
          >
            <PfpContainer>
              <Pfp>
                {invitor?.avatar && (
                  <PfpImg
                    src={
                      artist?.artist_name
                        ? artist?.profile_img ??
                          artist?.banner_img ??
                          StockImage
                        : invitor.avatar ?? AvatarPlaceholder
                    }
                    alt="invitee pfp"
                  />
                )}
                {!invitor?.avatar && (
                  <HeaderXxxl>
                    {artist?.artist_name || invitor?.username
                      ? artist?.artist_name
                        ? artist?.artist_name[0].toUpperCase()
                        : invitor?.username[0].toUpperCase()
                      : ""}
                  </HeaderXxxl>
                )}
              </Pfp>
            </PfpContainer>
            <HeaderL>
              {artist?.artist_name
                ? artist?.artist_name.toUpperCase()
                : invitor?.username.toUpperCase()}
            </HeaderL>
            <BodyText
              style={{
                opacity: "60%",
              }}
            >
              is inviting you to join!
            </BodyText>
          </FlexContainer>
          <FlexContainer
            gap="16px"
            direction="column"
            align="center"
            style={{ width: "100%" }}
          >
            <Button onClick={login}>
              <BodyText color={theme.colors.black}>Accept invite</BodyText>
            </Button>
          </FlexContainer>
        </FlexContainer>

        <OverLayImage
          style={{
            background: `url(${bgURL}) no-repeat`,
            backgroundBlendMode: "multiply",
            backgroundSize: `cover`,
            backgroundPosition: "center",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        />
      </Container>
    </NotFoundSection>
  );
}