import { styled } from "styled-components";
import { ButtonRect, Section } from "../GlobalStyles";

export const ModalContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 100dvh;
  z-index: 1000;
  overflow: hidden;
  padding: 16px 0 32px 0;
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
  flex-direction: column;
  gap: 20px;
`;

export const ActionModalContainer = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin-top: 20px;
`;

export const StyledPrimaryButton = styled(ButtonRect)`
  padding: 14px;
  font-size: 14px;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.greyBtn};
  font-family: ${(props) => props.theme.fontStyles.suisse};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 8px;
  position: relative;
  cursor: pointer;
  border: none;
  min-width: 160px;
`;

export const StyledSecondaryButton = styled.div`
  color: ${(props) => props.theme.colors.grey};
  font-size: 14px;
  font-family: ${(props) => props.theme.fontStyles.suisse};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;
