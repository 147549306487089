import { useQuery } from "@tanstack/react-query";
import { getRoom } from "../../../utils/matrix-utils";
import { eventLoader } from "../loader";

export const useEventLoader = ({ eventId, userId, date }) => {
  return useQuery({
    queryKey: ["event", eventId, userId],
    queryFn: async () => {
      const event = await eventLoader(eventId, userId, date);

      return event;
    },
    staleTime: 1000 * 60 * 3,
  });
};

export const useGetRoom = ({ room = null }) => {
  return useQuery({
    queryKey: ["room", room?.id],
    queryFn: async () => {
      const fetchedRoom = await getRoom(room);

      return fetchedRoom ?? [];
    },
    staleTime: 1000 * 60 * 3,
    enabled: room != null,
  });
};
