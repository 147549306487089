import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getUserArtistMessages,
} from "../../../api/profile";

export const useUserArtistMessages = ({ userId, artistId, isOwnProfile }) => {
  return useQuery({
    queryKey: ["artist_messages", userId.toString(), artistId.toString()],
    queryFn: async () => {
      const messages = await getUserArtistMessages(userId, artistId);
      return messages?.data ?? [];
    },
    enabled: isOwnProfile,
    refetchInterval: 1000 * 60 * 4, // Refetch messages every 1 min,
  });
};