import styled from "styled-components";
import Onchain from "../../../assets/svg/onchain.svg?react";
import RetroIcon from "../../../assets/svg/retro-moment.svg?react";
import { ButtonSm } from "../../../GlobalStyles";

export const Border = styled.div`
  height: ${({ type, size }) =>
    type === "past"
      ? "160px"
      : type === "active" && size === "big"
        ? "176px"
        : "106.5px"};
  min-height: ${({ type, size }) =>
    type === "past"
      ? "160px"
      : type === "active" && size === "big"
        ? "176px"
        : "106.5px"};
  max-height: ${({ type, size }) =>
    type === "past"
      ? "160px"
      : type === "active" && size === "big"
        ? "176px"
        : "106.5px"};
  width: ${({ type, size }) =>
    type == "past"
      ? "100%"
      : type === "active" && size === "big"
        ? "132px"
        : "80px"};
  min-width: ${({ type, size }) =>
    type == "past"
      ? "100%"
      : type === "active" && size === "big"
        ? "132px"
        : "80px"};
  max-width: ${({ type, size }) =>
    type == "past"
      ? "100%"
      : type === "active" && size === "big"
        ? "132px"
        : "80px"};
  padding: 4px;
  background: linear-gradient(
    rgba(180, 180, 180, 0.15),
    rgba(255, 255, 255, 0.15),
    rgba(175, 175, 175, 0.15)
  );
  backdrop-filter: blur(90px);
  -webkit-backdrop-filter: blur(90px);
  border-radius: 8px;
  position: relative;
`;

export const BigBorder = styled(Border)`
  height: 176px;
  min-height: 176px;
  max-height: 176px;
  width: 132px;
  min-width: 132px;
  max-width: 132px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  gap: 12px;
`;

export const OnchainLogo = styled(Onchain)`
  position: absolute;
  bottom: 7px;
  right: 7px;
  z-index: 1;
  height: 20px;
  width: 20px;
  stroke: white;
`;

export const RetroLogo = styled(RetroIcon)`
  position: absolute;
  bottom: 7px;
  right: 7px;
  z-index: 1;
  height: 18px;
  width: 18px;
  stroke: white;
  stroke-width: 1.5;
`;

export const MomentImage = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 4px;
`;

export const MomentVideo = styled.video`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 4px;
`;

export const UploadButton = styled(ButtonSm)`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
`;
