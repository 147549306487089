import { createContext, useState } from "react";
import { CheckInDispatchEvents } from "../constants/dispatchEvents";

export const CheckInContext = createContext({});

const initialState = {
  currentEvent: null,
  currentCheckIn: null,
  successfullyCheckedIn: false,
  successfullyClaimedMoment: false,
  successfullyEarnedXp: false,
  momentsCollected: [],
  earnedXp: null,
  AiImage: null,
  isEligible: false,
  isLoading: false,
  isCheckedIn: false,
  hasMomentExisted: false,
  momentNeedsLogin: false,
  selectingMoments: false,
  performances: [],
  selectedPerformances: [],
  isNearby: false,
  isTooFar: false,
  error: null,
};

/**
 * CheckInContextProvider is a function that provides a context for checking in to an event. It takes a params object as a parameter and returns a CheckInContextProvider component.
 *
 * @param {object} params - an object containing the children component
 * @return {React.JSX.Element} a CheckInContextProvider component
 */
const CheckInContextProvider = (params) => {
  const { children } = params;
  const [context, setContext] = useState(initialState);

  function dispatch(actionType, payload) {
    switch (actionType) {
      case CheckInDispatchEvents.SET_ELIGIBLE:
        {
          setContext((prev) => ({
            ...prev,
            isEligible: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_NEARBY:
        {
          setContext((prev) => ({
            ...prev,
            isNearby: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_CURRENT_EVENT:
        {
          setContext((prev) => ({
            ...prev,
            currentEvent: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_CURRENT_CHECK_IN:
        {
          setContext((prev) => ({
            ...prev,
            currentCheckIn: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_LOADING:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: payload,
            isCheckedIn: false,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_AI_IMAGE:
        {
          setContext((prev) => ({
            ...prev,
            AiImage: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_SELECTING_MOMENTS:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            selectingMoments: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_ERRORS:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            isCheckedIn: false,
            error: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_INELIGIBILITY:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            isCheckedIn: false,
            error: "You are not eligible to check in to this event.",
          }));
        }
        break;
      case CheckInDispatchEvents.SET_IS_TOO_FAR:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            isCheckedIn: false,
            isTooFar: true,
            error:
              "It looks like you’re no longer at the event. Go near the venue to check in.",
          }));
        }
        break;
      case CheckInDispatchEvents.SET_CHECK_IN_ALREADY:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            hasMomentExisted: false,
            momentsCollected: [],
            isCheckedIn: true,
            successfullyCheckedIn: false,
            error: "You have already checked in to this event",
          }));
        }
        break;
      case CheckInDispatchEvents.SUCCESSFULLY_CHECKED_IN:
        {
          setContext((prev) => ({
            ...prev,
            successfullyCheckedIn: true,
            isLoading: false,
            hasMomentExisted: false,
            momentsCollected: [],
          }));
        }
        break;
      case CheckInDispatchEvents.SET_HAS_MOMENT_EXISTED:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            hasMomentExisted: true,
            momentsCollected: payload,
            isCheckedIn: true,
            error:
              "You have already checked in to this event and claimed the moment.",
          }));
        }
        break;
      case CheckInDispatchEvents.SET_MOMENT_NEEDS_LOGIN:
        {
          setContext((prev) => ({
            ...prev,
            isLoading: false,
            momentsCollected: [],
            momentNeedsLogin: true,
            isCheckedIn: true,
            error: "You need to login to claim the moment.",
          }));
        }
        break;
      case CheckInDispatchEvents.SET_PERFORMANCES:
        {
          setContext((prev) => ({
            ...prev,
            performances: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_SELECTED_PERFORMANCES:
        {
          setContext((prev) => ({
            ...prev,
            selectedPerformances: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_MOMENTS_COLLECTED:
        {
          setContext((prev) => ({
            ...prev,
            momentsCollected: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_SUCCESSFULLY_CLAIMED_MOMENT:
        {
          setContext((prev) => ({
            ...prev,
            successfullyClaimedMoment: true,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_SUCCESSFULLY_EARNED_XP:
        {
          setContext((prev) => ({
            ...prev,
            successfullyEarnedXp: true,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_EARNED_XP:
        {
          setContext((prev) => ({
            ...prev,
            earnedXp: payload,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_RESET_EXCEPT_CURRENT_EVENT:
        {
          setContext((prev) => ({
            ...prev,
            successfullyCheckedIn: false,
            momentsCollected: null,
            isEligible: false,
            isLoading: false,
            isCheckedIn: false,
            hasMomentExisted: false,
            isNearby: false,
            isTooFar: false,
            error: null,
          }));
        }
        break;
      case CheckInDispatchEvents.SET_RESET:
        {
          setContext(initialState);
        }
        break;
    }
  }

  return (
    <CheckInContext.Provider value={{ context, dispatch }}>
      {children}
    </CheckInContext.Provider>
  );
};

export default CheckInContextProvider;
