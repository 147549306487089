import PropTypes from "prop-types";
import EphemeralTips from "../../Tips/EphemeralTips";
import BookmarkIcon from "../../../assets/svg/bookmark.svg?react";
import MapIcon from "../../../assets/svg/map.svg?react";
import BulbIcon from "../../../assets/svg/bulb.svg?react";
import VolumeHighIcon from "../../../assets/svg/volume-high.svg?react";
import VolumeMutedIcon from "../../../assets/svg/volume-muted.svg?react";
import {
  BodyText,
  Button,
  FooterText,
  IconWithText,
  OneLineText,
} from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  EPHEMERAL_TIPS,
  MOMENT_TYPES,
  UsernameTypes,
} from "../../../constants/constants";
import {
  ButtonsContainer,
  EventDetailsContainer,
  MomentInfoContainer,
  MomentInfoContent,
  MomentInfoText,
  UserAndButtonContainer,
} from "./MomentInfo-Styles";
import UserPill from "../../UserPill";
import ArtistIconWithName from "../../Artist/ArtistIconWithName";
import { Link } from "react-router-dom";
import _moment from "moment";
import LikeButton from "../../LikeButton";
import useAuth from "../../../hooks/useAuth";
import { getUsernameByType } from "../../../utils/username";
import CollectButton from "../CollectButton";
import ShareButton from "../../ShareButton";

const { colors } = theme;
export default function MomentInfo({
  moment,
  isMomentsFeed,
  isLikesFetched,
  isMuted,
  handleMute,
}) {
  const {
    user_id,
    moment_id,
    event_date,
    avatar,
    username,
    city,
    headline_artist,
    event_name,
    headline_artist_id,
    performance_id,
    performance_artist,
    performance_artist_id,
    combined_venue_id,
    venue_name,
    liked_by_user,
    total_likes,
    type,
    fan_photo_urls,
  } = moment || {};
  const { loggedInUser } = useAuth();
  const profileName = getUsernameByType(moment, UsernameTypes.USERNAME);
  const isOwner = loggedInUser?.id === user_id;
  const isVideo = fan_photo_urls?.some((url) => url.includes("mp4"));

  return (
    <MomentInfoContainer style={{ flexDirection: "column" }}>
      {type !== "retro" && (
        <EphemeralTips
          icon={<BulbIcon height={12} stroke={colors.white} />}
          message={
            <FooterText>
              Fans earn <FooterText color={colors.green}>XP</FooterText> when
              you like their photos.
            </FooterText>
          }
          type={EPHEMERAL_TIPS.MOMENT_TIP}
        />
      )}
      <MomentInfoContent>
        <MomentInfoText>
          <UserAndButtonContainer>
            <UserPill user={{ id: user_id, username, avatar }} />
          </UserAndButtonContainer>
          <EventDetailsContainer>
            <ArtistIconWithName
              artist={{
                id: performance_artist_id ?? headline_artist_id,
                name: performance_artist ?? headline_artist,
              }}
            />
            <IconWithText
              as={combined_venue_id && Link}
              to={`/venues/${combined_venue_id}/${venue_name}`}
            >
              <MapIcon fill={colors.white} height={18} width={18} />
              <OneLineText>
                {venue_name}
                {city && `, ${city}`}
              </OneLineText>
            </IconWithText>
            <IconWithText
              as={performance_id ? Link : "div"}
              to={`/performances/${performance_id}?date=${_moment(event_date).utc().format("YYYY-MM-DD")}`}
            >
              {performance_id && (
                <BookmarkIcon
                  stroke={colors.white}
                  fill={colors.white}
                  height={18}
                  width={18}
                />
              )}
              <BodyText color={colors.softGreen} weight="600">
                {_moment.utc(event_date).format("ddd D MMM")}
              </BodyText>
            </IconWithText>
          </EventDetailsContainer>
        </MomentInfoText>
        <ButtonsContainer
          style={{
            alignItems: "flex-end",
          }}
        >
          {type !== MOMENT_TYPES.RETRO && (
            <>
              <ButtonsContainer>
                <LikeButton
                  moment={{
                    creator_id: user_id,
                    moment_id,
                    artist_id: performance_artist_id,
                    user_id: loggedInUser?.id,
                  }}
                  likeCount={total_likes}
                  isCurrentUserLiked={liked_by_user}
                  isFetched={isLikesFetched}
                />
                {isOwner && !isMomentsFeed && (
                  <CollectButton moment={moment} type="small" />
                )}
              </ButtonsContainer>
            </>
          )}
          <ShareButton
            url={`/profile/${user_id}/moments/${moment_id}`}
            title={event_name ?? headline_artist ?? performance_artist}
            text={`Check out this Moment - ${profileName} @ ${
              headline_artist ?? performance_artist
            } - ${city}`}
          />
          {isVideo && (
            <Button onClick={handleMute} style={{ border: "none" }}>
              {!isMuted ? (
                <VolumeHighIcon height={16} width={16} />
              ) : (
                <VolumeMutedIcon height={16} width={16} />
              )}
            </Button>
          )}
        </ButtonsContainer>
      </MomentInfoContent>
    </MomentInfoContainer>
  );
}

MomentInfo.propTypes = {
  moment: PropTypes.object,
  isMomentsFeed: PropTypes.bool,
  isLikesFetched: PropTypes.bool,
  handleSetlist: PropTypes.func,
  handleIsCard: PropTypes.func,
  isMuted: PropTypes.bool,
  handleMute: PropTypes.func,
};
