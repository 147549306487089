import PropTypes from "prop-types";
import TicketsIcon from "../../../assets/svg/two-tickets.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import EmptyState from "../../EmptyState";
import { theme } from "../../../utils/theme";
import { Container, UserTicketsContainer } from "./UserTickets-Styles";
import { BodyText, FooterText } from "../../../GlobalStyles";

export default function UserTickets({ tickets, isTicketsLoading }) {
  // Whether or not the user has any events to render.
  const noTickets = !tickets || tickets.length == 0 || tickets[0] == null;

  if (isTicketsLoading) {
    return (
      <Container>
        <BodyText weight="600">Your tickets</BodyText>
        <UserTicketsContainer>
          {Array.from({ length: 12 }).map((_, index) => (
            <SkeletonElement
              key={index}
              width="100%"
              height="125px"
              radius="10px"
            />
          ))}
        </UserTicketsContainer>
      </Container>
    );
  }

  // Render the empty tab if the user doesn't have any events.
  if (noTickets) {
    return (
      <EmptyState
        icon={
          <TicketsIcon height={24} width={24} stroke="white" opacity={0.6} />
        }
        title="Coming Soon"
        description="Buy tickets direct from artists and venues."
      />
    );
  }

  // Render the list of events if the user has events.
  return (
    <Container>
      <BodyText weight="600">
        Your tickets
        <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
          ({tickets?.length})
        </FooterText>
      </BodyText>
      <UserTicketsContainer>{tickets.map(() => null)}</UserTicketsContainer>
    </Container>
  );
}

UserTickets.propTypes = {
  user: PropTypes.object,
  tickets: PropTypes.array,
  isTicketsLoading: PropTypes.bool,
};
