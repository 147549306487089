import PropTypes from "prop-types";
import { BodyText, Header } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { EmptyStateContainer } from "./EmptyState-Styles";

const { colors } = theme;

export default function EmptyState({
  icon,
  title,
  description,
  button,
  style,
}) {
  return (
    <EmptyStateContainer style={style}>
      {icon}
      <Header style={{ textTransform: "uppercase" }}>{title}</Header>
      <BodyText color={colors.grey}>{description}</BodyText>
      {button}
    </EmptyStateContainer>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.any,
  button: PropTypes.any,
  style: PropTypes.object,
};
