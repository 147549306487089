import { SEARCH_TABS } from "../../../constants/search";
import PropTypes from "prop-types";
import Bookmark from "../../../assets/svg/bookmark.svg?react";
import Mic from "../../../assets/svg/mic.svg?react";
import Map from "../../../assets/svg/map.svg?react";
import Profile from "../../../assets/svg/profile.svg?react";

const { EVENT, ARTIST, VENUE, FAN } = SEARCH_TABS;

export default function EmptyStateIcon({ activeTab }) {
  switch (activeTab) {
    case EVENT:
      return <Bookmark stroke="white" height={26} width={26} />;
    case ARTIST:
      return <Mic stroke="white" height={26} width={26} />;
    case VENUE:
      return <Map fill="white" height={26} width={26} />;
    case FAN:
      return <Profile stroke="white" height={26} width={26} />;
    default:
      return <Bookmark stroke="white" height={26} width={26} />;
  }
}

EmptyStateIcon.propTypes = {
  activeTab: PropTypes.string,
};
