import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { SEARCH_TABS } from "../../../constants/search";
import { useSearchParamsHook } from "../../../routes/Search/hooks/searchParams";
import { SearchSkeleton } from "../../../routes/Search/Search-Styles";
import DatePicker from "../DatePicker";
import LocationPicker from "../LocationPicker";
import SearchFilters from "../SearchFilters";
import SearchResults from "../SearchResults";
import SearchTabs from "../SearchTabs";
import { useDatePickerStore } from "./datePickerStore";
import {
  DatePickerContainer,
  Overlay,
  ResultsContainer,
  TabsAndFiltersContainer,
} from "./SubmitMode-Styles";

const { EVENT, VENUE } = SEARCH_TABS;

export default function SubmitMode({
  data,
  loading,
  fetchNextPage,
  hasNextPage,
}) {
  const [searchParams] = useSearchParams();
  const {
    tab: activeTab,
    isLocationPickerOpen,
    isRetro,
  } = useSearchParamsHook({ searchParams });
  const {
    isMonthPickerOpen,
    isYearPickerOpen,
    getIsDatePickerOpen,
    toggleDatePicker,
  } = useDatePickerStore();

  if (isLocationPickerOpen) return <LocationPicker />;

  return (
    <>
      <TabsAndFiltersContainer>
        {!isRetro && <SearchTabs />}
        {(activeTab === EVENT || activeTab === VENUE) && <SearchFilters />}
      </TabsAndFiltersContainer>
      <ResultsContainer blurred={getIsDatePickerOpen()}>
        {/* Loading */}
        {loading && (
          <div>
            <SearchSkeleton />
          </div>
        )}
        {!loading && (
          <SearchResults
            data={data}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        )}
        {getIsDatePickerOpen() && <Overlay onClick={toggleDatePicker} />}
      </ResultsContainer>
      {(isYearPickerOpen || isMonthPickerOpen) && (
        <DatePickerContainer>
          <DatePicker />
        </DatePickerContainer>
      )}
    </>
  );
}

SubmitMode.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
};
