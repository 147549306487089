import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  background-color: ${({ theme }) => theme.colors.background};
  background-blend-mode: multiply;
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 20px 24px 16px;
  flex: 1;
  position: relative;
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  height: 63svh;
  padding: 8px;
  position: relative;
  overflow: hidden;
`;

export const Video = styled.video`
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 16px;
`;
