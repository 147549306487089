import styled from "styled-components";
import { NavBarTypes, PROFILE_BADGE_TYPES } from "../../constants/constants";
import { BodyText, Section, Title } from "../../GlobalStyles";
import BadgeItem from "../../components/Badges/BadgeItem";
import { useParams } from "react-router-dom";
import { theme } from "../../utils/theme";
import { useQuery } from "@tanstack/react-query";
import badgeLoader from "./loader";
import LoadingScreen from "../../components/LoadingScreen";
import TopNav from "../../components/TopNav";
import ErrorPage from "../Error";

const { colors } = theme;

const BadgesSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  align-items: ${({ hasBadges }) => !hasBadges && "center"};
  justify-content: ${({ hasBadges }) => !hasBadges && "center"};
  flex: ${({ hasBadges }) => !hasBadges && "1"};
`;

/**
 * Function to render the user badges section based on the attained and next attainable badges.
 *
 * @return {React.JSX.Element} The user badges section component
 */
export default function UserBadges() {
  const { id } = useParams();
  // Get the badges data from the loader
  const {
    data: badges,
    error,
    isPending,
  } = useQuery({
    queryKey: ["badges", id],
    queryFn: async () => {
      const data = await badgeLoader({ params: { id } });
      return data;
    },
  });
  // Extract the attained badges and next attainable badges
  const attainedBadges = badges?.attained;
  const nextAttainables = badges?.nextAttainables;

  // Check if the user has attained badges or has next attainable badges
  const hasBadges = attainedBadges?.length > 0;
  const hasNextAttainables = nextAttainables?.length > 0;

  if (isPending) return <LoadingScreen />;

  if (error) return <ErrorPage />;

  // Render the user badges section
  return (
    <BadgesSection>
      {/* Render the navigation for badges */}
      <TopNav type={NavBarTypes.BADGES} />
      {/* Render the badges container based on the existence of attained or next attainable badges */}
      <BadgesContainer hasBadges={hasBadges || hasNextAttainables}>
        {/* Render the attained badges if they exist */}
        {hasBadges && (
          <>
            <Title>Claimed</Title>
            {attainedBadges?.map((badge) => (
              <BadgeItem
                key={badge.id}
                badge={badge}
                type={PROFILE_BADGE_TYPES.ATTAINED}
              />
            ))}
          </>
        )}
        {/* Render the next attainable badges if they exist */}
        {hasNextAttainables && (
          <>
            <BodyText weight="600" color={colors.grey}>
              {"Next up"}
            </BodyText>
            {nextAttainables?.map((badge) => (
              <BadgeItem
                key={badge.id}
                badge={badge}
                type={PROFILE_BADGE_TYPES.NEXT}
              />
            ))}
          </>
        )}
      </BadgesContainer>
    </BadgesSection>
  );
}
