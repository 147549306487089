import { useCallback, useEffect, useRef, useState } from "react";

const useCheckInModalToggle = (initialValue = false) => {
  const [isOpen, setOpen] = useState(initialValue);
  const ref = useRef < HTMLDivElement > null;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutside = (event) => {
    if (ref.current === event.target) {
      setOpen(false);
    }
  };

  const handleToggle = useCallback(() => {
    setOpen((previousValue) => !previousValue);
  }, []);

  return { ref, isOpen, handleToggle };
};

export default useCheckInModalToggle;
