import styled from "styled-components";
import {
  BodyText,
  Button,
  ButtonRect,
  FooterText,
  FooterTextSm,
} from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import moment from "moment";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import rehypeSanitize from "rehype-sanitize";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { MomentifyLogo2 } from "../../constants/constants";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px;
`;

const BadgeImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  align-self: start;
`;

const BadgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // align-items: center;
  // justify-content: space-between;
  gap: 8px;
  position: relative;
`;

const BadgeNameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const BadgeNameAndDatetime = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const BadgeDate = styled.div`
  display: flex;
  align-items: center;
  min-width: 75px;
  // gap: 5px;
  // text-align: right;
`;

const DotsButton = styled(Button)`
  border: none;
  background: transparent;
  padding: 0 3px 3px 3px;
  position: absolute;
  top: 0;
  right: 0;
`;

const ActionButton = styled(Button)`
  // padding: 0 3px 3px 3px;
  background: ${theme.colors.black};
  padding: 7px 15px;
  align-self: flex-start;
`;

const MessageSpan = styled(BodyText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
`;

const MessageSpanBold = styled(BodyText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  font-weight: 600;
`;

const SenderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const SenderContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MessageImgContainer = styled.img`
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 440px;
  // min-height: 300px;
  max-height: 300px;
`;

export default function MessageViewItem({
  message: _message,
  sender_name,
  img,
  isLoading,
}) {
  const {
    message,
    message_img,
    message_link,
    message_link_cta,
    created_at,
    id,
    title,
  } = _message;

  return (
    <Container>
      <SenderContainer>
        {
          isLoading
            ? (
              <>
                <SkeletonElement
                  width="40px"
                  height="40px"
                  radius="50%"
                  style={{ minWidth: "40px", minHeight: "40px" }} />
                <SenderContainerInfo>
                  <MessageSpanBold>
                    <SkeletonElement
                      width="100%"
                      height="16px"
                      // radius="50%"
                      style={{ minWidth: "120px", minHeight: "16px" }} />
                  </MessageSpanBold>
                  <BadgeDate>
                    <SkeletonElement
                      width="100%"
                      height="10px"
                      // radius="50%"
                      style={{ minWidth: "80px", minHeight: "10px" }} />
                  </BadgeDate>
                </SenderContainerInfo>
              </>
            )
            : (
              <>
                <BadgeImage src={sender_name == 'Momentify' ? MomentifyLogo2 : img} />
                <SenderContainerInfo>
                  <MessageSpanBold>{sender_name}</MessageSpanBold>
                  <BadgeDate>
                    <FooterTextSm color={colors.grey}>
                      {moment(created_at).format("ddd DD MMM")}
                      <span
                        style={{
                          padding: "0 4px",
                        }}
                      >
                        •
                      </span>
                      {moment(created_at).format("h:mm A")}
                    </FooterTextSm>
                  </BadgeDate>
                </SenderContainerInfo>
              </>
            )

        }
      </SenderContainer>

      <BadgeInfo>
        {
          isLoading
            ? (
              <BadgeNameAndDescription>
                <BodyText>
                  <SkeletonElement
                    width="100%"
                    height="16px"
                    // radius="50%"
                    style={{ minWidth: "120px", minHeight: "16px" }} />
                </BodyText>
              </BadgeNameAndDescription>
            )
            : (
              <BadgeNameAndDescription>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: "10px"
                }}>
                  <BodyText>{title}</BodyText>
                  <MarkdownPreview className="react-md" source={message} rehypePlugins={[rehypeSanitize]} styles={{ whiteSpace: 'pre-wrap' }} />
                </div>
                {message_img && <MessageImgContainer src={message_img} />}
                {message_link && message_link_cta && (
                  <ButtonRect
                    background={theme.colors.green}
                    theme={theme}
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    <a target="_blank" href={message_link}>
                      <BodyText color={theme.colors.black}>
                        {message_link_cta}
                      </BodyText>
                    </a>
                  </ButtonRect>
                )}
              </BadgeNameAndDescription>
            )
        }
      </BadgeInfo>
    </Container>
  );
}
