import PropTypes from "prop-types";
import { useRef, useState } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import { MOMENT_TYPES } from "../../constants/constants";

const CarouselContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  filter: ${({ isSetlistOpen }) => (isSetlistOpen ? "blur(15px)" : "none")};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavCirclesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  position: absolute;
  bottom: 160px;
  left: 0;
  z-index: 2;
`;

const NavCircle = styled.div`
  height: 12.5px;
  width: 12.5px;
  background: ${({ filled }) => (filled ? "white" : "transparent")};
  border-radius: 50%;
  border: 2px solid white;
  opacity: 0.7;
`;

export default function MomentCarousel({
  children,
  moment,
  isCard,
  isSetlistOpen,
  isMomentsFeed,
  isOwner,
}) {
  const { type } = moment || {};
  const [currentItem, setCurrentItem] = useState(0);
  const carouselRef = useRef(null);
  const remainingTime = new Date(moment?.created_at);
  remainingTime.setDate(remainingTime.getDate() + 2);

  // Handle scrolling or interaction event
  const handleScroll = () => {
    const container = carouselRef.current;
    const scrollLeft = container.scrollLeft;
    // Calculate the current item based on scroll position
    const itemWidth = container.offsetWidth;

    const newItem = Math.round(scrollLeft / itemWidth);

    if (newItem !== currentItem) {
      setCurrentItem(newItem);
    }
  };

  if (isMomentsFeed)
    return (
      <CarouselContainer
        ref={carouselRef}
        onScroll={handleScroll}
        id="moment-carousel"
        isSetlistOpen={isSetlistOpen}
      >
        {children}
      </CarouselContainer>
    );

  return (
    <>
      <Countdown
        date={remainingTime}
        renderer={({ completed }) =>
          !isCard &&
          (moment?.fan_photo_urls?.length > 0 || (!completed && isOwner)) &&
          !isSetlistOpen && (
            <NavCirclesContainer>
              {Array.from({ length: type === MOMENT_TYPES.RETRO ? 1 : 2 }).map(
                (_, index) =>
                  type !== MOMENT_TYPES.RETRO && (
                    <NavCircle key={index} filled={index === currentItem} />
                  )
              )}
            </NavCirclesContainer>
          )
        }
      />
      <CarouselContainer
        ref={carouselRef}
        onScroll={handleScroll}
        id="moment-carousel"
        isSetlistOpen={isSetlistOpen}
      >
        {children}
      </CarouselContainer>
    </>
  );
}

MomentCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  moment: PropTypes.object,
  isCard: PropTypes.bool,
  isSetlistOpen: PropTypes.bool,
  isMomentsFeed: PropTypes.bool,
  isOwner: PropTypes.bool,
};
