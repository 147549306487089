import { useContext } from "react";
import { NotificationDispatchEvents } from "../constants/dispatchEvents";
import useAuth from "./useAuth";
import { toast } from "react-toastify";
import { NotificationContext } from "../contexts/NotificationContext";
import { attachUserNotifiableToken, subscribeToTopic } from "../api/profile";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import {
  NotificationPermission,
  NotificationPermissionState,
  PushPermission,
  PushPermissionState,
} from "../enums/permission";
import DeniedNotification from "../components/Modal/DeniedNotification";
import DeniedPush from "../components/Modal/DeniedPush";
import useGlobalModal from "./useGlobalModal";

export default function useNotification() {
  const { loggedInUser } = useAuth();
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  const { context, dispatch } = useContext(NotificationContext);
  const {
    notifications,
    new_notification,
    notifiable_token,

    isNotificationPermitted,
    notificationPermissionState,
    isPushPermitted,
    pushPermissionState,
    isLoading,
    error,
  } = context;

  const _notificationPermissionState = notificationPermissionState;

  const firebaseConfig = {
    apiKey: import.meta.env.VITE_FCM_API_KEY,
    authDomain: `${import.meta.env.VITE_FCM_PROJECT_ID}.firebaseapp.com`,
    projectId: import.meta.env.VITE_FCM_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FCM_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FCM_SENDER_ID,
    appId: import.meta.env.VITE_FCM_APP_ID,
  };
  const FirebaseApp = initializeApp(firebaseConfig);
  const FirebaseMessaging = getMessaging(FirebaseApp);

  const requestNotifPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.info("Notification permission granted.");
    } else if (permission === "denied") {
      console.warn("Permission denied.");
    }

    return permission;
  };

  const generateToken = async (_FirebaseMessaging) => {
    const token = await getToken(_FirebaseMessaging, {
      vapidKey:
        "BJhF_7dNqQQ-jt4fZNRgKMP4pLb0G5aiYIR-4GQS_m3HhnWbgYhiWqJuJSkgj_I-u_YuOQYciG7v3u7pGa8iimY",
    });
    if (token) {
      await setNotifiableToken(token);
    }
    return token;
  };

  const subscribeToDefault = async (_FirebaseMessaging) => {
    const token = await generateToken(_FirebaseMessaging);

    if (token && loggedInUser?.id) {
      await subscribeToTopic(loggedInUser?.id, "system", token).catch(
        console.error
      );
      // await subscribeToTopic(loggedInUser?.id, 'artist_message', token).catch(console.error)
    }
  };

  const askNotificationPermission = async () => {
    dispatch(NotificationDispatchEvents.TOGGLE_LOADING);

    if ("Notification" in window == false) {
      console.warn("Notification is not supported by this browser.");
      dispatch(NotificationDispatchEvents.NOTIFICATION_NOT_SUPPORTED);
      dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      return;
    }

    const permission = await requestNotifPermission();
    if (
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      if (permission == NotificationPermissionState.GRANTED) {
        console.info("Notification permission is granted.");

        dispatch(NotificationDispatchEvents.NOTIFICATION_HAS_PERMISSION);
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.GRANTED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
        dispatch(
          NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
          NotificationPermissionState.GRANTED
        );

        const _FirebaseMessaging = getMessaging(FirebaseApp);
        await subscribeToDefault(_FirebaseMessaging);

        onMessage(_FirebaseMessaging, (payload) => {
          setNewNotification(payload.notification.title, {});
        });
      } else if (permission == NotificationPermissionState.DENIED) {
        console.warn("Notification permission is denied.");

        dispatch(NotificationDispatchEvents.NOTIFICATION_DENIED);
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.DENIED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else if (permission == NotificationPermissionState.DEFAULT) {
        console.warn("Default notification");
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.PROMPT
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else if (permission == NotificationPermissionState.PROMPT) {
        console.warn("Prompt notification");
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.PROMPT
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else {
        console.warn("Notification is not supported by this browser.");
        dispatch(NotificationDispatchEvents.NOTIFICATION_NOT_SUPPORTED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
        // errorHandler(permission, NotificationPermission)
      }
    }
  };

  const handleNotifPermission = async () => {
    // const permission = await requestNotifPermission()
    if (
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      // let permission = localStorage.getItem(NotificationPermission);
      if (navigator.permissions && navigator.permissions.query) {
        const result = await navigator.permissions.query({
          name: "notifications",
        });

        if (result == "granted") {
          console.info("Notification permission already granted.");
        } else if (result == "denied") {
          console.warn("Notification permission is denied.");
          dispatch(NotificationPermission, NotificationPermissionState.DENIED);
          dispatch(NotificationDispatchEvents.NOTIFICATION_DENIED);
          localStorage.setItem(
            NotificationPermission,
            NotificationPermissionState.DENIED
          );
          dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
        } else if (result == "prompt") {
          console.warn("prompt notification");
          dispatch(NotificationPermission, NotificationPermissionState.PROMPT);
          localStorage.setItem(
            NotificationPermission,
            NotificationPermissionState.PROMPT
          );
          dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
        }
        return;
      }

      console.warn("Notification is not supported by this browser.");
      dispatch(NotificationDispatchEvents.NOTIFICATION_NOT_SUPPORTED);
      dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      // errorHandler(permission, NotificationPermission)
    }
  };

  const getBrowserNotifState = async () => {
    if (navigator.permissions && navigator.permissions.query) {
      const result = await navigator.permissions.query({
        name: "notifications",
      });
      localStorage.setItem(NotificationPermission, result.state);
      dispatch(
        NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
        result.state
      );
      return result.state;
    }

    return null;
  };

  const handleNotificationPermission = async () => {
    //  if ('serviceWorker' in navigator) {

    dispatch(NotificationDispatchEvents.TOGGLE_LOADING);

    let permission = localStorage.getItem(NotificationPermission);

    if (navigator.permissions && navigator.permissions.query) {
      dispatch(NotificationDispatchEvents.NOTIFICATION_HAS_PERMISSION);

      const result = await navigator.permissions.query({
        name: "notifications",
      });

      if ("Notification" in window == false) {
        console.warn("Notification is not supported by this browser.");
        dispatch(NotificationDispatchEvents.NOTIFICATION_NOT_SUPPORTED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
        return;
      }

      // If the permission is granted, set the state to granted
      if (
        result.state == NotificationPermissionState.GRANTED ||
        permission == NotificationPermissionState.GRANTED
      ) {
        await askNotificationPermission();
        dispatch(
          NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
          NotificationPermissionState.GRANTED
        );
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.GRANTED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is prompt, set the state to prompt
      else if (
        (result.state == "DEFAULT" &&
          permission == NotificationPermissionState.PROMPT) ||
        !permission
      ) {
        dispatch(
          NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
          NotificationPermissionState.PROMPT
        );
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.PROMPT
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is denied, set the state to denied
      else if (
        result.state == NotificationPermissionState.DENIED ||
        permission == NotificationPermissionState.DENIED
      ) {
        dispatch(NotificationDispatchEvents.DENIED);
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.DENIED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is denied, set the state to denied
      else if (permission == NotificationPermissionState.SKIPPED) {
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.SKIPPED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
    } else if (navigator.geolocation) {
      dispatch(NotificationDispatchEvents.NOTIFICATION_HAS_PERMISSION);

      if (permission == NotificationPermissionState.GRANTED) {
        await askNotificationPermission();
        dispatch(
          NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
          NotificationPermissionState.GRANTED
        );
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.GRANTED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else if (
        permission == NotificationPermissionState.PROMPT ||
        permission == NotificationPermissionState.DENIED ||
        !permission
      ) {
        dispatch(
          NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
          NotificationPermissionState.PROMPT
        );
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.PROMPT
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else if (permission == NotificationPermissionState.SKIPPED) {
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.SKIPPED
        );
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
    }
  };
  const handlPushPermission = async () => {
    dispatch(NotificationDispatchEvents.TOGGLE_LOADING);

    let pushPermission = localStorage.getItem(PushPermission);

    if (navigator.permissions && navigator.permissions.query) {
      dispatch(NotificationDispatchEvents.PUSH_HAS_PERMISSION);

      const result = await navigator.permissions.query({
        name: "push",
      });

      if (!navigator.geolocation) {
        console.warn("Geolocation is not supported by this browser.");
        dispatch(NotificationDispatchEvents.PUSH_NOT_SUPPORTED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
        return;
      }

      // If the permission is granted, set the state to granted
      if (
        result.state == PushPermissionState.GRANTED ||
        pushPermission == PushPermissionState.GRANTED
      ) {
        // await getCurrentPosition();
        dispatch(
          NotificationDispatchEvents.SET_PUSH_PERMISSION_STATE,
          PushPermissionState.GRANTED
        );
        localStorage.setItem(PushPermission, PushPermissionState.GRANTED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is prompt, set the state to prompt
      else if (
        (result.state == PushPermissionState.PROMPT &&
          pushPermission == PushPermissionState.PROMPT) ||
        !pushPermission
      ) {
        dispatch(
          NotificationDispatchEvents.SET_PUSH_PERMISSION_STATE,
          PushPermissionState.PROMPT
        );
        localStorage.setItem(PushPermission, PushPermissionState.PROMPT);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is denied, set the state to denied
      else if (
        result.state == PushPermissionState.DENIED ||
        pushPermission == PushPermissionState.DENIED
      ) {
        dispatch(NotificationDispatchEvents.DENIED);
        localStorage.setItem(PushPermission, PushPermissionState.DENIED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is denied, set the state to denied
      else if (pushPermission == PushPermissionState.SKIPPED) {
        dispatch(NotificationDispatchEvents.SKIP_PUSH);
        localStorage.setItem(PushPermission, PushPermissionState.SKIPPED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
    } else if (navigator.geolocation) {
      dispatch(NotificationDispatchEvents.PUSH_HAS_PERMISSION);

      if (pushPermission == PushPermissionState.GRANTED) {
        // await getCurrentPosition();
        dispatch(
          NotificationDispatchEvents.SET_PUSH_PERMISSION_STATE,
          PushPermissionState.GRANTED
        );
        localStorage.setItem(PushPermission, PushPermissionState.GRANTED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else if (
        pushPermission == PushPermissionState.PROMPT ||
        pushPermission == PushPermissionState.DENIED ||
        !pushPermission
      ) {
        dispatch(
          NotificationDispatchEvents.SET_PUSH_PERMISSION_STATE,
          PushPermissionState.PROMPT
        );
        localStorage.setItem(PushPermission, PushPermissionState.PROMPT);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      } else if (pushPermission == PushPermissionState.SKIPPED) {
        dispatch(NotificationDispatchEvents.SKIP_PUSH);
        localStorage.setItem(PushPermission, PushPermissionState.SKIPPED);
        dispatch(NotificationDispatchEvents.TOGGLE_LOADING);
      }
    }
  };
  const errorHandler = (err, type) => {
    console.error(`ERROR(${err.code}): ${err.message}`);

    dispatch(NotificationDispatchEvents.SET_ERROR_MESSAGE, err.message);

    // If the permission is denied, set the state to denied
    if (err.code == 1) {
      if (type == NotificationPermission) {
        localStorage.setItem(
          NotificationPermission,
          NotificationPermissionState.DENIED
        );
        dispatch(NotificationDispatchEvents.NOTIFICATION_DENIED);
        setSheetModalContent(<DeniedNotification />);
      } else if (type == PushPermission) {
        localStorage.setItem(PushPermission, PushPermissionState.DENIED);
        dispatch(NotificationDispatchEvents.PUSH_DENIED);
        setSheetModalContent(<DeniedPush />);
      }
      toggleSheetModal();
    }
    // If the position is unavailable, set the error to unavailable
    else if (err.code == 2) {
      dispatch(
        NotificationDispatchEvents.SET_ERROR_MESSAGE,
        "Position is unavailable"
      );
    }
    // If the timeout expired, set the error to timeout
    else if (err.code == 3) {
      dispatch(NotificationDispatchEvents.SET_ERROR_MESSAGE, "Timeout expired");
    }
  };
  const skipNotification = () => {
    dispatch(
      NotificationDispatchEvents.SET_NOTIFICATION_PERMISSION_STATE,
      NotificationPermissionState.SKIPPED
    );
    localStorage.setItem(
      NotificationPermission,
      NotificationPermissionState.SKIPPED
    );
  };
  const skipPush = () => {
    dispatch(
      NotificationDispatchEvents.SET_PUSH_PERMISSION_STATE,
      PushPermissionState.SKIPPED
    );
    localStorage.setItem(PushPermission, PushPermissionState.SKIPPED);
  };

  const setNewNotification = async (title, body) => {
    if (!title || !body) return;

    toast.info(title, {
      toastId: "setNewNotification",
    });
    dispatch(NotificationDispatchEvents.SET_NEW_NOTIFICATION, {
      title,
      body,
    });
  };

  const setNotifications = async (notifications) => {
    if (!notifications) return;

    dispatch(NotificationDispatchEvents.SET_NOTIFICATIONS, notifications);
  };

  const setNotifiableToken = async (token) => {
    if (!token) return;

    dispatch(NotificationDispatchEvents.SET_NOTIFIABLE_TOKEN, token);

    const result = await attachUserNotifiableToken(token, loggedInUser?.id);
    if (!result?.data?.success) return;
  };

  return {
    FirebaseApp,
    FirebaseMessaging,
    generateToken,
    subscribeToDefault,

    setNewNotification,
    setNotifications,
    setNotifiableToken,
    new_notification,
    notifications,
    notifiable_token: notifiable_token ?? loggedInUser?.notif_token,

    isNotificationPermitted,
    notificationPermissionState: _notificationPermissionState,
    isPushPermitted,
    pushPermissionState,
    isLoading,
    error,

    handleNotificationPermission,
    handlPushPermission,
    errorHandler,
    skipNotification,
    skipPush,
    askNotificationPermission,
    handleNotifPermission,
    getBrowserNotifState,
  };
}
