import { useEffect, useState } from "react";
import { PermissionState } from "../../../enums/permission";

const checkPermissionStatus = async () => {
  if (!navigator.permissions || !navigator.permissions.query) {
    // Fallback for browsers that do not support navigator.permissions.query
    return PermissionState.PROMPT;
  }

  try {
    const result = await navigator.permissions.query({ name: "geolocation" });
    return result.state;
  } catch (error) {
    console.error("Permission check failed:", error);
    return PermissionState.DENIED;
  }
};

export const useGeolocationPermission = () => {
  const [permissionState, setPermissionState] = useState(PermissionState.PROMPT);

  useEffect(() => {
    const handlePermissionChange = async () => {
      const status = await checkPermissionStatus();
      setPermissionState(status);

      // For iOS Safari or fallback mechanism
      if (status === PermissionState.PROMPT || status === PermissionState.DENIED) {
        navigator.geolocation.getCurrentPosition(
          () => setPermissionState(PermissionState.GRANTED),
          () => setPermissionState(PermissionState.DENIED)
        );
      }
    };

    handlePermissionChange();

    // Listen for changes if supported
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        result.onchange = handlePermissionChange;
      });
    }
  }, []);

  return permissionState;
};

export default useGeolocationPermission;
