import mixpanel from 'mixpanel-browser';
const token = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN
 
export function initTracker() {
  mixpanel.init(token, {debug: true, track_pageview: true, persistence: 'localStorage'});
}

export function identifyTrackableUser(userId) {
  mixpanel.identify(userId)
}

export function sendTrackingData(type, payload) {  
  mixpanel.track(type, payload)
}