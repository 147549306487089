import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useMemo } from "react";

export const useLinkedWallets = () => {
  const { wallets, ready: walletsReady } = useWallets();
  const { user, ready } = usePrivy();

  const linkedWallets = useMemo(
    () =>
      ready &&
      user &&
      user.linkedAccounts.filter((acc) => acc.type === "wallet"),
    [user?.linkedAccounts, ready]
  );

  //  Get all linked wallets and check if they are connected
  const updatedLinkedWallets = useMemo(
    () =>
      ready &&
      walletsReady &&
      linkedWallets?.map((linkedWallet) => {
        // Check if the linked wallet exists in the connected wallets array
        const isConnected = wallets?.some(
          (connectedWallet) => connectedWallet.address === linkedWallet.address
        );

        // Return a new object with the `connected` property and the wallet object if connected
        if (isConnected) {
          const wallet = wallets?.find(
            (wallet) => wallet.address === linkedWallet.address
          );

          return {
            connected: true,
            ...wallet,
          };
        }

        // Return the linked wallet object if not connected
        return {
          ...linkedWallet,
          connected: isConnected,
        };
      }),
    [linkedWallets, wallets, ready, walletsReady]
  );

  return { linkedWallets: updatedLinkedWallets, ready };
};
