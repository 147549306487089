import { useQuery } from "@tanstack/react-query";
import { getFollowingList } from "../../../api/spotify";

export const useUserSpotifyFollowing = ({ accessToken }) => {
  return useQuery({
    queryKey: ["spotify-follows", accessToken],
    queryFn: async () => {
      const followingList = await getFollowingList({ accessToken });

      return followingList;
    },
    enabled: accessToken != "",
  });
};
