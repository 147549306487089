import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const QuickSearchItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  border-radius: 10px;
  padding: 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
`;

export const QuickSearchItemBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, bg }) => (bg ? bg : theme.colors.blueShade)};
  z-index: 1;
`;

export const QuickSearchItemText = styled(BodyText)`
  font-weight: 600;
  /* Truncate */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
`;
