import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export const ArtistCardSkeleton = () => {
  return (
    <>
      <SkeletonElement
        backgroundColor="#ffffff14"
        width="140px"
        height="21px"
        radius="16px"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 16,
          overflow: "scroll",
          marginRight: "-20px",
          paddingRight: 20,
          marginLeft: "-20px",
          paddingLeft: 20,
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              alignItems: "center",
              maxWidth: 122,
              minWidth: 122,
              height: 133,
              minHeight: 133,
              maxHeight: 133,
            }}
            key={index}
          >
            <SkeletonElement
              width="64px"
              height="64px"
              radius="50%"
              backgroundColor="#ffffff14"
            />

            <SkeletonElement
              width="100px"
              height="20px"
              radius="16px"
              backgroundColor="#ffffff14"
            />

            <SkeletonElement
              width="100%"
              height="32px"
              radius="10px"
              backgroundColor="#ffffff14"
            />
          </div>
        ))}
      </div>
    </>
  );
};
