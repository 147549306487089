import { usePrivy } from "@privy-io/react-auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import CameraIcon from "../../assets/svg/camera.svg?react";
import LoadingScreen from "../../components/LoadingScreen";
import TopNav from "../../components/TopNav";
import { AvatarPlaceholder, NavBarTypes } from "../../constants/constants";
import {
  BodyText,
  ButtonRect,
  FooterText,
  InvisibleInputImage,
  Section,
} from "../../GlobalStyles";
import useAuth from "../../hooks/useAuth";
import {
  isCinnyAuthenticated,
  isCinnySupported,
} from "../../utils/cinny-utils";
import { theme } from "../../utils/theme";
import { handleProfileSubmit } from "../../utils/users";

const { colors } = theme;

const EditProfileSection = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: ${({ center }) => (center ? "center" : "normal")};
  justify-content: ${({ center }) => (center ? "center" : "normal")};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 0 16px;
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UsernameLabel = styled.label`
  font-size: 14px;
  font-family: "Suisse Intl", sans-serif;
`;

const Input = styled.input`
  background: transparent;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.red : "rgba(255, 255, 255, 0.15)"};
  border-radius: 10px;
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 16px;

  &:focus {
    outline: none;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 16px;
  margin-right: 0;
  color: ${({ theme, color }) => color || theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes.mobile.footer};
`;

const InputCheckbox = styled.input`
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.red : "rgba(255, 255, 255, 0.15)"};
  border-radius: 4px;
  background-color: transparent !important;
  height: 24px !important;
  width: 24px !important;
  padding: 6px;
  cursor: pointer;

  &:checked {
    background-color: rgba(255, 255, 255, 0.12) !important;
    border: 1px solid ${({ theme }) => theme.colors.green};

    &::before {
      border: 6px solid ${({ theme }) => theme.colors.green} !important;
      border-width: 0 0 3px 3px !important;
    }
  }
`;

const Hyperlink = styled(Link)`
  color: ${({ theme }) => theme.colors.green} !important;
`;

const ErrorText = styled(BodyText)`
  color: ${({ theme }) => theme.colors.red};
  padding: 0 16px;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  align-self: center;
  position: relative;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
  object-position: center;
`;

const CameraIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: rgba(15, 15, 27, 1);
  padding: 6px;
  border: 1px solid rgba(53, 53, 64, 1);
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
  flex: 1;
  text-align: center;
`;

const SubmitButton = styled(ButtonRect)`
  border-radius: 10px;
  padding: 13.5px 0;
  min-width: 0;
  width: 288px;
  background: rgba(255, 255, 255, 0.12);
  border: none;
  align-self: center;
  margin-top: 16px;

  &:disabled {
    opacity: 0.6;
  }
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

/**
 * The CreateProfile component displays the create profile section of the application.
 *
 * @return {React.JSX.Element} The JSX element representing the CreateProfile section.
 */
export default function CreateProfile() {
  const { user, ready } = usePrivy();
  const { register, handleSubmit, setError, formState, watch } = useForm();
  const navigate = useNavigate();
  const { loggedInUser, updateCurrentUser } = useAuth();
  const [image, setImage] = useState();
  const [saving, setSaving] = useState(false);
  const watchTerms = watch("terms");

  useEffect(() => {
    if (loggedInUser?.username || loggedInUser?.avatar) {
      toast.error("You have already created a profile.");
      navigate(`/profile/${loggedInUser.id}`);
    }

    if (
      isCinnySupported() &&
      !isCinnyAuthenticated() &&
      !!loggedInUser?.privy_id
    ) {
      import("../../utils/federated-cinny-utils").then(async (module) => {
        await module.initMatrix(loggedInUser);
      });
    }
  }, [loggedInUser, navigate]);

  const handleImageChange = async (e) => {
    const imageToBeUploaded = e.target.files[0];
    setImage(imageToBeUploaded);
  };

  const onSubmit = async (data) => {
    if (loggedInUser?.username || loggedInUser?.avatar) {
      toast.error("You have already created a profile.");
      return navigate(`/profile/${loggedInUser.id}`);
    }

    setSaving(true);

    data.addedImage = image;

    const response = await handleProfileSubmit(data, loggedInUser, true);

    if (!response.success) {
      setError(response.error.name, {
        message: response.error.message,
      });
      setSaving(false);
      return;
    }

    delete data.image;
    delete data.terms;
    delete data.addedImage;

    await updateCurrentUser(data);

    setSaving(false);
    navigate(`/profile/favorite`);
  };

  if (!ready && !user && !loggedInUser) return <LoadingScreen />;

  return (
    <EditProfileSection>
      <TopNav type={NavBarTypes.CREATE_PROFILE} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ProfileImageContainer>
          <ProfileImage
            src={image ? URL.createObjectURL(image) : AvatarPlaceholder}
            alt="profile-img"
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
          <CameraIconContainer>
            <CameraIcon height={20} width={20} stroke="white" />
          </CameraIconContainer>
          <InvisibleInputImage
            type="file"
            accept="image/png, image/jpeg"
            {...register("image")}
            onChange={handleImageChange}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </ProfileImageContainer>
        <InputContainer>
          <UsernameLabel>Username</UsernameLabel>
          <Input
            placeholder="Type in a username"
            {...register("username")}
            autoFocus
            error={formState.errors.username ? 1 : 0}
          />
          {formState.errors.username && (
            <ErrorText color="red">
              {formState.errors.username.message}
            </ErrorText>
          )}
        </InputContainer>
        <CheckboxLabel color={watchTerms ? colors.white : colors.grey}>
          <InputCheckbox type="checkbox" {...register("terms")} />
          <BodyText>
            {"I agree to Momentify's "}
            <Hyperlink to="https://momentify.xyz/privacy" target="_blank">
              Terms and Conditions.
            </Hyperlink>{" "}
            (*)
          </BodyText>
        </CheckboxLabel>
        <SubmitButton
          type="submit"
          disabled={saving || !watchTerms || formState.errors.username}
        >
          <BodyText weight="bold">{saving ? "Creating..." : "Create"}</BodyText>
        </SubmitButton>
        <Footer>
          <FooterTextContainer>
            <FooterText color={colors.grey}>
              Accounts are set to public by default.
            </FooterText>
            <FooterText color={colors.grey}>
              You can change this in settings.
            </FooterText>
          </FooterTextContainer>
        </Footer>
      </Form>
    </EditProfileSection>
  );
}
