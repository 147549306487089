import PropTypes from "prop-types";

import useAuth from "../../../hooks/useAuth";
import MomentThumbnailCard from "../MomentThumbnailCard";
import RoomButton from "../../RoomButton";
import { OneLineText } from "../../Artist/ArtistEventDateBox/ArtistEventDateBox-Styles";
import {
  MomentContainer,
  ScrollableContainer,
} from "./MultiplePerformancesMoment-Styles";

/**
 * Component to display a carousel of active moments.
 *
 * @param {object[]} moments - Array of moment objects
 * @param {object} user - User object
 * @returns {React.JSX.Element} - Carousel of active moments
 */
export default function MultiplePerformancesMoment({ moments, user }) {
  const { loggedInUser } = useAuth();

  // Check if the user is the owner of the profile
  const isOwnProfile = user?.id === loggedInUser?.id;

  // Map over each moment and render a thumbnail card for it
  const momentCards = moments.map((moment) => {
    const { moment_id, performance_artist, headline_artist } = moment;

    // Return a MomentContainer with a MomentThumbnailCard, artist name and EventRoomButton if the profile is owned by the user
    return (
      <MomentContainer key={moment_id}>
        <MomentThumbnailCard moment={moment} type="active" />
        <OneLineText weight="600">
          {performance_artist ?? headline_artist}
        </OneLineText>
        {isOwnProfile && (
          <RoomButton moment={moment} size="small" shape="rect" />
        )}
      </MomentContainer>
    );
  });

  // Return the scrollable container with the mapped moment cards
  return (
    <ScrollableContainer
      center={moments.length <= 3 ? 1 : 0} // Center if 3 or less items
    >
      {momentCards}
    </ScrollableContainer>
  );
}

MultiplePerformancesMoment.propTypes = {
  moments: PropTypes.array,
  user: PropTypes.object,
};
