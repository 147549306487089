import PropTypes from "prop-types";
import { Title } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import Mic from "../../../assets/svg/mic.svg?react";
import {
  ArtistIconPlaceholder,
  Container,
  Image,
} from "./PerformancePageHeader-Styles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { colors } = theme;

export default function PerformancePageHeader({ performance, isLoading }) {
  if (isLoading) {
    return (
      <Container>
        <SkeletonElement height="40px" width="40px" radius="50%" />
        <SkeletonElement height="30px" width="200px" />
      </Container>
    );
  }

  return (
    <Container>
      {performance?.image ? (
        <Image src={performance.image} />
      ) : (
        <ArtistIconPlaceholder as="div">
          <Mic stroke={colors.grey} height={14.5} width={14.5} />
        </ArtistIconPlaceholder>
      )}
      <Title>{performance?.artist}</Title>
    </Container>
  );
}

PerformancePageHeader.propTypes = {
  performance: PropTypes.object,
  isLoading: PropTypes.bool,
};
