import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  padding: 4px 8px;
  background: #11111f33;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
`;

export const Image = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  object-fit: cover;
`;
