import PropTypes from "prop-types";
import { Button } from "../../../GlobalStyles";
import { Container } from "./MomentTopButtons-Styles";
import MusicIcon from "../../../assets/svg/music.svg?react";
import CardViewIcon from "../../../assets/svg/moments.svg?react";

export default function MomentTopButtons({
  handleSetlist,
  handleIsCard,
  isMomentsFeed,
  momentType,
}) {
  const isRetro = momentType === "retro";

  return (
    <Container isMomentsFeed={isMomentsFeed}>
      <Button
        onClick={handleSetlist}
        style={{
          border: "none",
        }}
      >
        <MusicIcon stroke="white" height={16} width={16} />
      </Button>
      {!isRetro && (
        <Button
          onClick={handleIsCard}
          style={{
            border: "none",
          }}
        >
          <CardViewIcon stroke="white" height={16} width={16} />
        </Button>
      )}
    </Container>
  );
}

MomentTopButtons.propTypes = {
  handleSetlist: PropTypes.func,
  handleIsCard: PropTypes.func,
  isMomentsFeed: PropTypes.bool,
  momentType: PropTypes.string,
};
