export const getFollowingList = async ({ accessToken }) => {
  let allArtists = [];
  let nextPage = "https://api.spotify.com/v1/me/following?type=artist&limit=20";

  while (nextPage) {
    const response = await fetch(nextPage, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      allArtists = allArtists.concat(data.artists.items);

      // Check if there is another page of results
      nextPage = data.artists.next;
    } else {
      console.error(
        "Failed to fetch following list:",
        response.status,
        response.statusText
      );
      return undefined;
    }
  }

  return allArtists;
};
