import PropTypes from "prop-types";
import IOS from "../../assets/svg/ios.svg?react";
import Android from "../../assets/svg/android.svg?react";
import Upload from "../../assets/svg/upload.svg?react";
import EllipsisVertical from "../../assets/svg/ellipsis-vertical.svg?react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
`;

const IconWithTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextHeader = styled.p`
  font-size: 24px;
  font-weight: 600;
`;

const InstructionContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export default function DownloadInfo({ type = "ios" }) {
  return (
    <Container>
      {type === "ios" ? (
        <IconWithTextContainer>
          <TextHeader>iOS</TextHeader>
          <IOS />
        </IconWithTextContainer>
      ) : (
        <IconWithTextContainer>
          <TextHeader>Android</TextHeader>
          <Android />
        </IconWithTextContainer>
      )}
      <Container>
        <InstructionContainer
          style={{
            opacity: 0.6,
          }}
        >
          1. Tap the {type === "ios" ? <Upload /> : <EllipsisVertical />}{" "}
          {type === "ios" ? "share icon" : "menu icon"}
        </InstructionContainer>
        <p
          style={{
            opacity: 0.6,
          }}
        >
          2. Select Add to Home Screen
        </p>
      </Container>
    </Container>
  );
}

DownloadInfo.propTypes = {
  type: PropTypes.string,
};
