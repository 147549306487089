import { useLoaderData, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Section } from "../../GlobalStyles";
import { FestivalEventType, StockImage } from "../../constants/constants";
import ArrowLeft from "../../assets/svg/arrow-left.svg?react";
import { useEffect, useState } from "react";
import FestivalEvents from "../../components/Festival/FestivalEvents";
import FestivalPromo from "../../components/Festival/FestivalPromo";
import FestivalDetails from "../../components/Festival/FestivalDetails";
import FestivalRoomButton from "../../components/Events/FestivalRoomButton";
import moment from "moment";
import useGlobalModal from "../../hooks/useGlobalModal";
import ErrorPage from "../Error";

const FestivalSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

const FestivalImage = styled.div`
  width: 100%;
  min-height: 268px;
  background: ${({ image, theme }) =>
    image
      ? `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url(${image})`
      : theme.colors.green};
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border: none;
  position: relative;
`;

const BackButton = styled(Button)`
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: #11111f33;
  border: 1px solid;
  border-color: #ffffff26;
  border-radius: 10px;
  padding: 7px;
  position: absolute;
  top: 16px;
  left: 16px;
`;

const FestivalLogo = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  left: 16px;
  bottom: 16px;
`;

const RoomChatButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const FestivalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
`;

export default function Festival() {
  const festival = useLoaderData();
  const { name, events, image, logo } = festival;
  const navigate = useNavigate();
  const { openSheetPWAModal } = useGlobalModal();
  const [todaysEvents, setTodaysEvents] = useState([]);
  const [nextEvents, setNextEvents] = useState([]);
  const [nextEvent, setNextEvent] = useState(null);
  const festivalImage =
    events.length > 0 ? events[0].image : image ?? StockImage;

  useEffect(() => {
    if (!events) return;

    openSheetPWAModal();
    filterEvents(events);

    return () => {
      setTodaysEvents([]);
      setNextEvents([]);
      setNextEvent(null);
    };
  }, []);

  useEffect(() => {
    if (!nextEvents) return;

    nextEvents.length >= 1 && setNextEvent(nextEvents[0]);
  }, [nextEvents]);

  const filterEvents = (events) => {
    // Filter event if today or upcoming using momentjs
    events.forEach((event) => {
      if (moment(event.date ?? event.event_date).isSame(new Date(), "day")) {
        setTodaysEvents((todaysEvents) => [...todaysEvents, event]);
      } else if (
        moment(event.date ?? event.event_date).isAfter(new Date(), "day")
      ) {
        setNextEvents((nextEvents) => [...nextEvents, event]);
      }
    });
  };

  if (!festival) return <ErrorPage />;

  return (
    <FestivalSection>
      {/* Image of Festival */}
      <FestivalImage image={festivalImage}>
        <BackButton onClick={() => navigate(-1)}>
          <ArrowLeft height={15} width={15} stroke="white" />
        </BackButton>
        <FestivalLogo src={logo} alt={name} />
        <RoomChatButtonContainer>
          <FestivalRoomButton dark />
        </RoomChatButtonContainer>
      </FestivalImage>
      {/* Content */}
      <FestivalContent>
        {/* Details */}
        <FestivalDetails festival={festival} nextEvent={nextEvent} />
        {/* Events */}
        {events.length > 0 && (
          <FlexContainer direction="column" gap="16px">
            {todaysEvents.length > 0 && (
              <FestivalEvents
                events={todaysEvents}
                type={FestivalEventType.TODAY}
              />
            )}
            {nextEvents.length > 0 && (
              <FestivalEvents
                events={nextEvents}
                type={FestivalEventType.UPCOMING}
              />
            )}
          </FlexContainer>
        )}
        {/* Promo */}
        <FestivalPromo festival={festival} />
      </FestivalContent>
    </FestivalSection>
  );
}
