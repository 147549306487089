import { Container, ImageContainer } from "./RetroAddThumbnail-Styles";
import RetroIcon from "../../../assets/svg/retro-moment.svg?react";
import { FooterText, Header } from "../../../GlobalStyles";
import PropTypes from "prop-types";
import moment from "moment";
import { theme } from "../../../utils/theme";
import { RetroLogo } from "../../Moments/MomentThumbnailCard/MomentThumbnailCard-Styles";

const { colors } = theme;

export default function RetroAddThumbnail({ performance, isSuccess }) {
  const { artist, venue, city, date, event_date } = performance || {};

  return (
    <Container>
      {isSuccess ? (
        <ImageContainer>
          <RetroLogo style={{ bottom: "12px", right: "12px" }} />
        </ImageContainer>
      ) : (
        <ImageContainer>
          <RetroIcon height={24} width={24} stroke={colors.white} />
        </ImageContainer>
      )}
      <Header truncate style={{ textTransform: "uppercase", marginTop: "8px" }}>
        {artist}
      </Header>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <FooterText truncate color={!city ? colors.grey : colors.white}>
          {venue}
        </FooterText>
        <FooterText truncate color={colors.grey}>
          {city}
        </FooterText>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
        <FooterText color={colors.green}>
          {moment(date).utc().format("ddd DD MMM")}
        </FooterText>
        {event_date && (
          <FooterText color={colors.grey}>
            {moment(event_date).utc().format("h:mm A")}
          </FooterText>
        )}
      </div>
    </Container>
  );
}

RetroAddThumbnail.propTypes = {
  performance: PropTypes.object.isRequired,
  isSuccess: PropTypes.bool,
};
