import RetroIcon from "../../../assets/svg/retro-moment.svg?react";
import { useRetroFlowStore } from "../../../routes/Search/hooks/searchStore";
import { theme } from "../../../utils/theme";
import { Container, ImageContainer } from "./RetroThumbnailCard-Styles";

export default function RetroThumbnailCard() {
  const { isSuccess } = useRetroFlowStore();
  return (
    <Container isRetroFlow={isSuccess}>
      <ImageContainer>
        <RetroIcon
          height={24}
          width={24}
          stroke={theme.colors.white}
          style={{ opacity: 0.6 }}
        />
      </ImageContainer>
    </Container>
  );
}
