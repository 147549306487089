import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { BodyText } from "../GlobalStyles";
import { theme } from "../utils/theme";

const { colors } = theme;

const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
`;

export default function ExpandableText({ text, maxLength = 100 }) {
  const [showAll, setShowAll] = useState(false);

  const displayedText = showAll ? text : `${text.slice(0, maxLength)}...`;

  const toggleText = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <Container showAll={showAll}>
        <BodyText>{displayedText}</BodyText>
      </Container>
      {text.length > maxLength && (
        <ToggleButton onClick={toggleText}>
          <BodyText color={colors.grey}>
            {showAll ? "Show less" : "Read more"}
          </BodyText>
        </ToggleButton>
      )}
    </>
  );
}

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};