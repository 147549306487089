import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export const BigSkeleton = () => {
  return (
    <>
      <SkeletonElement
        backgroundColor="#ffffff14"
        width="100px"
        height="21px"
        radius="16px"
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          gap: "8px",
        }}
      >
        {Array.from({ length: 4 }).map((_, index) => (
          <SkeletonElement
            key={index}
            height="171px"
            radius="8px"
            backgroundColor="#ffffff14"
          />
        ))}
      </div>
    </>
  );
};
