import styled from "styled-components";
import { Button, Title, FooterText, BodyText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import useGlobalModal from "../../hooks/useGlobalModal";
import Pin from "../../assets/svg/pin-white.svg?react";

const { fontSizes, colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
`;

const ModalButton = styled(Button)`
  background: rgba(255, 255, 255, 0.12);
  width: 100%;
  margin-top: 8px;
  padding: 16px;
  border: none;
`;

export default function DeniedPush() {
  const { toggleSheetModal } = useGlobalModal();

  return (
    <Container>
      <Pin color={colors.white} height={35} width={35} />
      <Title
        color={colors.white}
      >
        Oops!
      </Title>
      <FooterText color={colors.white}>
        Looks like you denied permission for us to send you push notification.
      </FooterText>
      <FooterText color={colors.white}>
        Don’t worry, you can
        <FooterText
          color={colors.green}
        >
          {" "}
          enable it in your browser settings{" "}
        </FooterText>
        so you don’t miss out on exciting events near you!
      </FooterText>
      <ModalButton onClick={toggleSheetModal}>
        <BodyText >
          Okay
        </BodyText>
      </ModalButton>
    </Container>
  );
}
