import styled from "styled-components";
import { BottomNavBarHeight } from "../../../constants/constants";

export const RetroAddFlowContainer = styled.div`
  position: absolute;
  min-height: calc(100svh - ${BottomNavBarHeight});
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 20px 80px;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  background: ${({ theme }) => theme.colors.background};
  z-index: 99;
`;

export const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.4;
  filter: blur(5px) brightness(0.4);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;

export const BottomContentContainer = styled(BottomContainer)`
  gap: 16px;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
`;
