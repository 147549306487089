import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can log the error to an error reporting service
  //   console.error("ErrorBoundary caught an error:", error, errorInfo);

  //   // Check if this is the specific error we're targeting
  //   if (error.message.includes("navigator.serviceWorker.addEventListener")) {
  //     // Redirect to a URL that will open in the default browser
  //     this.redirectToBrowser();
  //   }
  // }

  // redirectToBrowser() {
  //   if (this.isInWebView()) {
  //     window.location.href = "https://your-default-url.com";
  //   }
  // }

  // isInWebView() {
  //   // A simple heuristic to detect WebView
  //   return (
  //     (window.navigator.standalone ||
  //       window.matchMedia("(display-mode: standalone)").matches) &&
  //     /Safari/.test(navigator.userAgent) &&
  //     !/Chrome/.test(navigator.userAgent)
  //   );
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
