import PropTypes from "prop-types";
import { BodyText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import Map from "../../../assets/svg/map.svg?react";
import Bookmark from "../../../assets/svg/bookmark.svg?react";
import moment from "moment";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import {
  Container,
  IconWithText,
  LeftContainer,
} from "./PerformanceEvent-Styles";
import { OneLineText } from "../../Artist/ArtistEventDateBox/ArtistEventDateBox-Styles";

const { colors } = theme;

export default function PerformanceEvent({ performance, isLoading }) {
  const { date, venue, city, country } = performance || {};

  // Check if date is 12am
  const is12AM = moment(date).utc().format("HH:mm") === "00:00";

  if (isLoading) {
    return (
      <Container>
        <LeftContainer>
          <IconWithText>
            <SkeletonElement height="20px" width="28px" />
            <SkeletonElement height="20px" width="200px" />
          </IconWithText>
          <IconWithText>
            <SkeletonElement height="20px" width="25px" />
            <SkeletonElement height="20px" width="150px" />
          </IconWithText>
        </LeftContainer>
      </Container>
    );
  }

  return (
    <Container>
      <LeftContainer>
        <IconWithText>
          <Map fill={colors.white} height={20} width={20} />
          <OneLineText>
            {venue}, {city} {country}
          </OneLineText>
        </IconWithText>
        <IconWithText>
          <Bookmark
            stroke={colors.white}
            fill={colors.white}
            height={20}
            width={20}
          />
          {date && (
            <>
              <BodyText color={colors.green}>
                {moment(new Date(date)).utc().format("ddd D MMM")}
              </BodyText>
              {!is12AM && (
                <BodyText color={colors.grey}>
                  {moment(new Date(date)).utc().format("h:mm a")}
                </BodyText>
              )}
            </>
          )}
        </IconWithText>
      </LeftContainer>
    </Container>
  );
}

PerformanceEvent.propTypes = {
  performance: PropTypes.object,
  isLoading: PropTypes.bool,
};
