import styled from "styled-components";
import { BottomNavBarHeight } from "../../../constants/constants";

export const BackButtonContainer = styled.div`
  z-index: 5;
  position: absolute;
  top: 16px;
  left: 20px;
`;

export const VisibleCardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100svh - ${BottomNavBarHeight});
  position: absolute;
  top: 0;
  left: 0;
  gap: 40px;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
`;

export const MomentCardsContainer = styled.div`
  padding: 0 10%;
  width: 100vw;
  gap: 25px;
  z-index: 2;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CollectCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  z-index: 2;
`;
