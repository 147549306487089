import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getEventsFeed } from "../api/events";
import {
  deleteMoment,
  getAllMoments,
  getLikeMomentPhoto,
  removeLikeMomentPhoto,
  setLikeMomentPhoto,
} from "../api/moment";
import useGlobalModal from "./useGlobalModal";

// export const useToggleInterested = () => {
//   return useMutation({
//     mutationKey: ["toggleInterested"],
//     mutationFn: ({
//       interested,
//       eventId,
//       loggedInUser,
//       errorHandling,
//       event,
//     }) => {
//       if (interested)
//         removeEventParticipant(eventId, loggedInUser, "interested").catch(
//           errorHandling
//         );
//       // If user is not interested, add them to the list
//       else
//         addEventParticipant(
//           eventId,
//           loggedInUser,
//           "interested",
//           event.headline_artist
//         ).catch(errorHandling);
//     },
//   });
// };

export const useGetLikedMomentPhoto = ({ user_id, moment_id }) => {
  return useMutation({
    mutationKey: ["getLikedMomentPhoto", user_id, moment_id],
    mutationFn: () => {
      const user = getLikeMomentPhoto({
        user_id,
        moment_id,
      });

      return user;
    },
  });
};

export const useUnlikeMomentPhoto = () => {
  return useMutation({
    mutationKey: ["unlikeMomentPhoto"],
    mutationFn: (user) => {
      const unlikedMomentPhoto = removeLikeMomentPhoto(user.data);

      return unlikedMomentPhoto;
    },
  });
};

export const useLikeMomentPhoto = () => {
  return useMutation({
    mutationKey: ["likeMomentPhoto"],
    mutationFn: (moment) => {
      const likedMomentPhoto = setLikeMomentPhoto(moment);

      return likedMomentPhoto;
    },
  });
};

// TODO: Only render 10 at a time
// export const useMomentsFeed = ({ userId }) => {
//   return useQuery({
//     queryKey: ["moments-feed", userId],
//     queryFn: async () => {
//       let moments = await getAllMoments(userId);

//       if (!moments) return [];

//       // TODO: This should be done on the query
//       // Filter out moments where fan_photo_urls are empty arrays and user is private
//       moments = moments.filter(
//         (moment) => moment.fan_photo_urls?.length > 0 && !moment.private
//       );

//       // TODO: Some kind of recommendation system, maybe based on location
//       // Randomize the order of the moments
//       moments.sort(() => Math.random() - 0.5);

//       return moments;
//     },
//     staleTime: 1000 * 60 * 5, // 5 mins stale time
//   });
// };

export const useMomentsFeed = ({ userId, limit = 20, enabled = true }) => {
  return useInfiniteQuery({
    queryKey: ["moments-feed", userId],
    queryFn: async ({ pageParam = 0 }) => {
      let moments = await getAllMoments(userId, limit, pageParam);

      if (!moments) return [];

      // Filter out moments where fan_photo_urls are empty arrays and user is private
      // moments = moments.filter(
      //   (moment) => moment.fan_photo_urls?.length > 0 && !moment.private
      // );

      // Randomize the order of the moments
      moments.sort(() => Math.random() - 0.5);

      return moments;
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < limit) {
        return undefined; // No more pages to load
      }
      return allPages.length * limit; // Return the offset for the next page
    },
    staleTime: Infinity,
    enabled: enabled,
  });
};

export const useEventsFeed = ({ userId, limit = 20 }) => {
  return useInfiniteQuery({
    queryKey: ["events-feed", userId],
    queryFn: async ({ pageParam = 0 }) => {
      let events;
      if (userId) events = await getEventsFeed(userId, limit, pageParam);

      if (!events || !events?.success) events = [];
      else if (events?.data) events = events.data;

      return events;
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < limit) {
        return undefined; // No more pages to load
      }
      return allPages.length * limit; // Return the offset for the next page
    },
    staleTime: Infinity,
    enabled: !!userId,
  });
};

export const useDeleteMoment = ({ userId, momentId }) => {
  const queryClient = useQueryClient();
  const { toggleCenterModal } = useGlobalModal();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: [
      "moment",
      {
        userId,
        momentId,
      },
    ],
    mutationFn: async ({ userId, momentId }) => {
      await deleteMoment(userId, momentId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["moments", userId.toString()],
        refetchType: "all",
      });
      toggleCenterModal();
      navigate(`/profile/${userId}`);
      toast.success("Moment deleted");
    },
  });
};
