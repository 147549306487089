import styled from "styled-components";
import { theme } from "../../utils/theme";
import { BodyText, ButtonRect } from "../../GlobalStyles";
import EyeIcon from "../../assets/svg/eye.svg?react"
import TrashPlainIcon from "../../assets/svg/trash-plain.svg?react"
import { removeUserMessage } from "../../api/profile";

const DropdownContentWrapper = styled.div`
  display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
  align-items: center;
  flex-direction: column;
  background: ${theme.colors.black};
  border-radius: 10px;
  border: 1px solid ${theme.colors.grey2};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 200px;
  max-width: 200px;
  z-index: ${({ index }) => index - 1};
  top: 20px;
  left: -196px;
  padding: 8px 0;
  gap: 4px;
`;

const DropdownItemWrapper = styled(ButtonRect)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  padding: 0 16px;
  height: 40px;
  width: 100%;

  border: none;
  background: transparent;

  &:disabled {
    opacity: .6;
  }
`;

const DropdownItemDivider = styled.div`
  background: ${theme.colors.grey2};
  height: 1px;
  width: 100%;
`

const DropdownItemLabel = styled(BodyText)`
  line-height: 1;
`

export default function NotificationDropdownContent({ index, isOpen = false, isRead = false, isSystem = false, setIsDropdownOpen, notification_id, handlePopSelectedItem, handleReadClick, refetch }) {
  const handleRemoveClick = async () => {
    await removeUserMessage(notification_id)
    refetch()
    setIsDropdownOpen(!isOpen)
    handlePopSelectedItem(notification_id)
  }

  return (
    <DropdownContentWrapper isOpen={isOpen} index={index}>
      <DropdownItemWrapper onClick={handleReadClick} disabled={isRead}>
        <div style={{
          display: "flex",
          alignItems: "center",
          height: "21px"
        }}>
        <EyeIcon stroke="white" width="16px" height="14px" />

        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          height: "21px"
        }}>
        <DropdownItemLabel color={isRead ? theme.colors.grey : theme.colors.softWhite}>Mark as read</DropdownItemLabel>
        </div>
      </DropdownItemWrapper>
      <DropdownItemWrapper onClick={handleRemoveClick}>
        <div style={{
          display: "flex",
          alignItems: "center",
          height: "21px"
        }}>
          <TrashPlainIcon stroke="white" width="16px" height="16px" />      
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          height: "21px"
        }}>
          <DropdownItemLabel>Clear this notification</DropdownItemLabel>
        </div>
      </DropdownItemWrapper>
    </DropdownContentWrapper >
  )
}