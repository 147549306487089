import { ButtonRect } from "../../GlobalStyles";
import PropTypes from "prop-types";

export default function RectButtonIconText({ icon, text, onClick, style }) {
  return (
    <ButtonRect onClick={onClick} style={style}>
      {icon}
      {text}
    </ButtonRect>
  );
}

RectButtonIconText.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
