import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Map from "../../assets/svg/map.svg?react";
import { BodyText, FooterText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const VenueDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const VenueIcon = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;
  object-fit: cover;
`;

const VenueIconPlaceholder = styled(VenueIcon)`
  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
`;

const VenuTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const VenueDescriptionContainer = styled(VenuTextContainer)`
  gap: 0px;
`;

const VenueName = styled(BodyText)`
  /* Make Text two lines then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1.2;
  font-weight: 600;
`;

export default function VenueSearchItem({ venue, innerRef }) {
  const { id, image, venue_name, city, country } = venue || {};
  return (
    <Container as={Link} to={`/venues/${id}/${venue_name}`} ref={innerRef}>
      <VenueDetails>
        {image ? (
          <VenueIcon src={image} />
        ) : (
          <VenueIconPlaceholder as="div">
            <Map stroke={colors.white} height={12} width={12} />
          </VenueIconPlaceholder>
        )}
        <VenuTextContainer>
          <VenueName>{venue_name}</VenueName>
          <VenueDescriptionContainer>
            <FooterText color={colors.grey}>
              {city}, {country}
            </FooterText>
          </VenueDescriptionContainer>
        </VenuTextContainer>
      </VenueDetails>
    </Container>
  );
}

VenueSearchItem.propTypes = {
  venue: PropTypes.object.isRequired,
  innerRef: PropTypes.object,
};
