import { createContext, useState } from "react";
import { AccountKitDispatchEvents } from "../constants/dispatchEvents";
import PropTypes from "prop-types";

export const AccountKitContext = createContext({});

const initialState = {
  alchemyProvider: null,
  txnHash: null,
  claimStatus: null,
  error: null,
  moment: null,
  currentPendingMessage: null,
  pendingMessages: [
    "We're just putting the finishing touches on your Moment!",
    "Almost there! Just a \"Moment\"! 😉",
  ],
};

export default function AccountKitProvider(params) {
  const { children } = params;
  const [context, setContext] = useState(initialState);

  const dispatch = (actionType, payload) => {
    switch (actionType) {
      case AccountKitDispatchEvents.SET_ALCHEMY_PROVODER:
        {
          setContext((prev) => ({
            ...prev,
            alchemyProvider: payload,
          }));
        }
        break;
      case AccountKitDispatchEvents.SET_TXN_HASH:
        {
          setContext((prev) => ({
            ...prev,
            txnHash: payload,
          }));
        }
        break;
      case AccountKitDispatchEvents.SET_CLAIM_STATUS:
        {
          setContext((prev) => ({
            ...prev,
            claimStatus: payload,
          }));
        }
        break;
      case AccountKitDispatchEvents.SET_ERROR:
        {
          setContext((prev) => ({
            ...prev,
            error: payload,
          }));
        }
        break;
      case AccountKitDispatchEvents.SET_MOMENT:
        {
          setContext((prev) => ({
            ...prev,
            moment: payload,
          }));
        }
        break;
      case AccountKitDispatchEvents.SET_CURRENT_PENDING_MESSAGE:
        {
          setContext((prev) => ({
            ...prev,
            currentPendingMessage: payload,
          }));
        }
        break;
    }
  };

  return (
    <AccountKitContext.Provider value={{ context, dispatch }}>
      {children}
    </AccountKitContext.Provider>
  );
}

AccountKitProvider.propTypes = {
  children: PropTypes.node,
};
