import { X } from "@phosphor-icons/react";
import { isAndroid, isIOS } from "react-device-detect";
import { BodyText, Header } from "../../../GlobalStyles";
import Logo from "../../../assets/images/mom-cube-logo.png";
import Share from "../../../assets/svg/share.svg?react";
import ThreeDots from "../../../assets/svg/three-dots.svg?react";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { theme } from "../../../utils/theme";
import {
  CloseButton,
  Container,
  FlexContainer,
  LogoImage,
  SheetOverwrite,
} from "./PWANudgeModal-Styles";

const { colors } = theme;

export default function PWANudgeModal() {
  const { toggleSheetModal } = useGlobalModal();

  const AndroidInstructions = () => (
    <FlexContainer direction="column">
      <FlexContainer gap="6px">
        <BodyText>1. Tap the</BodyText>
        <ThreeDots height={20} />
        <BodyText color={colors.red} weight="600">
          Menu <BodyText>icon</BodyText>
        </BodyText>
      </FlexContainer>
      <BodyText>
        2. Select{" "}
        <BodyText color={colors.purple} weight="600">
          Install App
        </BodyText>
      </BodyText>
    </FlexContainer>
  );

  const IOSInstructions = () => (
    <FlexContainer direction="column">
      <FlexContainer gap="6px">
        <BodyText>1. Tap the</BodyText>
        <Share height={20} stroke="white" />
        <BodyText color={colors.red}>
          Share <BodyText>icon</BodyText>
        </BodyText>
      </FlexContainer>
      <BodyText>
        2. Select <BodyText color={colors.purple}>Add to Home Screen</BodyText>
      </BodyText>
    </FlexContainer>
  );
  return (
    <>
      <SheetOverwrite />
      <Container>
        <CloseButton onClick={toggleSheetModal}>
          <X height={22} width={22} color={colors.grey} />
        </CloseButton>
        <FlexContainer gap="16px" style={{ width: "90%" }}>
          <LogoImage src={Logo} alt="Logo" />
          <FlexContainer direction="column" gap="8px">
            <Header style={{ textTransform: "uppercase" }}>
              Momentify App
            </Header>
            <BodyText color={colors.grey}>
              Add Momentify to your home screen for a better app experience!
            </BodyText>
          </FlexContainer>
        </FlexContainer>
        {isIOS ? (
          <IOSInstructions />
        ) : isAndroid ? (
          <AndroidInstructions />
        ) : (
          <IOSInstructions />
        )}
      </Container>
    </>
  );
}
