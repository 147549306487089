import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BodyText, ButtonRectSm, FooterText } from "../../GlobalStyles";
import EditIcon from "../../assets/svg/edit.svg?react";
import useAuth from "../../hooks/useAuth";
import { theme } from "../../utils/theme";
import ShareButton from "../ShareButton";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import FollowUserButton from "./FollowUserButton";
import {
  ProfileBottomButtons,
  ProfileContainer,
  ProfileStats,
  ProfileStatsText,
} from "./Styles/ProfileDetails-Styles";
import useGlobalModal from "../../hooks/useGlobalModal";
import XPModal from "../XP/XPModal";

const { colors } = theme;

export default function ProfileDetails({ user, isStatsLoading, isPrivate }) {
  const { loggedInUser } = useAuth();
  const { id: userId } = useParams();
  const { setCenterModalContent, toggleCenterModal } = useGlobalModal();
  const navigate = useNavigate();
  const isOwner = loggedInUser?.id == userId;

  const openXPModal = () => {
    if (isPrivate && !isOwner) return;

    setCenterModalContent(<XPModal userId={userId} />);
    toggleCenterModal();
  };

  // Check if the profile is of the logged in user
  const isOwnProfile = loggedInUser?.id == userId;
  const linkEnabled = !(isPrivate ?? true) || isOwnProfile;

  // const { fetchedUser: user, isUserFollowed } = data || {};
  const { total_xp, user_followers, user_following } = user || {};

  if (isStatsLoading)
    return (
      <ProfileContainer>
        <ProfileStats>
          <SkeletonElement width="70px" />
          <SkeletonElement width="70px" />
          <SkeletonElement width="70px" />
          <SkeletonElement width="70px" />
        </ProfileStats>
        <ProfileBottomButtons>
          <SkeletonElement width="110px" height="26px" radius="10px" />

          <SkeletonElement
            width="22px"
            height="22px"
            radius="50%"
            margin="4px"
          />
        </ProfileBottomButtons>
      </ProfileContainer>
    );

  return (
    <ProfileContainer>
      <ProfileStats>
        <BodyText color={colors.softGreen} onClick={openXPModal}>
          {total_xp ?? 0}xp
        </BodyText>
        <ProfileStatsText as={Link} to={linkEnabled ? "following" : ""}>
          <BodyText>{user_following ?? 0}</BodyText>
          <BodyText color={colors.grey}>Following</BodyText>
        </ProfileStatsText>
        <ProfileStatsText as={Link} to={linkEnabled ? "followers" : ""}>
          <BodyText>{user_followers ?? 0}</BodyText>
          <BodyText color={colors.grey}>Followers</BodyText>
        </ProfileStatsText>
      </ProfileStats>
      <ProfileBottomButtons>
        {!isOwnProfile ? (
          <FollowUserButton user={user} />
        ) : (
          <ButtonRectSm onClick={() => navigate("/profile/edit")}>
            <EditIcon stroke="white" height={14} width={14} />
            <FooterText weight="600">Edit Profile</FooterText>
          </ButtonRectSm>
        )}
        <ShareButton
          url={window.location.href}
          title={user?.username}
          text={`Check out this fan profile - ${user?.username}`}
        />
      </ProfileBottomButtons>
    </ProfileContainer>
  );
}

ProfileDetails.propTypes = {
  user: PropTypes.object,
  isUserFollowed: PropTypes.bool,
  isStatsLoading: PropTypes.bool,
  isFollowingLoading: PropTypes.bool,
  isPrivate: PropTypes.bool,
};
