import styled from "styled-components";
import {
  BodyText,
  ButtonRect,
  HeaderL,
  Section,
  Subheader,
} from "../../GlobalStyles";
import useGeolocation from "../../hooks/useGeolocation";
import { theme } from "../../utils/theme";
import PromptModal from "../Modal/PromptModal";
import InfoCapsule from "./InfoCapsule";
import Claim from "../../assets/svg/geo-claim.svg?react";
import Chat from "../../assets/svg/geo-chat.svg?react";
import Party from "../../assets/svg/geo-party.svg?react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PermissionState } from "../../enums/permission";
import { BACKGROUND_IMAGES } from "../../constants/constants";

const { fontSizes, colors } = theme;

const PageContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 24px 33px 24px 33px;
  text-align: center;
  background: transparent;
  background-color: transparent;
`;

export const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  filter: blur(5px) brightness(0.4);
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  z-index: 1;
`;

const ModalContainer = styled.div`
  margin: 16px 0;
  position: relative;
  z-index: 1;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
  z-index: 1;
`;

export const SkipButton = styled.button`
  background: transparent;
  border: none;
  margin-top: 20px;
  z-index: 1;
`;

export default function GetLocationPage({ handleSkip }) {
  const { getCurrentPosition, permissionState } = useGeolocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (permissionState === PermissionState.DENIED) setLoading(false);
  }, [permissionState]);

  const infos = [
    {
      icon: (
        <Claim
          height={35}
          width={35}
          stroke="rgb(255, 255, 255, 0.6)"
          strokeWidth={2}
        />
      ),
      title: "Claim",
      desc: "Check in to unlock and collect Moments and receive points in the future",
    },
    {
      icon: (
        <Chat
          height={35}
          width={35}
          stroke="rgb(255, 255, 255, 0.6)"
          strokeWidth={2}
        />
      ),
      title: "Chat",
      desc: "Connect with other fans planning to attend events",
    },
    {
      icon: (
        <Party
          height={35}
          width={35}
          stroke="rgb(255, 255, 255, 0.6)"
          strokeWidth={2}
        />
      ),
      title: "Party",
      desc: "Replay the experience and share memories with other fans",
    },
  ];

  const handleGetLocation = async () => {
    if (permissionState !== PermissionState.DENIED) setLoading(true);

    await getCurrentPosition();
  };

  return (
    <PageContainer>
      <BgImage src={BACKGROUND_IMAGES.SEARCH.WEEK} alt="background image" />
      {/* Header */}
      <HeaderContainer>
        <HeaderL size={fontSizes.mobile.title}>DISCOVER AND CHECK IN</HeaderL>
        <Subheader weight="400"> at Events near you</Subheader>
      </HeaderContainer>
      {/* Info Container */}
      <InfoContainer>
        {infos.map((info, index) => (
          <InfoCapsule key={index} icon={info.icon} desc={info.desc} />
        ))}
      </InfoContainer>
      <ModalContainer>
        <PromptModal
          title="Allow “Momentify” to access your location while you are using the app?"
          desc="We need access to your location to show you relevant search results."
          onConfirm={{
            func: () => {},
            text: "Allow",
          }}
          onCancel={{
            func: () => {},
            text: "Don't Allow",
          }}
        />
      </ModalContainer>
      <ButtonRect background={colors.green} onClick={handleGetLocation}>
        <BodyText weight="600" color="black">
          {loading ? "Getting your location..." : "Share my location"}
        </BodyText>
      </ButtonRect>
      <SkipButton onClick={handleSkip}>
        <BodyText color={colors.grey}>Skip</BodyText>
      </SkipButton>
    </PageContainer>
  );
}

GetLocationPage.propTypes = {
  handleSkip: PropTypes.func.isRequired,
};
