import { BodyText, Header } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import {
  ButtonsContainer,
  Container,
  PrimaryButton,
  SecondaryButton,
} from "../UploadMediaModal/UploadMediaModal-Styles";
import PropTypes from "prop-types";
import { useDeleteMoment } from "../../../hooks/momentQueries";
import { useQueryClient } from "@tanstack/react-query";
import Trash from "../../../assets/svg/trash.svg?react";

const { colors } = theme;

export default function DeleteMomentModal({ userId, momentId }) {
  const { toggleCenterModal } = useGlobalModal();
  const queryClient = useQueryClient();
  const { fetchStatus } =
    queryClient.getQueryState(["moments", userId.toString()]) || {};
  const { mutateAsync, isPending } = useDeleteMoment({ userId, momentId });

  const handleDeleteMoment = async () => {
    await mutateAsync({ userId, momentId });
  };

  return (
    <Container
      style={{
        padding: "16px",
      }}
    >
      <Trash stroke={colors.red} height={24} width={24} />
      <Header style={{ textTransform: "uppercase", marginTop: "16px" }}>
        Delete Moment?
      </Header>
      <BodyText color={colors.grey} style={{ margin: "12px 0 24px 0" }}>
        This action cannot be undone. This Moment will be deleted permanently.
      </BodyText>
      <ButtonsContainer>
        <SecondaryButton
          onClick={handleDeleteMoment}
          disabled={isPending || fetchStatus === "fetching"}
        >
          <BodyText weight="600" color={colors.red}>
            {isPending ? "Deleting..." : "Confirm"}
          </BodyText>
        </SecondaryButton>
        <PrimaryButton
          onClick={toggleCenterModal}
          disabled={isPending || fetchStatus === "fetching"}
        >
          <BodyText weight="600">Back</BodyText>
        </PrimaryButton>
      </ButtonsContainer>
    </Container>
  );
}

DeleteMomentModal.propTypes = {
  userId: PropTypes.number.isRequired,
  momentId: PropTypes.number.isRequired,
};
