import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyText, ButtonRect, OneLineText } from "../../GlobalStyles";
import TicketButton from "../Events/TicketButton";
import { getTicketSuffix, isProd } from "../../utils/utils";
import { useBuyTicketStore } from "../BuyTicket/buyTicketStore";
import { useMemo } from "react";
import TwoTicketIcon from "../../assets/svg/two-tickets.svg?react";
import { GlassmorphPlaceholder } from "../../constants/constants";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TicketContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 11.5px;

  background: ${({ theme, glass }) =>
    glass
      ? `linear-gradient(
    122.5deg,
    rgba(180, 180, 180, 0.2) -11.54%,
    rgba(79, 79, 79, 0.2) -11.53%,
    rgba(255, 255, 255, 0.2) 24.12%,
    rgba(175, 175, 175, 0.2) 97.15%
  );`
      : theme.colors.blueShade};
  border-radius: 10px;
  gap: 8px;
`;

export default function EventTickets({ event, contract }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { setIsOpen } = useBuyTicketStore();
  const { headline_artist_id, artists, urls: tickets } = event || {};

  const headlineArtist = useMemo(
    () => artists?.find((artist) => artist.id === headline_artist_id),
    [artists, headline_artist_id]
  );
  const { artist_name, profile_img } = headlineArtist || {};

  const handleOpenDirectTicket = () => {
    if (!loggedInUser) return login();
    setIsOpen(true);
  };

  return (
    <Container>
      <BodyText weight="600">Tickets</BodyText>
      {headlineArtist && contract && !isProd && (
        <TicketContainer glass>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <BodyText style={{ textWrap: "nowrap" }}>Direct from</BodyText>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img
                src={profile_img ?? GlassmorphPlaceholder}
                alt={artist_name}
                style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                onError={(e) => {
                  e.target.src = GlassmorphPlaceholder;
                }}
              />
              <OneLineText weight="600">{artist_name}</OneLineText>
            </div>
          </div>
          <ButtonRect
            primary
            onClick={handleOpenDirectTicket}
            style={{ padding: "12px 16px", gap: "8px" }}
          >
            <TwoTicketIcon stroke="white" height={16} width={16} />
            <BodyText weight="600" style={{ textWrap: "nowrap" }}>
              $20 - $40
            </BodyText>
          </ButtonRect>
        </TicketContainer>
      )}

      {tickets &&
        tickets[0] != null &&
        tickets.map((item, index) => {
          if (!item) return;
          // Extract the key and value from each object
          const [name, url] = Object.entries(item)[0];
          const ticketName = name.charAt(0).toUpperCase() + name.slice(1);
          const ticketSuffix = getTicketSuffix(ticketName?.toUpperCase());
          const ticketUrl = `${url}${ticketSuffix}`;

          return (
            <TicketContainer key={index}>
              <BodyText>{ticketName}</BodyText>
              <TicketButton
                event={event}
                ticketType={ticketName}
                ticketUrl={ticketUrl}
              />
            </TicketContainer>
          );
        })}
    </Container>
  );
}

EventTickets.propTypes = {
  event: PropTypes.object,
  contract: PropTypes.object,
};
