import { useEffect, useState } from "react";
import { Container, Content, Header } from "./AnimatedDropdown-Styles";
import PropTypes from "prop-types";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { theme } from "../../../utils/theme";

const { colors } = theme;

export default function AnimatedDropdown({
  header,
  content,
  bg,
  maxHeight,
  isHeaderCenter,
  noArrow,
  isClosed,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isClosed) setIsOpen(false);
  }, [isClosed]);

  const toggleSummary = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container bg={bg}>
      <Header onClick={toggleSummary}>
        {isHeaderCenter && <div />}
        {header}
        {!noArrow &&
          (isOpen ? (
            <CaretUp color={colors.grey} weight="bold" />
          ) : (
            <CaretDown color={colors.grey} weight="bold" />
          ))}
      </Header>
      {/* Animated content container */}
      <Content isOpen={isOpen} maxHeight={maxHeight}>
        {content}
      </Content>
    </Container>
  );
}

AnimatedDropdown.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node,
  bg: PropTypes.string,
  maxHeight: PropTypes.string,
  isHeaderCenter: PropTypes.bool,
  noArrow: PropTypes.bool,
  isClosed: PropTypes.bool,
};
