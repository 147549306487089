import { MINT_ERROR_MESSAGES } from "../constants/constants";

const {
  PRIO_ZERO,
  PENDING,
  HIGH_GAS,
  ALREADY_CLAIMED,
  FAILED_UPLOAD,
  TIMEOUT,
  NO_PRIMARY_WALLET,
  PRIMARY_WALLET_NOT_CONNECTED,
} = MINT_ERROR_MESSAGES;

export const handleMintErrorMessages = (error) => {
  switch (error) {
    case PRIO_ZERO.DETAILS:
      return PRIO_ZERO.MESSAGE;
    case PENDING.DETAILS:
      return PENDING.MESSAGE;
    case TIMEOUT.DETAILS:
      return TIMEOUT.MESSAGE;
    case HIGH_GAS.DETAILS:
      return PRIO_ZERO.MESSAGE;
    case ALREADY_CLAIMED.DETAILS:
      return ALREADY_CLAIMED.MESSAGE;
    case PRIMARY_WALLET_NOT_CONNECTED.DETAILS:
      return PRIMARY_WALLET_NOT_CONNECTED.MESSAGE;
    case NO_PRIMARY_WALLET.DETAILS:
      return NO_PRIMARY_WALLET.MESSAGE;
    case FAILED_UPLOAD.DETAILS:
      return FAILED_UPLOAD.MESSAGE;
    default:
      return "Something went wrong. Please try again later.";
  }
};
