import styled from "styled-components";
import { BodyText, Section } from "../../GlobalStyles";

const ProfileSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
`;

export default function EventsError() {
  return (
    <ProfileSection>
      <BodyText>
        Woops! Looks like an error occurred while fetching the nearest events.
      </BodyText>
    </ProfileSection>
  );
}
