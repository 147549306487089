import styled from "styled-components";
import { BottomNavBarHeight } from "../../../constants/constants";
import { Link } from "react-router-dom";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100svh - ${BottomNavBarHeight});
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 16px 20px;
  z-index: 99;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.3);
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
`;

export const SetlistLink = styled(Link)`
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-size: ${({ theme }) => theme.fontSizes.mobile.footer};
  opacity: 0.6;
  text-decoration: underline !important;
`;

export const MomentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const SetlistContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  gap: 12px;
`;

export const SetlistContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const SongContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-between;
  gap: 16px;
  width: 100%;
`;
