import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 16px;
  padding: 0px 20px 16px;
`;

export const ProfileStats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const ProfileStatsText = styled(ProfileStats)`
  gap: 4px;
  align-items: center;
`;

export const ProfileBottomButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
