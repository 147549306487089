import {
  BodyText,
  ButtonRectSm,
  FooterText,
  OneLineText,
} from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import EmptyState from "../../EmptyState";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import {
  Container,
  HeaderContainer,
  MomentInfoContainer,
  SetlistContainer,
  SetlistContentContainer,
  SetlistLink,
  SongContainer,
} from "./SetlistModal-Styles";
import { useSetlistIdQuery, useSetlistSearchQuery } from "./setlistQueries";
import PropTypes from "prop-types";
import MusicIcon from "../../../assets/svg/music.svg?react";
import SpotifyIcon from "../../../assets/svg/spotify.svg?react";
import BackButton from "../../BackButton";
import { useMemo } from "react";
import {
  EventDetailsContainer,
  EventImage,
  EventTextContainer,
} from "../../CheckIn/CheckInToast/CheckInToast-Styles";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import _moment from "moment";
import { Link } from "react-router-dom";

const { colors } = theme;
export default function SetlistModal({ moment, isMomentsFeed, handleClose }) {
  const {
    performance_id: performanceId,
    performance_artist: artistName,
    headline_artist: headlineArtist,
    venue_name: venueName,
    country,
    event_date: date,
    event_image: eventImage,
    fan_photo_urls: fanPhotoUrls,
  } = moment || {};

  const artist = artistName || headlineArtist;
  const isVideo = fanPhotoUrls?.some((url) => url.includes(".mp4"));

  const { data, isLoading } = useSetlistIdQuery({
    performanceId,
    date,
  });

  const { data: searchData, isLoading: searchLoading } = useSetlistSearchQuery({
    artistName: artist,
    venueName,
    date,
    idQueryResult: data,
    idQueryLoading: isLoading,
  });

  // Filter out setlists that do not song name
  const filteredSetlist = useMemo(() => {
    const setlist = data?.sets?.set || searchData?.sets?.set || [];

    return setlist
      .map((set) => {
        if (set.song) {
          set.song = set.song.filter(
            (song) => song.name && song.name.trim() !== ""
          );
        }
        return set;
      })
      .filter((set) => set.song && set.song.length > 0);
  }, [data, searchData]);

  const setlistUrl = data?.url || searchData?.url;

  const MomentDetailsComponent = () => {
    return (
      <EventDetailsContainer
        style={{
          padding: 0,
        }}
      >
        {isVideo ? (
          <EventImage
            as="video"
            src={`${fanPhotoUrls[0]}#t=0`}
            muted
            loop
            autoPlay
            playsInline
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            onLoadedMetadata={(e) => {
              e.target.pause();
            }}
            style={{
              pointerEvents: "none",
            }}
          />
        ) : (
          <EventImage
            src={
              fanPhotoUrls?.length > 0
                ? fanPhotoUrls[0]
                : eventImage ?? GlassmorphPlaceholder
            }
            alt={artist}
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          />
        )}
        <EventTextContainer>
          <OneLineText weight="600" style={{ marginBottom: "4px" }}>
            {artist}
          </OneLineText>
          <OneLineText color={colors.grey} size={theme.fontSizes.mobile.footer}>
            {venueName}, {country}
          </OneLineText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FooterText color={colors.green}>
              {_moment(date).utc().format("ddd DD MMM YYYY")}{" "}
            </FooterText>
          </div>
        </EventTextContainer>
      </EventDetailsContainer>
    );
  };

  if (isLoading || searchLoading) {
    return (
      <Container isMomentsFeed={isMomentsFeed}>
        <HeaderContainer>
          <div style={{ flex: 1 }}>
            <BackButton onClick={handleClose} />
          </div>
          <MomentInfoContainer>
            <MomentDetailsComponent />
            <div>
              <FooterText style={{ opacity: 0.6 }}>Powered by</FooterText>{" "}
              <FooterText style={{ textDecoration: "underline", opacity: 0.6 }}>
                Setlist.fm
              </FooterText>
            </div>
          </MomentInfoContainer>
          <div style={{ flex: 1 }} />
        </HeaderContainer>
        <SetlistContentContainer>
          {Array.from({ length: 4 }, (_, index) => (
            <SetlistContainer
              style={{
                gap: "16px",
                padding: 0,
              }}
              key={index}
            >
              <SkeletonElement width="30%" />
              <SetlistContainer
                style={{
                  padding: 0,
                  paddingLeft: "12px",
                  gap: "12px",
                }}
              >
                {Array.from({ length: 4 }, (_, index) => (
                  <SkeletonElement key={index} width="80%" />
                ))}
              </SetlistContainer>
            </SetlistContainer>
          ))}
        </SetlistContentContainer>
      </Container>
    );
  }

  return (
    <Container isMomentsFeed={isMomentsFeed}>
      <HeaderContainer>
        <div style={{ flex: 1 }}>
          <BackButton onClick={handleClose} />
        </div>
        <MomentInfoContainer>
          <MomentDetailsComponent />
          <div>
            <FooterText style={{ opacity: 0.6 }}>Powered by</FooterText>{" "}
            {setlistUrl ? (
              <SetlistLink to={setlistUrl} target="_blank">
                Setlist.fm
              </SetlistLink>
            ) : (
              <FooterText style={{ textDecoration: "underline", opacity: 0.6 }}>
                Setlist.fm
              </FooterText>
            )}
          </div>
        </MomentInfoContainer>
        <div style={{ flex: 1 }} />
      </HeaderContainer>
      {!filteredSetlist?.length && (
        <EmptyState
          icon={<MusicIcon height={24} width={24} stroke="white" />}
          title="Be The First To Add The Setlist"
          description={
            <BodyText>
              Add the setlist yourself and it’ll appear here after 48 hours!
            </BodyText>
          }
          button={
            <Link to={"https://www.setlist.fm/edit"} target="_blank">
              <ButtonRectSm
                style={{
                  background: theme.colors.glass,
                  border: "none",
                  marginTop: "8px",
                  minHeight: "32px",
                }}
              >
                <MusicIcon stroke={theme.colors.white} height={14} width={14} />
                <FooterText weight="600">Go to Setlist.fm</FooterText>
              </ButtonRectSm>
            </Link>
          }
          style={{
            padding: "40% 16px 20px",
          }}
        />
      )}
      {filteredSetlist && (
        <SetlistContentContainer>
          {filteredSetlist.map((set, index) => (
            <SetlistContainer
              style={{
                gap: "16px",
                padding: 0,
              }}
              key={index}
            >
              {set?.name && (
                <BodyText style={{ textTransform: "capitalize" }} weight="600">
                  {set.name}
                </BodyText>
              )}
              {set?.song && (
                <SetlistContainer
                  style={{
                    padding: 0,
                    paddingLeft: set?.name && "12px",
                    gap: "16px",
                  }}
                >
                  {set.song.map(
                    (song, index) =>
                      song.name && (
                        <SongContainer key={index}>
                          <SongContainer
                            style={{ justifyContent: "flex-start" }}
                          >
                            {/* Add 0 at the beginning if index is less than 10 */}
                            <FooterText
                              color={colors.grey}
                              style={{
                                width: "16px",
                              }}
                            >
                              {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            </FooterText>
                            <BodyText>{song.name}</BodyText>
                          </SongContainer>
                          <Link
                            to={`https://open.spotify.com/search/artist:${artist} track:${song.name}`}
                            target="_blank"
                          >
                            <SpotifyIcon
                              height={20}
                              width={20}
                              fill={colors.white}
                            />
                          </Link>
                        </SongContainer>
                      )
                  )}
                </SetlistContainer>
              )}
            </SetlistContainer>
          ))}
        </SetlistContentContainer>
      )}
    </Container>
  );
}

SetlistModal.propTypes = {
  moment: PropTypes.object,
  performanceId: PropTypes.string,
  artistName: PropTypes.string,
  venueName: PropTypes.string,
  date: PropTypes.string,
  isMomentsFeed: PropTypes.bool,
  handleClose: PropTypes.func,
};
