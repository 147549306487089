import PropTypes from "prop-types";
import { ArtistIcon, Container } from "./ArtistIconWithName-Styles";
import { ArtistIconPlaceholder } from "../ArtistItem/ArtistItem-Styles";
import Mic from "../../../assets/svg/mic.svg?react";
import { theme } from "../../../utils/theme";
import { OneLineText } from "../ArtistEventDateBox/ArtistEventDateBox-Styles";
import { Link } from "react-router-dom";

export default function ArtistIconWithName({ artist }) {
  const { image, name, id } = artist || {};

  return (
    <Container as={id ? Link : "div"} to={`/artists/${id}`}>
      <OneLineText size={theme.fontSizes.mobile.subheader} weight="600">
        {name}
      </OneLineText>
      {image ? (
        <ArtistIcon src={image} alt={name} />
      ) : (
        <ArtistIconPlaceholder
          as="div"
          style={{
            width: "20px",
            height: "20px",
            minWidth: "20px",
            minHeight: "20px",
          }}
        >
          <Mic stroke={theme.colors.white} height={8} width={8} />
        </ArtistIconPlaceholder>
      )}
    </Container>
  );
}

ArtistIconWithName.propTypes = {
  artist: PropTypes.object,
};
