import { create } from "zustand";
import { MAX_VIDEO_DURATION } from "../../constants/constants";

export const useVideoEditorStore = create((set) => ({
  // Retro flow state
  isOpen: false,
  setIsOpen: (value) => set(() => ({ isOpen: value })),
  video: null,
  setVideo: (value) => set(() => ({ video: value })),
  outputVideo: null,
  setOutputVideo: (value) => set(() => ({ outputVideo: value })),
  videoToUpload: null,
  setVideoToUpload: (value) => set(() => ({ videoToUpload: value })),
  startTime: 0,
  setStartTime: (value) => set(() => ({ startTime: value })),
  endTime: MAX_VIDEO_DURATION,
  setEndTime: (value) => set(() => ({ endTime: value })),
  // Loading state
  isLoading: false,
  setIsLoading: (value) => set(() => ({ isLoading: value })),
  isVideoProcessing: false,
  setIsVideoProcessing: (value) => set(() => ({ isVideoProcessing: value })),
  isVideoUploading: false,
  setIsVideoUploading: (value) => set(() => ({ isVideoUploading: value })),
  videoDuration: 0,
  setVideoDuration: (value) => set(() => ({ videoDuration: value })),
  outputVideoDuration: 0,
  setOutputVideoDuration: (value) =>
    set(() => ({ outputVideoDuration: value })),
  marks: [],
  setMarks: (value) => set(() => ({ marks: value })),
  thumbnails: [],
  setThumbnails: (value) => set(() => ({ thumbnails: value })),
  // Success state
  isSuccess: false,
  setIsSuccess: (value) => set(() => ({ isSuccess: value })),
  // Error state
  isError: false,
  setIsError: (value) => set(() => ({ isError: value })),
  isNotAllowed: false,
  setIsNotAllowed: (value) => set(() => ({ isNotAllowed: value })),
  // Reset state
  resetKeepOpen: () =>
    set(() => ({
      video: null,
      outputVideo: null,
      videoToUpload: null,
      videoDuration: 0,
      outputVideoDuration: 0,
      startTime: 0,
      endTime: MAX_VIDEO_DURATION,
      isVideoProcessing: false,
      isVideoUploading: false,
      marks: [],
      thumbnails: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      isNotAllowed: false,
    })),
  reset: () =>
    set(() => ({
      isOpen: false,
      video: null,
      outputVideo: null,
      videoToUpload: null,
      videoDuration: 0,
      outputVideoDuration: 0,
      startTime: 0,
      endTime: MAX_VIDEO_DURATION,
      isVideoProcessing: false,
      isVideoUploading: false,
      marks: [],
      thumbnails: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      isNotAllowed: false,
    })),
}));
