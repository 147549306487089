import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundGradient};
  height: 275px;
  min-height: 275px;
  max-height: 275px;
  min-width: calc(100vw - 40px);
  width: calc(100vw - 40px);
  max-width: calc(100vw - 40px);
  border-radius: 8px;
  padding: 16px;
  gap: 24px;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const EventName = styled(BodyText)`
  /* Make it only 1 line and ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MomentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  flex: 1;
`;

export const RemainingTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  white-space: nowrap;
`;
