import PropTypes from "prop-types";
import {
  QuickSearchItemBg,
  QuickSearchItemContainer,
  QuickSearchItemText,
} from "./QuickSearchItem-Styles";
import { Link } from "react-router-dom";

export default function QuickSearchItem({ text, filter, bg }) {
  return (
    <QuickSearchItemContainer as={Link} to={`/search?${filter}`}>
      <QuickSearchItemBg bg={bg} />
      <QuickSearchItemText>{text}</QuickSearchItemText>
    </QuickSearchItemContainer>
  );
}

QuickSearchItem.propTypes = {
  text: PropTypes.string,
  filter: PropTypes.string,
  bg: PropTypes.string,
};
