import QRCode from "react-qr-code";
import { styled } from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex: 0 0 auto;
  border-radius: 8px;
`;

function MomentifyQrCode({ size, value }) {
  return (
    <Container size={size}>
      <QRCode size={size} value={value} />
    </Container>
  );
}

MomentifyQrCode.propTypes = {
  size: PropTypes.number,
  value: PropTypes.string,
};

export default MomentifyQrCode;
