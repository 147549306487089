export const addMoment = async ({ checkInId, eventId, userId, imageUrl }) => {
  try {
    const moment = await fetch(`${import.meta.env.VITE_API_URL}/moments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ checkInId, eventId, userId, imageUrl }),
    });

    return moment.json();
  } catch (err) {
    console.warn(err);
    return false;
  }
};

export const addPerformanceMoment = async ({
  checkInId,
  eventId,
  performanceId,
  performanceArtist,
  performanceArtistId,
  userId,
  imageUrl,
  officialCheckinNumber,
  artistIds,
}) => {
  try {
    const moment = await fetch(
      `${import.meta.env.VITE_API_URL}/moments/performance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          checkInId,
          eventId,
          performanceId,
          performanceArtist,
          performanceArtistId,
          userId,
          imageUrl,
          officialCheckinNumber,
          artistIds,
        }),
      }
    );

    return moment.json();
  } catch (err) {
    console.warn(err);
    return false;
  }
};

export const getUserMomentByEvent = async ({ eventId, userId }) => {
  try {
    const moment = await fetch(
      `${import.meta.env.VITE_API_URL}/moments/event/${eventId}/user/${userId}`
    );

    return moment.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getMomentByMomentIdUserId = async ({
  momentId,
  ownerId,
  loggedInUserId,
}) => {
  try {
    const moment = await fetch(
      `${import.meta.env.VITE_API_URL}/moments/${momentId}/user/${ownerId}?loggedInUserId=${loggedInUserId}`
    );

    return moment.json();
  } catch (err) {
    console.error(err);
  }
};

export const uploadFanMomentPhoto = async (momentId, userId, photo) => {
  let photoUploaded = await fetch(
    `${import.meta.env.VITE_API_URL}/moment/${momentId}/user/${userId}/upload-fan-photo`,
    {
      method: "POST",
      body: photo,
    }
  ).catch((error) => {
    console.error(error);
    return false;
  });

  if (!photoUploaded || photoUploaded.status !== 200) {
    return false;
  }

  photoUploaded = await photoUploaded.json();

  if (!photoUploaded) {
    return false;
  }

  return photoUploaded;
};

export const deleteFanMomentPhoto = async (momentId, userId) => {
  try {
    await fetch(
      `${import.meta.env.VITE_API_URL}/moment/${momentId}/user/${userId}/delete-fan-photo?`,
      {
        method: "DELETE",
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getFanMomentPhotos = async (momentId, userId) => {
  try {
    const photos = await fetch(
      `${import.meta.env.VITE_API_URL}/moment/${momentId}/user/${userId}/fan-photos`
    );

    return photos.json();
  } catch (err) {
    console.error(err);
  }
};

export const getAllMoments = async (userId, limit, offset) => {
  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/moments?userId=${userId}&limit=${limit}&offset=${offset}`
    );

    return moments.json();
  } catch (err) {
    if (err.code === 20) return;
    console.error(err);
  }
};

export const setLikeMomentPhoto = async ({
  user_id,
  creator_id,
  moment_id,
  artist_id,
}) => {
  const payload = {
    action_type: "like",
    target_type: "moment.photo",
    target_id: moment_id,
    artist_id: artist_id,
    user_id: user_id,
    creator_id: creator_id,
  };
  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const removeLikeMomentPhoto = async ({
  user_id,
  creator_id,
  moment_id,
  artist_id,
  action_id,
}) => {
  const payload = {
    action_type: "like",
    target_type: "moment.photo",
    target_id: moment_id,
    artist_id: artist_id,
    user_id: user_id,
    creator_id: creator_id,
    action_id: action_id,
  };
  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return moments.json();
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getLikeMomentPhoto = async ({ user_id, moment_id, signal }) => {
  if (!user_id || !moment_id) return false;

  const action_type = "like";
  const target_type = "moment.photo";

  try {
    const moments = await fetch(
      `${import.meta.env.VITE_API_URL}/users/activity/${user_id}?action_type=${action_type}&target_type=${target_type}&target_id=${moment_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal,
      }
    );

    if (moments?.status >= 400) {
      return false;
    }

    return moments.json();
  } catch (err) {
    if (err.message == "signal is aborted without reason") return false;

    console.error(err);
    return false;
  }
};

export const uploadFanMomentNftImage = async (
  momentId,
  userId,
  currentPassId,
  photo
) => {
  let nftImageUploaded = await fetch(
    `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/${userId}/moment/${momentId}/upload/image/${currentPassId}`,
    {
      method: "POST",
      body: photo,
    }
  ).catch((error) => {
    console.error(error);
    return false;
  });

  if (!nftImageUploaded || nftImageUploaded.status !== 200) {
    return false;
  }

  nftImageUploaded = await nftImageUploaded.json();

  if (!nftImageUploaded) {
    return false;
  }

  return nftImageUploaded;
};

export const uploadFanMomentNftMetadata = async (
  momentId,
  userId,
  currentPassId,
  metadata
) => {
  let nftMetadataUploaded = await fetch(
    `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/${userId}/moment/${momentId}/upload/metadata/${currentPassId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(metadata),
    }
  ).catch((error) => {
    console.error(error);
    return false;
  });

  if (!nftMetadataUploaded || nftMetadataUploaded.status !== 200) {
    return false;
  }

  nftMetadataUploaded = await nftMetadataUploaded.json();

  if (!nftMetadataUploaded) {
    return false;
  }

  return nftMetadataUploaded;
};

/**
 * @param {number} userId
 * @param {Object} payload
 * @param {number} payload.userId
 * @param {string} payload.token_id
 * @param {number} payload.artist_id
 * @param {string} payload.user_name
 * @param {string} payload.artist_name
 * @param {string} payload.user_image_upload
 * @param {string} payload.moment_id
 * @param {string} payload.event_id
 * @param {string} payload.transaction_reference
 * @param {string} payload.minted_at
 * @param {string} payload.mint_status
 * @param {string} payload.failure_reason
 * @param {string} payload.metadata_upload
 * @returns {Promise<Object>}
 */
export const setFanMomentNft = async (userId, payload) => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/${userId}/moment/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result?.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * @param {number} userId
 * @param {Object} payload
 * @param {Date} payload.updated_at
 * @param {number} payload.userId
 * @param {string} payload.token_id
 * @param {number} payload.artist_id
 * @param {string} payload.user_name
 * @param {string} payload.artist_name
 * @param {string} payload.moment_id
 * @param {string} payload.event_id
 * @param {string} payload.transaction_reference
 * @param {string} payload.minted_at
 * @param {string} payload.mint_status
 * @param {string} payload.failure_reason
 * @returns {Promise<Object>}
 */
export const updateFanMomentNft = async (userId, passId, payload) => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/${userId}/moment/update/${passId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return err?.response?.data;
  }
};

export const getFanMomentNft = async (userId, momentId) => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/${userId}/moment/${momentId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return err?.response?.data;
  }
};

export const getAllFanMoments = async () => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/moments`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return err?.response?.data;
  }
};

export const getContractType = async () => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/fan/contract/Moment`
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return err?.response?.data;
  }
};

export const getTokenIdByTransaction = async (owner, contractAddress, txn) => {
  console.log("getTokenIdByTransaction params", {
    owner,
    contractAddress,
    txn,
  });

  if (!owner || !contractAddress || !txn) {
    console.error("Invalid parameters passed to getTokenIdByTransaction", {
      owner,
      contractAddress,
      txn,
    });
    return false;
  }

  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/onchain/listNFT`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          owner,
          contractAddress,
          txn,
        }),
      }
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result?.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const refreshMomentMetadata = async (tokenId, contractAddress) => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/onchain/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokenId,
          contractAddress,
        }),
      }
    );

    result = await result.json();

    if (!result?.success) {
      return false;
    }

    return result?.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addRetroMoment = async ({
  userId,
  eventId,
  eventDate,
  venueName,
  venueId,
  country,
  city,
  performanceId,
  performanceArtist,
  performanceArtistId,
}) => {
  try {
    let result = await fetch(`${import.meta.env.VITE_API_URL}/retro/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        eventId,
        performanceId,
        performanceArtist,
        performanceArtistId,
        eventDate,
        venueName,
        venueId,
        country,
        city,
      }),
    });

    if (!result.ok) {
      console.warn(`${result.status} ${result.statusText}`);
      return false;
    }

    result = await result.json();

    if (!result.success) {
      console.warn(result.message);
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getRetroMoment = async (userId, performanceId) => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/retro/${performanceId}`
    );

    if (!result.ok) {
      console.warn(`${result.status} ${result.statusText}`);
      return false;
    }

    result = await result.json();

    if (!result.success) {
      console.warn(result.message);
      return false;
    }

    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const deleteMoment = async (userId, momentId) => {
  try {
    let result = await fetch(
      `${import.meta.env.VITE_API_URL}/moments/${momentId}/users/${userId}`,
      {
        method: "DELETE",
      }
    );

    if (!result.ok) {
      console.warn(`${result.status} ${result.statusText}`);
      throw new Error(result.statusText);
    }

    result = await result.json();

    if (!result.success) {
      throw new Error("Failed to delete moment");
    }

    return { success: true };
  } catch (err) {
    console.error(err);
  }
};

export const uploadFanMomentVideo = async (momentId, userId, video) => {
  const formData = new FormData();
  formData.append("video", video, "output.mp4");

  let videoUploaded = await fetch(
    `${import.meta.env.VITE_API_URL}/moment/${momentId}/user/${userId}/upload-fan-video`,
    {
      method: "POST",
      body: formData,
    }
  ).catch((error) => {
    console.error(error);
    return false;
  });

  if (!videoUploaded || videoUploaded.status !== 200) {
    return false;
  }

  videoUploaded = await videoUploaded.json();

  if (!videoUploaded) {
    return false;
  }

  return videoUploaded;
};
