import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export const ArtistBoxSkeleton = () => {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 8,
        minHeight: 180,
        maxHeight: 180,
        maxWidth: 100,
        minWidth: 100,
      }}
    >
      {/* Image */}
      <div
        style={{
          position: "relative",
        }}
      >
        <SkeletonElement
          style={{ margin: "auto" }}
          width={"88px"}
          height={"88px"}
          radius="50%"
        />
      </div>
      {/* Name */}

      <SkeletonElement height={"20px"} />
      {/* Genres */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 8,
          minHeight: 16, // Adjust this value to fit the design requirements
          maxHeight: 32, // Adjust this value to fit the design requirements
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <SkeletonElement height={"20px"} />
      </div>
    </div>
  );
};
