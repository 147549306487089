import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Image = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ArtistIconPlaceholder = styled(Image)`
  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.colors.grayGradientBg};
`;
