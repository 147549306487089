import { getCreatedAtAndCreatedAtOffset } from "../utils/utils";

export const sendReport = async ({
  user_id,
  type,
  entity_type,
  entity_id,
  reason,
  reference_url,
}) => {
  try {
    const { created_at, created_at_offset } = getCreatedAtAndCreatedAtOffset();

    const result = await fetch(`${import.meta.env.VITE_API_URL}/users/report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id,
        type,
        entity_type,
        entity_id,
        reason,
        reference_url,
        created_at,
        created_at_offset,
      }),
    });

    if (!result.ok) {
      console.error(result.error);
      return { success: false, error: "Failed to send report" };
    }

    const res = await result.json();

    if (!res.success) {
      console.error(res.error);
      return { success: false, error: "Failed to send report" };
    }

    return { success: true };
  } catch (err) {
    console.error(err);
    return { success: false, error: "Failed to send report" };
  }
};
