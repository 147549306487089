import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import { AddIcon, AddMomentButton } from "./AddToMomentsButton-Styles";
import PlusIcon from "../../../assets/svg/plus.svg?react";
import { theme } from "../../../utils/theme";
import { useRetroFlowStore } from "../../../routes/Search/hooks/searchStore";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import { format } from "date-fns";

export default function AddToMomentsButton({ performance, claimedMoment }) {
  const { isOpen, setIsOpen, setPerformance } = useRetroFlowStore();
  const { login, loggedInUser } = useMomentifyLogin();
  const isFutureEvent =
    new Date(performance?.date).getTime() > new Date().getTime();

  const handleButtonClick = () => {
    if (!performance || !!claimedMoment || isFutureEvent) return;

    if (isOpen) return;

    if (!loggedInUser) return login();

    setIsOpen(true);

    setPerformance(performance);
  };

  return (
    <AddMomentButton
      onClick={handleButtonClick}
      disabled={!!claimedMoment || isFutureEvent}
    >
      {!claimedMoment && !isFutureEvent ? (
        <>
          <AddIcon as="div">
            <PlusIcon
              height={8}
              width={8}
              stroke={theme.colors.white}
              strokeWidth={2}
            />
          </AddIcon>
          <FooterText weight="600">Add to Moments</FooterText>
        </>
      ) : isFutureEvent ? (
        <FooterText>This is an upcoming event</FooterText>
      ) : claimedMoment.type === "retro" ? (
        <FooterText>
          Retro-Added at{" "}
          <FooterText color={theme.colors.green}>
            {format(new Date(claimedMoment.created_at), "eee d MMM")}
          </FooterText>
        </FooterText>
      ) : (
        <FooterText>
          Checked in at {format(new Date(claimedMoment.created_at), "h:mmaaa")}
        </FooterText>
      )}
    </AddMomentButton>
  );
}

AddToMomentsButton.propTypes = {
  performance: PropTypes.object,
  claimedMoment: PropTypes.object,
};
