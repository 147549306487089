import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { ScrollableEventsContainer } from "../../../routes/Events/Events-Styles";
import { pad } from "lodash";

export const ThinSkeletonElements = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <SkeletonElement
          backgroundColor="#ffffff14"
          width="280px"
          height="28px"
          radius="16px"
        />
        <SkeletonElement
          backgroundColor="#ffffff14"
          width="240px"
          height="20px"
          radius="16px"
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          overflow: "scroll",
          marginRight: "-20px",
          paddingRight: 20,
        }}
      >
        {Array.from({ length: 12 }).map((_, index) => (
          <div
            style={{
              width: 118,
              height: 205,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center !important",
              gap: 4,
              position: "relative",
            }}
            key={index}
          >
            <SkeletonElement
              width="118px"
              height="140px"
              radius="16px"
              backgroundColor="#ffffff14"
              style={{
                margin: "0px 6px",
              }}
            />
            <SkeletonElement
              width="100%"
              height="18px"
              radius="16px"
              backgroundColor="#ffffff14"
            />
            <SkeletonElement
              width="80px"
              height="16px"
              radius="16px"
              backgroundColor="#ffffff14"
            />
            <SkeletonElement
              width="76px"
              height="16px"
              radius="16px"
              backgroundColor="#ffffff14"
            />
          </div>
        ))}
      </div>
    </>
  );
};
