import styled from "styled-components";
import { BodyText, Section } from "../../GlobalStyles";

export const VenueSection = styled(Section)`
  display: flex;
  flex-direction: column;
`;

export const VenueImage = styled.div`
  min-height: 268px;
  width: 100%;
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 2;
  cursor: pointer;
`;

export const VenueDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 16px;
`;

export const VenueHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const VenueNameAndAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const VenueEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SeeAllEvents = styled(BodyText)`
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
`;
