import { PrivyProvider } from "@privy-io/react-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import { WagmiProvider } from "@privy-io/wagmi";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GlobalStyles } from "./GlobalStyles";
import router from "./Router";
import logo from "./assets/images/mom-cube-logo.png";
import { Interim } from "./components/Interim";
import AccountKitProvider from "./contexts/AccountKitContext";
import LocationContextProvider from "./contexts/LocationContext";
import GlobalModalProvider from "./contexts/ModalContext";
import MomentUploadProvider from "./contexts/MomentUploadContext";
import NotificationProvider from "./contexts/NotificationContext";
import UserProvider from "./contexts/UserContext";
import { theme } from "./utils/theme";
import { isProd, isStaging } from "./utils/utils";
import { wagmiConfig } from "./utils/wagmi";
import { useEffect, useState } from "react";
import { getIpInfo } from "./api/user";
import en from "i18n-iso-countries/langs/en.json";
import countries from "i18n-iso-countries";
import ErrorBoundary from "./ErrorBoundary";

// Adding global styles to overwrite the modal container's border
const SheetOverwrite = createGlobalStyle`
  #privy-modal-content img {
    background: none !important;
  }

  #privy-modal-content select {
    margin: 13px 0px 13px 13px !important;
    width: 95px !important;
  }

  #phone-number-input {
    padding-left: 112px;
  }
`;

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 2,
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Function component for rendering the App.
 *
 * @return {React.JSX.Element} The rendered App component
 */
export default function App() {
  const [countryCode, setCountryCode] = useState("GB"); // Default to 'US' in case fetching fails

  useEffect(() => {
    countries.registerLocale(en);
    const fetchCountryCode = async () => {
      try {
        const ipinfo = await getIpInfo();
        const CC = ipinfo?.country ?? "GB";
        const city = ipinfo?.city ?? null;
        setCountryCode(ipinfo?.country ?? "GB"); // Fallback to 'US' if country code is not available

        const countryName = countries.getName(CC, "en") ?? null;
        localStorage.setItem("countryName", countryName);
        localStorage.setItem("cityName", city);
      } catch (error) {
        console.error("Error fetching country code:", error);
      }
    };

    fetchCountryCode();
  }, []);

  if (window.innerWidth >= 550) {
    // Render GlobalStyles and NonMobileAccess for larger screens
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Interim />
      </ThemeProvider>
    );
  }

  // Render the main App component for smaller screens
  return (
    <ErrorBoundary>
      <PrivyProvider
        appId={import.meta.env.VITE_PRIVY_APP_ID} // Use environment variable for Privy app ID
        config={{
          loginMethods: isProd ? ["sms"] : ["google", "discord", "sms"], // Set login methods based on environment
          appearance: {
            theme: theme.colors.background, // Set theme color
            accentColor: theme.colors.green, // Set accent color
            logo: logo,
            showWalletLoginFirst: false, // Set wallet login preference
          },
          intl: {
            defaultCountry: countryCode ?? "GB", // Set default country for internationalization
          },
          embeddedWallets: {
            noPromptOnSignature: true,
          },
        }}
        style={{
          ":img": {
            background: "none",
          },
        }}
      >
        <SheetOverwrite />
        <UserProvider>
          <AccountKitProvider>
            <NotificationProvider>
              <ThemeProvider theme={theme}>
                <GlobalModalProvider>
                  <LocationContextProvider>
                    <MomentUploadProvider>
                      <QueryClientProvider client={queryClient}>
                        <WagmiProvider config={wagmiConfig}>
                          <RouterProvider router={router} />
                          {!isProd && !isStaging && (
                            <ReactQueryDevtools initialIsOpen="false" />
                          )}
                        </WagmiProvider>
                      </QueryClientProvider>
                    </MomentUploadProvider>
                  </LocationContextProvider>
                </GlobalModalProvider>
              </ThemeProvider>
            </NotificationProvider>
          </AccountKitProvider>
        </UserProvider>
      </PrivyProvider>
    </ErrorBoundary>
  );
}
