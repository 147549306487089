import { useEffect, useState } from "react";
import inviteIcon from "../../../public/svgs/invite-icon.svg";
import { getActionByUserIdAndType } from "../../api/profile";
import { Image } from "../../components/Performance/PerformancePageHeader.jsx/PerformancePageHeader-Styles";
import { BACKGROUND_IMAGES } from "../../constants/constants";
import { BodyText, Button, HeaderL } from "../../GlobalStyles";
import useAuth from "../../hooks/useAuth";
import { handleInviteFriend } from "../../utils/utils";

export const EmptyEvents = () => {
  const [inviteLink, setInviteLink] = useState(null);
  const { loggedInUser } = useAuth();

  useEffect(() => {
    if (!inviteLink) {
      getActionByUserIdAndType({
        user_id: loggedInUser?.id,
        action_type: "invite_friend",
        target_type: "user",
      }).then((res) => {
        setInviteLink(res?.data);
      });
    }
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "30px",
        gap: "24px",
        background: `url(${BACKGROUND_IMAGES.LOGIN})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <HeaderL>PHOTOS FROM EVENTS YOU ATTEND WILL APPEAR HERE!</HeaderL>
      <BodyText style={{ opacity: 0.6 }}>
        Invite others to the app to see their photos!
      </BodyText>
      <Button
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(17, 17, 31, 0.2)",
          backgroundBlendMode: "multiply",
          mixBlendMode: "normal",
          border: "1px solid rgba(255, 255, 255, 0.12)",
          borderRadius: "10px",
          width: "fit-content",
          height: "fit-content",
          gap: "8px",
          padding: "8px 25px",
        }}
        onClick={() => {
          handleInviteFriend(inviteLink, loggedInUser, setInviteLink);
        }}
      >
        <Image
          style={{
            borderRadius: 0,
            width: 15,
            height: 15,
            background: "none",
            position: "relative",
            bottom: 1,
          }}
          src={inviteIcon}
        />

        <BodyText>Invite friend</BodyText>
      </Button>
      <BodyText style={{ color: "#6EDBBB" }}>+100xp</BodyText>
    </div>
  );
};
