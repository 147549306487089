import PropTypes from "prop-types";
import { useState } from "react";
import { toast } from "react-toastify";
import { BUTTON_SIZES } from "../../constants/constants";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import { useFollowMutate } from "../../routes/Profile/hooks/profileQueries";
import FollowButton from "../FollowButton";
import { useUserStore } from "../../routes/Profile/store/userStore";

export default function FollowUserButton({ user }) {
  const { loggedInUser, login } = useMomentifyLogin();

  const { addUserFollowedUsers, removeUserFollowedUsers, userFollowedUsers } =
    useUserStore();

  const [followed, setFollowed] = useState(
    userFollowedUsers?.some((use) => use.id == user.id) ?? false
  );

  const followMutation = useFollowMutate({
    loggedInUserId: loggedInUser?.id,
    userId: user?.id,
  });

  const handleClick = async (e) => {
    try {
      e.preventDefault();

      if (!loggedInUser) return login();

      if (loggedInUser.id === user.id)
        return toast.error("You can't follow yourself");

      followMutation.mutate({ isFollowing: followed });
      if (followed) {
        removeUserFollowedUsers(user);
      } else addUserFollowedUsers(user);

      setFollowed(!followed);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FollowButton
      isFollowing={followed}
      handleClick={handleClick}
      size={BUTTON_SIZES.SMALL}
    />
  );
}

FollowUserButton.propTypes = {
  user: PropTypes.object,
  isFollowing: PropTypes.bool,
};
