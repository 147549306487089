export const getIpInfo = async () => {
    try {
        const response = await fetch('https://ipinfo.io/json', {
            method: "GET",
            headers: {
              Authorization: `Bearer ${import.meta.env.VITE_IPINFO_TOKEN}`,
              "Content-Type": "application/json",
            },

          });
        if (response.ok) {
            const data = await response.json();
            return data;
        }

      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };

  