import moment from "moment-timezone";
import {
  getIfUserFollowsFan,
  getUserById,
  getUserLatestMessages,
  getUserNotifications,
  getUserStats,
} from "../../api/profile";
import {
  isCinnyAuthenticated,
  isCinnySupported,
} from "../../utils/cinny-utils";
import {
  createOrUpdatePostEventRoomHandler,
  createOrUpdatePreEventRoomHandler,
  getRoom,
  roomURLHandler,
  roomURLHandlerMoment,
} from "../../utils/matrix-utils";

export const profileLoader = async ({ params }, signal) => {
  if (!params.id) return null;

  const { id: userId } = params;

  try {
    const loggedInUserID = localStorage.getItem("loggedInUserID");

    const isOwnProfile = loggedInUserID == userId; // TODO: Need different authentication dito. Use privy siguro para mas secure

    let fetchedUser = await getUserStats(userId, signal);

    if (!fetchedUser || fetchedUser?.length == 0) return;

    fetchedUser = fetchedUser[0];

    const isUserFollowed =
      loggedInUserID !== userId
        ? await getIfUserFollowsFan(loggedInUserID, userId, signal).catch(
            (err) => {
              if (err.code === 20) return;
              console.error(err);
            }
          )
        : false;

    const messages = await getUserLatestMessages(userId, signal);
    const notifications = await getUserNotifications(userId, signal);

    const moments = await fetchMoments(userId, isOwnProfile, signal);

    const events = await fetchEvents(userId, isOwnProfile, signal);

    return {
      fetchedUser,
      isUserFollowed: isUserFollowed?.success ? isUserFollowed?.data : false,
      moments,
      events,
      notifications: notifications?.data ?? [],
      messages: messages?.data ?? [],
    };
  } catch (err) {
    const fetchedUser = await getUserById(userId);
    if (!fetchedUser) return null;

    return { fetchedUser };
  }
};

export const fetchMoments = async (userId, isOwnProfile, signal) => {
  if (!userId) return false;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/profile/${userId}/moments`,
    {
      signal,
    }
  ).catch((err) => {
    if (err.code === 20) return false;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return;
  }

  let moments = await response.json().catch((err) => {
    console.error(err);
  });

  if (!isOwnProfile) return moments;

  if (isCinnySupported() && isCinnyAuthenticated()) {
    moments?.activeMoments.map(async (moment) => {
      const postEventRoom = await getRoom(moment.moments[0]?.room ?? null);
      if (!postEventRoom) {
        moment.room = await createOrUpdatePostEventRoomHandler(
          moment.moments[0]
        );
        return moment;
      }

      if (
        !postEventRoom.room?.room_address &&
        !moment?.moments[0]?.room?.room_address
      ) {
        await roomURLHandlerMoment(moment.moments[0]);
      }
      return moment;
    });
  }

  return moments;
};

export const fetchEvents = async (userId, isOwnProfile, signal) => {
  const localTime = moment().local().format("YYYY-MM-DD HH:mm:ss");
  const userTimezone = moment.tz.guess();
  const userTime = moment.tz(localTime, "YYYY-MM-DD HH:mm:ss", userTimezone).format("YYYY-MM-DD HH:mm:ss");

  const events = await fetch(
    `${import.meta.env.VITE_API_URL}/profile/${userId}/events?date_time_param=${userTime}`,
    {
      signal,
    }
  ).catch((err) => {
    if (err.code === 20) return false;
    console.error(err);
  });

  if (!events || events?.status != 200) {
    return;
  }

  const eventsJson = await events.json().catch((err) => {
    console.error(err);
  });

  if (
    eventsJson?.events_interested_details.length == 0 ||
    eventsJson?.events_interested_details[0] == null
  )
    return null;

  if (!isOwnProfile) return eventsJson?.events_interested_details;

  if (isCinnySupported() && isCinnyAuthenticated()) {
    eventsJson?.events_interested_details.map(async (event) => {
      const preEventRoom = await getRoom(event?.room);

      if (!preEventRoom) {
        event.room = await createOrUpdatePreEventRoomHandler(event);
      }

      return event;
    });
    eventsJson?.events_interested_details.map(async (event) => {
      return roomURLHandler(event);
    });
  }

  return eventsJson?.events_interested_details;
};
