import { useNavigate } from "react-router-dom";
import BackNoTail from "../assets/svg/back-no-tail.svg?react";
import { ButtonGhost } from "../GlobalStyles";
import PropTypes from "prop-types";

/**
 * Renders a back button component.
 *
 * @param {function} onClick - The function to be called when the button is clicked
 * @return {React.JSX.Element} The rendered back button component
 */
export default function BackButton({ onClick }) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <ButtonGhost
      onClick={onClick ?? handleOnClick}
      style={{
        opacity: 0.6,
        padding: "4px 13px 4px 0px",
        justifyContent: "flex-start",
      }}
    >
      <BackNoTail height={12} stroke="white" />
    </ButtonGhost>
  );
}

BackButton.propTypes = {
  onClick: PropTypes.func,
};
