import PropTypes from "prop-types";
import {
  BodyText,
  FooterText,
  InvisibleInputImage,
} from "../../../GlobalStyles";
import useMomentPhotoUpload from "../../../hooks/useMomentPhotoUpload";
import PhotoPlus from "../../../assets/svg/photo-plus.svg?react";
import VideoPlus from "../../../assets/svg/video-plus.svg?react";
import Countdown from "react-countdown";
import IconWithText from "../../IconWithText";
import Clock from "../../../assets/svg/clock.svg?react";
import { theme } from "../../../utils/theme";
import { BACKGROUND_IMAGES } from "../../../constants/constants";
import MomentDropdown from "../../Dropdown/MomentDropdown";
import {
  AddPhotoButton,
  BackgroundContainer,
  Container,
  SubtitleContainer,
  UploadCountdownContainer,
  UploadPhotoContainer,
} from "./MomentAddMedia-Styles";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useVideoEditorStore } from "../../VideoEditor/videoEditorStore";
import { RetroMomentPhoto } from "../MomentPhotos/MomentPhotos-Styles";
const { colors } = theme;
export default function MomentAddMedia({ moment }) {
  const { created_at, moment_id, user_id, type } = moment || {};
  const { loggedInUser } = useAuth();
  const remainingTime = new Date(created_at);
  remainingTime.setDate(remainingTime.getDate() + 2);
  const { handleMediaChange, loading } = useMomentPhotoUpload();
  const { isVideoUploading } = useVideoEditorStore();
  const hasEnoughXp = loggedInUser?.xp >= 10;
  const isRetro = type === "retro";

  const disabled = isVideoUploading || loading || !hasEnoughXp;

  const handleDisabledAddPhoto = () => {
    if (hasEnoughXp) return;

    toast.error("Not enough XP for this action!");
  };

  if (isRetro) {
    return (
      <BackgroundContainer>
        <Container>
          <RetroMomentPhoto src={BACKGROUND_IMAGES.SEARCH.WEEK} />
          <UploadPhotoContainer>
            <SubtitleContainer>
              <FooterText color={colors.grey}>
                Spend <FooterText color={colors.green}>5xp</FooterText> to add a
                video or photo to this Moment
              </FooterText>
            </SubtitleContainer>
            <AddPhotoButton disabled={disabled}>
              <VideoPlus height={14} width={14} stroke="white" />
              <BodyText weight="600">Add Video</BodyText>
              <BodyText color={colors.green}>-5xp</BodyText>
              <InvisibleInputImage
                type="file"
                accept="video/*"
                onChange={(e) => handleMediaChange(e, "video")}
                onClick={(e) => {
                  e.target.value = null;
                }}
                disabled={disabled}
              />
            </AddPhotoButton>
            <AddPhotoButton
              style={{
                opacity: disabled ? 0.6 : 1,
              }}
              onClick={handleDisabledAddPhoto}
            >
              <PhotoPlus height={14} width={14} stroke="white" />
              <BodyText weight="600">Add Photo</BodyText>
              <BodyText color={colors.green}>-5xp</BodyText>
              <InvisibleInputImage
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => {
                  handleMediaChange(e, "image");
                }}
                disabled={disabled}
                onClick={(e) => {
                  e.target.value = null;
                }}
              />
            </AddPhotoButton>
          </UploadPhotoContainer>
        </Container>
      </BackgroundContainer>
    );
  }

  return (
    <Countdown
      date={remainingTime}
      renderer={({ days, hours, minutes, completed }) => {
        // Convert days to hours
        hours += days * 24;

        if (completed) return <></>;

        return (
          <BackgroundContainer>
            <Container>
              <RetroMomentPhoto src={BACKGROUND_IMAGES.SEARCH.WEEK} />
              <UploadCountdownContainer>
                <IconWithText
                  icon={<Clock height={14} width={14} fill={colors.green} />}
                  text={`${hours}h ${minutes}m`}
                />
                <MomentDropdown momentId={moment_id} userId={user_id} />
              </UploadCountdownContainer>
              <UploadPhotoContainer>
                <SubtitleContainer>
                  <FooterText color={colors.grey}>
                    You have <strong>48 hours</strong> after an event to add a
                    video or photo to your Moment.
                  </FooterText>
                </SubtitleContainer>
                <AddPhotoButton disabled={disabled}>
                  <VideoPlus height={14} width={14} stroke="white" />
                  <BodyText weight="600">Add Video</BodyText>
                  <BodyText color={colors.green}>+50xp</BodyText>
                  <InvisibleInputImage
                    type="file"
                    accept="video/*"
                    onChange={(e) => handleMediaChange(e, "video")}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    disabled={disabled}
                  />
                </AddPhotoButton>
                <AddPhotoButton disabled={disabled}>
                  <PhotoPlus height={14} width={14} stroke="white" />
                  <BodyText weight="600">Add Photo</BodyText>
                  <BodyText color={colors.green}>+25xp</BodyText>
                  <InvisibleInputImage
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => handleMediaChange(e, "image")}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    disabled={disabled}
                  />
                </AddPhotoButton>
              </UploadPhotoContainer>
            </Container>
          </BackgroundContainer>
        );
      }}
    />
  );
}

MomentAddMedia.propTypes = {
  moment: PropTypes.object,
};
