import { BodyText } from "../../../GlobalStyles";
import useGlobalModal from "../../../hooks/useGlobalModal";
import CollectMomentModal from "../../Modal/CollectMomentModal";
import PropTypes from "prop-types";
import { differenceInHours } from "date-fns";
import YourMoment from "../../Modal/YourMomentModal";
import HandPlus from "../../../assets/svg/hand-plus.svg?react";
import { CollectButtonContainer, SmallButton } from "./CollectButton-Styles";

export default function CollectButton({ moment, type }) {
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const {
    fan_photo_urls,
    created_at,
    private: isPrivate,
    transaction_reference,
  } = moment;
  const within48Hours =
    differenceInHours(new Date(), new Date(created_at)) < 48;

  const handleSheetModal = () => {
    if (transaction_reference)
      setSheetModalContent(<YourMoment moment={moment} />);
    else {
      setSheetModalContent(
        <CollectMomentModal
          hasImage={fan_photo_urls?.length > 0}
          within48Hours={within48Hours}
          isPublic={isPrivate}
          moment={moment}
        />
      );
    }
    toggleSheetModal();
  };

  if (type === "small") {
    return (
      <SmallButton onClick={handleSheetModal}>
        <HandPlus height={18} width={18} stroke="black" />
      </SmallButton>
    );
  }

  return (
    <CollectButtonContainer onClick={handleSheetModal}>
      <BodyText color="white">
        {transaction_reference ? "View details" : "Collect"}
      </BodyText>
    </CollectButtonContainer>
  );
}

CollectButton.propTypes = {
  moment: PropTypes.object,
  type: PropTypes.string,
};
