import styled from "styled-components";

export const EventTodayParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 20px;
  justify-content: left;
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => (gap ? gap : "12px")};
  align-items: center;
  justify-content: left;
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "12px")};
  align-items: left;
  justify-content: center;
`;

export const BottomFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.glass};
  border-radius: 16px;
  padding: 16px;
`;

export const PinIcon = styled.img`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  object-fit: cover;
  background: none;
`;

export const ArtistImage = styled.img`
  width: 58px;
  height: 58px;
  border-radius: 10px;
  object-fit: cover;
  background: none;
`;

export const DistanceBox = styled.div`
  padding: 10px 12px;
  gap: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.glass};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

export const PinImage = styled.img`
  width: 12px;
  background: transparent;
`;
