import styled from "styled-components";
import PropTypes from "prop-types";
import { BodyText, Title } from "../../GlobalStyles";
import { EventItemTypes, FestivalEventType } from "../../constants/constants";
import EventItem from "../Events/EventItem";
import useAuth from "../../hooks/useAuth";
import { theme } from "../../utils/theme";
import { useState } from "react";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SeeAllEvents = styled(BodyText)`
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  width: fit-content;
`;

export default function FestivalEvents({ events, type }) {
  const title = type === FestivalEventType.TODAY ? "Today" : "Next up";
  const { loggedInUser } = useAuth();
  const [showAllEvents, setShowAllEvents] = useState(false);

  const displayedEvents = showAllEvents ? events : events.slice(0, 3);

  const handleSeeAllEvents = () => {
    setShowAllEvents(!showAllEvents);
  };

  return (
    <Container>
      <Title color={colors.grey}>{title}</Title>
      {displayedEvents.map((event, index) => (
        <EventItem
          key={index}
          event={event}
          type={EventItemTypes.FESTIVAL}
          isOwnProfile={loggedInUser && true}
        />
      ))}
      {events.length > 3 && (
        <SeeAllEvents onClick={handleSeeAllEvents}>
          {showAllEvents ? "Show less" : "See more"}
        </SeeAllEvents>
      )}
    </Container>
  );
}

FestivalEvents.propTypes = {
  events: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};
