import PropTypes from "prop-types";
import Dropdown from "..";
import { BodyText, ButtonGhost } from "../../../GlobalStyles";
import Refresh from "../../../assets/svg/refresh.svg?react";
import { InvisibleInputImage } from "../../../GlobalStyles";
import { DropdownItem } from "../Dropdown-Styles";
import useMomentPhotoUpload from "../../../hooks/useMomentPhotoUpload";
import { useVideoEditorStore } from "../../VideoEditor/videoEditorStore";
import PhotoPlus from "../../../assets/svg/photo-plus.svg?react";
import VideoPlus from "../../../assets/svg/video-plus.svg?react";
import Plus from "../../../assets/svg/plus.svg?react";
import { theme } from "../../../utils/theme";
import { useParams } from "react-router-dom";

const { colors } = theme;
export default function MomentMediaDropdown({ isVideo, type }) {
  const { id } = useParams();
  const isProfile = !!id;

  const isAdd = type === "add";

  const { handleMediaChange, fanMedia, loading } = useMomentPhotoUpload();
  const { isVideoUploading } = useVideoEditorStore();

  const disabled = fanMedia || isVideoUploading || loading;

  return (
    <Dropdown
      button={
        <ButtonGhost as={isProfile ? "div" : "button"}>
          {isAdd ? (
            <Plus
              height={isProfile ? 14 : 16}
              width={isProfile ? 14 : 16}
              stroke="white"
            />
          ) : (
            <Refresh
              height={isProfile ? 14 : 16}
              width={isProfile ? 14 : 16}
              stroke="white"
            />
          )}
        </ButtonGhost>
      }
    >
      <DropdownItem disabled={disabled}>
        <VideoPlus height={16} width={16} stroke={colors.white} />
        <BodyText style={{ textWrap: "nowrap" }}>
          {isAdd ? "Add Video" : `Change ${!isVideo ? "to" : ""} Video`}
        </BodyText>
        <InvisibleInputImage
          disabled={disabled}
          type="file"
          accept="video/*"
          onChange={(e) => {
            handleMediaChange(e, "video");
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
      </DropdownItem>
      <DropdownItem disabled={disabled}>
        <PhotoPlus height={16} width={16} stroke={colors.white} />
        <BodyText style={{ textWrap: "nowrap" }}>
          {isAdd ? "Add Photo" : `Change ${isVideo ? "to" : ""} Photo`}
        </BodyText>
        <InvisibleInputImage
          disabled={disabled}
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            handleMediaChange(e, "image");
          }}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
      </DropdownItem>
    </Dropdown>
  );
}

MomentMediaDropdown.propTypes = {
  isVideo: PropTypes.bool,
  type: PropTypes.string,
};
