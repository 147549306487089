import { useNavigate } from "react-router-dom";
import { BodyText, ButtonRect, HeaderL } from "../../../GlobalStyles";
import { useRetroFlowStore } from "../../../routes/Search/hooks/searchStore";
import { theme } from "../../../utils/theme";
import RetroAddThumbnail from "../RetroAddThumbnail";
import {
  RetroAddFlowContainer,
  BgImage,
  BottomContainer,
  BottomContentContainer,
  BackButtonContainer,
} from "./RetroAddFlow-Styles";
import { useQueryClient } from "@tanstack/react-query";
import { addRetroMoment, getRetroMoment } from "../../../api/moment";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import { toast } from "react-toastify";
import { BACKGROUND_IMAGES, MOMENT_TYPES } from "../../../constants/constants";
import BackButton from "../../BackButton";

const { colors } = theme;

export default function RetroAddFlow() {
  const {
    setIsOpen,
    isLoading,
    setIsLoading,
    performance,
    setPerformance,
    setRetroMoment,
    retroMoment,
    isSuccess,
    setIsSuccess,
  } = useRetroFlowStore();
  const navigate = useNavigate();
  const { loggedInUser, login } = useMomentifyLogin();
  const queryClient = useQueryClient();

  const addMoment = async () => {
    if (!loggedInUser) return login();

    if (!performance) return toast.error("Please select a performance");

    const {
      event_id,
      performance_id,
      artist,
      date,
      event_date,
      venue,
      venue_id,
      city,
      country,
      artist_id,
    } = performance || {};

    if (!performance_id || !artist) return toast.error("Performance not found");

    setIsLoading(true);

    const existingMoment = await getRetroMoment(
      loggedInUser.id,
      performance_id
    );

    if (existingMoment) {
      setIsLoading(false);
      return toast.error("Retro Moment already claimed");
    }

    const retroMoment = await addRetroMoment({
      userId: loggedInUser.id,
      eventId: event_id,
      eventDate: event_date || date,
      venueName: venue,
      venueId: venue_id,
      country,
      city,
      performanceId: performance_id,
      performanceArtistId: artist_id,
      performanceArtist: artist,
    });

    if (!retroMoment) {
      setIsLoading(false);
      return toast.error(
        "An error occurred claiming the Moment. Please try again later."
      );
    }

    retroMoment.type = MOMENT_TYPES.RETRO;

    setRetroMoment(retroMoment);
    setIsSuccess(true);
    setIsLoading(false);

    // Add retro moment attributes to performance
    performance.moment_id = retroMoment?.moment_id;
    performance.created_at = retroMoment?.created_at;
    performance.performance_artist = retroMoment?.performance_artist;
    performance.type = retroMoment?.type;
    performance.user_id = retroMoment?.user_id;

    setPerformance(performance);

    queryClient.removeQueries({
      queryKey: ["moments", loggedInUser.id.toString()],
    });
  };

  const closeFlow = () => {
    setIsOpen(false);
    setPerformance(null);
    setRetroMoment(null);
    setIsSuccess(false);
  };

  const redirectToMoment = () => {
    navigate(
      `/profile/${retroMoment.user_id}/moments/${retroMoment.moment_id}`
    );
    closeFlow();
  };

  const handleButtonClick = async () => {
    if (isSuccess) {
      navigate(`/profile/${loggedInUser.id}`);
      closeFlow();
      return;
    }

    await addMoment();
  };
  return (
    <RetroAddFlowContainer>
      <BackButtonContainer>
        <BackButton type="ghost" onClick={closeFlow} />
      </BackButtonContainer>
      <BgImage src={BACKGROUND_IMAGES.SEARCH.WEEK} alt="background image" />
      {isSuccess ? (
        <BottomContentContainer onClick={redirectToMoment}>
          <BodyText color={colors.grey} style={{ opacity: 0.5 }}>
            Tap to open
          </BodyText>
          <div>
            <RetroAddThumbnail performance={performance} isSuccess />
          </div>
        </BottomContentContainer>
      ) : (
        <RetroAddThumbnail performance={performance} />
      )}
      <BottomContainer>
        <BottomContentContainer>
          {isSuccess && (
            <BodyText>
              You now have a Retro-added Moment of this event.
            </BodyText>
          )}
          <HeaderL style={{ textTransform: "uppercase" }}>
            {isSuccess ? "Congratulations!" : "Did you attend this event?"}
          </HeaderL>
        </BottomContentContainer>
        <BottomContentContainer>
          <ButtonRect
            background={colors.greyBtn}
            style={{ border: "none", minHeight: "40px" }}
            onClick={handleButtonClick}
            disabled={isLoading}
          >
            <BodyText weight="600">
              {isLoading
                ? "Adding Moment..."
                : isSuccess
                  ? "Go to Profile"
                  : "Yes, add to Moments"}
            </BodyText>
          </ButtonRect>
          <ButtonRect
            onClick={closeFlow}
            style={{ border: "none", opacity: "0.6" }}
          >
            <BodyText weight="600">
              {isSuccess ? "Go back to Search" : "No, go back to Search"}
            </BodyText>
          </ButtonRect>
        </BottomContentContainer>
      </BottomContainer>
    </RetroAddFlowContainer>
  );
}
