import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useEphemeralStore = create(
  persist(
    (set) => ({
      HOME_TIP_EVENTS: false,
      HOME_TIP_PROFILE: false,
      HOME_TIP_SEARCH: false,
      MOMENT_TIP: false,
      COLLECTIBLE_TIP: false,
      setToClosed: (TIP_KEY) => {
        set({ [TIP_KEY]: true });
      },
    }),
    {
      name: `ephemeral-store-${localStorage.getItem("loggedInUserID") ?? "loggedOut"}`, // unique name of the store in local storage
      partialize: (state) => ({
        MOMENT_TIP: state.MOMENT_TIP,
      }),
    }
  )
);
