import { useReadContracts } from "wagmi";
import { FooterText, Header, HeaderL } from "../../../GlobalStyles";
import { BalanceContainer, Container } from "./WalletCardBalance-Styles";
import PropTypes from "prop-types";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { theme } from "../../../utils/theme";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import { erc20Abi, formatUnits } from "viem";
import { formatPrice } from "../../../utils/utils";
import { USDC_ADDRESS } from "../../../constants/constants";

export default function WalletCardBalance({ wallet }) {
  const { address, meta } = wallet || {};

  const { data, isLoading } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: USDC_ADDRESS,
        abi: erc20Abi,
        functionName: "decimals",
      },
    ],
  });

  const { 0: balance, 1: decimals } = data || {};

  const formattedBalance =
    data && !isLoading && formatUnits(balance?.result, decimals?.result); // USDC has 6 decimal places

  const balanceArray = formatPrice(formattedBalance);

  if (isLoading) return <SkeletonElement height="30px" width="120px" />;

  return (
    <Container>
      <BalanceContainer>
        <HeaderL
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          $ {balanceArray[0] ?? "0"}.
          <Header
            as="span"
            style={{ marginBottom: "2.5px", fontSize: "21.7px" }}
          >
            {balanceArray[1]?.slice(0, 2) ?? "00"}
          </Header>{" "}
        </HeaderL>
        <USDCIcon height={24} width={24} />
      </BalanceContainer>
      <FooterText
        style={{
          textTransform: "capitalize",
          color: theme.colors.grey,
        }}
      >
        {meta?.name}
      </FooterText>
    </Container>
  );
}

WalletCardBalance.propTypes = {
  wallet: PropTypes.object,
  currency: PropTypes.string,
};
