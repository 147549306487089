import PropTypes from "prop-types";
import CheckInComponent from "./CheckInComponent";
import { useEffect } from "react";

function CheckInEvent({ onClose, onRefresh }) {
  useEffect(() => {
    // Save the current overflow style
    const originalOverflow = document.body.style.overflow;

    const root = document.getElementById("root");

    root.style.overflow = "hidden";
    root.scrollTop = 0;

    // Set the body's overflow style to 'hidden' to disable scrolling
    document.body.style.overflow = "hidden";
    document.body.scrollTop = 0;

    // Clean up the effect by restoring the original overflow style
    return () => {
      document.body.style.overflow = originalOverflow;
      root.style.overflow = "auto";
    };
  }, []); // The empty dependency array ensures this effect runs only once

  return <CheckInComponent onClose={onClose} onRefresh={onRefresh} />;
}

CheckInEvent.propTypes = {
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default CheckInEvent;
