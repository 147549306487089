import styled from "styled-components";
import { Button } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const ArtistDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  overflow: hidden;
`;

export const ArtistIcon = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ArtistIconPlaceholder = styled(ArtistIcon)`
  display: grid;
  place-content: center;
  background: ${({ theme }) => theme.colors.glass};
  backdrop-filter: blur(200px);
  -webkit-backdrop-filter: blur(200px);
`;

export const FollowSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
`;

export const PerformanceButton = styled(Button)`
  padding: 7px 12px;
  gap: 8px;
`;
