// import path from 'path'
// import dotenv from 'dotenv'
// dotenv.config({ path: path.resolve(__dirname, '../../.env') })

export const CINNY_USER_ID = "cinny_user_id";
export const CINNY_DEVICE_ID = "cinny_device_id";
export const CINNY_HOMESERVER_BASE_URL = "cinny_hs_base_url";
export const CINNY_ACCESS_TOKEN = "cinny_access_token";
export const CINNY_SUPPORTED_BROWSER_VERSIONS = [
    {
        browser: 'Safari',
        version: 16.4
    },
    {
        browser: 'Chrome',
        version: 91
    }
]
export const FESTIVAL_ROOM = import.meta.env.VITE_MATRIX_FESTIVAL_ROOM;

// export const VITE_MATRIX_APP_URL = import.meta.env.VITE_MATRIX_APP_URL ?? process.env.VITE_MATRIX_APP_URL
// export const VITE_API_URL = import.meta.env.VITE_API_URL ?? process.env.VITE_API_URL
