import { useParams, useNavigate } from "react-router";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect, useState } from "react";
import LockIcon from "../../assets/svg/lock.svg?react";
import HintIcon from "../../assets/svg/hint.svg?react";
import LoadingScreen from "../../components/LoadingScreen";
import ProfileDetails from "../../components/Profile/ProfileDetails";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import ProfileTabs from "../../components/Profile/ProfileTabs";
import useAccountKit from "../../hooks/useAccountKit";
import useAuth from "../../hooks/useAuth";
import useGlobalModal from "../../hooks/useGlobalModal";
import ErrorPage from "../Error";
import { useFollowerQuery } from "../Followers/hooks/followersQueries";
import { useFollowing } from "../Following/hooks/followingQueries";
import { ProfileContainer, ProfileSection } from "./Profile-Styles";
import {
  useAlchemyQuery,
  useUserCollectibles,
  useUserEvents,
  useUserMessages,
  useUserMoments,
  useUserNotifications,
  useUserStats,
} from "./hooks/profileQueries";
import EmptyState from "../../components/EmptyState";
import { useLinkedWallets } from "../../hooks/useLinkedWallets";
import EphemeralTips from "../../components/Tips/EphemeralTips";
import { theme } from "../../utils/theme";
import { FooterText } from "../../GlobalStyles";
import { EPHEMERAL_TIPS } from "../../constants/constants";
import useNotification from "../../hooks/useNotification";
import { NotificationPermissionState } from "../../enums/permission";

/**
 * Profile component to display user profile information
 */
export default function Profile() {
  const { id: userId } = useParams();
  const { ready, user } = usePrivy();
  const { loggedInUser } = useAuth();
  const { notificationPermissionState } = useNotification();
  const { openSheetPWAModal } = useGlobalModal();
  const { linkedWallets, ready: walletsReady } = useLinkedWallets();
  const { setAlchemyProvider } = useAccountKit();
  const navigate = useNavigate();
  const [randomizer] = useState(Math.floor(Math.random() * 3));

  const isOwnProfile = loggedInUser?.id ? loggedInUser?.id == userId : false;

  const userStatsQuery = useUserStats({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userMomentsQuery = useUserMoments({
    userId: userId ?? "",
    isOwnProfile: true,
  });
  const userEventsQuery = useUserEvents({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userCollectiblesQuery = useUserCollectibles({
    userId: userId ?? "",
    isOwnProfile: true,
  });

  const userNotificationsQuery = useUserNotifications({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: isOwnProfile,
  });

  const userMessagesQuery = useUserMessages({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: isOwnProfile,
  });

  useAlchemyQuery({
    linkedWallets,
    ready,
    walletsReady,
    setAlchemyProvider,
    userWallet: loggedInUser?.embedded_wallet_id,
  });

  useFollowing({
    userId,
    enabled: !(userStatsQuery?.data?.private ?? true) || isOwnProfile,
  });

  useFollowerQuery({
    id: userId,
    enabled: !(userStatsQuery?.data?.private ?? true) || isOwnProfile,
  });

  // Open global modal after loading
  useEffect(() => {
    openSheetPWAModal();
  }, []);

  // Display loading screen if data is not ready
  if (!ready) return <LoadingScreen />;

  if (userStatsQuery?.data?.length === 0)
    return <ErrorPage description={"We can't find this user."} />;

  // Render the profile section with user information
  return (
    <ProfileSection>
      <ProfileContainer>
        <ProfileHeader
          user={userStatsQuery?.data}
          hasNotification={
            userNotificationsQuery?.data?.filter((row) => !row.read_at)
              ?.length > 0
          }
          hasMessages={
            userMessagesQuery?.data?.filter((row) => !row.read_at)?.length > 0
          }
          isStatsLoading={userStatsQuery?.isLoading}
          isNotificationLoading={userNotificationsQuery?.isLoading}
          isMessagesLoading={userMessagesQuery?.isLoading}
        />
        <ProfileDetails
          user={userStatsQuery?.data}
          isStatsLoading={userStatsQuery?.isLoading}
          isPrivate={userStatsQuery?.data?.private}
        />
        {!userStatsQuery?.isLoading && isOwnProfile && (
          <EphemeralTips
            icon={<HintIcon fill={theme.colors.green} height={12} width={12} />}
            message={
              randomizer === 0 && !loggedInUser?.location ? (
                <FooterText color={theme.colors.grey}>
                  <FooterText
                    color={theme.colors.grey}
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate("/profile/settings")}
                  >
                    Add your home city
                  </FooterText>{" "}
                  for better recommendations.{" "}
                  <FooterText color={theme.colors.green}>+50xp</FooterText>
                </FooterText>
              ) : randomizer === 1 &&
                !user?.email?.address &&
                !user?.google?.email ? (
                <FooterText color={theme.colors.grey}>
                  <FooterText
                    color={theme.colors.grey}
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate("/profile/settings")}
                  >
                    Add your email
                  </FooterText>{" "}
                  to receive updates and benefits.{" "}
                  <FooterText color={theme.colors.green}>+50xp</FooterText>
                </FooterText>
              ) : randomizer === 2 &&
                [NotificationPermissionState.PROMPT]?.includes(
                  notificationPermissionState
                ) ? (
                <FooterText color={theme.colors.grey}>
                  Turn on push notifications to receive alerts even when you are
                  not using the app.
                </FooterText>
              ) : null
            }
            type={EPHEMERAL_TIPS.HOME_TIP_PROFILE}
          />
        )}
        {/* Display private account message or profile tabs based on user data */}
        {loggedInUser?.id !== userStatsQuery?.data?.id &&
        userStatsQuery?.data?.private ? (
          <EmptyState
            icon={<LockIcon height="25px" width="21.5px" stroke="white" />}
            title="This account is private"
            description="This user has chosen not to share their profile in public."
          />
        ) : (
          <ProfileTabs
            user={userStatsQuery?.data}
            events={userEventsQuery?.data}
            moments={userMomentsQuery?.data}
            collectibles={userCollectiblesQuery?.data}
            defaultActiveTab="moments"
            isMomentsLoading={userMomentsQuery?.isLoading}
            isEventsLoading={userEventsQuery?.isLoading}
            isCollectiblesLoading={userCollectiblesQuery?.isLoading}
          />
        )}
      </ProfileContainer>
    </ProfileSection>
  );
}
