import styled from "styled-components";

export const IndividualMomentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RoomButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  gap: 8px;
`;
