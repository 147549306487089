import styled from "styled-components";
import { BodyText, ButtonSm, FooterText } from "../../../GlobalStyles";

export const PerformanceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
  position: relative;
  border-radius: 10px;
  border: none;
  gap: 22px;
  transition: 0.2s ease-in-out;
  width: 100%;
`;

export const PerformanceDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const PerformanceDateContainer = styled.div`
  height: 58px;
  width: 58px;
  min-width: 58px;
  border-radius: 10px;
  padding: 6px;
  background: ${({ theme }) => theme.colors.blueShade};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PerformanceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
`;

export const PerformanceDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const PerformanceArtist = styled(BodyText)`
  /* 1 lines then ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;

export const PerformanceVenue = styled(FooterText)`
  /* 1 lines then ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PerformanceCountryWithTime = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PerformanceButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const PerformanceButton = styled(ButtonSm)`
  height: auto;
  width: auto;
  padding: 5px;
  border: 1.5px solid ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;
