import { useQuery } from "@tanstack/react-query";
import { performanceMomentsLoader } from "../loader";

export const usePerformanceQuery = ({ id, date }) => {
  return useQuery({
    queryKey: ["performance", id],
    queryFn: async () => {
      return await performanceMomentsLoader(id, date);
    },
  });
};
