import { useQuery } from "@tanstack/react-query";
import { getActionByUserIdAndType } from "../../../api/profile";

export const useInviteLink = ({ userId }) => {
  return useQuery({
    queryKey: ["invite-link", userId],
    queryFn: async () => {
      const inviteLink = await getActionByUserIdAndType({
        user_id: userId,
        action_type: "invite_friend",
        target_type: "user",
      });

      return inviteLink?.data;
    },
    enabled: !!userId,
    staleTime: Infinity,
  });
};
