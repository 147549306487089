import { BodyText } from "../../../GlobalStyles";
import { Container } from "./PaymentMethods-Styles";
import { PAYMENT_METHODS } from "../../../constants/constants";
import PaymentMethod from "../PaymentMethod";
import PropTypes from "prop-types";

const { WALLET, CREDIT_CARD, NEW_CARD } = PAYMENT_METHODS;

export default function PaymentMethods({ isCards }) {
  if (isCards)
    return (
      <Container>
        <BodyText weight="600">Add new payment method</BodyText>
        <PaymentMethod method={NEW_CARD} />
      </Container>
    );

  return (
    <Container>
      <BodyText weight="600">Payment method</BodyText>
      <PaymentMethod method={CREDIT_CARD} />
      <PaymentMethod method={WALLET} />
    </Container>
  );
}

PaymentMethods.propTypes = {
  isCards: PropTypes.bool,
};
