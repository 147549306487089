import { BodyText } from "../../GlobalStyles";

export const Header = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <BodyText
        style={{
          fontWeight: 600,
          fontSize: 20,
          color: "#F7F7F7",
          fontStyle: "Suisse Int'l",
        }}
      >
        Events for you
      </BodyText>
    </div>
  );
};
