import PropTypes from "prop-types";
import { BodyText, FooterText } from "../../../GlobalStyles";
import RecentSearchItem from "../RecentSearchItem";
import { SearchSkeleton } from "../../../routes/Search/Search-Styles";
import { HeaderContainer, RecentsContainer } from "./RecentSearches-Styles";
import { theme } from "../../../utils/theme";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import {
  useClearSearchHistory,
  useSearchHistory,
} from "../../../routes/Search/hooks/searchQueries";
import PartialEmptyState from "../../PartialEmptyState";
import SearchIcon from "../../../assets/svg/search.svg?react";
import { useSearchParamsHook } from "../../../routes/Search/hooks/searchParams";
import { useSearchParams } from "react-router-dom";

const { colors } = theme;

export default function RecentSearches() {
  const { loggedInUser } = useAuth();
  const [cleared, setCleared] = useState(false);
  const [searchParams] = useSearchParams();
  const { isRetro } = useSearchParamsHook({ searchParams });
  // Search History Queries
  const searchHistoryQuery = useSearchHistory({ loggedInUser });
  const isLoading = searchHistoryQuery.isLoading;
  const recents = searchHistoryQuery.data;
  const clearSearchMutation = useClearSearchHistory();

  const handleClear = async () => {
    setCleared(true);

    clearSearchMutation.mutate({ loggedInUser });
  };

  if (isLoading) {
    return (
      <RecentsContainer>
        <SearchSkeleton style={{ padding: "0" }} />
      </RecentsContainer>
    );
  }

  if (!recents || (recents.length === 0 && !isLoading) || cleared)
    return (
      <RecentsContainer>
        <HeaderContainer>
          <BodyText weight="600" color={colors.grey}>
            Recent searches
          </BodyText>
        </HeaderContainer>
        <PartialEmptyState
          icon={
            <SearchIcon
              stroke={theme.colors.white}
              height={24}
              width={24}
              opacity={0.6}
            />
          }
          description="Your previous searches will appear here."
          style={{ flex: 0, paddingTop: "15%" }}
        />
      </RecentsContainer>
    );

  return (
    <RecentsContainer>
      <HeaderContainer>
        <BodyText weight="600" color={colors.grey}>
          Recent searches
        </BodyText>
        {recents.length > 0 && !!loggedInUser && (
          <FooterText
            color={colors.grey}
            style={{ cursor: "pointer" }}
            onClick={handleClear}
          >
            Clear all
          </FooterText>
        )}
      </HeaderContainer>
      {
        // Only show artists in retro
        isRetro &&
          recents
            .filter((recent) => recent?.query?.type === "artist")
            .map((recent) => (
              <RecentSearchItem key={recent.id} recent={recent} />
            ))
      }
      {!isRetro &&
        recents.map((recent) => (
          <RecentSearchItem key={recent.id} recent={recent} />
        ))}
      {(isRetro
        ? recents.filter((recent) => recent?.query?.type === "artist").length <
          8
        : recents.length < 4) && (
        <PartialEmptyState
          icon={
            <SearchIcon
              stroke={theme.colors.white}
              height={24}
              width={24}
              opacity={0.6}
            />
          }
          description={
            isRetro
              ? "Search artist and add their past event to your Moments."
              : "Search for artists, events, venues and other fans."
          }
        />
      )}
    </RecentsContainer>
  );
}

RecentSearches.propTypes = {
  loading: PropTypes.bool,
  recents: PropTypes.array,
};
