import styled from "styled-components";
import { BodyText, ButtonRect, FooterText, HeaderL } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import useGlobalModal from "../../hooks/useGlobalModal";
import Pin from "../../assets/svg/pin-white.svg?react";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
`;

const ModalButton = styled(ButtonRect)`
  background: rgba(255, 255, 255, 0.12);
  width: 100%;
  margin-top: 8px;
  padding: 16px;
  border: none;
`;

export default function DeniedGeo() {
  const { toggleSheetModal } = useGlobalModal();

  return (
    <Container>
      <Pin stroke="white" height={35} width={35} />
      <HeaderL>Oops!</HeaderL>
      <FooterText>
        Looks like you denied permission for us to check your location.
      </FooterText>
      <FooterText>
        Don’t worry, you can
        <FooterText weight="600" color={colors.green}>
          {" "}
          enable it in your browser settings{" "}
        </FooterText>
        so you don’t miss out on exciting events near you!
      </FooterText>
      <ModalButton onClick={toggleSheetModal}>
        <BodyText weight="600">Okay</BodyText>
      </ModalButton>
    </Container>
  );
}
