import styled from "styled-components";
import PropTypes from "prop-types";
import { Title } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import EmailModalHeader from "../../assets/svg/mail-open.svg?react";
import EmailModalGoogle from "../../assets/svg/google.svg?react";
import EmailModalKeyboard from "../../assets/svg/keyboard.svg?react";
import { StyledPrimaryButton } from "../../styles/modal.styles";
import useGlobalModal from "../../hooks/useGlobalModal";

const { colors } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-blend-mode: multiply;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: left;
  padding: 35px 27px;
  position: relative;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  gap: ${({ gap }) => gap};
  text-align: center;
`;

const Button = styled(StyledPrimaryButton)`
  width: 287px;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export default function LinkEmailModal({ linkGoogle, linkEmail }) {
  const { toggleSheetModal } = useGlobalModal();

  return (
    <Container>
      <FlexContainer gap="24px" direction="column" align="center">
        <FlexContainer
          gap="8px"
          direction="column"
          align="center"
          style={{ width: "100%" }}
        >
          <EmailModalHeader height={50} width={50} color={colors.grey} />
          <Title>How do you want to link your email?</Title>
        </FlexContainer>
        <FlexContainer
          gap="16px"
          direction="column"
          align="center"
          style={{ width: "100%" }}
        >
          <Button onClick={() => linkGoogle()}>
            <EmailModalGoogle height={16} width={16} color={colors.grey} />
            Connect with Google
          </Button>
          <Button
            onClick={() => {
              toggleSheetModal();
              linkEmail();
            }}
          >
            <EmailModalKeyboard height={16} width={16} color={colors.grey} />
            Add manually
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
}

LinkEmailModal.propTypes = {
  linkGoogle: PropTypes.func,
  linkEmail: PropTypes.func,
};
