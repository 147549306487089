import styled from "styled-components";
import { Button } from "../../GlobalStyles";

export const ChatButton = styled(Button)`
  padding: 8px;
  background-color: ${({ dark }) => dark && "#11111f33"};
  backdrop-filter: ${({ dark }) => dark && "blur(20px)"};
  -webkit-backdrop-filter: ${({ dark }) => dark && "blur(20px)"};
  gap: 8px;
  width: auto;
`;
