import styled from "styled-components";
import { ButtonRect } from "../../../GlobalStyles";

export const BackgroundContainer = styled.div`
  scroll-snap-align: center;
  scroll-snap-stop: always;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  width: 100vw;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding-bottom: 163px;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
`;

export const UploadCountdownContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
`;

export const UploadPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AddPhotoButton = styled(ButtonRect)`
  position: relative;
`;
