import { DESKTOP_IMAGES, DESKTOP_VIDEOS } from "../../constants/constants";
import DownloadInfo from "../Desktop/DownloadInfo";
import {
  ContentContainer,
  DownloadInfoContainer,
  Header,
  InstructionHeader,
  InstructionsContainer,
  InstructionTextContainer,
  Navbar,
  QRCodeImage,
  Section,
  Text,
  TextContainer,
  Video,
} from "./Interim-Styles";
import { BetaIcon } from "../BetaIcon";
import Logo from "../../assets/images/mom-logo-full.webp";

export const Interim = () => {
  return (
    <Section>
      <Navbar>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <img
            src={Logo}
            alt="logo"
            style={{ height: "32px", background: "none" }}
          />
          <BetaIcon />
        </div>
      </Navbar>
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div>
          <Video
            poster={DESKTOP_IMAGES.BLACK_POSTER}
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={DESKTOP_VIDEOS.INTERIM} type="video/mp4" />
          </Video>
        </div>
        <ContentContainer className="flex flex-col gap-[100px]">
          <TextContainer className="flex flex-col gap-10">
            <Header className="font-druk font-extrabold text-6xl uppercase tracking-wider">
              Get the App
            </Header>
            <InstructionsContainer className="flex items-center gap-8">
              <QRCodeImage
                src={DESKTOP_IMAGES.QR}
                alt="app-qr"
                className="w-[200px] h-[200px] rounded-lg"
              />
              <InstructionTextContainer className="flex flex-col gap-6">
                <InstructionHeader className="text-2xl font-semibold">
                  Installing Momentify is simple!
                </InstructionHeader>
                <Text
                  style={{
                    opacity: 0.6,
                  }}
                >
                  Simply ‘add’ Momentify to your Home Screen. No App Store
                  download is required.
                </Text>
                <Text
                  style={{
                    opacity: 0.6,
                  }}
                >
                  Momentify uses the latest Progressive Web App (PWA)
                  technology. Read more here
                </Text>
              </InstructionTextContainer>
            </InstructionsContainer>
          </TextContainer>
          <DownloadInfoContainer className="flex gap-6">
            <DownloadInfo />
            <DownloadInfo type="android" />
          </DownloadInfoContainer>
        </ContentContainer>
      </div>
    </Section>
  );
};
