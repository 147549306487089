import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { BodyText } from "../GlobalStyles";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? gap : "8px")};
`;

export default function IconWithText({ icon, text, gap }) {
  return (
    <Container gap={gap}>
      {React.cloneElement(icon, {
        style: { bottom: "2px", position: "relative" },
      })}
      <BodyText>{text}</BodyText>
    </Container>
  );
}

IconWithText.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  gap: PropTypes.string,
};
