import { Link } from "react-router-dom";
import styled from "styled-components";
import { BodyText, FooterText } from "../../GlobalStyles";

export const Card = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
  position: relative;
  border-radius: 10px;
  border: none;
  gap: 22px;
  transition: 0.2s ease-in-out;
  width: 100%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const EventImage = styled.img`
  height: 58px;
  width: 58px;
  min-width: 58px;
  transition: 0.3s ease;
  border-radius: 10px;
  object-fit: cover;
`;

export const EventDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
`;

export const EventDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const EventHeadlineArtist = styled(BodyText)`
  /* 1 lines then ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
`;
export const EventVenue = styled(FooterText)`
  /* 1 lines then ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EventDate = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const RoomButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;
