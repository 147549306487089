import { createContext, useState } from "react";
import { MomentUploadDispatchEvents } from "../constants/dispatchEvents";
import PropTypes from "prop-types";

export const MomentUploadContext = createContext();

const initialState = {
  fanMedia: null,
  loading: false,
  moment: null,
  error: null,
};

export default function MomentUploadProvider(params) {
  const { children } = params;
  const [context, setContext] = useState(initialState);

  const dispatch = (actionType, payload) => {
    switch (actionType) {
      case MomentUploadDispatchEvents.SET_LOADING:
        {
          setContext((prev) => ({
            ...prev,
            loading: payload,
          }));
        }
        break;
      case MomentUploadDispatchEvents.SET_FAN_MEDIA:
        {
          setContext((prev) => ({
            ...prev,
            fanMedia: payload,
          }));
        }
        break;
      case MomentUploadDispatchEvents.SET_MOMENT:
        {
          setContext((prev) => ({
            ...prev,
            moment: payload,
          }));
        }
        break;
      case MomentUploadDispatchEvents.SET_ERROR:
        {
          setContext((prev) => ({
            ...prev,
            error: payload,
          }));
        }
        break;
      case MomentUploadDispatchEvents.SET_RESET:
        {
          setContext(initialState);
        }
        break;
    }
  };

  return (
    <MomentUploadContext.Provider value={{ context, dispatch }}>
      {children}
    </MomentUploadContext.Provider>
  );
}

MomentUploadProvider.propTypes = {
  children: PropTypes.node,
};
