import PropTypes from "prop-types";
import styled from "styled-components";
import ArtistItem from "../Artist/ArtistItem";
import { BodyText } from "../../GlobalStyles";
import { useEffect, useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ArtistsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.blueShade};
  gap: 8px;
  padding: 8px 16px;
  border-radius: 10px;
`;

const SeeAllArtists = styled(BodyText)`
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
`;

export default function EventArtists({ artists, isPostEvent }) {
  const [showAllArtists, setShowAllArtists] = useState(false);
  const [displayedArtists, setDisplayedArtists] = useState([]);

  useEffect(() => {
    if (!artists || artists.length === 0) return;

    // Set initial state for showing all Artists
    setDisplayedArtists(artists?.slice(0, 3));
  }, []);

  const handleSeeAllArtists = () => {
    setShowAllArtists(!showAllArtists);

    if (!showAllArtists) {
      setDisplayedArtists(artists);
    } else {
      setDisplayedArtists(artists?.slice(0, 3));
    }
  };
  return (
    <Container>
      <BodyText weight="600">Line up</BodyText>
      <ArtistsContainer>
        {displayedArtists.length > 0 &&
          displayedArtists.map((artist, index) => (
            <ArtistItem key={index} artist={artist} isPostEvent={isPostEvent} />
          ))}
      </ArtistsContainer>
      {/* Render 'See more' or 'Show less' button based on the number of events */}
      {artists.length > 3 && (
        <SeeAllArtists onClick={handleSeeAllArtists}>
          {showAllArtists ? "Show less" : "See more"}
        </SeeAllArtists>
      )}
    </Container>
  );
}

EventArtists.propTypes = {
  artists: PropTypes.array,
  isPostEvent: PropTypes.bool,
};
