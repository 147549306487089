import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CheckInEvent from "../../components/CheckIn/CheckInEvent";
import CheckInToast from "../../components/CheckIn/CheckInToast";
import EventArtists from "../../components/Event/EventArtists";
import EventHeader from "../../components/Event/EventHeader";
import EventInfo from "../../components/Event/EventInfo";
import EventTickets from "../../components/Event/EventTickets";
import withCheckInContextProvider from "../../hoc/withCheckInContextProvider";
import useAuth from "../../hooks/useAuth";
import useCheckIn from "../../hooks/useCheckIn";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import useGeolocation from "../../hooks/useGeolocation";
import ErrorPage from "../Error";
import { useOneEvent } from "../Events/hooks/eventsQueries";
import { EventDetails, EventSection, EventSkeleton } from "./Event-Styles";
import { useEventLoader, useGetRoom } from "./hooks/eventQueries";
import PartialEmptyState from "../../components/PartialEmptyState";
import { BodyText, ButtonRectSm, FooterText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { REPORT_ENTITY_TYPES, REPORT_TYPES } from "../../constants/constants";
import { useUserStore } from "../Profile/store/userStore";
import BuyTicket from "../../components/BuyTicket";
import { useBuyTicketStore } from "../../components/BuyTicket/buyTicketStore";
import { isProd } from "../../utils/utils";
import { contract } from "../../constants/ticketing-placeholders";

/**
 * The Event component handles the logic for an event, including participant count, user interest, location, check-in, and event details display.
 * @returns {React.JSX.Element} The rendered Event component

 */
// We can use state here, store clicked event image and show it immediately
function Event() {
  const { id: eventId } = useParams();
  const [searchParams] = useSearchParams();
  const { isOpen: isBuyTicketsOpen } = useBuyTicketStore();
  const date = searchParams.get("date");
  const { loggedInUser, fetchSessionCookie } = useAuth();
  const eventLoaderQuery = useEventLoader({
    eventId: eventId,
    userId: loggedInUser?.id,
    date: date,
  });
  const navigate = useNavigate();

  const [participantsCount, setParticipantsCount] = useState(0);
  const { userParticipated } = useUserStore();

  const [interested, setInterested] = useState(
    userParticipated.includes(parseInt(eventId))
  );
  const [room, setRoom] = useState(null);

  const { handleToggle, isOpen } = useCheckInModalToggle();
  const {
    handleLocationPermission,
    currentLocation,
    resetLocation,
    isGeolocationPermitted,
    getCurrentPosition,
  } = useGeolocation();
  const {
    currentEvent,
    setCurrentEvent,
    handleCheckInCurrentEvent,
    hasMomentExisted,
  } = useCheckIn();

  const oneEventQuery = useOneEvent({
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    event_id: eventLoaderQuery?.data?.id,
    date: date,
  });

  const roomQuery = useGetRoom({
    room: eventLoaderQuery?.data?.room ?? null,
  });

  // Move this to custom hook. Move current position to state management
  // Use effect for getting current postion
  useEffect(() => {
    if (isGeolocationPermitted) {
      // Get current location every 10 seconds
      const intervalId = setInterval(() => {
        getCurrentPosition();
        // queryClient.refetchQueries({
        //   queryKey: ["one_event"],
        //   type: "active",
        // });
      }, 1000);

      // Clear interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [getCurrentPosition, isGeolocationPermitted]);

  useEffect(() => {
    const data = eventLoaderQuery.data;

    if (!eventLoaderQuery.isLoading) {
      setParticipantsCount(data?.interested_count ?? 0);
      setInterested(userParticipated.includes(parseInt(eventId)));
      setParticipantsCount(data?.interested_count);
    }
  }, [
    eventLoaderQuery.data,
    eventLoaderQuery.isLoading,
    userParticipated,
    eventId,
  ]);

  // Redundant pieces of code? Location should be stored in session
  // Get location and session on load
  useEffect(() => {
    if (eventLoaderQuery?.data?.length == 0 || eventLoaderQuery?.isLoading)
      return;

    // handleRoomLoad();
    handleLocationPermission();
    fetchSessionCookie();

    // Reset location when unmounting
    return () => {
      resetLocation();
      setCurrentEvent(null);
    };
  }, [eventLoaderQuery.data, eventLoaderQuery.isLoading]);

  useEffect(() => {
    if (roomQuery.isLoading || roomQuery?.data?.length == 0) return;
    setRoom(roomQuery?.data ?? null);
  }, [roomQuery.data, roomQuery.isLoading]);

  useEffect(() => {
    if (isOpen) return;

    if (!oneEventQuery.isLoading) {
      setCurrentEvent(oneEventQuery?.data ?? null);
    }
  }, [oneEventQuery.data, oneEventQuery.isLoading]);

  const handleParticipantCount = async () => {
    if (interested) {
      setParticipantsCount((prevCount) => prevCount - 1);
      return;
    }

    setParticipantsCount((prevCount) => prevCount + 1);
  };

  const handleOpenCheckInModal = () => {
    if (!navigator.geolocation) return;

    handleToggle();
    handleCheckInCurrentEvent();
  };

  if (eventLoaderQuery.isLoading) return <EventSkeleton />;

  if (
    !eventLoaderQuery?.data ||
    eventLoaderQuery?.data?.length == 0 ||
    eventLoaderQuery.error
  )
    return <ErrorPage description="We can't find this event." />;

  if (eventLoaderQuery.data && isOpen && !isBuyTicketsOpen) {
    return (
      <CheckInEvent
        onClose={handleToggle}
        onRefresh={handleCheckInCurrentEvent}
      />
    );
  }

  if (eventLoaderQuery.data && isBuyTicketsOpen && contract && !isProd) {
    return <BuyTicket event={eventLoaderQuery?.data} contract={contract} />;
  }

  return (
    <>
      <EventSection>
        <EventHeader
          event={eventLoaderQuery?.data}
          room={room ?? null}
          interested={interested}
          participantsCount={participantsCount}
          handleParticipantCount={handleParticipantCount}
        />
        <EventDetails>
          {/* Event Main Info */}
          <EventInfo event={eventLoaderQuery?.data} />
          {/* Event Artists */}
          {eventLoaderQuery?.data?.artists?.length > 0 && (
            <EventArtists
              artists={eventLoaderQuery?.data?.artists}
              isPostEvent={
                moment(eventLoaderQuery?.data?.date).format("DD") ==
                  moment().format("DD") && moment().format("HH") > 23
              }
            />
          )}
          {/* Tickets */}
          <EventTickets event={eventLoaderQuery?.data} contract={contract} />
        </EventDetails>
        <CheckInToast
          event={currentEvent}
          onClick={handleOpenCheckInModal}
          hasMomentExisted={hasMomentExisted}
        />
        <PartialEmptyState
          description="Did we get something wrong?"
          button={
            <div>
              <ButtonRectSm
                onClick={() =>
                  navigate(
                    `/report?type=${REPORT_TYPES.EVENT}&entity_type=${REPORT_ENTITY_TYPES.EVENT_ENTITY}&entity_id=${eventLoaderQuery?.data?.id}`
                  )
                }
                style={{ marginBottom: 12 }}
              >
                <FooterText weight="600">Let us know</FooterText>
              </ButtonRectSm>
              <BodyText color={theme.colors.grey}>
                and earn <BodyText color={theme.colors.green}>XP!</BodyText>
              </BodyText>
            </div>
          }
          style={{ paddingBottom: "12px" }}
        />
      </EventSection>
    </>
  );
}

export default withCheckInContextProvider(Event);
