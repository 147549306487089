import {
  createOrUpdateRoomByPostEvent,
  createOrUpdateRoomByPreEvent,
  getRoomByRoomID,
} from "../api/matrix";
import { getSecret, isCinnyAuthenticated } from "../utils/cinny-utils";
import { CINNY_ACCESS_TOKEN } from "../routes/Chat/constants";

export function parseMatrixID(privyID) {
  return privyID.replace("did:privy:", "");
}

export function generateMatrixID(privyID) {
  let string = parseMatrixID(privyID);
  return `@${string}:${import.meta.env.VITE_MATRIX_SERVER}`;
}

export const getRoom = async (room) => {
  let matrixToken = getSecret(CINNY_ACCESS_TOKEN);
  if (!room) return null;

  if (!room?.room_address) {
    room = await getRoomByRoomID(
      import.meta.env.VITE_API_URL,
      matrixToken,
      room.id
    );
  }
  return room;
};

export const roomURLHandler = async (event) => {
  if (!isCinnyAuthenticated()) return event;

  let matrixToken = getSecret(CINNY_ACCESS_TOKEN);

  let preEventRoom = await getRoom(event?.room ?? null);

  if (!!preEventRoom && !preEventRoom.room_address) {
    const roomData = await fetch(
      `${import.meta.env.VITE_API_URL}/matrix/preEvent/addRoomURL?matrixAuthToken=${matrixToken}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event_id: preEventRoom.event_id,
          room_id: preEventRoom.id,
          room_name: preEventRoom.room_name ?? "",
        }),
      }
    ).then((res) => res.json());

    event.room.room_address = roomData.room_id;
  } else if (!!preEventRoom && !!preEventRoom.room_address) {
    event.room.room_address = preEventRoom.room_address;
  }

  return event;
};

export const roomURLHandlerMoment = async (moment) => {
  if (!isCinnyAuthenticated()) return moment;

  let matrixToken = getSecret(CINNY_ACCESS_TOKEN);

  let postEventRoom = moment?.room ?? null;

  if (!postEventRoom) {
    return moment;
  }

  if (!!postEventRoom?.room_address) {
    return moment;
  }

  const roomData = await fetch(
    `${import.meta.env.VITE_API_URL}/matrix/rooms/postEvents/addRoomURL?matrixAuthToken=${matrixToken}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        performance_id: postEventRoom.performance_id,
        room_id: postEventRoom.id,
        room_name: postEventRoom.room_name ?? "",
      }),
    }
  ).then((res) => res.json());

  moment.room.room_address = roomData.room_id;
  return moment;
};

export const createOrUpdatePreEventRoomHandler = async (event) => {
  if (!isCinnyAuthenticated()) return event?.room ?? null;

  const mxToken = getSecret(CINNY_ACCESS_TOKEN);
  const preEventRoom = await createOrUpdateRoomByPreEvent(
    import.meta.env.VITE_API_URL,
    mxToken,
    event.id,
    event.headline_artist
  );

  return preEventRoom?.room ?? null;
};

export const createOrUpdatePostEventRoomHandler = async (moment) => {
  if (!isCinnyAuthenticated()) return moment?.room ?? null;

  const mxToken = getSecret(CINNY_ACCESS_TOKEN);
  const postEventRoom = await createOrUpdateRoomByPostEvent(
    import.meta.env.VITE_API_URL,
    mxToken,
    moment.event_id,
    moment.event_date,
    moment.performance_id
  );

  return postEventRoom?.room ?? null;
};
