import { styled } from "styled-components";
import {
  BodyText,
  ButtonRect,
  FooterText,
  InvisibleInputImage,
  Section,
} from "../../GlobalStyles";
import { usePrivy } from "@privy-io/react-auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { theme } from "../../utils/theme";
import useAuth from "../../hooks/useAuth";
import { AvatarPlaceholder, NavBarTypes } from "../../constants/constants";
import CameraIcon from "../../assets/svg/camera.svg?react";
import { useState } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { handleProfileSubmit } from "../../utils/users";
import { useQueryClient } from "@tanstack/react-query";
import TopNav from "../../components/TopNav";

const { colors } = theme;

const EditProfileSection = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  padding: 0 16px;
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 0;
`;

const Label = styled.label`
  font-size: 14px;
  font-family: "Suisse Intl", sans-serif;
`;

const Input = styled.input`
  background: transparent;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.red : "rgba(255, 255, 255, 0.15)"};
  border-radius: 10px;
  color: white;
  width: 100%;
  font-size: 14px;
  padding: 16px;

  &:focus {
    outline: none;
  }
`;

const ErrorText = styled(BodyText)`
  color: ${({ theme }) => theme.colors.red};
  padding: 0 16px;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  align-self: center;
  position: relative;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
  object-position: center;
`;

const CameraIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background: rgba(15, 15, 27, 1);
  padding: 6px;
  border: 1px solid rgba(53, 53, 64, 1);
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
  flex: 1;
  text-align: center;
`;

const SubmitButton = styled(ButtonRect)`
  border-radius: 10px;
  padding: 13.5px 0;
  min-width: 0;
  width: 288px;
  background: rgba(255, 255, 255, 0.12);
  border: none;

  &:disabled {
    opacity: 0.6;
  }
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

/**
 * The EditProfile component renders the EditProfile section of the application.
 *
 * @return {React.JSX.Element} The JSX element representing the EditProfile section.
 */
export default function EditProfile() {
  const { user, ready } = usePrivy();
  const { register, handleSubmit, setError, formState, clearErrors } =
    useForm();
  const navigate = useNavigate();
  const { loggedInUser, updateCurrentUser } = useAuth();
  const [image, setImage] = useState();
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  const handleImageChange = async (e) => {
    const imageToBeUploaded = e.target.files[0];

    if (!imageToBeUploaded) return;

    setImage(imageToBeUploaded);
    clearErrors();
  };

  const onSubmit = async (data) => {
    setSaving(true);

    data.addedImage = image;

    const response = await handleProfileSubmit(data, loggedInUser);

    if (!response.success) {
      setError(response.error.name, {
        message: response.error.message,
      });
      setSaving(false);
      return;
    }

    delete data.image;
    delete data.addedImage;

    await updateCurrentUser(data);

    queryClient.removeQueries({
      queryKey: ["profile", loggedInUser.id.toString()],
    });

    setSaving(false);
    navigate(`/profile/${loggedInUser.id}`);
  };

  if (!ready && !user && !loggedInUser) return <LoadingScreen />;

  return (
    <EditProfileSection>
      <TopNav type={NavBarTypes.EDIT_PROFILE} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ProfileImageContainer>
          <ProfileImage
            src={
              image
                ? URL.createObjectURL(image)
                : loggedInUser?.avatar ?? AvatarPlaceholder
            }
            alt="profile-img"
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
          <CameraIconContainer>
            <CameraIcon height={20} width={20} stroke="white" />
          </CameraIconContainer>
          <InvisibleInputImage
            type="file"
            accept="image/png, image/jpeg"
            {...register("image")}
            onChange={handleImageChange}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </ProfileImageContainer>
        <InputContainer>
          <Label>Username</Label>
          <Input
            defaultValue={loggedInUser?.username}
            placeholder="Type in a username"
            {...register("username")}
            autoFocus
            error={formState.errors.username ? 1 : 0}
          />
          {formState.errors.username && (
            <ErrorText color="red">
              {formState.errors.username.message}
            </ErrorText>
          )}
        </InputContainer>
        <Footer>
          <SubmitButton
            type="submit"
            disabled={saving || formState.errors.username}
          >
            <BodyText weight="bold">
              {saving ? "Saving..." : "Confirm"}
            </BodyText>
          </SubmitButton>
          <FooterTextContainer>
            <FooterText color={colors.grey}>
              Accounts are set to public by default.
            </FooterText>
            <FooterText color={colors.grey}>
              You can change this in settings.
            </FooterText>
          </FooterTextContainer>
        </Footer>
      </Form>
    </EditProfileSection>
  );
}
