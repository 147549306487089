import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BookmarkIcon from "../../assets/svg/bookmark.svg?react";
import MapIcon from "../../assets/svg/map.svg?react";
import { BodyText, IconWithText, Title } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import EventCountdown from "../Events/EventCountdown";
import ShareButton from "../ShareButton";

const { colors } = theme;

const DivisionContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "column")};
  gap: ${({ gap }) => (gap ? gap : "0px")};
  align-items: ${({ align }) => (align ? align : "normal")};
  justify-content: ${({ justify }) => (justify ? justify : "normal")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  margin: ${({ margin }) => (margin ? margin : "0")};
`;

const ArtistName = styled(Title)`
  /* One line then ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export default function EventInfo({ event }) {
  return (
    <DivisionContainer gap="14px">
      {/* Main Artist and Event Name */}
      <DivisionContainer direction="row" justify="space-between">
        <DivisionContainer align="flex-start" gap="4px">
          <DivisionContainer
            direction="row"
            align="center"
            justify="space-between"
          >
            <ArtistName>{event?.headline_artist}</ArtistName>
          </DivisionContainer>
          {event && event.artist && event?.artists[0] != event.event_name && (
            <BodyText color={colors.offWhite}>{event?.event_name}</BodyText>
          )}
        </DivisionContainer>
        <ShareButton
          url={window.location.href}
          title={event?.event_name ?? event?.headline_artist}
          text={`Check out this event - ${event?.headline_artist} - ${
            event?.city
          } - ${moment(event.date).format("DD/MM/YY")}`}
        />
      </DivisionContainer>
      {/* Venue and Date */}
      <DivisionContainer gap="4px">
        <IconWithText
          as={event?.combined_venue_id ? Link : "div"}
          to={`/venues/${event?.combined_venue_id}/${event?.venue}`}
        >
          <MapIcon fill={colors.white} height={20} width={20} />
          <BodyText>{`${event?.venue}, ${event?.city}`}</BodyText>
        </IconWithText>
        <DivisionContainer
          direction="row"
          align="center"
          justify="space-between"
        >
          <IconWithText>
            <BookmarkIcon
              stroke={colors.white}
              fill={colors.white}
              height={16}
              width={16}
            />
            <BodyText color={colors.softGreen}>
              {moment(event.date).format("ddd D MMM")}
            </BodyText>
            <BodyText color={colors.grey}>
              {moment(event.date).format("LT")}
            </BodyText>
          </IconWithText>
          <DivisionContainer align="flex-end">
            <EventCountdown date={event.date} />
          </DivisionContainer>
        </DivisionContainer>
      </DivisionContainer>
    </DivisionContainer>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object,
};
