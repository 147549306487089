export const fetchCollectibles = async (userId, signal) => {
  if (!userId) return false;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/user/${userId}/collectibles`,
    {
      signal,
    }
  ).catch((err) => {
    if (err.code === 20) return false;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return [];
  }

  const collectibles = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectibles || !collectibles.success) return [];

  return collectibles?.data || [];
};

export const fetchSingleCollectible = async (userId, collectibleId, type = 'airdrop') => {
  if (!userId || !collectibleId) return null;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/user/${userId}/collectible/${collectibleId}/${type}`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const collectible = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectible || !collectible.success) return null;

  return collectible?.data || null;
};
