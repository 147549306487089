import styled from "styled-components";
import { SelectAllButton } from "../../components/CheckIn/ClaimMoments";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const Textbox = styled.textarea`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #ffffff1f;
  padding: 16px;
  margin: 24px 0;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-size: ${({ theme }) => theme.fontSizes.mobile.body};
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
`;

export const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Checkbox = styled(SelectAllButton)``;
