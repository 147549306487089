import { BottomNavBarHeight } from "../../../constants/constants";
import {
  SkeletonElement,
  SkeletonWrapper,
} from "../../Skeleton/Skeleton-Style";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  EventDetailsContainer,
  MomentInfoContainer,
  MomentInfoContent,
  MomentInfoText,
  UserAndButtonContainer,
} from "../MomentInfo/MomentInfo-Styles";

export const MomentSkeleton = ({ isOwner, isFYP }) => {
  return (
    <SkeletonWrapper
      style={{
        height: `calc(100svh - ${BottomNavBarHeight})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <SkeletonElement
        height="100svh"
        width="100%"
        style={{
          position: "fixed",
        }}
      />
      <MomentInfoContainer />
      <MomentInfoContainer>
        <MomentInfoContent>
          <MomentInfoText>
            <UserAndButtonContainer>
              <SkeletonElement height="25px" width="100px" radius="10px" />
            </UserAndButtonContainer>
            <EventDetailsContainer
              style={{
                gap: "12px",
                marginTop: "14px",
              }}
            >
              <SkeletonElement height="19px" width="150px" />
              <SkeletonElement height="16px" width="170px" />
              <SkeletonElement height="16px" width="80px" />
            </EventDetailsContainer>
          </MomentInfoText>
          <ButtonsContainer>
            <SkeletonElement height="32px" width="32px" radius="50%" />
            <SkeletonElement height="32px" width="32px" radius="50%" />
            <SkeletonElement height="32px" width="32px" radius="50%" />
            {isOwner && !isFYP && (
              <SkeletonElement height="32px" width="32px" radius="50%" />
            )}
          </ButtonsContainer>
        </MomentInfoContent>
      </MomentInfoContainer>
    </SkeletonWrapper>
  );
};

MomentSkeleton.propTypes = {
  isOwner: PropTypes.bool,
  isFYP: PropTypes.bool,
};
