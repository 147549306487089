import styled from "styled-components";
import { theme } from "../utils/theme";
import PropTypes from "prop-types";

const SpinnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.green};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const Line = styled.div`
  position: absolute;
  top: 0;
  left: ${({ stroke }) => `calc(50% - ${stroke}px / 2)`};
  display: flex;
  align-items: flex-start;
  height: 100%;
  width: ${({ stroke }) => `${stroke}px`};

  &::before {
    content: "";
    height: 22%;
    width: 100%;
    border-radius: ${({ stroke }) => `calc(${stroke}px / 2)`};
    background-color: ${({ theme }) => theme.colors.green};
    animation: pulse ${({ speed }) => speed}s ease-in-out infinite;
    transition: background-color 0.3s ease;
    transform-origin: center bottom;
  }

  &:nth-child(1) {
    transform: rotate(calc(360deg / -12 * 1));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 1)`};
    }
  }
  &:nth-child(2) {
    transform: rotate(calc(360deg / -12 * 2));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 2)`};
    }
  }
  &:nth-child(3) {
    transform: rotate(calc(360deg / -12 * 3));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 3)`};
    }
  }
  &:nth-child(4) {
    transform: rotate(calc(360deg / -12 * 4));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 4)`};
    }
  }
  &:nth-child(5) {
    transform: rotate(calc(360deg / -12 * 5));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 5)`};
    }
  }
  &:nth-child(6) {
    transform: rotate(calc(360deg / -12 * 6));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 6)`};
    }
  }
  &:nth-child(7) {
    transform: rotate(calc(360deg / -12 * 7));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 7)`};
    }
  }
  &:nth-child(8) {
    transform: rotate(calc(360deg / -12 * 8));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 8)`};
    }
  }
  &:nth-child(9) {
    transform: rotate(calc(360deg / -12 * 9));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 9)`};
    }
  }
  &:nth-child(10) {
    transform: rotate(calc(360deg / -12 * 10));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 10)`};
    }
  }
  &:nth-child(11) {
    transform: rotate(calc(360deg / -12 * 11));

    &::before {
      animation-delay: ${({ speed }) => `calc(${speed}s / -12 * 11)`};
    }
  }

  @keyframes pulse {
    0%,
    80%,
    100% {
      transform: scaleY(0.75);
      opacity: 0;
    }
    20% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
`;

export default function Spinner({ size = 40, stroke = 3, color = theme.colors.green, speed = 1 }) {
  return (
    <SpinnerContainer size={size} color={color}>
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
      <Line color={color} speed={speed} stroke={stroke} />
    </SpinnerContainer>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  stroke: PropTypes.number,
  color: PropTypes.string,
  speed: PropTypes.number,
};
