import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const RecentItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const RecentDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const RecentIconPlaceholder = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
  display: grid;
  place-content: center;
  border: 1px solid #ffffff26;
`;

export const RecentContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

export const RecentName = styled(BodyText)`
  /* Make Text two lines then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 1.2;
`;
