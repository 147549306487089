import PropTypes from "prop-types";
import { setImageofMoment } from "../../../utils/utils";
import {
  GlassmorphPlaceholder,
  MOMENT_TYPES,
} from "../../../constants/constants";
import {
  Border,
  Container,
  MomentImage,
  OnchainLogo,
  RetroLogo,
  UploadButton,
} from "./MomentThumbnailCard-Styles";
import useMomentPhotoUpload from "../../../hooks/useMomentPhotoUpload";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import MomentMediaDropdown from "../../Dropdown/MomentMediaDropdown";

/**
 * Renders a thumbnail card for a given moment.
 *
 * @param {Object} moment - the moment object
 * @param {string} type - the type of the card
 * @return {React.JSX.Element} the rendered thumbnail card component
 */
export default function MomentThumbnailCard({
  moment,
  type = "past",
  size,
  inModal,
}) {
  const {
    fan_photo_urls,
    transaction_reference,
    type: momentType,
    user_id,
  } = moment;
  const hasFanPhoto = fan_photo_urls?.length > 0;
  const momentAsset = hasFanPhoto
    ? fan_photo_urls[0]
    : setImageofMoment(moment);
  const isVideo = fan_photo_urls?.some((photo) => photo.includes("mp4"));

  const { setMoment } = useMomentPhotoUpload();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const isOwnProfile = user_id === loggedInUser?.id;

  const handleNavigate = () => {
    if (inModal) return;

    navigate(`moments/${moment?.moment_id}`);
  };
  const handleButtonClick = () => {
    setMoment(moment);
  };

  if (type === "active") {
    return (
      <Border type={type} size={size}>
        {isOwnProfile && !inModal && (
          <UploadButton onClick={handleButtonClick}>
            <MomentMediaDropdown
              isVideo={isVideo}
              type={hasFanPhoto ? "edit" : "add"}
            />
          </UploadButton>
        )}
        <Container onClick={handleNavigate}>
          {momentAsset && momentAsset.includes(".mp4") ? (
            <MomentImage
              as="video"
              src={momentAsset}
              muted
              loop
              autoPlay
              playsInline
              onError={(e) => (e.target.src = GlassmorphPlaceholder)}
              onLoadedMetadata={(e) => {
                e.target.pause();
              }}
              style={{
                pointerEvents: "none",
              }}
            />
          ) : (
            <MomentImage
              src={momentAsset}
              alt="Moment"
              onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            />
          )}
          {momentType === MOMENT_TYPES.RETRO ? (
            <RetroLogo />
          ) : momentType === MOMENT_TYPES.ONCHAIN || transaction_reference ? (
            <OnchainLogo />
          ) : (
            <></>
          )}
        </Container>
      </Border>
    );
  }

  return (
    <Border type={type}>
      <Container>
        {momentAsset && momentAsset.includes(".mp4") ? (
          <MomentImage
            as="video"
            src={momentAsset}
            muted
            loop
            autoPlay
            playsInline
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
            onLoadedMetadata={(e) => {
              e.target.pause();
            }}
            style={{
              pointerEvents: "none",
            }}
          />
        ) : (
          <MomentImage
            src={momentAsset}
            alt="Moment"
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          />
        )}
        {momentType === MOMENT_TYPES.RETRO ? (
          <RetroLogo />
        ) : momentType === MOMENT_TYPES.ONCHAIN || transaction_reference ? (
          <OnchainLogo />
        ) : (
          <></>
        )}
      </Container>
    </Border>
  );
}

MomentThumbnailCard.propTypes = {
  moment: PropTypes.object.isRequired,
  type: PropTypes.string,
  noImage: PropTypes.bool,
  size: PropTypes.string,
  inModal: PropTypes.bool,
};
