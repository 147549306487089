import { getBadgesByFestival } from "../../api/badges";
import { getFestivalEvents } from "../../api/festivals";
import festivals from "../../constants/festival-constants";

export default async function festivalLoader({ params }) {
	if (!params.id) return null;

	const { id } = params;

	try {
		const festival = festivals.find((festival) => festival.id == id);
	
		const events = await getFestivalEvents(id);
		
		festival.events = events;

		const badges = await getBadgesByFestival(id);

		festival.badges = badges;

		if (!festival) return null;
	
		return festival;
	}
	catch (error) {
		console.warn(error);
		return null;
	}

}