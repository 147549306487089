import styled from "styled-components";

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  text-align: center;
  align-items: center;
  padding: 16px 40px;
  padding-top: 25%;

  @media (max-height: 700px) {
    padding-top: 10%;
  }
`;
