import { create } from "zustand";

export const useBuyTicketStore = create((set) => ({
  isOpen: false,
  setIsOpen: (value) => set(() => ({ isOpen: value })),
  ticketsSelected: [],
  updateTicketSelected: (ticket, isRemove) => {
    set((state) => ({
      ticketsSelected: isRemove
        ? state.ticketsSelected.filter((t, index) => {
            const ticketIndex = state.ticketsSelected.findIndex(
              (item) => item.id === ticket.id
            );
            return index !== ticketIndex;
          })
        : [...state.ticketsSelected, ticket],
    }));
  },
  total: 0,
  handleTotal: (price, isSubtract) => {
    if (isSubtract) {
      set((state) => ({ total: state.total - price }));
    } else {
      set((state) => ({ total: state.total + price }));
    }
  },
  reset: () => {
    set({
      isOpen: false,
      ticketsSelected: [],
      total: 0,
    });
  },
}));
