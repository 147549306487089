import PropTypes from "prop-types";
import styled from "styled-components";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import Mic from "../../assets/svg/mic.svg?react";
import Map from "../../assets/svg/map.svg?react";
import Profile from "../../assets/svg/profile.svg?react";
import { BodyText } from "../../GlobalStyles";
import { useSearchParams } from "react-router-dom";

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0 20px;
  overflow-x: scroll;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  gap: 8px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  border-bottom: ${({ active }) => (active ? "2px solid white" : "none")};
`;

export default function SearchTabs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  const tabs = [
    {
      id: "event",
      icon: <Bookmark stroke="white" strokeWidth="1" height={14} />,
      label: "Events",
    },
    {
      id: "artist",
      icon: <Mic stroke="white" height={14} />,
      label: "Artists",
    },
    {
      id: "venue",
      icon: <Map fill="white" height={16} />,
      label: "Venues",
    },
    {
      id: "fan",
      icon: <Profile stroke="white" strokeWidth="1" height={14} />,
      label: "Fans",
    },
  ];

  const handleActiveTab = (tab) => {
    if (tab === activeTab) return;

    searchParams.set("tab", tab);
    searchParams.delete("past");
    searchParams.delete("year");
    searchParams.delete("month");
    searchParams.delete("country");
    searchParams.delete("city");
    searchParams.delete("locationPicker");
    setSearchParams(searchParams);
  };

  return (
    <TabsContainer>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          onClick={() => handleActiveTab(tab.id)}
          active={tab.id === activeTab}
        >
          {tab.icon}
          <BodyText>{tab.label}</BodyText>
        </Tab>
      ))}
    </TabsContainer>
  );
}

SearchTabs.propTypes = {
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func,
};
