import { getUserFollowers } from "../../api/profile";

export const followersLoader = async ({ params }) => {
  const userId = params.id;
  const loggedInUserId = localStorage.getItem("loggedInUserID");

  const followers = await getUserFollowers(userId, loggedInUserId);

  if (!followers.success) {
    return { error: followers.error };
  }

  return { followers: followers.data };
};
