import { BodyText, FooterText, HeaderL } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import MomentThumbnailCard from "../../Moments/MomentThumbnailCard";
import { format } from "date-fns";
import {
  Container,
  Footer,
  Header,
  MomentDescription,
  MomentDetails,
  TransactionLink,
} from "./YourMomentModal-Styles";

const { colors } = theme;

export default function YourMoment({ moment, txnHash }) {
  const {
    event_date,
    headline_artist,
    performance_artist,
    venue_name,
    fan_photo_urls,
    transaction_reference,
  } = moment;
  const transactionLink = `https://basescan.org/tx/${transaction_reference || txnHash}`;

  return (
    <Container>
      <Header>
        <HeaderL style={{ textTransform: "uppercase" }}>
          Your onchain Moment
        </HeaderL>
      </Header>
      <MomentDetails>
        <MomentThumbnailCard
          moment={moment}
          type="active"
          size="small"
          noImage={!fan_photo_urls || fan_photo_urls?.length === 0}
          inModal
        />
        <MomentDescription>
          <FooterText color={colors.grey}>
            {format(new Date(event_date), "EEE dd MMM")}
          </FooterText>
          <BodyText>{performance_artist ?? headline_artist}</BodyText>
          <FooterText color={colors.grey}>{venue_name}</FooterText>
        </MomentDescription>
      </MomentDetails>
      <Footer>
        <BodyText>
          <FooterText color={colors.grey}>
            This is a non-transferrable digital collectible,{" "}
          </FooterText>
          <TransactionLink to={transactionLink} target="_blank">
            <FooterText color={colors.green}>see transaction.</FooterText>
          </TransactionLink>
        </BodyText>
      </Footer>
    </Container>
  );
}

YourMoment.propTypes = {
  moment: PropTypes.object,
  txnHash: PropTypes.string,
};
