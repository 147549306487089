import styled from "styled-components";
import { HorizontalScrollableContainer } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  padding-left: 20px;
  margin-right: 20px;
`;

export const NavCirclesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const NavCircle = styled.div`
  height: 8px;
  width: 8px;
  background: ${({ filled, theme }) =>
    filled ? theme.colors.white : "transparent"};
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.6);
`;

export const ActiveMomentsCarousel = styled(HorizontalScrollableContainer)`
  padding-left: 20px;
  gap: 8px;
  /* Snap */
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
