import { useQuery } from "@tanstack/react-query";
import { getUserMomentByEvent } from "../../../api/moment";

export const useCheckMomentExists = ({ eventId, userId }) => {
  return useQuery({
    queryKey: ["check_moment_exists", eventId, userId],
    queryFn: async () => {
      if (userId) {
        const { data: moment } = await getUserMomentByEvent({
          eventId: eventId,
          userId: userId,
        });

        if (moment) {
          return true;
        }
      }

      return false;
    },
    enabled: !!eventId && !!userId,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};
