import styled from "styled-components";
import { ButtonRect } from "../../../GlobalStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  padding-top: 0;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const ConfirmButton = styled(ButtonRect)`
  background: ${({ theme }) => theme.colors.greyBtn};
  padding: 10px 36px;
  border: none;
  margin-top: 16px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 16px;
`;
