import styled from "styled-components";
import { BodyText, FooterText } from "../../GlobalStyles";
import PropTypes from "prop-types";

const InfoCapsuleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  gap: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.modalBg};
`;

const IconContainer = styled.div`
  display: flex;
  border-radius: 50%;
  padding: 8px 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export default function InfoCapsule({ icon, title, desc }) {
  return (
    <InfoCapsuleContainer>
      <IconContainer>{icon}</IconContainer>
      <InfoContainer>
        <BodyText weight="600">{title}</BodyText>
        <FooterText>{desc}</FooterText>
      </InfoContainer>
    </InfoCapsuleContainer>
  );
}

InfoCapsule.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  desc: PropTypes.string,
};
