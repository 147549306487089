import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  MONTHS,
  PAST_YEARS,
  SEARCH_PARAMS,
  YEARS,
} from "../../../constants/search";
import { BodyText, ButtonRectSm, FooterTextSm } from "../../../GlobalStyles";
import { useSearchParamsHook } from "../../../routes/Search/hooks/searchParams";
import { theme } from "../../../utils/theme";
import { useDatePickerStore } from "../SubmitMode/datePickerStore";
import {
  ArrowButton,
  Container,
  DateButton,
  Dates,
  Header,
  TypePicker,
} from "./DatePicker-Styles";

const { YEAR, MONTH } = SEARCH_PARAMS;

export default function DatePicker() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, month, isPast, isRetro } = useSearchParamsHook({
    searchParams,
  });
  const { isMonthPickerOpen, isYearPickerOpen, toggleDatePicker } =
    useDatePickerStore();

  const setDatePicked = (date) => {
    if (date <= 12) {
      searchParams.set(MONTH, date);
      searchParams.delete("quickSearch");
      setSearchParams(searchParams);
    } else {
      searchParams.set(YEAR, date);
      searchParams.delete("quickSearch");
      setSearchParams(searchParams);
    }

    toggleDatePicker();
  };

  // add pagination
  const pageSize = 12;
  const totalPages = Math.ceil(
    (isPast ? PAST_YEARS.length : YEARS.length) / pageSize
  );
  const [page, setPage] = useState(1);

  const handlePast = (isPast) => {
    searchParams.set(SEARCH_PARAMS.PAST, isPast ? true : false);
    setSearchParams(searchParams);
  };

  // Get if year and date picked are past
  const getIsPast = (index) => {
    const currentYear = year
      ? parseInt(year) === new Date().getFullYear()
      : new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    return currentYear && MONTHS.indexOf(MONTHS[currentMonth]) > index;
  };

  // Get if year and date picked are future
  const getIsFuture = (index) => {
    return year
      ? parseInt(year) >= new Date().getFullYear() &&
          MONTHS.indexOf(MONTHS[new Date().getMonth()]) < index
      : new Date().getFullYear() &&
          MONTHS.indexOf(MONTHS[new Date().getMonth()]) < index;
  };

  const FutureYears = useMemo(() => {
    return YEARS.slice((page - 1) * pageSize, page * pageSize).map(
      (date, index) => (
        <DateButton
          key={index}
          onClick={() => setDatePicked(date)}
          selected={year == date}
        >
          <BodyText>{date}</BodyText>
        </DateButton>
      )
    );
  }, [page, year, isPast]);

  const PastYears = useMemo(() => {
    return PAST_YEARS.slice((page - 1) * pageSize, page * pageSize).map(
      (date, index) => (
        <DateButton
          key={index}
          onClick={() => setDatePicked(date)}
          selected={year == date}
        >
          <BodyText>{date}</BodyText>
        </DateButton>
      )
    );
  }, [page, year, isPast]);

  const Months = useMemo(
    () =>
      MONTHS.slice((page - 1) * pageSize, page * pageSize).map(
        (date, index) => (
          <DateButton
            key={index}
            onClick={() => setDatePicked(index + 1)}
            selected={month == date}
            disabled={isPast || isRetro ? getIsFuture(index) : getIsPast(index)}
          >
            <BodyText>{date}</BodyText>
          </DateButton>
        )
      ),
    [page, month, isPast, isRetro]
  );

  return (
    <Container>
      {isYearPickerOpen && !isRetro && (
        <TypePicker>
          <ButtonRectSm
            onClick={() => handlePast(true)}
            background={
              isPast ? theme.colors.searchGrey : theme.colors.blackTransparent
            }
            style={{
              border: `1px solid ${isPast ? "transparent" : theme.colors.border}`,
            }}
          >
            <FooterTextSm weight="600">Past</FooterTextSm>
          </ButtonRectSm>
          <ButtonRectSm
            onClick={() => handlePast(false)}
            background={
              !isPast ? theme.colors.searchGrey : theme.colors.blackTransparent
            }
            style={{
              border: `1px solid ${!isPast ? "transparent" : theme.colors.border}`,
            }}
          >
            <FooterTextSm weight="600">Future</FooterTextSm>
          </ButtonRectSm>
        </TypePicker>
      )}
      <Header>
        {isYearPickerOpen && (
          <ArrowButton
            onClick={() => setPage(Math.max(1, page - 1))}
            disabled={page === 1}
          >
            <CaretLeft size={20} fill="white" />
          </ArrowButton>
        )}
        <BodyText weight="600">
          Choose {isYearPickerOpen ? "Year" : "Month"}
        </BodyText>
        {isYearPickerOpen && (
          <ArrowButton
            onClick={() => setPage(Math.min(totalPages, page + 1))}
            disabled={page === totalPages}
          >
            <CaretRight size={20} fill="white" />
          </ArrowButton>
        )}
      </Header>
      <Dates>
        {isYearPickerOpen && (isPast || isRetro)
          ? PastYears
          : isYearPickerOpen && (!isPast || !isRetro)
            ? FutureYears
            : isMonthPickerOpen
              ? Months
              : null}
      </Dates>
    </Container>
  );
}

DatePicker.propTypes = {
  setDatePicked: PropTypes.func,
  type: PropTypes.string,
};
