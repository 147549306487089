export const getBadgeQualifications = async (userId) => {
  try {
    let badgeQualifications = await fetch(
      `${import.meta.env.VITE_API_URL}/profile/${userId}/badges/check`
    );

    badgeQualifications = await badgeQualifications.json();

    return badgeQualifications;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllBadges = async () => {
  try {
    let badges = await fetch(`${import.meta.env.VITE_API_URL}/badges`);

    badges = await badges.json();

    return badges;
  } catch (err) {
    throw new Error(err);
  }
};

export const getBadgeById = async (badgeId) => {
  try {
    let badge = await fetch(
      `${import.meta.env.VITE_API_URL}/badges/${badgeId}`
    );

    badge = await badge.json();

    return badge;
  } catch (err) {
    throw new Error(err);
  }
};

export const getBadgesByUser = async (userId, signal) => {
  try {
    let badges = await fetch(
      `${import.meta.env.VITE_API_URL}/profile/${userId}/badges`,
      {
        signal,
      }
    );

    badges = await badges.json();

    return badges;
  } catch (err) {
    if (err.code === 20) return;
    console.error(err);
  }
};

export const getBadgesByFestival = async (festivalId) => {
  try {
    let badges = await fetch(
      `${import.meta.env.VITE_API_URL}/festivals/${festivalId}/badges`
    );

    badges = await badges.json();

    return badges;
  } catch (err) {
    throw new Error(err);
  }
};
