import { styled } from "styled-components";
import { Section } from "../../GlobalStyles";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import SettingsCategories from "../../components/Settings/SettingsCategory";
import { NavBarTypes } from "../../constants/constants";
import LoadingScreen from "../../components/LoadingScreen";
import AppVersion from "../../components/Settings/AppVersion";
import TopNav from "../../components/TopNav";

const SettingsSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
`;

export default function Settings() {
  const { user, ready } = usePrivy();
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (ready && !user && !loggedInUser) navigate("/login");
  }, [ready, user, loggedInUser, navigate]);

  if (!ready && !user && !loggedInUser) {
    return <LoadingScreen />;
  }

  return (
    <SettingsSection>
      <TopNav type={NavBarTypes.SETTINGS} />
      <SettingsCategories />
      <AppVersion />
    </SettingsSection>
  );
}
