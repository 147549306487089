import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme, bg }) => bg ?? theme.colors.blue};
  padding: 16px;
  border-radius: 8px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  cursor: pointer;
  width: 100%;
`;

export const Content = styled.div`
  max-height: ${({ isOpen, maxHeight }) =>
    isOpen ? maxHeight ?? "300px" : "0"};
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftPartItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
