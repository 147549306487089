import PropTypes from "prop-types";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { theme } from "../../../utils/theme";
import {
  Container,
  Content,
  Header,
  ModalButton,
} from "./SubscribeModal-Styles";
import BellRinging from "../../../assets/svg/bell-ringing.svg?react";
import { BodyText, FooterText, HeaderL } from "../../../GlobalStyles";

const { colors } = theme;

export default function SubscribeModal({ artist, handleSubscribeClick }) {
  const { toggleSheetModal } = useGlobalModal();

  const handleOnClick = async () => {
    toggleSheetModal();
    await handleSubscribeClick();
  };

  return (
    <Container>
      <Header>
        <BellRinging height={24} width={24} stroke={colors.green} />
        <HeaderL>SUBSCRIBE TO {artist?.artist_name?.toUpperCase()}</HeaderL>
      </Header>
      <Content>
        <BodyText weight="400" color={colors.grey}>
          Be updated and receive messages from the artist.
        </BodyText>
        <FooterText color={colors.grey}>
          I agree to share my contact details with {artist?.artist_name}, who
          may contact me.
        </FooterText>
        <ModalButton onClick={handleOnClick}>
          <BodyText weight="600">Confirm</BodyText>
        </ModalButton>
      </Content>
    </Container>
  );
}

SubscribeModal.propTypes = {
  artist: PropTypes.object,
  handleSubscribeClick: PropTypes.func,
};
