import styled from "styled-components";
import { DrukSpan, DrukWideSpan } from "../../../GlobalStyles";

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    rgba(180, 180, 180, 0.04),
    rgba(255, 255, 255, 0.04),
    rgba(175, 175, 175, 0.04)
  );
  backdrop-filter: blur(90px);
  -webkit-backdrop-filter: blur(90px);
  padding: 12px;
  scroll-snap-align: center;
  min-height: 480px;
  min-width: 328px;
  max-height: 480px;
  max-width: 328px;
  aspect-ratio: 1/1;
  border: 1.2px solid rgba(255, 255, 255, 0.15);
  border-radius: 32px;
  position: relative;
  overflow: hidden;
`;

export const MomentImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  height: 454px;
  width: 302px;
  border-radius: 24px;
  background: ${({ bg }) =>
    bg
      ? `linear-gradient(
      180deg,
      rgba(12, 13, 23, 0) 62.28%,
      rgba(12, 13, 23, 0.6) 100%
    ),
    linear-gradient(180deg, rgba(12, 13, 23, 0.5) 0%, rgba(12, 13, 23, 0) 25.8%), url(${bg})`
      : `linear-gradient(
    122.72deg,
    rgba(180, 180, 180, 0.2) 1.74%,
    rgba(180, 180, 180, 0.2) 1.74%,
    rgba(79, 79, 79, 0.2) 1.75%,
    rgba(255, 255, 255, 0.2) 33.05%,
    rgba(175, 175, 175, 0.2) 97.16%
  )`};

  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    122.72deg,
    rgba(180, 180, 180, 0.2) 1.74%,
    rgba(180, 180, 180, 0.2) 1.74%,
    rgba(79, 79, 79, 0.2) 1.75%,
    rgba(255, 255, 255, 0.2) 33.05%,
    rgba(175, 175, 175, 0.2) 97.16%
  );
`;

export const MomentContainer = styled.div`
  height: 454px;
  width: 302px;
  border-radius: 24px;
  backdrop-filter: ${({ blur }) => (blur ? "blur(10px)" : null)};
  -webkit-backdrop-filter: ${({ blur }) => (blur ? "blur(10px)" : null)};
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`;

export const CardName = styled(DrukWideSpan)`
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 22px;
  font-weight: 950;
  line-height: 1;
`;

export const MomentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MomentInfo = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
`;

export const MomentInfoLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MomentInfoRight = styled(MomentInfoLeft)`
  align-items: flex-end;
`;

export const Footer = styled(Header)`
  align-items: center;
  padding: 12px 16px;
  background: rgba(197, 143, 178, 0.04);
  backdrop-filter: ${({ blur }) => (blur ? "blur(10px)" : null)};
  -webkit-backdrop-filter: ${({ blur }) => (blur ? "blur(10px)" : null)};
  height: 68px;
  width: 100%;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

export const FooterInfo = styled(MomentInfoLeft)`
  gap: 4px;
`;

export const FooterInfoText = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FooterInfoRight = styled(MomentInfoRight)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const FooterIconsContainer = styled(MomentInfoLeft)`
  height: 40px;
  justify-content: space-between;
  gap: 8px;
`;

export const Avatar = styled.img`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  object-fit: cover;
`;

export const QuestionMark = styled(DrukSpan)`
  font-size: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
`;
