import PropTypes from "prop-types";
import styled from "styled-components";
import { useState } from "react";
import Joy from "../../assets/svg/joy.svg?react";
import Envelope from "../../assets/svg/mail-plus.svg?react";
import { FollowingSkeleton } from "../Following/FollowingSkeleton";
import ProfileItem from "../Profile/ProfileItem";
import EmptyState from "../EmptyState";
import { BodyText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import RectButtonIconText from "../RectButtonIconText";
import { handleInviteFriend } from "../../utils/utils";
import PartialEmptyState from "../PartialEmptyState";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
  padding-top: 0px;
  flex: 1;
`;

export default function FanFollowers({ fans, isLoading = true }) {
  const { id } = useParams();
  const { loggedInUser } = useAuth();
  const [inviteLink, setInviteLink] = useState(null);

  const isOwnProfile = loggedInUser?.id == id;

  if (isLoading) {
    return (
      <Container>
        {Array.from({ length: 12 }).map((_, index) => (
          <FollowingSkeleton key={index} />
        ))}
      </Container>
    );
  }
  if (!fans || fans.length === 0) {
    // Render the empty tab if there are no fans.
    return (
      <EmptyState
        icon={<Joy height={24} width={24} stroke="white" opacity={0.6} />}
        title="You got this!!"
        description={
          <BodyText color={theme.colors.grey}>
            Invite your friends to Momentify and earn{" "}
            <span style={{ color: theme.colors.green }}>XP.</span>
          </BodyText>
        }
        button={
          isOwnProfile && (
            <>
              <RectButtonIconText
                icon={
                  <Envelope
                    stroke={theme.colors.white}
                    height={14}
                    width={14}
                  />
                }
                text={<BodyText weight="600">Invite</BodyText>}
                onClick={() => {
                  handleInviteFriend(inviteLink, loggedInUser, setInviteLink);
                }}
              />
              <BodyText color={theme.colors.green}>+100xp</BodyText>
            </>
          )
        }
      />
    );
  }

  return (
    <Container>
      {fans.map((fan, index) => (
        <ProfileItem key={index} user={fan} />
      ))}
      {fans.length < 8 && (
        <PartialEmptyState
          icon={
            <Envelope
              stroke={theme.colors.white}
              height={24}
              width={24}
              opacity={0.6}
            />
          }
          description={
            <BodyText color={theme.colors.grey}>
              Invite more friends to earn{" "}
              <span style={{ color: theme.colors.green }}>XP.</span>
            </BodyText>
          }
          button={
            <>
              <RectButtonIconText
                icon={
                  <Envelope
                    stroke={theme.colors.white}
                    height={14}
                    width={14}
                  />
                }
                text={<BodyText weight="600">Invite</BodyText>}
                onClick={() => {
                  handleInviteFriend(inviteLink, loggedInUser, setInviteLink);
                }}
              />
              <BodyText color={theme.colors.green}>+100xp</BodyText>
            </>
          }
        />
      )}
    </Container>
  );
}

FanFollowers.propTypes = {
  fans: PropTypes.array,
  isLoading: PropTypes.bool,
};
