import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const ArtistEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SeeAllEvents = styled(BodyText)`
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
`;
