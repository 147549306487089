import styled from "styled-components";

export const PartialEmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 16px 40px 40px;
  width: 100%;

  @media (max-height: 700px) {
    padding-top: 20%;
  }
`;
