import PropTypes from "prop-types";
import CloseIcon from "../../../assets/svg/close.svg?react";
import { EPHEMERAL_TIPS } from "../../../constants/constants";
import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  Container,
  IconWithTextContainer,
  MomentTipContainer,
} from "./EphemeralTips-Styles";
import { useEphemeralStore } from "./hooks/ephemeralStore";

export default function EphemeralTips({ icon, message, type }) {
  const ephemeralTypes = useEphemeralStore();
  const beenShown = ephemeralTypes[type];

  const closeTip = () => {
    ephemeralTypes.setToClosed(type);
  };

  if (beenShown || !message) {
    return null;
  }

  if (type === EPHEMERAL_TIPS.MOMENT_TIP) {
    return (
      <MomentTipContainer>
        <IconWithTextContainer>
          <IconWithTextContainer
            style={{
              marginBottom: "1px",
              bottom: "1px",
              position: "relative",
            }}
          >
            {icon}
          </IconWithTextContainer>
          {typeof message === "string" ? (
            <FooterText color={theme.colors.grey}>{message}</FooterText>
          ) : (
            message
          )}
        </IconWithTextContainer>
        <IconWithTextContainer
          onClick={closeTip}
          style={{
            marginLeft: "8px",
          }}
        >
          <CloseIcon height={10} width={10} stroke={theme.colors.grey} />
        </IconWithTextContainer>
      </MomentTipContainer>
    );
  }

  return (
    <Container
      style={{
        marginBottom: type === EPHEMERAL_TIPS.HOME_TIP_PROFILE ? "16px" : "0px",
      }}
    >
      <IconWithTextContainer>
        <IconWithTextContainer
          style={{
            marginBottom: "1px",
            bottom: "1px",
            position: "relative",
          }}
        >
          {icon}
        </IconWithTextContainer>
        {typeof message === "string" ? (
          <FooterText color={theme.colors.grey}>{message}</FooterText>
        ) : (
          message
        )}
      </IconWithTextContainer>
      <IconWithTextContainer onClick={closeTip}>
        <CloseIcon height={10} width={10} stroke={theme.colors.grey} />
      </IconWithTextContainer>
    </Container>
  );
}

EphemeralTips.propTypes = {
  icon: PropTypes.element,
  message: PropTypes.any,
  type: PropTypes.string,
};
