import styled from "styled-components";
import FilterButton from "./FilterButton";
import Calendar from "../../assets/svg/calendar.svg?react";
import Pin from "../../assets/svg/pin-white.svg?react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { FooterText } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { useDatePickerStore } from "./SubmitMode/datePickerStore";
import { useSearchParamsHook } from "../../routes/Search/hooks/searchParams";
import { getShortMonthNameByNumber } from "../../utils/date-utils";

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-x: scroll;
  padding: 0 20px;
`;

export default function SearchFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    tab: activeTab,
    country,
    city,
    year,
    month,
    isLocationPickerOpen,
    isRetro,
  } = useSearchParamsHook({ searchParams });

  const {
    toggleYearPicker,
    toggleMonthPicker,
    isYearPickerOpen,
    isMonthPickerOpen,
  } = useDatePickerStore();

  const toggleLocationPicker = () => {
    searchParams.set("locationPicker", !isLocationPickerOpen);
    setSearchParams(searchParams);
  };

  const isFilterActive = country || city || year || month;

  const removeYear = () => {
    searchParams.delete("year");
    setSearchParams(searchParams);
  };

  const removeMonth = () => {
    searchParams.delete("month");
    setSearchParams(searchParams);
  };

  const removeLocation = () => {
    searchParams.delete("country");
    searchParams.delete("city");
    setSearchParams(searchParams);
  };

  const clearFilters = async () => {
    searchParams.delete("country");
    searchParams.delete("city");
    searchParams.delete("year");
    searchParams.delete("month");
    setSearchParams(searchParams);
  };

  return (
    <FiltersContainer>
      <FilterButton
        icon={<Pin stroke="white" height={12} width={12} />}
        name={city || country || "Location"}
        onClick={toggleLocationPicker}
        onRemove={(city || country) && removeLocation}
        active={!!city || !!country || isLocationPickerOpen}
      />
      {(activeTab === "event" || isRetro) && (
        <>
          <FilterButton
            icon={<Calendar stroke="white" height={12} width={12} />}
            name={year || "Year"}
            onClick={toggleYearPicker}
            onRemove={!!year && removeYear}
            active={!!year || isYearPickerOpen}
          />
          <FilterButton
            icon={<Calendar stroke="white" height={12} width={12} />}
            name={getShortMonthNameByNumber(month) || "Month"}
            onClick={toggleMonthPicker}
            onRemove={month && removeMonth}
            active={!!month || isMonthPickerOpen}
          />
        </>
      )}
      {isFilterActive && (
        <FooterText
          onClick={clearFilters}
          color={theme.colors.grey}
          style={{ cursor: "pointer", textWrap: "noWrap" }}
        >
          clear all
        </FooterText>
      )}
    </FiltersContainer>
  );
}

SearchFilters.propTypes = {
  isRetro: PropTypes.bool,
};
