import PropTypes from "prop-types";
import styled from "styled-components";
import { BodyText, ButtonRect, FooterText, HeaderXl } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { SkipButton } from "./GetLocationPage";
import useGeolocation from "../../hooks/useGeolocation";
import PinWhite from "../../assets/svg/pinW.svg?react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  background: transparent;
  position: relative;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(17, 17, 31, 1);
  background-blend-mode: multiply;
  background: linear-gradient(
    180deg,
    rgba(4, 15, 11, 0) 0%,
    rgba(4, 15, 11, 0.2736) 50.52%,
    rgba(4, 15, 11, 0.72) 84.37%
  );

  padding: 27.5px;
  justify-content: center;
  gap: 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 12px;
`;

export default function GetLocationComponent({ getLocation, gettingLocation }) {
  const { colors } = theme;
  const { skipLocation } = useGeolocation();
  const handleSkipGeo = () => skipLocation();
  return (
    <Container>
      <ContentContainer>
        <PinWhite
          height={24}
          width={24}
          stroke={theme.colors.gray}
          strokeWidth={0.8}
          style={{
            margin: "0 auto",
          }}
        />
        <HeaderXl
          style={{
            fontSize: 26,
          }}
        >
          DISCOVER YOUR EVENTS!
        </HeaderXl>
        <FooterText style={{ fontSize: 14, opacity: 0.6 }}>
          Add your location so you can discover and check in at Events near you
          and create memories!
        </FooterText>
      </ContentContainer>
      <ButtonRect background={theme.colors.green} onClick={getLocation}>
        <BodyText style={{ fontSize: 14 }} color="black" weight="600">
          {gettingLocation ? "Getting location..." : "Share my location"}
        </BodyText>
      </ButtonRect>
      <SkipButton
        style={{
          marginBottom: 200,
          marginTop: 0,
        }}
        onClick={handleSkipGeo}
      >
        <BodyText color={colors.grey}>Skip</BodyText>
      </SkipButton>
    </Container>
  );
}

GetLocationComponent.propTypes = {
  getLocation: PropTypes.func.isRequired,
  gettingLocation: PropTypes.bool.isRequired,
};
