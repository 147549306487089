import { useQuery } from "@tanstack/react-query";
import { followingLoader } from "../loader";
import useAuth from "../../../hooks/useAuth";
import { useUserStore } from "../../Profile/store/userStore";
import { getUserSubscriptions } from "../../../api/profile";

export const useFollowing = ({ userId, enabled = true }) => {
  const { loggedInUser } = useAuth();
  const { setUserFollowedArtists, setUserFollowedUsers, userFollowedArtists, userFollowedUsers } = useUserStore();

  return useQuery({
    queryKey: ["following", userId],
    queryFn: async () => {
      
      const follows = await followingLoader({ params: { id: userId } })
      

      if(userId == loggedInUser?.id && userFollowedArtists?.length == 0 && userFollowedUsers?.length == 0)
      {
        setUserFollowedArtists(follows.artists ?? [])
        setUserFollowedUsers(follows.fans ?? [])

      }

      return follows;
    
    },
    enabled: !!userId && enabled,
    refetchOnMount: true,
  });
};

export const useSubscription = ({ userId, enabled = true }) => {
  const { loggedInUser } = useAuth();
  const { setUserSubscriptions, userSubscriptions } = useUserStore();

  return useQuery({
    queryKey: ["subscription", userId],
    queryFn: async () => {
      
      const subscriptions = await getUserSubscriptions(userId)


      if(userId == loggedInUser?.id && userSubscriptions?.length == 0)
      {
        setUserSubscriptions(subscriptions?.data ?? [])
        
      }

      return subscriptions;
    
    },
    enabled: !!userId && enabled,
    refetchOnMount: true,
  });
};
