import PropTypes from "prop-types";
import { useState } from "react";
import BackButton from "../../BackButton";
import { MomentContainer, NavContainer, Overlay } from "../Moment-Styles";
import SetlistModal from "../../Modal/SetlistModal";
import MomentInfo from "../MomentInfo";
import MomentTopButtons from "../MomentTopButtons";
import MomentCardsView from "../MomentCardsView";
import MomentPhotos from "../MomentPhotos";

/**
 * React component for displaying a moment with various interactive features.
 *
 * @param {object} moment - the moment data to be displayed
 * @param {element} nav - the navigation element
 * @param {boolean} isMomentsFeed - indicates whether the moment is a "For You Page" moment
 * @return {React.JSX.Element} the rendered React component
 */
export default function MomentView({ moment, isMomentsFeed, isLikesFetched }) {
  const { event_date, performance_id, performance_artist, venue_name, type } =
    moment;
  const [isCard, setIsCard] = useState(false);
  const [isSetlistOpen, setIsSetlistOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(isMomentsFeed ? false : true);

  const handleIsCard = () => {
    setIsCard(!isCard);
  };

  const handleSetlist = () => {
    setIsSetlistOpen(!isSetlistOpen);
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <MomentContainer>
      {/* Nav */}
      {!isCard && !isSetlistOpen && (
        <NavContainer>
          {!isMomentsFeed && (
            <BackButton
              onClick={isSetlistOpen ? handleSetlist : null}
              type="ghost"
            />
          )}
          <MomentTopButtons
            handleSetlist={handleSetlist}
            handleIsCard={handleIsCard}
            isMomentsFeed={isMomentsFeed}
            momentType={type}
          />
        </NavContainer>
      )}
      {/* Moment Photos */}
      <MomentPhotos
        moment={moment}
        isCard={isCard}
        isSetlistOpen={isSetlistOpen}
        isMomentsFeed={isMomentsFeed}
        isMuted={isMuted}
      />
      {/* Moment Info */}
      {!isCard && !isSetlistOpen && (
        <MomentInfo
          moment={moment}
          isMomentsFeed={isMomentsFeed}
          isLikesFetched={isLikesFetched}
          isMuted={isMuted}
          handleMute={handleMute}
        />
      )}
      {/* Moment Cards */}
      {isCard && (
        <MomentCardsView
          moment={moment}
          handleIsCard={handleIsCard}
          isMomentsFeed={isMomentsFeed}
        />
      )}
      {/* Setlist Modal */}
      {isSetlistOpen && (
        <SetlistModal
          moment={moment}
          artistName={performance_artist}
          venueName={venue_name}
          date={event_date}
          performanceId={performance_id}
          isMomentsFeed={isMomentsFeed}
          handleClose={handleSetlist}
        />
      )}
      {!isMomentsFeed && <Overlay />}
    </MomentContainer>
  );
}

MomentView.propTypes = {
  moment: PropTypes.object.isRequired,
  selectedPhotoFromNav: PropTypes.object,
  nav: PropTypes.element,
  isMomentsFeed: PropTypes.bool,
  isLikesFetched: PropTypes.bool,
};
