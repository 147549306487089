import { Link } from "react-router-dom";
import { BodyText } from "../../../GlobalStyles";
import { VenuePlaceholder } from "../../../constants/constants";

import SubscribeButton from "../../Artist/SubscribeButton";
import { useUserStore } from "../../../routes/Profile/store/userStore";
import { useEffect, useState } from "react";
import { useSubscription } from "../../../utils/users";

export const ArtistCard = ({ artist }) => {
  const { artist_id, artist_name, artist_image } = artist;
  const { userSubscriptions, userFollowedArtists } = useUserStore();

  const [isFollowing, setIsFollowing] = useState(
    userFollowedArtists.some((art) => art?.id == artist_id) ?? false
  );

  const [isSubscribed, setIsSubscribed] = useState(
    userSubscriptions.some((art) => art?.id == artist_id) ?? false
  );

  const { handleSubscribeClick, showSubscribeButton } = useSubscription(
    artist,
    isFollowing,
    isSubscribed
  );

  useEffect(() => {
    setIsSubscribed(
      userSubscriptions.some((art) => art?.id == artist_id) ?? false
    );
  }, [artist, userSubscriptions, artist_id]);

  return (
    <Link
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        textOverflow: "ellipsis",
        alignItems: "center",
        maxWidth: 122,
        minWidth: 122,
        height: 133,
        minHeight: 133,
        maxHeight: 133,
      }}
      to={`/artists/${artist_id}`}
    >
      <img
        style={{
          minWidth: 64,
          minHeight: 64,
          maxWidth: 64,
          maxHeight: 64,
          borderRadius: "50%",
        }}
        src={artist_image ?? VenuePlaceholder}
      />

      <BodyText
        style={{
          fontWeight: 400,
          fontSize: 14,
          color: "rgba(247, 247, 247, 1)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
          textAlign: "center",
        }}
      >
        {artist_name}
      </BodyText>

      <SubscribeButton
        isSubscribed={isSubscribed}
        size="wide"
        handleClick={(e) => {
          e.preventDefault();
          if (isSubscribed) {
            handleSubscribeClick();
          } else {
            showSubscribeButton();
          }
        }}
      />
    </Link>
  );
};
