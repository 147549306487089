import { createContext, useState } from "react";
import { UserDispatchEvents } from "../constants/dispatchEvents";
import PropTypes from "prop-types";


export const UserContext = createContext();

const initialState = {
  loggedInUser: null,
  sessionId: null,
};

export default function UserProvider(params) {
  const { children } = params;
  const [context, setContext] = useState(initialState);

  const dispatch = (actionType, payload) => {
    switch (actionType) {
      case UserDispatchEvents.SET_LOGGED_IN_USER:
        {
          setContext((prev) => ({
            ...prev,
            loggedInUser: payload,
          }));
        }
        break;
      case UserDispatchEvents.SET_SESSION:
        {
          setContext((prev) => ({
            ...prev,
            sessionId: payload,
          }));
        }
        break;
    }
  };

  return (
    <UserContext.Provider value={{ context, dispatch }}>
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node,
};
