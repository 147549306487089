import PropTypes from "prop-types";
import { BodyText, HeaderL } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import BellRinging from "../../../assets/svg/bell-ringing.svg?react";
import {
  Container,
  Content,
  Header,
  ModalButton,
} from "./NotificationModal-Styles";

const { colors } = theme;

export default function NotificationModal({ askNotificationPermission }) {
  const { toggleSheetModal } = useGlobalModal();

  const handleOnClick = async () => {
    askNotificationPermission();
    toggleSheetModal();
  };

  return (
    <Container>
      <BellRinging height={24} width={24} stroke={colors.green} />
      <Header>
        <HeaderL style={{ textTransform: "uppercase" }}>
          Turn on Notifications
        </HeaderL>
      </Header>
      <Content>
        <BodyText color={colors.grey}>
          Turn on push notifications to receive alerts even when you are not
          using the app.
        </BodyText>
        <ModalButton onClick={handleOnClick}>
          <BodyText weight="600">Confirm</BodyText>
        </ModalButton>
      </Content>
    </Container>
  );
}

NotificationModal.propTypes = {
  askNotificationPermission: PropTypes.func,
};
