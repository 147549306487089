import styled from "styled-components";
import useGlobalModal from "../../hooks/useGlobalModal";
import { BottomNavBarHeight } from "../../constants/constants";

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: fixed;
  z-index: 9999;
  height: 100dvh;
  width: 100dvw;
`;

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - ${BottomNavBarHeight} / 2));
`;

export default function CenteredGlobalModal() {
  const { centerModalContent, toggleCenterModal } = useGlobalModal();

  return (
    <>
      <Backdrop onClick={toggleCenterModal} />
      <ModalContainer>{centerModalContent}</ModalContainer>
    </>
  );
}
