import PropTypes from "prop-types";
import {
  BACKGROUND_IMAGES,
  BottomNavBarHeight,
  GlassmorphPlaceholder,
  MOMENT_TYPES,
} from "../../../constants/constants";
import MomentCarousel from "../MomentCarousel";
import useAuth from "../../../hooks/useAuth";
import {
  MenuContainer,
  MomentPhoto,
  MomentVideo,
  MomentVideoContainer,
  RetroMomentContainer,
  RetroMomentPhoto,
} from "./MomentPhotos-Styles";
import MomentFanPhotoButtons from "../MomentFanPhotoButtons";
import { theme } from "../../../utils/theme";
import MomentAddMedia from "../MomentAddMedia";
import { setImageofMoment } from "../../../utils/utils";
import RetroIcon from "../../../assets/svg/retro-moment.svg?react";
import { useEffect, useRef } from "react";

const { RETRO } = MOMENT_TYPES;
const { colors } = theme;

export default function MomentPhotos({
  moment,
  isCard,
  isSetlistOpen,
  isMomentsFeed,
  isMuted,
}) {
  const { type, user_id, created_at, fan_photo_urls } = moment || {};
  const { loggedInUser } = useAuth();
  const isOwner = loggedInUser?.id === user_id;
  const isRetro = type === RETRO;
  const momentImage = setImageofMoment(moment);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
    };
  }, [moment]);

  return (
    <MomentCarousel
      moment={moment}
      isCard={isCard}
      isSetlistOpen={isSetlistOpen}
      isMomentsFeed={isMomentsFeed}
      isOwner={isOwner}
    >
      {/* Menu */}
      {!isMomentsFeed && isOwner && (
        <MenuContainer>
          <MomentFanPhotoButtons moment={moment} date={created_at} />
        </MenuContainer>
      )}
      {/* Adding of Photos */}
      {isOwner && !isMomentsFeed && !fan_photo_urls?.length && (
        <MomentAddMedia moment={moment} />
      )}
      {/* Video */}
      {fan_photo_urls?.length > 0 && fan_photo_urls[0].includes("mp4") ? (
        <MomentVideoContainer>
          <RetroMomentPhoto
            src={BACKGROUND_IMAGES.SEARCH.WEEK}
            style={{
              zIndex: -1,
            }}
          />
          <MomentVideo
            ref={videoRef}
            src={fan_photo_urls[0]}
            loop
            playsInline
            muted={isMuted}
            autoPlay={!isMomentsFeed ? true : false}
          />
        </MomentVideoContainer>
      ) : (
        // Photo
        fan_photo_urls?.length > 0 && (
          <MomentPhoto
            src={fan_photo_urls[0]}
            onError={(e) => (e.target.src = GlassmorphPlaceholder)}
          />
        )
      )}
      {/* Retro Moment View */}
      {!isOwner && !isMomentsFeed && !fan_photo_urls?.length && isRetro && (
        <RetroMomentContainer>
          <RetroIcon
            height={52}
            width={52}
            stroke={colors.white}
            style={{
              zIndex: 1,
              opacity: 0.5,
              marginBottom: BottomNavBarHeight,
            }}
          />
          <RetroMomentPhoto src={BACKGROUND_IMAGES.SEARCH.WEEK} />
        </RetroMomentContainer>
      )}
      {/* Moment Image without fan media */}
      {!isMomentsFeed && !isRetro && <MomentPhoto src={momentImage} />}
    </MomentCarousel>
  );
}

MomentPhotos.propTypes = {
  moment: PropTypes.object,
  isCard: PropTypes.bool,
  isSetlistOpen: PropTypes.bool,
  isMomentsFeed: PropTypes.bool,
  isMuted: PropTypes.bool,
};
