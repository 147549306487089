import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Logo from "../../assets/images/mom-logo-full.webp";
import useGlobalModal from "../../hooks/useGlobalModal";

import {
  ContentContainer,
  HeaderContainer,
  LoginButton,
  LoginSection,
  LogoImage,
  Navbar,
} from "./Login-Styles";
import { BodyText, HeaderXxxl } from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import { BetaIcon } from "../../components/BetaIcon";

export default function Login() {
  const { ready, authenticated, user, loggedInUser, login } =
    useMomentifyLogin();
  const navigate = useNavigate();
  const { openSheetPWAModal } = useGlobalModal();

  useEffect(() => {
    openSheetPWAModal();
  }, []);

  useEffect(() => {
    const redirectToProfile = async () => {
      if (authenticated && user && loggedInUser) {
        navigate(`/profile/${loggedInUser.id}`);
      }
    };

    redirectToProfile();
  }, [authenticated, user, loggedInUser, navigate]);

  const notFullyReady = !ready || (authenticated && !loggedInUser);

  if ((!authenticated && !user) || notFullyReady) {
    return (
      <LoginSection>
        <Navbar>
          <LogoImage src={Logo} />
          <BetaIcon />
        </Navbar>
        <ContentContainer>
          <HeaderContainer>
            <HeaderXxxl
              style={{ textTransform: "uppercase", lineHeight: "63.18px" }}
            >
              Make More
              <br />
              Music Memories
            </HeaderXxxl>
            <BodyText color={theme.colors.grey}>
              From finding an event, to capturing the Moment, we make going to
              live music even more fun!
            </BodyText>
          </HeaderContainer>
          <LoginButton onClick={() => login()} disabled={notFullyReady}>
            <BodyText weight="600" color={theme.colors.black}>
              Log in
            </BodyText>
          </LoginButton>
        </ContentContainer>
      </LoginSection>
    );
  }
}
