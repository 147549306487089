import PropTypes from "prop-types";
import Moments from "../../assets/svg/moments.svg?react";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import Tickets from "../../assets/svg/two-tickets.svg?react";
import Collectibles from "../../assets/svg/collectibles.svg?react";
import CommonTabs from "../CommonTabs";
import UserEvents from "./UserEvents";
import UserMoments from "./UserMoments";
import UserCollectibles from "./UserCollectibles";
import UserTickets from "./UserTickets";

export default function ProfileTabs({
  user = undefined,
  events,
  moments,
  collectibles,
  defaultActiveTab,
  isMomentsLoading,
  isEventsLoading,
  isCollectiblesLoading,
}) {
  const tabs = [
    {
      id: "moments",
      icon: <Moments height="16px" />,
      component: (
        <UserMoments
          user={user}
          moments={moments}
          isMomentsLoading={isMomentsLoading}
        />
      ),
    },
    {
      id: "events",
      icon: <Bookmark height="16px" strokeWidth={1.5} />,
      component: (
        <UserEvents
          user={user}
          events={events}
          isEventsLoading={isEventsLoading}
        />
      ),
    },
    {
      id: "tickets",
      icon: <Tickets height="16px" strokeWidth={1.5} />,
      component: <UserTickets />,
    },
    {
      id: "collectibles",
      icon: <Collectibles height="16px" strokeWidth={1} />,
      component: (
        <UserCollectibles
          collectibles={collectibles}
          isCollectiblesLoading={isCollectiblesLoading}
        />
      ),
    },
  ];

  return <CommonTabs tabs={tabs} defaultActiveTab={defaultActiveTab} />;
}

ProfileTabs.propTypes = {
  user: PropTypes.object,
  events: PropTypes.array,
  moments: PropTypes.object,
  collectibles: PropTypes.array,
  defaultActiveTab: PropTypes.string.isRequired,
  isMomentsLoading: PropTypes.bool,
  isEventsLoading: PropTypes.bool,
  isCollectiblesLoading: PropTypes.bool,
};
