import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SkeletonElement = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || "#ffffff14"};
  animation: ${shimmer} ${({ duration }) => duration || "1.5s"} infinite;
  border-radius: ${({ radius }) => radius || "4px"};
  height: ${({ height }) => height || "16px"};
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "0"};
`;
