import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonGhostL, Title } from "../../GlobalStyles";
import BellIcon from "../../assets/svg/bell.svg?react";
import MailIcon from "../../assets/svg/mail.svg?react";
import SettingsIcon from "../../assets/svg/settings.svg?react";
import { AvatarPlaceholder } from "../../constants/constants";
import useAuth from "../../hooks/useAuth";
import { theme } from "../../utils/theme";
import BackButton from "../BackButton";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import {
  Container,
  GreenDot,
  ProfileImage,
  ProfileLeftHeader,
  ProfileRightHeader,
} from "./Styles/ProfileHeader-Styles";

const { colors } = theme;

export default function ProfileHeader({
  user,
  hasNotification,
  hasMessages,
  isStatsLoading,
  isMessagesLoading,
  isNotificationLoading,
}) {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { id: userId } = useParams();

  // Check if the profile is of the logged in user
  const isOwnProfile = loggedInUser?.id == userId;

  // Get the username for the profile
  const profileName = user?.username || "User";

  return (
    <Container>
      <ProfileLeftHeader>
        {!isOwnProfile && <BackButton />}
        {isStatsLoading ? (
          <>
            <SkeletonElement
              width="40px"
              height="40px"
              radius="50%"
              style={{
                minWidth: "40px",
                minHeight: "40px",
                marginRight: "12px",
              }}
            />
            <SkeletonElement width="80px" height="22px" />
          </>
        ) : (
          <>
            <ProfileImage
              src={user?.avatar ?? AvatarPlaceholder}
              onError={(e) => (e.target.src = AvatarPlaceholder)}
              style={{ marginRight: "12px" }}
            />
            <Title>{profileName}</Title>
          </>
        )}
      </ProfileLeftHeader>
      <ProfileRightHeader>
        {isOwnProfile &&
          (isMessagesLoading ? (
            <>
              <SkeletonElement
                width="22px"
                height="22px"
                radius="50%"
                margin="4px"
              />
            </>
          ) : (
            <ButtonGhostL onClick={() => navigate(`/profile/messages`)}>
              <MailIcon stroke={colors.white} height={16} width={16} />
              {hasMessages && <GreenDot />}
            </ButtonGhostL>
          ))}
        {isOwnProfile &&
          (isNotificationLoading ? (
            <>
              <SkeletonElement
                width="22px"
                height="22px"
                radius="50%"
                margin="4px"
              />
            </>
          ) : (
            <ButtonGhostL onClick={() => navigate(`/profile/notifications`)}>
              <BellIcon stroke={colors.white} height={16} width={16} />
              {hasNotification && <GreenDot />}
            </ButtonGhostL>
          ))}
        {isOwnProfile &&
          (isNotificationLoading && isMessagesLoading ? (
            <SkeletonElement
              width="22px"
              height="22px"
              radius="50%"
              margin="4px"
            />
          ) : (
            <ButtonGhostL onClick={() => navigate("/profile/settings")}>
              <SettingsIcon height={16} width={16} stroke={colors.white} />
            </ButtonGhostL>
          ))}
      </ProfileRightHeader>
    </Container>
  );
}

ProfileHeader.propTypes = {
  user: PropTypes.object,
  hasNotification: PropTypes.bool,
  hasMessages: PropTypes.bool,
  isStatsLoading: PropTypes.bool,
  isNotificationLoading: PropTypes.bool,
  isMessagesLoading: PropTypes.bool,
};
