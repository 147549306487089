import styled from "styled-components";
import { BodyText } from "../../../GlobalStyles";

export const ArtistEventContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;

export const ArtistEventDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const OneLineText = styled(BodyText)`
  /* 1 line only then put ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArtistEventDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.blueGradientBg};
  min-width: 58px;
  min-height: 58px;
  max-width: 58px;
  max-height: 58px;
`;

export const ArtistEventDetais = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArtistEventVenue = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const ArtistEventButtons = styled.div`
  display: flex;
  gap: 25.5px;
`;
