import { useContext } from "react";
import { LocationContext } from "../contexts/LocationContext";
import { LocationDispatchEvents } from "../constants/dispatchEvents";
import { LocationPermission, PermissionState } from "../enums/permission";
import useGlobalModal from "./useGlobalModal";
import DeniedGeo from "../components/Modal/DeniedGeo";

function useGeolocation() {
  const { context, dispatch } = useContext(LocationContext);
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const {
    currentLocation,
    currentCountry,
    currentCity,
    isGeolocationPermitted,
    isLocationSkipped,
    permissionState,
    isLoading,
    error,
  } = context;

  const options = {
    enableHighAccuracy: true,
  };

  const getCurrentPosition = async () => {
    dispatch(LocationDispatchEvents.TOGGLE_LOADING);

    if (!navigator.geolocation) {
      console.warn("Geolocation is not supported by this browser.");
      dispatch(LocationDispatchEvents.NOT_SUPPORTED);
      dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(LocationDispatchEvents.SET_LOCATION, position);
        dispatch(LocationPermission, PermissionState.GRANTED);
        localStorage.setItem(LocationPermission, PermissionState.GRANTED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      },
      errorHandler,
      options
    );
  };

  const handleLocationPermission = async () => {
    dispatch(LocationDispatchEvents.TOGGLE_LOADING);

    let locationPermission = localStorage.getItem(LocationPermission);

    if (navigator.permissions && navigator.permissions.query) {
      dispatch(LocationDispatchEvents.HAS_PERMISSION);

      const result = await navigator.permissions.query({
        name: "geolocation",
      });

      if (!navigator.geolocation) {
        console.warn("Geolocation is not supported by this browser.");
        dispatch(LocationDispatchEvents.NOT_SUPPORTED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
        return;
      }

      // If the permission is granted, set the state to granted
      if (
        result.state == PermissionState.GRANTED ||
        locationPermission == PermissionState.GRANTED
      ) {
        await getCurrentPosition();
        dispatch(
          LocationDispatchEvents.SET_PERMISSION_STATE,
          PermissionState.GRANTED
        );
        localStorage.setItem(LocationPermission, PermissionState.GRANTED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is prompt, set the state to prompt
      else if (
        (result.state == PermissionState.PROMPT &&
          locationPermission == PermissionState.PROMPT) ||
        !locationPermission
      ) {
        dispatch(
          LocationDispatchEvents.SET_PERMISSION_STATE,
          PermissionState.PROMPT
        );
        localStorage.setItem(LocationPermission, PermissionState.PROMPT);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is denied, set the state to denied
      else if (
        result.state == PermissionState.DENIED ||
        locationPermission == PermissionState.DENIED
      ) {
        dispatch(LocationDispatchEvents.DENIED);
        localStorage.setItem(LocationPermission, PermissionState.DENIED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      }
      // If the permission is denied, set the state to denied
      else if (locationPermission == PermissionState.SKIPPED) {
        dispatch(LocationDispatchEvents.SKIP_LOCATION);
        localStorage.setItem(LocationPermission, PermissionState.SKIPPED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      }
    } else if (navigator.geolocation) {
      dispatch(LocationDispatchEvents.HAS_PERMISSION);

      if (locationPermission == PermissionState.GRANTED) {
        await getCurrentPosition();
        dispatch(
          LocationDispatchEvents.SET_PERMISSION_STATE,
          PermissionState.GRANTED
        );
        localStorage.setItem(LocationPermission, PermissionState.GRANTED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      } else if (
        locationPermission == PermissionState.PROMPT ||
        locationPermission == PermissionState.DENIED ||
        !locationPermission
      ) {
        dispatch(
          LocationDispatchEvents.SET_PERMISSION_STATE,
          PermissionState.PROMPT
        );
        localStorage.setItem(LocationPermission, PermissionState.PROMPT);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      } else if (locationPermission == PermissionState.SKIPPED) {
        dispatch(LocationDispatchEvents.SKIP_LOCATION);
        localStorage.setItem(LocationPermission, PermissionState.SKIPPED);
        dispatch(LocationDispatchEvents.TOGGLE_LOADING);
      }
    }
  };

  const setCountry = (country) => {
    dispatch(LocationDispatchEvents.SET_COUNTRY, country);
  };

  const setCity = (city) => {
    dispatch(LocationDispatchEvents.SET_CITY, city);
  };

  const errorHandler = (err) => {
    console.error(`ERROR(${err.code}): ${err.message}`);

    dispatch(LocationDispatchEvents.SET_ERROR_MESSAGE, err.message);

    // If the permission is denied, set the state to denied
    if (err.code == 1) {
      localStorage.setItem(LocationPermission, PermissionState.DENIED);
      dispatch(LocationDispatchEvents.DENIED);
      setSheetModalContent(<DeniedGeo />);
      toggleSheetModal();
    }
    // If the position is unavailable, set the error to unavailable
    else if (err.code == 2) {
      dispatch(
        LocationDispatchEvents.SET_ERROR_MESSAGE,
        "Position is unavailable"
      );
    }
    // If the timeout expired, set the error to timeout
    else if (err.code == 3) {
      dispatch(LocationDispatchEvents.SET_ERROR_MESSAGE, "Timeout expired");
    }
  };

  const resetLocation = () => {
    dispatch(LocationDispatchEvents.RESET_LOCATION);
  };

  const skipLocation = () => {
    dispatch(
      LocationDispatchEvents.SET_PERMISSION_STATE,
      PermissionState.SKIPPED
    );
    localStorage.setItem(LocationPermission, PermissionState.SKIPPED);
  };

  return {
    currentLocation,
    currentCountry,
    currentCity,
    isGeolocationPermitted,
    isLocationSkipped,
    permissionState,
    isLoading,
    error,
    options,
    handleLocationPermission,
    getCurrentPosition,
    setCountry,
    setCity,
    resetLocation,
    skipLocation,
    errorHandler,
  };
}

export default useGeolocation;
