import PropTypes from "prop-types";
import { FooterText } from "../../GlobalStyles";
import TopNav from "../TopNav";
import EventDetails from "./EventDetails";
import {
  Section,
  Container,
  TicketsContainer,
  TicketsHeader,
} from "./BuyTicket-Styles";
import { theme } from "../../utils/theme";
import Ticket from "./Ticket";
import Total from "./Total";
import { useBuyTicketStore } from "./buyTicketStore";
import { tickets } from "../../constants/ticketing-placeholders";
import { useCheckoutStore } from "../Checkout/checkoutStore";

const { colors } = theme;

export default function BuyTicket({ event, contract }) {
  const { per_account_max } = contract || {};
  const { ticketsSelected, reset: resetBuyTicketStore } = useBuyTicketStore();
  const { reset: resetCheckoutStore } = useCheckoutStore();
  const isMaxQuantity = ticketsSelected.length >= per_account_max;

  const handleBack = () => {
    resetBuyTicketStore();
    resetCheckoutStore();
  };

  return (
    <Section>
      <TopNav type="Buy ticket" onBack={handleBack} />
      <Container>
        <EventDetails event={event} />
        <TicketsContainer style={{ gap: "12px" }}>
          <TicketsHeader>
            <FooterText>Max of {per_account_max} per account</FooterText>
            <FooterText color={isMaxQuantity ? colors.green : colors.grey}>
              {ticketsSelected.length}/{per_account_max} selected
            </FooterText>
          </TicketsHeader>
          {tickets?.length > 0 && (
            <TicketsContainer style={{ gap: "16px" }}>
              {tickets.map((ticket) => (
                <Ticket
                  key={ticket.id}
                  ticket={ticket}
                  isMaxQuantity={isMaxQuantity}
                />
              ))}
            </TicketsContainer>
          )}
        </TicketsContainer>
      </Container>
      <Total />
    </Section>
  );
}

BuyTicket.propTypes = {
  event: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
};
