import styled from "styled-components";
import { HorizontalScrollableContainer } from "../../../GlobalStyles";

export const ScrollableContainer = styled(HorizontalScrollableContainer)`
  gap: 25px;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  padding-right: 0;
`;

export const MomentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 6px;
  max-width: 100px;
`;
