import { useQuery } from "@tanstack/react-query";
import { getXpHistory } from "../../api/profile";

export const useXpHistoryQuery = ({ userId, limit }) => {
  return useQuery({
    queryKey: ["xp-history", userId],
    queryFn: () => {
      return getXpHistory(userId, limit);
    },
  });
};
