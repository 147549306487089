import _moment from "moment";
import { isProd } from "./utils";

export const momentNftAttributesGenerator = (user, moment) => {
  if (!user || !moment) {
    return false;
  }

  const isPublic = !user?.private;
  const hasImage = moment.fan_photo_urls ? !!moment.fan_photo_urls[0] : false;

  const attributes = [];
  if (isPublic && hasImage) {
    attributesPusher(attributes);
  } else if (!isPublic && hasImage) {
    attributesPusher(attributes);
  } else if (isPublic && !hasImage) {
    attributesPusher(attributes);
  } else if (!isPublic && !hasImage) {
    attributesPusher(attributes);
  }

  return attributesMapper(attributes, moment);
};

const attributesMapper = (attributes = [], moment) => {
  const momentLink = isProd
    ? `https://app.momentify.xyz/profile/${moment.user_id}/moments/${moment.moment_id}`
    : `https://staging-app.momentify.xyz/profile/${moment.user_id}/moments/${moment.moment_id}`;

  return attributes.map((att) => {
    switch (att.trait_type) {
      case "Artist Name":
        att.value = moment.performance_artist;
        break;
      case "Venue Name":
        att.value = moment.venue_name;
        break;
      case "Date":
        att.value = moment.event_date
          ? _moment(new Date(moment.event_date)).utc().format("Do MMM yyyy")
          : "";
        break;
      case "Check in order":
        att.value = moment.official_checkin_number;
        break;
      case "Event start time":
        att.value = moment.event_date
          ? _moment(new Date(moment.event_date)).utc().format("HH:mm")
          : "";
        break;
      case "User id":
        att.value = moment.user_id;
        break;
      case "Fan image":
        att.value =
          !moment.fan_photo_urls || moment?.fan_photo_urls?.length == 0
            ? false
            : true;
        break;
      case "Link to moment":
        att.value = moment.id ? momentLink : "";
        break;

      default:
        break;
    }

    return att;
  });
};

const attributesPusher = (attributes = []) => {
  attributes.push({
    trait_type: "Artist Name",
    value: "",
  });
  attributes.push({
    trait_type: "Venue Name",
    value: "",
  });
  attributes.push({
    trait_type: "Date",
    value: "",
  });
  attributes.push({
    trait_type: "Check in order",
    value: "",
  });
  attributes.push({
    trait_type: "Event start time",
    value: "",
  });
  attributes.push({
    trait_type: "User id",
    value: "",
  });
  attributes.push({
    trait_type: "Fan image",
    value: "",
  });
  attributes.push({
    trait_type: "Link to moment",
    value: "",
  });

  return attributes;
};
