import styled from "styled-components";
import { BottomNavBarHeight } from "../../constants/constants";

export const ScrollableContainer = styled.div`
  height: calc(100svh - ${BottomNavBarHeight});
  width: 100dvw;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  -webkit-overflow-scrolling: auto !important;
  scroll-behavior: smooth;
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 20px 0;
  z-index: 1;
  width: 100%;
`;

export const MomentContainer = styled.div`
  height: calc(100svh - ${BottomNavBarHeight});
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.6) 100%
  );
  pointer-events: none;
`;
